import moment from "moment-timezone";

export function generateColumns(
  recurrence: boolean,
  timezone: string,
  offset = 0,
  max = 7,
): { label: string; value: any }[] {
  if (recurrence)
    return moment.weekdays(true).map((label, value) => ({
      label: label.slice(0, 3),
      value,
    }));

  return new Array(max)
    .fill(null)
    .map((_, i) => moment().tz(timezone).add(i + offset * max, "days"))
    .map((day) => ({
      label: day.format("DD MMM · ddd"),
      value: day.startOf("day").toISOString(),
    }));
}
