import axios, { AxiosInstance } from "axios";

import { ServiceResponse } from "..";

import { States, StatesInitials } from "../../../@constants/locations/states";

interface ILocationQueryParams {
  state: StatesInitials | string;
  q: string;
}

export class LocationsService {
  constructor(private api: AxiosInstance) {}

  async getCitiesByState(state: States | string): Promise<ServiceResponse> {
    let result = { data: null, error: null };

    try {
      const { data } = await this.api.get(`/locations/cities/${state}`);

      result.data = data || [];
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result.error = error.response?.data;
      } else {
        result.error = error as any;
      }
    } finally {
      return result;
    }
  }

  async list(params?: Partial<ILocationQueryParams>): Promise<ServiceResponse> {
    let result = { data: null, error: null };

    try {
      const { data } = await this.api.get(`/locations`, { params });

      result.data = data || [];
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result.error = error.response?.data;
      } else {
        result.error = error as any;
      }
    } finally {
      return result;
    }
  }
}
