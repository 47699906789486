import { Flex, Heading, Text } from "@chakra-ui/react";

import { ILesson } from "@isurf-tech/types/domain";

import MapPinIcon from "../../assets/icons/map-pin";

export default function CardLocation({ lesson }: { lesson: ILesson }) {
  return (
    <Flex px="32px" py="16px">
      <Flex>
        <MapPinIcon />
      </Flex>
      <Flex direction="column" ml={3}>
        <Heading fontWeight="semibold" fontSize="sm" color="neutral.darkest">
          Ponto de encontro
        </Heading>

        <Text fontSize="sm" color="neutral.darkest">
          {`${lesson?.location?.address ?? ""} · ${
            lesson?.location?.city ?? ""
          }/${lesson?.location?.state ?? ""}`}
        </Text>
        <Text fontSize="xs" color="neutral.dark">
          {lesson?.locationDetails ?? ""}
        </Text>
      </Flex>
    </Flex>
  );
}
