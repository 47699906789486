export const routesName = {
  main: "/",
  lessons: "/lessons",
  lessonById: "/lessons/:id",
  newLessonPreview: "new-lesson",
  registerSchools: "/register/school",
  registerStudents: "/register/student",
  schoolsPerfil: "/schools/perfil",
  studentsPerfil: "/students/perfil",
  schools: "/schools",
  students: "/students",
  schoolsDashboard: "/schools/dashboard",
  schoolsAnalyse: "/schools/analyse",
  schoolsCreateClass: "/schools/lessons/create",
  schoolsClassroomById: "/schools/classroom/:id",
  paymentCheckout: "/payment/checkout",
  search: "/search",
  studentsDashboard: "/students/dashboard",
  notFound: "/not-found",
  messages: "/messages",
  recoveryPassword: "/recovery-password",
  external: {
    help: "https://blog.isurf.app/how-to-use",
  },
};

export default routesName;
