export default function FacebookIcon({ size = 24, color = "black" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9997 3.66699H14.4997C13.3946 3.66699 12.3348 4.10598 11.5534 4.88738C10.772 5.66878 10.333 6.72859 10.333 7.83366V10.3337H7.83301V13.667H10.333V20.3337H13.6663V13.667H16.1663L16.9997 10.3337H13.6663V7.83366C13.6663 7.61265 13.7541 7.40068 13.9104 7.2444C14.0667 7.08812 14.2787 7.00033 14.4997 7.00033H16.9997V3.66699Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
