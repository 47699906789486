import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Grid, GridItem } from "@chakra-ui/react";

import { ILesson } from "@isurf-tech/types/domain";

import { Schedules as SchedulesType } from "../../@types/lessons";
import ArrowLeftIcon from "../../assets/icons/arrow-left";
import AlbumPhotos from "../../components/albums/album";
import Footer from "../../components/footer";
import SimpleHeader from "../../components/headers/simple";
import Schedules from "../../components/schedules";
import routesName from "../../config/routes";
import useCheckout from "../../hooks/checkout";
import { useResposiveness } from "../../hooks/responsiveness";
import { lessonService } from "../../services/api";
import InformationsLesson from "./informations";
import LessonName from "./name";

interface StatePropsI {
  preview: boolean;
  lesson: ILesson;
}

export default function LessonPage() {
  const { isMobile } = useResposiveness();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation<StatePropsI>();
  const [lesson, setLesson] = useState<ILesson>({} as ILesson);
  const [loading, setLoading] = useState(true);

  const { handleSelect, schedulesSelect, goToCheckout } = useCheckout();

  useEffect(() => {
    (async () => {
      const lessonId = params.id as string;

      if (
        location.state?.lesson &&
        (location.state.lesson.id === lessonId ||
          lessonId === routesName.newLessonPreview)
      ) {
        setLoading(false);
        setLesson(location.state.lesson);
      }

      if (location?.state?.preview || location?.state?.lesson?.schedules?.length)
        return;

      const { data } = await lessonService.findOne(lessonId);

      if (!data || !data.id) return navigate(routesName.notFound);

      setLoading(false);

      return setLesson(data);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLoading]);

  return (
    <Grid
      w="100vw"
      templateAreas={
        isMobile
          ? `
            "header"
            "media"
            "name"
            "schedules"
            "about"
            "footer"
          `
          : `
        "header header"
        "media media"
        "name schedules"
        "about schedules"
        "footer footer"
      `
      }
      gap={8}
      gridTemplateColumns="repeat(12, 1fr)"
    >
      <GridItem area={"header"} colSpan={12}>
        <SimpleHeader
          title="Detalhe da aula"
          backButton
          backButtonIcon={<ArrowLeftIcon />}
          onBack={() => navigate(-1)}
        />
      </GridItem>

      {lesson?.photos?.length > 0 && (
        <GridItem
          area={"media"}
          colSpan={12}
          px={isMobile ? "" : "80px"}
          mt={4}
          mb={isMobile ? 2 : 10}
        >
          <AlbumPhotos photos={lesson.photos} />
        </GridItem>
      )}

      <GridItem
        area={"name"}
        colSpan={isMobile ? 12 : 6}
        pl={isMobile ? "16px" : "80px"}
        pr={isMobile ? "16px" : "0px"}
      >
        <LessonName lesson={lesson} loading={loading} />
      </GridItem>

      <GridItem
        area={"about"}
        colSpan={isMobile ? 12 : 6}
        pl={isMobile ? "16px" : "80px"}
        pr={isMobile ? "16px" : "0px"}
      >
        <InformationsLesson lesson={lesson} />
      </GridItem>

      <GridItem
        area={"schedules"}
        colSpan={isMobile ? 12 : 6}
        pl={isMobile ? "0px" : "80px"}
        pr={isMobile ? "0px" : "80px"}
        display="flex"
        justifyContent={isMobile ? "center" : "flex-end"}
      >
        <Schedules
          data={lesson?.schedules as unknown as SchedulesType}
          price={lesson.price}
          selecteds={schedulesSelect}
          onChange={(schedules) => handleSelect(lesson, schedules)}
          onClick={() => goToCheckout(lesson)}
        />
      </GridItem>

      <GridItem area={"footer"} colSpan={12}>
        <Footer />
      </GridItem>
    </Grid>
  );
}
