import moment from "moment-timezone";
import React from "react";
import { useFormContext } from "react-hook-form";
import * as yup from "yup";

import { Box, GridItem, Heading } from "@chakra-ui/react";

import CardTime from "../../../../components/cards/time";
import DatePicker from "../../../../components/inputs/date-picker";
import RadioGroup from "../../../../components/radio";
import { useResposiveness } from "../../../../hooks/responsiveness";
import CreateClassCalendar from "./components/calendar";
import DurationOptions from "./components/duration-options";


const DURATION_MIN = 20;

export const formSchema = {
  adStartDate: yup.string().required("Campo obrigatório"),
  adEndDate: yup.string().required("Campo obrigatório"),
  duration: yup
    .number()
    .min(
      DURATION_MIN,
      `Duração deve ser maior que ${DURATION_MIN} ou selecione uma opção`
    )
    .required("Campo obrigatório"),
  recurrence: yup.boolean().nullable().default(true),
  schedules: yup.object(),
};

export default function StepAppointment() {
  const { isMobile } = useResposiveness();
  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const adStartDate = watch("adStartDate", moment().startOf("day").toDate());
  const adEndDate = watch("adEndDate", moment().startOf("day").toDate());
  const recurrence = watch("recurrence", false);
  const duration = watch("duration", 60);
  const schedules = watch("schedules", {});

  const handleRecurrence = (value: any) => {
    if (value === "RECURRENCE") return true;
    if (value === "UNIQUE") return false;

    return value;
  };

  const handleRecurrenceView = (value: any) => {
    if (value === true) return "RECURRENCE";
    if (value === false) return "UNIQUE";

    return value;
  };

  return (
    <React.Fragment>
      <GridItem colStart={1} colSpan={isMobile ? 4 : 6} mt={2}>
        <Heading fontSize="xl" fontWeight="semibold">
          Horários
        </Heading>
      </GridItem>

      <GridItem colStart={1} colSpan={isMobile ? 4 : 5} mt={8}>
        <DatePicker
          label="Data de inicio do anúncio"
          value={moment(adStartDate).startOf("day").toDate()}
          inputProps={register("adStartDate")}
          error={errors?.adStartDate?.message}
          onChange={(date: Date) =>
            setValue("adStartDate", date.toISOString(), {
              shouldValidate: true,
            })
          }
          datePickerProps={{
            dateFormat: "dd/MM/yyyy",
            allowSameDay: false,
            minDate: moment().startOf("day").toDate(),
          }}
        />
      </GridItem>

      <GridItem colStart={1} colSpan={isMobile ? 4 : 5} mt={8}>
        <DatePicker
          label="Término  do anúncio"
          value={moment(adEndDate).startOf("day").toDate()}
          inputProps={register("adEndDate")}
          error={errors?.adEndDate?.message}
          onChange={(date) =>
            setValue("adEndDate", date.toISOString(), {
              shouldValidate: true,
            })
          }
          datePickerProps={{
            dateFormat: "dd/MM/yyyy",
            allowSameDay: false,
            minDate: moment(adStartDate).startOf("day").toDate(),
            openToDate: moment(adStartDate).startOf("day").toDate(),
          }}
        />
      </GridItem>

      <GridItem colStart={1} colSpan={isMobile ? 4 : 6} mt={8}>
        <Heading fontSize="xl" fontWeight="semibold">
          Defina os horários
        </Heading>
      </GridItem>

      <GridItem
        colStart={1}
        colSpan={isMobile ? 4 : 3}
        mt={8}
        gridRowStart={isMobile ? "auto" : 8}
        gridAutoFlow="row"
      >
        <DurationOptions
          label={<Heading fontSize="md">Duração da aula</Heading>}
          value={duration}
          error={errors?.duration?.message}
          control={register("duration", {
            value: duration,
          })}
          onChange={(time) => setValue("duration", time.value)}
        />
      </GridItem>

      <GridItem
        colStart={isMobile ? 1 : 5}
        gridRowStart={isMobile ? "auto" : 8}
        colSpan={isMobile ? 2 : 1}
        mt={8}
      >
        <Heading fontSize="md" whiteSpace="nowrap">
          Personalizado
        </Heading>
        <CardTime
          mt={4}
          type="min"
          value={duration}
          borderRadius="md"
          maxW={"calc(25vw - 8px)"}
          onChange={(time) => setValue("duration", time)}
          inputProps={register("duration")}
        />
      </GridItem>

      <GridItem colStart={1} colSpan={isMobile ? 4 : 5} mt={6}>
        <RadioGroup
          label=""
          direction="row"
          options={[
            { label: "Repetir semanalmente", value: "RECURRENCE" },
            { label: "Horários únicos", value: "UNIQUE" },
          ]}
          helperText="Ao selecionar repetir semanalmente todos horários serão repetidos nas
          próximas semanas. Horários únicos contarão somente nos 7 dias
          seguintes a data de início do anúncio."
          radioProps={{
            defaultValue: "RECURRENCE",
            value: handleRecurrenceView(recurrence),
            onChange: (value: any) =>
              setValue("recurrence", handleRecurrence(value)),
          }}
          error={errors?.recurrence?.message}
        />
      </GridItem>

      <GridItem
        colStart={1}
        colSpan={isMobile ? 4 : 12}
        mt={10}
        mx={isMobile ? "-16px" : "-60px"}
      >
        <Box
          overflow={isMobile ? "hidden" : "auto"}
          overflowX={isMobile ? "scroll" : "visible"}
        >
          <CreateClassCalendar
            w={isMobile ? "calc(100vw * 3.8)" : "100%"}
            duration={duration < DURATION_MIN ? DURATION_MIN : duration}
            recurrence={recurrence}
            schedules={schedules}
            onChange={(value: any) =>
              setValue("schedules", value, {
                shouldValidate: false,
              })
            }
          />
        </Box>
      </GridItem>
    </React.Fragment>
  );
}
