import { Icon, IconProps } from "@chakra-ui/react";

export default function CardsListIcon(props: IconProps) {
  return (
    <Icon width="781" height="231" viewBox="0 0 781 231" fill="none" {...props}>
      <rect
        x="0.5"
        y="0.5"
        width="657.015"
        height="88.6709"
        rx="3.5"
        fill="white"
        stroke="#C5CED6"
      />
      <rect
        x="36.0322"
        y="33.1836"
        width="117"
        height="24"
        rx="4"
        fill="#C5CED6"
      />
      <rect
        x="185.032"
        y="33.1836"
        width="235"
        height="24"
        rx="4"
        fill="#C5CED6"
      />
      <rect
        x="616.048"
        y="33.1392"
        width="13.4612"
        height="23.3924"
        rx="4"
        fill="#C5CED6"
      />
      <rect
        x="514.692"
        y="33.1392"
        width="79.1835"
        height="23.3924"
        rx="4"
        fill="#C5CED6"
      />
      <rect
        x="59.748"
        y="68.728"
        width="657.015"
        height="88.6709"
        rx="3.5"
        fill="white"
        stroke="#C5CED6"
      />
      <rect
        x="94.0322"
        y="101.184"
        width="117"
        height="24"
        rx="4"
        fill="#C5CED6"
      />
      <rect
        x="243.032"
        y="101.184"
        width="235"
        height="24"
        rx="4"
        fill="#C5CED6"
      />
      <rect
        x="675.296"
        y="101.367"
        width="13.4612"
        height="23.3924"
        rx="4"
        fill="#C5CED6"
      />
      <rect
        x="573.941"
        y="101.367"
        width="79.1835"
        height="23.3924"
        rx="4"
        fill="#C5CED6"
      />
      <rect
        x="123.484"
        y="141.829"
        width="657.015"
        height="88.6709"
        rx="3.5"
        fill="white"
        stroke="#C5CED6"
      />
      <rect
        x="156.032"
        y="174.184"
        width="117"
        height="24"
        rx="4"
        fill="#C5CED6"
      />
      <rect
        x="305.032"
        y="174.184"
        width="235"
        height="24"
        rx="4"
        fill="#C5CED6"
      />
      <rect
        x="739.032"
        y="174.468"
        width="13.4612"
        height="23.3924"
        rx="4"
        fill="#C5CED6"
      />
      <rect
        x="637.678"
        y="174.468"
        width="79.1835"
        height="23.3924"
        rx="4"
        fill="#C5CED6"
      />
    </Icon>
  );
}
