import { useRef } from "react";
import { useNavigate } from "react-router-dom";

import { Flex, Heading, IconButton } from "@chakra-ui/react";

import ArrowLeftIcon from "../../assets/icons/arrow-left";
import { useResposiveness } from "../../hooks/responsiveness";

interface SimpleHeaderPropsI {
  title: string;
  backButton?: boolean;
  backButtonIcon?: any;
  onBack?: () => void;
}

export default function SimpleHeader({
  title = "Title",
  backButton = false,
  backButtonIcon,
  onBack,
}: SimpleHeaderPropsI) {
  const navigate = useNavigate();
  const { isMobile } = useResposiveness();
  const refButtonBack = useRef<HTMLButtonElement>(null);

  const handleOnBack = () => {
    if (onBack) {
      onBack();
    } else {
      navigate(-1);
    }
  };

  return (
    <Flex
      w="100%"
      minHeight="80px"
      borderBottom="hairline"
      borderColor="neutral.medium"
      padding="28px 32px"
      align="center"
      justify={isMobile ? "center" : "start"}
    >
      {backButton && (
        <IconButton
          ref={refButtonBack}
          variant="unstyled"
          aria-label="back-button"
          icon={backButtonIcon || <ArrowLeftIcon size={22} />}
          sx={{
            outlineOffset: "-1px",
          }}
          ml="-8px"
          mr={2}
          onClick={handleOnBack}
          maxW={isMobile ? "40px" : "auto"}
        />
      )}

      <Heading
        flex={isMobile ? 3 : 1}
        fontSize={isMobile ? "md" : "sm"}
        fontWeight="bold"
        color="neutral.darkest"
        textAlign={isMobile ? "center" : "start"}
        ml={isMobile ? `-40px` : "0px"}
      >
        {title}
      </Heading>
    </Flex>
  );
}
