import moment from "moment-timezone";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  Flex,
  Heading,
  Skeleton,
  Stack,
  Tab,
  TabList,
  Tabs,
} from "@chakra-ui/react";

import { EntityStatus } from "@isurf-tech/types/core/enums";
import {
  IClassroom,
  IClassroomStudent,
  IStudent,
} from "@isurf-tech/types/domain";

import { QueryClassroomStudentDto } from "../../@types/classroom-student";
import CardStudentClassroom from "../../components/cards/student-classrooms";
import EmptyList from "../../components/empty-list";
import Header from "../../components/headers/main";
import Paginator from "../../components/paginator";
import routesName from "../../config/routes";
import { DialogActionResponse } from "../../contexts/dialog";
import { useAuth } from "../../hooks/auth";
import { useDialog } from "../../hooks/dialog";
import { useQuery } from "../../hooks/query-param";
import { useResposiveness } from "../../hooks/responsiveness";
import { studentService } from "../../services/api";

const tabOptions = ["Agendadas", "Em aberto"];

enum filterOptions {
  "SCHEDULED",
  "OPEN",
}

interface QueryParams extends QueryClassroomStudentDto {
  type: filterOptions;
}

export default function StudentDashboard() {
  const navigate = useNavigate();
  const dialog = useDialog();
  const query = useQuery<QueryParams>();
  const { isMobile } = useResposiveness();
  const { user, hasToken, refreshUser } = useAuth<IStudent>();
  const [classrooms, setClassrooms] = useState<IClassroomStudent[]>([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [offset, setOffset] = useState(5);
  const [loading, setLoading] = useState(true);

  const requestClassrooms = useCallback(
    async (params: QueryClassroomStudentDto) => {
      if (!(hasToken && user)) return;

      setLoading(true);

      const { data } = await studentService.findClassroom(user.id, {
        ...params,
        page: page - 1,
      });

      const { results: classrooms = [], count, offset } = data || {};

      setClassrooms(classrooms || []);
      setCount(count);
      setOffset(offset || 5);
      setLoading(false);
    },
    [hasToken, page, user]
  );

  const updateQueryParams = useCallback(
    (params: any) => {
      const override = Object.assign({}, query?.object, params);
      const urlParams = new URLSearchParams(override).toString();

      navigate(routesName.studentsDashboard.concat(`?${urlParams}`), {
        replace: true,
      });
    },
    [navigate, query?.object]
  );

  const lessonDetails = useCallback(
    (classroom?: IClassroomStudent) =>
      classroom?.classroom?.lesson
        ? navigate(
            routesName.lessonById.replace(":id", classroom.classroom.lesson.id),
            {
              state: {
                lesson: classroom.classroom.lesson,
              },
            }
          )
        : null,
    [navigate]
  );

  const handleRequestCancelClassroom = async (
    classroom: IClassroom,
    description: string
  ): Promise<DialogActionResponse> => {
    const { data, error } = await studentService.cancelStudent(
      classroom.id!,
      description ?? ""
    );

    if (error?.statusCode && error.message)
      return {
        type: "error",
        title: "Erro ao cancelar aula.",
        description:
          error?.message ||
          "Houve um erro ao cancelar aula, por favor tente novamente ou entre em contato com o suporte.",
      };

    await refreshUser();

    return {
      type: "success",
      title: "Aula cancelada com sucesso!",
      description:
        data?.message ||
        "É uma pena que não poderemos te ver hoje, mas tudo bem! Aproveite e veja nossas outras aulas.",
    };
  };

  const validateCancelClassroom = (classroomStudent?: IClassroomStudent) => {
    if (!classroomStudent) return;

    const { lesson, schedule } = classroomStudent.classroom;

    const limitDateCancelation = Number(lesson?.limitDateCancelation || 0);
    const diffFromNow = moment(schedule.start)
      .startOf("day")
      .diff(moment().startOf("day"), "days");

    let errorMessage;

    if (diffFromNow < 0)
      errorMessage = `Voce nao pode cancelar uma aula já iniciada`;

    if (limitDateCancelation >= diffFromNow)
      errorMessage = `Voce nao pode cancelar essa aula, pois o limite de ${limitDateCancelation} dias para cancelamento ja passou`;

    if (classroomStudent.status === EntityStatus.CANCELED)
      errorMessage = `A aula ja foi cancelada`;

    if (errorMessage) {
      return dialog.open({
        type: "error",
        title: "Erro ao cancelar aula",
        description:
          errorMessage ??
          "Erro ao cancelar aula. Tente novamente ou entre em contato com o suporte.",
      });
    }

    return false;
  };

  const handleCancel = async (classroomStudent?: IClassroomStudent) => {
    if (!classroomStudent) return;
    if (validateCancelClassroom(classroomStudent)) return;

    return dialog.open({
      type: "text-input",
      title: "Cancelamento de aula",
      description:
        "Explique o motivo de cancelamento da sua aula. A aula será cancelada e você poderá agendar outra aula.",

      inputProps: {
        label: "Motivo do cancelamento",
        type: "text-area",
        fullWidth: true,
      },
      closeLabel: false,
      redirect: {
        label: "Cancelar turmas",
        action: (form: { description: string }) =>
          handleRequestCancelClassroom(
            classroomStudent.classroom,
            form.description
          ),
      },
    });
  };

  useEffect(() => {
    const { type, ...params } = query?.object || {};
    const typeParam = filterOptions[type];

    if (typeParam in filterOptions) setTabIndex(typeParam as any as number);
    if (Number(params.page) >= 0) setPage(Number(params.page));
    if (Number(params.offset) >= 0) setOffset(Number(params.offset));

    requestClassrooms(params).then().catch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let params: any = { page, offset };

    const filterType = filterOptions[tabIndex];

    if (filterType in filterOptions) params.type = filterType;

    updateQueryParams(params);
  }, [navigate, updateQueryParams, tabIndex, page, requestClassrooms, offset]);

  useEffect(() => {
    const pageParam = query?.object?.page || {};

    if (Number(pageParam) >= 0 && pageParam !== page) {
      const timeout = setTimeout(() => {
        requestClassrooms({
          ...query?.object,
          page,
        })
          .then()
          .catch();
      }, 500);

      return () => clearTimeout(timeout);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, requestClassrooms]);

  return (
    <Box w="100vw" h="100vh">
      <Header />

      <Flex direction="column" p="3% 6%" flex={1} height="calc(100% - 64px)">
        <Heading fontSize="lg"> Minhas aulas</Heading>

        <Tabs
          index={tabIndex}
          mt={8}
          size="xs"
          onChange={(index) => setTabIndex(index)}
          display="flex"
          flexDirection="column"
          height={isMobile ? "78%" : "100%"}
          maxH={isMobile ? "78%" : "calc(100vh - 40%)"}
        >
          <TabList>
            {tabOptions.map((option) => (
              <Tab key={option}>{option}</Tab>
            ))}
          </TabList>

          {loading ? (
            <Stack p={0} pt={8}>
              <Skeleton height="100px" borderRadius="sm" />
              <Skeleton height="100px" borderRadius="sm" />
              <Skeleton height="100px" borderRadius="sm" />
              <Skeleton height="100px" borderRadius="sm" />
              {!isMobile && (
                <>
                  <Skeleton height="100px" borderRadius="sm" />
                </>
              )}
            </Stack>
          ) : !classrooms?.length ? (
            <Flex
              direction="column"
              mt={isMobile ? 10 : 10}
              mb={10}
              height={isMobile ? "80%" : "100%"}
              justify="center"
              align="center"
            >
              <EmptyList
                title="Sem aulas hoje"
                description="Você não tem nenhuma aula hoje, mas não se preocupe, você pode encontrar uma aula que se encaixe no seu horário."
              />
            </Flex>
          ) : (
            <Flex p={0} pt={8} direction="column" flex={1} overflowY="scroll">
              {classrooms &&
                classrooms?.map((classroom, index: number) => (
                  <CardStudentClassroom
                    key={classroom.id || index.toString()}
                    data={classroom}
                    mt={index > 0 ? 4 : 0}
                    menuOptions={[
                      {
                        label: "Ver detalhes",
                        onClick: () => lessonDetails(classroom),
                      },
                      {
                        label: "Cancelar Aula",
                        onClick: () => handleCancel(classroom),
                      },
                    ]}
                  />
                ))}
            </Flex>
          )}
        </Tabs>

        <Flex mt={8} justify="space-between" align="center">
          <Button size="md" onClick={() => navigate(routesName.search)}>
            Encontrar mais aulas
          </Button>

          <Paginator
            page={page}
            count={count}
            offset={offset}
            onChange={(next: number) => setPage(next)}
          />
        </Flex>
      </Flex>
    </Box>
  );
}
