import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Button,
  Flex,
  GridItem,
  Heading,
  SimpleGrid,
  Slide,
  Text,
} from "@chakra-ui/react";

import ArrowLeftIcon from "../../../assets/icons/arrow-left";
import CardLocation from "../../../components/cards/location";
import CardSchedule from "../../../components/cards/schedule";
import SimpleHeader from "../../../components/headers/simple";
import routesName from "../../../config/routes";
import { useClassroom } from "../../../hooks/classroom";
import { useResposiveness } from "../../../hooks/responsiveness";
import ClassroomCancelation from "./components/cancelation";
import ClassroomMessages from "./components/messages";
import ClassroomStudents from "./components/students";

export default function ClassroomPage() {
  const { isMobile } = useResposiveness();
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const { loading, classroom, load } = useClassroom();

  useEffect(() => {
    (async () => {
      if (params.id) await load(params.id);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Slide
      direction="right"
      in={true}
      style={{ zIndex: 10, overflow: "hidden", overflowY: "scroll" }}
    >
      <SimpleGrid
        columns={isMobile ? 4 : 12}
        columnGap={8}
        px={isMobile ? "16px" : "80px"}
        pb="80px"
      >
        <GridItem
          colStart={1}
          colSpan={isMobile ? 4 : 12}
          mx={isMobile ? "-16px" : "-80px"}
        >
          <SimpleHeader
            title="Detalhe da aula"
            backButton
            backButtonIcon={<ArrowLeftIcon size={24} />}
            onBack={() => navigate(routesName.schoolsDashboard)}
          />
        </GridItem>

        <GridItem colStart={1} colSpan={isMobile ? 4 : 6} mt={10}>
          <Heading fontSize="xl">Resumo</Heading>
        </GridItem>

        <GridItem
          colSpan={isMobile ? 4 : 6}
          mt={10}
          display="flex"
          justifyContent={isMobile ? "flex-start" : "flex-end"}
        >
          {/* <Button variant="outline" size="md">
            Editar aula
          </Button> */}
          <Button
            variant="outline"
            size="md"
            ml={isMobile ? 0 : 2}
            onClick={() =>
              navigate(
                routesName.lessonById.replace(":id", classroom?.lesson.id!),
                {
                  state: {
                    lesson: classroom?.lesson,
                  },
                }
              )
            }
          >
            Ver anúncio
          </Button>
        </GridItem>

        <GridItem colSpan={isMobile ? 4 : 6} mt={5}>
          <CardSchedule
            schedule={{
              ...(classroom?.schedule || {}),
              lesson: classroom?.lesson,
            }}
            small
            h="100%"
          />
        </GridItem>

        <GridItem
          colSpan={isMobile ? 4 : 6}
          mt={5}
          border="hairline"
          color="neutral.medium"
          borderRadius="sm"
        >
          <CardLocation lesson={classroom?.lesson!} />
        </GridItem>

        <GridItem colSpan={isMobile ? 4 : 6} mt={12}>
          <Flex alignItems="center">
            <Heading my="auto" fontSize="lg">
              Alunos
            </Heading>
            <Text fontSize="sm" color="neutral.dark" ml={4} my="auto">
              {classroom?.students?.length || 0}/{classroom?.lesson?.seats || 0}{" "}
              inscritos
            </Text>
          </Flex>
        </GridItem>

        <ClassroomStudents
          loading={loading}
          classroom={classroom}
          classroomStudents={classroom?.students}
        />

        <ClassroomMessages
          loading={loading}
          classroom={classroom?.id}
          messages={classroom?.messages}
        />

        <ClassroomCancelation classroom={classroom} />
      </SimpleGrid>
    </Slide>
  );
}
