import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import {
  Box,
  Button,
  Flex,
  GridItem,
  Heading,
  SimpleGrid,
} from "@chakra-ui/react";

import { LessonCreateParams } from "../../../@types/lessons";
import CloseIcon from "../../../assets/icons/close";
import SimpleHeader from "../../../components/headers/simple";
import Stepper from "../../../components/stepper";
import routesName from "../../../config/routes";
import { useDialog } from "../../../hooks/dialog";
import { useLoader } from "../../../hooks/loader";
import { useResposiveness } from "../../../hooks/responsiveness";
import useLocalStorage from "../../../hooks/storage";
import { lessonService } from "../../../services/api";
import SteppersCreateClass, { formSchema as formSchemaSteps } from "./steppers";

const steps = [
  "configuração",
  "preço",
  "localização",
  "horário",
  "fotos",
  "conclusão",
];

const formSchema = yup.object().shape(formSchemaSteps).required();

const fieldsErrorsByStep = {
  0: ["name", "modality", "level", "seats", "description"],
  1: ["price"],
  2: ["location", "locationDetails"],
  3: ["adStartDate", "adEndDate", "duration", "recurrence", "schedules"],
  4: ["photos"],
  5: ["limitDateSubscription", "limitDateCancelation"],
} as { [key in number]: string[] };

export default function CreateClassPage() {
  const navigate = useNavigate();
  const { isMobile } = useResposiveness();
  const [step, setStep] = useLocalStorage("lesson-form-step", 0);
  const [form, setForm] = useLocalStorage("lesson-form-values", {});
  const dialog = useDialog();
  const loader = useLoader();

  const formMethods = useForm({
    resolver: yupResolver(formSchema),
    mode: "onTouched",
    reValidateMode: "onBlur",
    defaultValues: form,
  });
  const errorsKeys = Object.keys(formMethods.formState.errors);
  const stepHaveError = fieldsErrorsByStep[step].some((field) =>
    errorsKeys.includes(field)
  );

  const nextStep = async () => {
    const result = await formMethods.trigger(fieldsErrorsByStep[step] as any);

    if (result && step + 1 < steps.length && !stepHaveError)
      return setStep(step + 1);
  };

  const previusStep = () => {
    let target = step - 1;

    if (target < 0) return giveUp();
    if (target >= 0) setStep(step - 1);
  };

  const onSubmit = async (data: LessonCreateParams) => {
    if (step !== steps.length - 1) return;

    loader.run();

    const { data: response = {}, error } = await lessonService.create<{
      lessonId: string;
    }>(data);

    const { lessonId } = (response as any) || { lessonId: null };

    loader.stop();

    dialog.open({
      type: "success",
      title: !lessonId || error ? "Error ao criar!" : "Aula criada",
      description: error
        ? error?.message
        : "Você poderá acompanhar sua aula e editar algumas informações depois.",
      redirect: lessonId
        ? {
            label: "Ver anúncio",
            action: () => {
              if (lessonId)
                navigate(routesName.lessonById.replace(":id", lessonId), {
                  state: {
                    lesson: formMethods.getValues(),
                  },
                });
            },
          }
        : undefined,
      closeLabel: "Concluir",
      onClose: () => {
        navigate(routesName.schoolsDashboard);
      },
    });
  };

  const giveUp = () => {
    setStep(null);
    setForm(null);
    navigate(routesName.schoolsDashboard);
  };

  useEffect(() => {
    const subscription = formMethods.watch((value, { name, type }) => {
      setForm(value);
    });

    return () => subscription.unsubscribe();
  }, [formMethods, setForm]);

  return (
    <FormProvider {...formMethods}>
      <Box pb={10}>
        <SimpleGrid
          columns={isMobile ? 4 : 12}
          columnGap={8}
          px={isMobile ? "16px" : "80px"}
          as="form"
          onSubmit={formMethods.handleSubmit(onSubmit)}
        >
          <GridItem
            colStart={1}
            colSpan={isMobile ? 4 : 12}
            mx={isMobile ? "-16px" : "-80px"}
          >
            <SimpleHeader
              title="Criar aula"
              backButton
              backButtonIcon={<CloseIcon fontSize={24} />}
              onBack={giveUp}
            />
          </GridItem>

          <GridItem
            colStart={1}
            colSpan={isMobile ? 4 : 6}
            mt={10}
            mx={isMobile ? "-16px" : "0px"}
          >
            <Box
              overflow="hidden"
              overflowX="scroll"
              pl={isMobile ? "16px" : "0px"}
            >
              <Stepper
                current={step}
                steps={steps}
                w={isMobile ? "160vw" : "100%"}
              />
            </Box>
          </GridItem>

          <GridItem colStart={1} colSpan={isMobile ? 4 : 6} mt={8}>
            <Heading fontSize="sm" fontWeight="normal">
              Etapa {step + 1} de {steps.length}
            </Heading>
          </GridItem>

          <SteppersCreateClass current={step} />

          <GridItem colStart={1} colSpan={4} mt={14} pr={isMobile ? 0 : 10}>
            <Flex direction={isMobile ? "column-reverse" : "row"}>
              <Button
                variant="outline"
                size="lg"
                onClick={previusStep}
                mt={isMobile ? 4 : 0}
                w="100%"
              >
                {step > 0 ? "voltar" : "Cancelar"}
              </Button>

              {step < steps.length - 1 && (
                <Button
                  size="lg"
                  ml={isMobile ? 0 : 4}
                  onClick={nextStep}
                  w="100%"
                  disabled={stepHaveError}
                >
                  Avançar
                </Button>
              )}

              {step >= steps.length - 1 && (
                <Button
                  type="submit"
                  size="lg"
                  ml={isMobile ? 0 : 4}
                  w="100%"
                  disabled={stepHaveError}
                >
                  Concluir
                </Button>
              )}
            </Flex>
          </GridItem>
        </SimpleGrid>
      </Box>
    </FormProvider>
  );
}
