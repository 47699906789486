import React, {
  createContext,
  useCallback,
  useMemo,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { EntityStatus } from "@isurf-tech/types/core/enums";
import { ISchool, IStudent } from "@isurf-tech/types/domain";
import { EntityUserType } from "@isurf-tech/types/domain/enums";

import { STORAGE_KEY_ACCESS_TOKEN } from "../config/http";
import routesName from "../config/routes";
import useLocalStorage from "../hooks/storage";
import api, { authService } from "../services/api";

export interface AuthContextI<T = ISchool | IStudent> {
  user: (T & { entity: EntityUserType }) | null;
  authUser: (token: string) => Promise<void>;
  refreshUser: () => Promise<void>;
  logout: () => void;
  hasToken: boolean;
}

export const AuthContext = createContext<AuthContextI>(null as any);

export function AuthProvider({ children }: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState<any>(null);
  const [token, setToken] = useLocalStorage(STORAGE_KEY_ACCESS_TOKEN, null);

  const authUser = useCallback(
    async (token: string) => {
      setToken(token);

      const { data, error } = await authService.profile();

      if (
        error &&
        error.statusCode === 401 &&
        ![
          routesName.registerStudents,
          routesName.registerSchools,
          routesName.main,
        ].includes(location.pathname)
      ) {
        setToken(null);
        setUser(null);
        navigate(routesName.main);
        return;
      }

      // if (
      //   data?.status === EntityStatus.ACTIVE &&
      //   user?.status !== EntityStatus.AWAITING_APPROVAL &&
      //   location.pathname === routesName.schoolsAnalyse
      // )
      //   navigate(routesName.schoolsDashboard);

      setUser(data);

      return data;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname, navigate, setToken, user?.status]
  );

  const refreshUser = useCallback(async () => {
    const { data } = await authService.profile();

    setUser(data);
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setUser(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (token && !api.hasDefaultToken()) {
      api.setDefaultToken(token);
    }

    if (token && !user) {
      authUser(token)
        .then(() => {})
        .catch(() => {});
    }
  }, [user, token, navigate, authUser]);

  // REDIRECT IF SCHOOL IS AWAITING APPROVAL
  useEffect(() => {
    if (
      user?.entity === EntityUserType.SCHOOL &&
      user?.status === EntityStatus?.AWAITING_APPROVAL
    ) {
      if (
        [routesName.schoolsAnalyse, routesName.schoolsPerfil].includes(
          location.pathname
        )
      ) {
        navigate(location.pathname);
      } else {
        navigate(routesName.schoolsAnalyse);
      }
    }
  }, [location.pathname, navigate, user]);

  const value = useMemo(
    () => ({
      user,
      authUser,
      refreshUser,
      hasToken: !!token,
      logout,
    }),
    [user, authUser, refreshUser, token, logout]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
