import { Box } from "@chakra-ui/react";

import StepAlbum, { formSchema as AlbumFormSchema } from "./album";
import StepAppointment, {
  formSchema as AppointmentFormSchema,
} from "./appointment";
import StepConclusion, {
  formSchema as ConclusionFormSchema,
} from "./conclusion";
import StepLocalization, {
  formSchema as LocationFormSchema,
} from "./localization";
import StepPrices, { formSchema as PricesFormSchema } from "./prices";
import StepSetup, { formSchema as SetupFormSchema } from "./setup";

interface SteppersCreateClassPropsI {
  current: number;
}

export const formSchema = Object.assign(
  {},
  SetupFormSchema,
  PricesFormSchema,
  LocationFormSchema,
  AppointmentFormSchema,
  AlbumFormSchema,
  ConclusionFormSchema
);

export default function SteppersCreateClass({
  current,
}: SteppersCreateClassPropsI) {
  switch (current) {
    case 0:
      return <StepSetup />;

    case 1:
      return <StepPrices />;

    case 2:
      return <StepLocalization />;

    case 3:
      return <StepAppointment />;

    case 4:
      return <StepAlbum />;

    case 5:
      return <StepConclusion />;

    default:
      return <Box>ERROR</Box>;
  }
}
