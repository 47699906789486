import { Box, Flex, Grid, GridItem } from "@chakra-ui/react";

import KeepCalmIlustration from "../../assets/ilustrations/keep-calm";
import SimpleHeader from "../../components/headers/simple";
import { useQuery } from "../../hooks/query-param";
import { useResposiveness } from "../../hooks/responsiveness";
import RecoveryConfirm from "./components/confirm";
import RecoveryRequest from "./components/request";

export default function RecoveryPasswordPage() {
  const { isMobile } = useResposiveness();
  const { token } = useQuery<{ token?: string }>().object;

  return (
    <Box h="calc(100vh)">
      <SimpleHeader
        title={token ? "Alterar a senha" : "Esqueci a senha"}
        backButton
      />

      <Grid
        templateColumns={isMobile ? "repeat(4, 1fr)" : "repeat(12, 1fr)"}
        templateRows={isMobile ? "auto auto" : "auto"}
        gap={8}
        h="calc(100vh - 80px)"
      >
        <GridItem colSpan={5} padding={isMobile ? "24px 16px" : "32px 80px"}>
          {token ? <RecoveryConfirm /> : <RecoveryRequest />}
        </GridItem>

        {!isMobile && (
          <GridItem colSpan={7}>
            <Flex bg="brand.lightest" h="100%" justify="center" align="center">
              <KeepCalmIlustration />
            </Flex>
          </GridItem>
        )}
      </Grid>
    </Box>
  );
}
