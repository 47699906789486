import moment from "moment-timezone";
import { memo, useMemo } from "react";

import { Flex, Text } from "@chakra-ui/react";

function ScheduleCardTime({
  index,
  selected,
  date,
  time,
  onClick,
  disabled = false,
}: any) {
  const tz = useMemo(
    () => time.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
    [time?.timezone]
  );

  const handleColorText = () => {
    if (disabled && time.start) return "neutral.medium";
    if (time.start && !selected) return "neutral.dark";

    return "neutral.light";
  };

  return (
    <Flex
      p={3}
      as="button"
      disabled={disabled}
      align="center"
      justify="center"
      border="hairline"
      borderColor={
        time.start
          ? selected
            ? "brand.dark"
            : "neutral.medium"
          : "neutral.light"
      }
      mb={index >= 0 ? 1 : 0}
      borderRadius="sm"
      bg={
        time.start
          ? selected
            ? "brand.dark"
            : "neutral.lightest"
          : "neutral.light"
      }
      cursor={!disabled && time.start ? "pointer" : "default"}
      _hover={
        time.start && !selected
          ? {
              bg: "neutral.light",
            }
          : {}
      }
      onClick={() => onClick(date, time)}
      _disabled={{
        bg: time.start ? "neutral.light" : "neutral.light",
        borderColor: time.start ? "brand.light" : "neutral.light",
      }}
    >
      <Text fontSize="2xs" color={handleColorText()} fontWeight="semibold">
        {moment(time.start).tz(tz).format("HH:mm")}
      </Text>
    </Flex>
  );
}

export default memo(ScheduleCardTime);
