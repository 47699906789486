import { ComponentStyleConfig } from "@chakra-ui/react";

const DividerTheme: ComponentStyleConfig = {
  baseStyle: {
    width: "100%",
    height: "1px",
    bg: "neutral.medium",
  },
  sizes: {},
  variants: {},
  defaultProps: {},
};

export default DividerTheme;
