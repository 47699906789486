import React, { createContext, useMemo } from "react";

import { useDisclosure } from "@chakra-ui/react";

import FullLoader from "../components/loaders/full";

interface LoaderContextI {
  isOpen: boolean;
  run: () => void;
  stop: () => void;
}

export const LoaderContext = createContext<LoaderContextI>(null as any);

export function LoaderProvider({ children }: any) {
  const {
    isOpen,
    onOpen: run,
    onClose: stop,
  } = useDisclosure({ id: "loader" });

  const value = useMemo(() => ({ isOpen, run, stop }), [isOpen, run, stop]);

  return (
    <LoaderContext.Provider value={value}>
      {children}
      <FullLoader />
    </LoaderContext.Provider>
  );
}
