import moment from "moment-timezone";
import React from "react";
import { useFormContext } from "react-hook-form";

import { Button, Flex } from "@chakra-ui/react";

import { SEARCH_FORM_FIELD_MOBILE } from ".";
import BoxInput from "../../../inputs/box";

export enum OPTIONS {
  LOCALIZATION = "localization",
  SCHOOL = "school",
  DATE = "date",
  MODALITY = "modality",
}

interface MobileSearchOptionsProps {
  onClick?: (option: OPTIONS) => void;
  onSubmit?: (form: any) => void;
  onAbort?: () => void;
}

export default function MobileSearchOptions({
  onClick,
  onSubmit,
  onAbort,
}: MobileSearchOptionsProps) {
  const { watch } = useFormContext();

  const localization = watch(SEARCH_FORM_FIELD_MOBILE.LOCALIZATION, "");
  const school = watch(SEARCH_FORM_FIELD_MOBILE.SCHOOL, "");
  const date = watch(SEARCH_FORM_FIELD_MOBILE.DATE, moment().toISOString());
  const modality = watch(SEARCH_FORM_FIELD_MOBILE.MODALITY, []) ?? [];
  const level = watch(SEARCH_FORM_FIELD_MOBILE.LEVEL, []) ?? [];

  const modalityAndLevel = [...modality, ...level];

  const handleOnClick = (option: OPTIONS) => {
    if (onClick) onClick(option);
  };

  return (
    <React.Fragment>
      <Flex
        as="form"
        direction="column"
        justify="space-between"
        flex={1}
        onSubmit={onSubmit}
      >
        <Flex direction="column">
          <BoxInput
            label="Localização"
            value={localization ?? "Qual praia você quer surfar?"}
            onClick={() => handleOnClick(OPTIONS.LOCALIZATION)}
            noInput
          />

          <BoxInput
            label="Escola"
            value={school ?? "Nome da escola"}
            onClick={() => handleOnClick(OPTIONS.SCHOOL)}
            boxProps={{
              mt: 2,
            }}
            noInput
          />

          <BoxInput
            label="Data e hora"
            value={moment(date).format("DD/MM/YYYY") ?? "Insira a data"}
            onClick={() => handleOnClick(OPTIONS.DATE)}
            boxProps={{
              mt: 2,
            }}
            noInput
          />

          <BoxInput
            label="Modalidade ou level"
            value={
              modalityAndLevel && modalityAndLevel.length
                ? (modalityAndLevel || []).join(", ")
                : "Escolha a modalidade ou level"
            }
            onClick={() => handleOnClick(OPTIONS.MODALITY)}
            boxProps={{
              mt: 2,
            }}
            noInput
          />
        </Flex>

        <Flex direction="column" mt="auto" mb="15%">
          <Button size="md" type="submit" w="100%">
            Buscar
          </Button>

          <Button size="md" w="100%" mt={2} bg="neutral.dark" onClick={onAbort}>
            Limpar
          </Button>
        </Flex>
      </Flex>
    </React.Fragment>
  );
}
