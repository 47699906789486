import { useEffect, useRef } from "react";

import { Box, Flex, keyframes, Text } from "@chakra-ui/react";

interface StepperPropsI {
  steps: string[];
  current?: number;
  w: any;
}

export default function Stepper({ current = 0, steps, w }: StepperPropsI) {
  const stepRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (current < 2) {
      stepRef?.current?.scrollIntoView({ behavior: "smooth", inline: "start" });
    }

    if (current === 2) {
      stepRef?.current?.scrollIntoView({
        behavior: "smooth",
        inline: "center",
      });
    }

    if (current >= 3) {
      stepRef?.current?.scrollIntoView({ behavior: "smooth", inline: "end" });
    }
  }, [current]);

  return (
    <Box w={w} ref={stepRef}>
      <Flex bg="neutral.light" borderRadius="full" flex={steps.length - 1}>
        <Box
          flex={(1 / steps.length) * (current + 1)}
          borderRadius="full"
          h="20px"
          bg="brand.dark"
          animation={`${keyframes`
          0% {
              transform: scaleX(0%);
              transform-origin:left center;
          }
          100% {
              transform: scaleX(100%);
              transform-origin:left center;
          }
      `} 1.8s ease-in-out`}
        />
      </Flex>
      <Flex justify="center" mt={2}>
        {steps.map((step) => (
          <Text
            key={step}
            flex={1}
            textTransform="lowercase"
            fontSize="xs"
            fontWeight="semibold"
            color="neutral.dark"
            textAlign="center"
            sx={{
              "&::first-letter": {
                textTransform: "capitalize",
              },
            }}
          >
            {step}
          </Text>
        ))}
      </Flex>
    </Box>
  );
}
