import { useEffect, useRef, useState } from "react";
import Lottie from "react-lottie";

import {
  Button,
  CircularProgress,
  Flex,
  Heading,
  HStack,
  PinInput,
  PinInputField,
  Text,
  useToken,
} from "@chakra-ui/react";

import { useResposiveness } from "../../../hooks/responsiveness";
import { ServiceResponse } from "../../../services/api";

const TIME_VERIFY = 1000 * 60 * 3;
const TIME_DELAY_MESSAGE = 1800;

interface BaseVerificationPropsI {
  title: string;
  description: string;
  animationData: any;
  requestVerify: () => Promise<ServiceResponse>;
  validate: (code: string) => Promise<ServiceResponse>;
  onRedirect?: () => void;
}

export default function BaseVerification({
  title,
  description,
  animationData,
  requestVerify,
  validate,
  onRedirect = () => {},
}: BaseVerificationPropsI) {
  const { isMobile } = useResposiveness();
  const [brandMediumColor] = useToken("colors", ["brand.medium"]);
  const [seconds, setSeconds] = useState<any>(-1);
  const [tried, setTried] = useState(false);
  const [pin, setPin] = useState("");
  const [validating, setValidating] = useState(true);
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<string>("");
  const validatingPrevious = useRef<boolean>();

  const requestCode = async () => {
    setError("");
    setPin("");

    await requestVerify();

    setSeconds(TIME_VERIFY);
    setTried(true);
  };

  const validateCode = async () => {
    setError("");
    setValidating(true);

    const { data, error } = await validate(pin);

    if (data)
      return setSuccess(data?.message || "Código validado com sucesso!");
    if (error) setError(error?.message || "Código inválido!");

    setValidating(false);
  };

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    }
  }, [seconds]);

  useEffect(() => {
    validatingPrevious.current = validating;
  }, [validating]);

  useEffect(() => {
    if (!!success === true) {
      setTimeout(onRedirect, TIME_DELAY_MESSAGE);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(() => {
    if (pin.length === 6 && validatingPrevious.current === false) {
      validateCode()
        .then(() => {})
        .catch((err) => {});
    } else {
      setValidating(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pin]);

  return (
    <Flex direction="column" align="center">
      <Flex>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData,
          }}
          isClickToPauseDisabled
          height={isMobile ? "80vh" : "25%"}
          width={isMobile ? "80vw" : "25%"}
          style={{
            cursor: "wait",
          }}
        />
      </Flex>

      <Heading color="neutral.darkest" fontSize="lg">
        {title}
      </Heading>

      <Text textAlign="center" color="neutral.dark" fontSize="sm" mt={4}>
        {description}
      </Text>

      {validating ? (
        <CircularProgress
          isIndeterminate
          color={brandMediumColor}
          size="30px"
          mt={6}
        />
      ) : (
        <HStack mt={6}>
          <PinInput
            isDisabled={!tried}
            type="alphanumeric"
            size="lg"
            placeholder="•"
            value={pin}
            onChange={(code) => setPin(code)}
          >
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
          </PinInput>
        </HStack>
      )}

      {success && (
        <Text textAlign="center" color="green.500" fontSize="sm" mt={4}>
          {success}
        </Text>
      )}

      {error && (
        <Text color="red.500" fontSize="sm" mt={2}>
          {error}
        </Text>
      )}

      <Flex
        w="100%"
        direction="column"
        align="center"
        justify="center"
        mt={6}
        pb={4}
      >
        <Button
          size="md"
          w="80%"
          disabled={seconds > 0 || validating || (pin.length > 5 && !error)}
          onClick={requestCode}
        >
          {validating ? (
            "Validando..."
          ) : (
            <>
              {tried ? "Reenviar" : "Enviar"} codigo{" "}
              {seconds > 0 && `em (${seconds}) segundos`}
              {!!error && "novamente"}
            </>
          )}
        </Button>
      </Flex>
    </Flex>
  );
}
