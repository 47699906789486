import { Outlet } from "react-router-dom";

import { AuthProvider } from "./auth";
import ClassroomProvider from "./classroom";
import { DialogProvider } from "./dialog";
import { LessonsProvider } from "./lessons";
import { LoaderProvider } from "./loader";
import { MobileSearchProvider } from "./mobile-search";
import { ResponsivenessProvider } from "./responsiveness";
import SchedulesProvider from "./schedules";
import { SessionProvider } from "./session";

export const AppProviders = ({ children = <Outlet /> }: any) => {
  return (
    <SchedulesProvider>
      <ClassroomProvider>{children}</ClassroomProvider>
    </SchedulesProvider>
  );
};

export default function Providers({ children }: any) {
  return (
    <LoaderProvider>
      <ResponsivenessProvider>
        <DialogProvider>
          <AuthProvider>
            <SessionProvider>
              <MobileSearchProvider>
                <LessonsProvider>{children}</LessonsProvider>
              </MobileSearchProvider>
            </SessionProvider>
          </AuthProvider>
        </DialogProvider>
      </ResponsivenessProvider>
    </LoaderProvider>
  );
}
