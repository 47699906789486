import { useCallback } from "react";

import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  useNumberInput,
  UseNumberInputProps,
  useToken,
} from "@chakra-ui/react";

import MinurIcon from "../../assets/icons/minus";
import PlusIcon from "../../assets/icons/plus";

interface NumberInputPropsI {
  label: string;
  value?: number;
  placeholder?: string;
  helperText?: string;
  error?: string | any;
  inputProps?: any;
  numberInputProps?: UseNumberInputProps;
  onChange?: (value: any) => any;
}

export default function NumberInput({
  label,
  inputProps,
  error,
  helperText,
  numberInputProps,
  onChange,
  ...props
}: NumberInputPropsI) {
  const [neutralLightColor] = useToken("colors", ["neutral.light"]);
  const [neutralDarkColor] = useToken("colors", ["neutral.dark"]);
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput(numberInputProps);

  const inputPropsDefault = getInputProps(inputProps);

  const handleBlur = useCallback(
    (e: any) => {
      if (inputProps.onBlur) inputProps.onBlur(e);
    },
    [inputProps]
  );

  const handleValue = useCallback(
    (target: "add" | "sub", e: any): any => {
      const value = inputPropsDefault.value;
      const operator = target === "add" && value > 0 ? -1 : 1;
      const current = String(Number(inputPropsDefault.value) + operator);

      if (onChange) {
        onChange(current);
        handleBlur(e);
      }
    },
    [handleBlur, inputPropsDefault.value, onChange]
  );

  const inc = getDecrementButtonProps({
    onPointerDown: (...params) => handleValue("add", ...params),
  });
  const dec = getIncrementButtonProps({
    onPointerDown: (...params) => handleValue("sub", ...params),
  });

  return (
    <FormControl {...props} isInvalid={!!error} onBlur={handleBlur}>
      <Flex w="min-content" alignItems="center">
        <FormLabel>{label}</FormLabel>

        <Flex align="center" ml={2}>
          <IconButton
            variant="outline"
            aria-label="minur-icon"
            borderColor="neutral.medium"
            icon={<MinurIcon size={24} color={neutralDarkColor} />}
            sx={{
              outlineOffset: -1,
            }}
            p={0}
            _hover={{
              bg: `${neutralLightColor} !important`,
            }}
            {...inc}
          />

          <Input
            variant="unstyled"
            fontWeight="normal"
            fontSize="lg"
            p="0px !important"
            textAlign="center"
            mx={2}
            minHeight="min-content"
            minW="45px"
            {...inputPropsDefault}
          />

          <IconButton
            variant="outline"
            aria-label="plus-icon"
            borderColor="neutral.medium"
            icon={<PlusIcon size={24} color={neutralDarkColor} />}
            sx={{
              outlineOffset: -1,
            }}
            _hover={{
              bg: `${neutralLightColor} !important`,
            }}
            {...dec}
          />
        </Flex>
      </Flex>

      {error && <FormErrorMessage>{error}</FormErrorMessage>}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
