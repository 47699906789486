import React from "react";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Flex,
  GridItem,
  Heading,
  Link,
  Skeleton,
  Stack,
} from "@chakra-ui/react";

import MessageListIcon from "../../../../assets/icons/message-list";
import ClassroomMessage from "../../../../components/cards/classroom-message";
import EmptyList from "../../../../components/empty-list";
import routesName from "../../../../config/routes";
import useMessages from "../../../../hooks/messages";
import { useResposiveness } from "../../../../hooks/responsiveness";

const MAX_LIST = 3;

export default function ClassroomMessages({
  classroom: classroomId = "" as string,
  messages = [],
  loading = false,
}: {
  messages: any[];
  classroom?: string;
  loading?: boolean;
}) {
  const navigate = useNavigate();
  const { isMobile } = useResposiveness();
  const { newMessageDialog } = useMessages();

  return (
    <React.Fragment>
      <GridItem
        colSpan={isMobile ? 4 : 6}
        mt={10}
        display="flex"
        alignItems="center"
      >
        <Heading fontSize="lg">Mensagens enviadas</Heading>
      </GridItem>

      {!isMobile && (
        <GridItem
          colSpan={isMobile ? 4 : 6}
          mt={10}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          onClick={() => newMessageDialog(classroomId)}
        >
          <Button size="md" w={isMobile ? "100%" : "min-content"}>
            Nova Mensagem
          </Button>
        </GridItem>
      )}

      <GridItem colSpan={isMobile ? 4 : 12} mt={10}>
        {loading ? (
          <Stack spacing={4}>
            <Skeleton height="10vh" borderRadius="sm" />
            <Skeleton height="10vh" borderRadius="sm" />
            <Skeleton height="10vh" borderRadius="sm" />
          </Stack>
        ) : !messages.length ? (
          <EmptyList
            title="Ainda não há mensagens"
            description="Envie uma mensagem para seus alunos e veja aqui as mensagens enviadas."
            mt={4}
            icon={<MessageListIcon />}
          />
        ) : (
          messages
            ?.slice(0, MAX_LIST)
            .map((message: any, index: number) => (
              <ClassroomMessage
                key={message?.id ?? index.toString()}
                message={message}
                mt={index > 0 ? 4 : 0}
              />
            ))
        )}

        {messages.length >= MAX_LIST && (
          <Flex mt={4} justify="center">
            <Link
              onClick={() =>
                navigate(
                  routesName.messages.concat(`?classroom=${classroomId ?? ""}`)
                )
              }
            >
              Ver mais
            </Link>
          </Flex>
        )}
      </GridItem>

      {isMobile && (
        <GridItem
          colSpan={isMobile ? 4 : 6}
          mt={10}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          onClick={() => newMessageDialog(classroomId)}
        >
          <Button size="md" w={isMobile ? "100%" : "min-content"}>
            Nova Mensagem
          </Button>
        </GridItem>
      )}
    </React.Fragment>
  );
}
