import { Country, DefaultInputComponentProps } from "react-phone-number-input";
import PhoneNumberInput from "react-phone-number-input/react-hook-form-input";

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";

interface PhoneInputPropsI extends DefaultInputComponentProps {
  name?: string;
  label?: string;
  formControlProps?: any;
  control: any;
  defaultCountry?: Country;
  error?: string | any;
  isDisabled?: boolean;
}

export default function PhoneInput({
  label,
  formControlProps,
  defaultCountry = "BR",
  control,
  name = "telephone",
  error,
  isDisabled,
  ...props
}: PhoneInputPropsI) {
  return (
    <FormControl isInvalid={!!error} isDisabled={isDisabled} {...formControlProps}>
      {label && <FormLabel>{label}</FormLabel>}

      <PhoneNumberInput
        name={name}
        defaultCountry={defaultCountry}
        control={control}
        inputComponent={Input}
        {...props}
      />

      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
}
