import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Select as SelectComponent,
  SelectProps,
} from "@chakra-ui/react";

interface SelectPropsI extends Omit<FormControlProps, "onChange"> {
  label?: string;
  value?: string;
  placeholder?: string;
  helperText?: string;
  error?: string | any;
  options: {
    id?: string;
    label: string;
    value: any;
  }[];
  selectProps?: SelectProps;
  onChange?: SelectProps["onChange"];
}

export default function Select({
  label,
  value,
  helperText,
  options = [],
  placeholder,
  error,
  onChange,
  selectProps,
  ...props
}: SelectPropsI) {
  return (
    <FormControl {...props} isInvalid={!!error}>
      {label && <FormLabel>{label}</FormLabel>}
      <SelectComponent
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        {...selectProps}
      >
        {options.map((option, index) => (
          <option key={option.id || index.toString()} value={option.value}>
            {option.label}
          </option>
        ))}
      </SelectComponent>

      {error && <FormErrorMessage>{error}</FormErrorMessage>}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
