import { useContext } from "react";

import { DialogContext } from "../contexts/dialog";

export function useDialog() {
  const context = useContext(DialogContext);

  if (!context) throw Error("hook with provider not defined");

  return context;
}
