export default function TwitterIcon({ size = 24, color = "black" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3227_4884)">
        <path
          d="M21.1663 4.49959C20.3683 5.06249 19.4848 5.49302 18.5497 5.77459C18.0478 5.19752 17.3808 4.7885 16.6389 4.60286C15.897 4.41722 15.1159 4.46392 14.4014 4.73664C13.6869 5.00935 13.0734 5.49493 12.6438 6.12769C12.2143 6.76045 11.9894 7.50987 11.9997 8.27459V9.10792C10.5352 9.1459 9.08407 8.8211 7.77551 8.16246C6.46696 7.50382 5.34161 6.53179 4.49967 5.33292C4.49967 5.33292 1.16634 12.8329 8.66634 16.1663C6.95011 17.3312 4.90564 17.9154 2.83301 17.8329C10.333 21.9996 19.4997 17.8329 19.4997 8.24959C19.4989 8.01747 19.4766 7.78592 19.433 7.55792C20.2835 6.71917 20.8837 5.66018 21.1663 4.49959V4.49959Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3227_4884">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
