import { ComponentStyleConfig } from "@chakra-ui/react";

const TextTheme: ComponentStyleConfig = {
  baseStyle: {
    fontFamily: "heading",
  },
  sizes: {},
  variants: {},
  defaultProps: {},
};

export default TextTheme;
