import { useCallback, useEffect, useState } from "react";
import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";

import { Box, Button, Divider, Flex, Heading, Text } from "@chakra-ui/react";

import animationData from "../../assets/animations/nof-found-ilustration.json";
import routesName from "../../config/routes";
import { useResposiveness } from "../../hooks/responsiveness";

const LIMIT = 5;

export default function NotFoundPage() {
  const { isMobile } = useResposiveness();
  const navigate = useNavigate();
  const [time, setTime] = useState<any>(1);

  const handleRedirect = useCallback((path: any = routesName.main) => {
    return navigate(path);
  }, [navigate]);

  useEffect(() => {
    if (time >= LIMIT) {
      return handleRedirect(-1);
    }

    const intervalId = setInterval(() => {
      setTime(time + 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [handleRedirect, time]);

  return (
    <Flex w="100vw" h="100vh" px="6%" pt="8%" justify="center">
      <Flex flex={1}>
        <Box>
          <Heading fontSize="25vh" color="brand.darkest">
            404
          </Heading>
          <Heading fontSize="8vh" color="brand.dark">
            Se perdeu no mar?
          </Heading>

          <Divider my={6} />

          <Flex mt={4} align="center">
            <Button size="lg" onClick={() => handleRedirect()}>
              Home
            </Button>

            <Text
              ml={10}
              fontSize="lg"
              color="neutral.medium"
              fontWeight="normal"
              textAlign="center"
            >
              Em {time} você será redirecionado de volta
            </Text>
          </Flex>
        </Box>
      </Flex>

      <Flex
        justify="center"
        align="center"
        height={isMobile ? "80vh" : "60vh"}
        width={isMobile ? "80vw" : "60vw"}
        flex={1}
      >
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData,
          }}
          isClickToPauseDisabled
          style={{
            cursor: "auto",
          }}
        />
      </Flex>
    </Flex>
  );
}
