export default function HowToWorkFourtyIcon() {
  return (
    <svg
      width="197"
      height="104"
      viewBox="0 0 197 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1160_13468"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="197"
        height="104"
      >
        <rect x="0.0712891" width="196.857" height="104" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_1160_13468)">
        <path d="M269.494 0H-61.4199V1.10548H269.494V0Z" fill="#C5CED6" />
        <path
          d="M269.494 25.668H-61.4199V26.7734H269.494V25.668Z"
          fill="#C5CED6"
        />
        <path
          d="M269.494 51.3438H-61.4199V52.4492H269.494V51.3438Z"
          fill="#C5CED6"
        />
        <path
          d="M269.494 77.0156H-61.4199V78.1211H269.494V77.0156Z"
          fill="#C5CED6"
        />
        <path
          d="M269.494 102.684H-61.4199V103.789H269.494V102.684Z"
          fill="#C5CED6"
        />
        <g opacity="0.2">
          <path
            opacity="0.2"
            d="M269.493 103.789H-61.8262V53.3053L-31.4403 68.2809L0.00683594 72.548L27.5995 64.2274L60.8892 38.4109L77.6334 28.7416L89.8748 26.3833L101.585 27.0097L120.843 36.5242L147.028 53.2022L167.575 55.8406L183.862 50.2247L218.398 21.9466L236.026 15.6602L254.473 18.5123L269.493 27.1866V103.789Z"
            fill="#0F7E99"
          />
        </g>
        <path
          d="M-4.07552 72.535C-33.916 72.535 -61.4866 54.2872 -61.833 54.0514L-61.0076 52.828C-60.5801 53.1154 -18.1446 81.1872 17.9161 67.1255C31.0639 61.9666 40.4236 54.0219 49.4811 46.3057C58.9367 38.2504 67.8616 30.6447 80.0366 27.3431C93.995 23.5623 109.251 26.9819 120.843 36.5111C130.756 44.6548 150.492 57.7289 171.342 53.8229C181.969 51.8257 190.923 44.0652 200.408 35.8479C218.59 20.0911 237.398 3.7964 269.928 27.1736L269.066 28.3675C237.479 5.66834 219.128 21.5798 201.374 36.9607C191.734 45.3108 182.632 53.1744 171.614 55.2674C150.168 59.2914 130.019 45.9519 119.907 37.6461C108.683 28.4264 93.9287 25.1174 80.4198 28.7654C68.5691 31.9787 59.7842 39.4812 50.4319 47.4259C41.2859 55.2232 31.8304 63.2784 18.4541 68.4963C11.2618 71.2359 3.62051 72.6057 -4.07552 72.535Z"
          fill="#0F7E99"
        />
        <path
          d="M97.813 34.5127C102.343 34.5127 106.016 30.8403 106.016 26.3101C106.016 21.7799 102.343 18.1074 97.813 18.1074C93.2828 18.1074 89.6104 21.7799 89.6104 26.3101C89.6104 30.8403 93.2828 34.5127 97.813 34.5127Z"
          fill="#0F7E99"
        />
      </g>
    </svg>
  );
}
