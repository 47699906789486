import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";

import { Divider, Modal, ModalContent } from "@chakra-ui/react";

import routesName from "../../../../config/routes";
import { useMobileSearch } from "../../../../hooks/mobile-search";
import DialogHeader from "../../header";
import MobileSearchDate from "./Date";
import MobileSearchLocalizations from "./localizations";
import MobileSearchModality from "./modality";
import MobileSearchOptions, { OPTIONS } from "./options";
import MobileSearchSchools from "./school";

const TITLES = {
  [OPTIONS.SCHOOL]: "Escolas",
  [OPTIONS.LOCALIZATION]: "Localizações",
  [OPTIONS.DATE]: "Datas",
  [OPTIONS.MODALITY]: "Modalidades",
  DEFAULT: "Buscar",
};

export const SEARCH_FORM_FIELD_MOBILE = {
  LOCALIZATION: "localization",
  SCHOOL: "school",
  DATE: "date",
  MODALITY: "modality",
  LEVEL: "level",
};

const formSchema = yup.object().shape({}).required();

export default function MobileSearchDialog() {
  const { isOpen, close } = useMobileSearch();
  const location = useLocation();
  const navigate = useNavigate();

  const [searchOption, setSearchOption] = useState<OPTIONS | null>(null);

  const formMethods = useForm({
    resolver: yupResolver(formSchema),
  });

  const handleOnClose = () => {
    setSearchOption(null);
    close();
  };

  const onSubmit = async (form: any) => {
    const params = {} as any;

    if (form[SEARCH_FORM_FIELD_MOBILE.LOCALIZATION])
      params[SEARCH_FORM_FIELD_MOBILE.LOCALIZATION] =
        form[SEARCH_FORM_FIELD_MOBILE.LOCALIZATION];

    if (form[SEARCH_FORM_FIELD_MOBILE.SCHOOL])
      params[SEARCH_FORM_FIELD_MOBILE.SCHOOL] =
        form[SEARCH_FORM_FIELD_MOBILE.SCHOOL];

    if (form[SEARCH_FORM_FIELD_MOBILE.DATE])
      params[SEARCH_FORM_FIELD_MOBILE.DATE] =
        form[SEARCH_FORM_FIELD_MOBILE.DATE];

    if (form[SEARCH_FORM_FIELD_MOBILE.MODALITY])
      params[SEARCH_FORM_FIELD_MOBILE.MODALITY] = (
        form[SEARCH_FORM_FIELD_MOBILE.MODALITY] || []
      ).join(", ");

    if (form[SEARCH_FORM_FIELD_MOBILE.LEVEL])
      params[SEARCH_FORM_FIELD_MOBILE.LEVEL] = (
        form[SEARCH_FORM_FIELD_MOBILE.LEVEL] || []
      ).join(", ");

    const url = new URLSearchParams(params);

    navigate(routesName.search.concat(`?${url.toString()}`));

    close();
  };

  const clear = () => {
    formMethods.reset();

    if (location.pathname === routesName.search) {
      navigate(location.pathname, {
        replace: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={close} size="full">
      <ModalContent padding="16px" max-width="100vw" overflowY="scroll">
        <DialogHeader
          title={TITLES[searchOption || "DEFAULT"]}
          close={handleOnClose}
          withBottom
        />

        <Divider mx="-32px" my={4} w="calc(100% + 64px)" />

        <FormProvider {...formMethods}>
          {searchOption === null && (
            <MobileSearchOptions
              onClick={(option) => setSearchOption(option)}
              onSubmit={formMethods.handleSubmit(onSubmit)}
              onAbort={clear}
            />
          )}

          {searchOption === OPTIONS.LOCALIZATION && (
            <MobileSearchLocalizations onSubmit={() => setSearchOption(null)} />
          )}

          {searchOption === OPTIONS.SCHOOL && (
            <MobileSearchSchools onSubmit={() => setSearchOption(null)} />
          )}

          {searchOption === OPTIONS.DATE && (
            <MobileSearchDate onSubmit={() => setSearchOption(null)} />
          )}

          {searchOption === OPTIONS.MODALITY && (
            <MobileSearchModality onSubmit={() => setSearchOption(null)} />
          )}
        </FormProvider>
      </ModalContent>
    </Modal>
  );
}
