export default function SurferIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_521_3884)">
        <path
          d="M25.7907 11.238C28.3103 10.8019 30.0054 8.39708 29.5692 5.87758C29.1331 3.35775 26.7283 1.66248 24.2088 2.09859C21.6889 2.53476 19.9935 4.93975 20.4297 7.45958C20.8658 9.97909 23.2708 11.6742 25.7907 11.238ZM24.6382 4.57955C25.7899 4.38021 26.8889 5.15519 27.0883 6.30702C27.2876 7.45852 26.513 8.55765 25.3613 8.757C24.2093 8.9564 23.11 8.18164 22.9107 7.03014C22.7113 5.87815 23.4862 4.77896 24.6382 4.57955Z"
          fill="#0F7E99"
        />
        <path
          d="M37.0644 32.2336L25.5364 31.8535L25.4715 24.8773C25.4671 24.4033 25.1968 23.9719 24.7721 23.7613L20.3997 21.5921L25.7716 15.4413L33.7913 18.543C34.0118 18.6283 34.2417 18.6473 34.459 18.6097C34.8805 18.5367 35.254 18.2509 35.4196 17.823C35.6705 17.1744 35.3479 16.4454 34.6996 16.1946L14.9164 8.54316L11.2512 2.9355C10.8709 2.35347 10.0912 2.18998 9.50868 2.57036C8.92668 2.95082 8.76325 3.73088 9.14371 4.31288L13.0304 10.2597C13.1745 10.4802 13.3843 10.65 13.6301 10.7451L18.8249 12.7542L14.261 19.79C14.0781 20.0719 14.0156 20.4155 14.0873 20.7439L15.4511 26.9845L9.21109 31.2775C9.19483 31.2888 9.17998 31.3015 9.16449 31.3133L3.11426 31.1138C2.41975 31.0912 1.83767 31.6356 1.81452 32.3306C1.79155 33.0255 2.33637 33.6073 3.03113 33.6303L5.41814 33.7091L5.30685 37.0806C5.28387 37.7755 5.82853 38.3574 6.52329 38.3804C6.92713 38.3938 7.33268 38.3658 7.72814 38.2974C7.72831 38.2974 7.72814 38.2974 7.72831 38.2974C10.0401 37.8972 11.8632 36.1491 12.4275 33.9403L36.9816 34.7501C37.0697 34.7529 37.1557 34.7469 37.2392 34.7324C37.8148 34.6328 38.2614 34.1403 38.2814 33.5335C38.304 32.8384 37.7593 32.2564 37.0644 32.2336ZM7.87268 35.6632L7.93451 33.7923L9.80273 33.8541C9.43422 34.6844 8.73934 35.3468 7.87268 35.6632ZM23.2729 14.4747L18.0676 20.4349L17.1421 19.9755L21.2242 13.6825L23.2729 14.4747ZM17.5752 28.5795C17.9967 28.2895 18.2007 27.7733 18.0915 27.2735L17.1072 22.7688L17.8163 23.1207L17.8165 23.1208C17.8169 23.1211 17.8173 23.1214 17.8178 23.1216L22.9611 25.6733L23.018 31.7703L13.3984 31.453L17.5752 28.5795Z"
          fill="#0F7E99"
        />
      </g>
      <defs>
        <clipPath id="clip0_521_3884">
          <rect
            width="39.1111"
            height="39.1111"
            fill="white"
            transform="translate(0.444336 0.444336)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
