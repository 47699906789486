import { AuthService } from "./services/auth";
import { ClassroomService } from "./services/classroom";
import { CMSService } from "./services/cms";
import { InvitesService } from "./services/invites";
import { LessonService } from "./services/lesson";
import { LocationsService } from "./services/locations";
import { MediasService } from "./services/medias";
import { PaymentsService } from "./services/payments";
import { ScheduleService } from "./services/schedules";
import { SchoolsService } from "./services/schools";
import { StudentService } from "./services/students";
import { SupportService } from "./services/support";
import api from "./setup";

export interface ServiceResponse<T = any | null> {
  data: T;
  error: {
    statusCode: number;
    message: string;
  } | null;
}

export const authService = new AuthService(api);
export const lessonService = new LessonService(api);
export const scheduleService = new ScheduleService(api);
export const classroomService = new ClassroomService(api);
export const schoolsService = new SchoolsService(api);
export const mediasService = new MediasService(api);
export const studentService = new StudentService(api);
export const paymentsService = new PaymentsService(api);
export const invitesService = new InvitesService(api);
export const locationsService = new LocationsService(api);
export const supportService = new SupportService(api);
export const cmsService = new CMSService(api);

export default api;
