import React from "react";

import { Flex, Heading, IconButton } from "@chakra-ui/react";

import CloseIcon from "../../assets/icons/close";
import { useResposiveness } from "../../hooks/responsiveness";

interface DialogHeaderPropsI {
  title?: string;
  close?: () => any;
  withBottom?: boolean;
}

export default function DialogHeader({
  title = "",
  close,
  withBottom = false,
}: DialogHeaderPropsI) {
  const { isMobile } = useResposiveness();

  return (
    <Flex
      direction={isMobile ? "row-reverse" : "row"}
      justify="space-between"
      alignItems="center"
    >
      <Heading
        flex={5}
        fontSize={isMobile ? "sm" : "lg"}
        color="neutral.darkest"
        fontWeight="semibold"
        textAlign={isMobile ? "center" : "start"}
        ml={isMobile ? "-40px" : "0px"}
        sx={{
          "&::first-letter": {
            textTransform: "capitalize",
          },
        }}
      >
        {title}
      </Heading>

      {isMobile && (
        <IconButton
          autoFocus={false}
          variant="unstyled"
          aria-label="close icon"
          icon={<CloseIcon />}
          onClick={close}
        />
      )}
    </Flex>
  );
}
