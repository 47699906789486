import React, { useRef } from "react";

import { Box } from "@chakra-ui/react";

interface UploadButtonPropsI {
  children: any;
  onUpload: (files: File[]) => void;
  maxFiles?: number;
}

export default function UploadButton({
  children,
  onUpload,
  maxFiles = 8,
}: UploadButtonPropsI) {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleOnChange = (e: any) => {
    const files = e.target.files as FileList;

    if (onUpload) onUpload(Array.from(files));
  };

  return (
    <Box>
      <input
        type="file"
        ref={inputRef}
        hidden
        accept="image/*"
        multiple
        onChange={handleOnChange}
        maxLength={maxFiles}
      />

      {React.cloneElement(children, {
        onClick: () => inputRef?.current?.click(),
      })}
    </Box>
  );
}
