import React, { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import * as yup from "yup";

import { GridItem, Heading, Text } from "@chakra-ui/react";

import { MediaContext, MediaObject } from "../../../../@types/medias";
import CardFeedbackInfo from "../../../../components/cards/feedback-info";
import Galery from "../../../../components/galery";
import { useLoader } from "../../../../hooks/loader";
import { useResposiveness } from "../../../../hooks/responsiveness";
import { mediasService } from "../../../../services/api";

export const formSchema = {
  photos: yup
    .array()
    .of(yup.string())
    .min(2, "Selecione no mínimo 2 fotos")
    .required("Campo obrigatório")
    .default([]),
};

const isFileUrl = (file: any) => typeof file === "string";

export default function StepAlbum() {
  const { isMobile } = useResposiveness();
  const {
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();
  const loader = useLoader();

  const photos = watch("photos");

  const handleAddImages = useCallback(
    async (files: File[] | string[]) => {
      if (files.length && !files.some(isFileUrl)) {
        loader.run();

        const filesObj = (files as File[]).filter((f: any) => !isFileUrl(f));

        const {
          data: { links },
        } = await mediasService.getUploadUrls(
          filesObj.map((file) => ({
            context: MediaContext["FORM-MEDIAS"],
            object: MediaObject.ALBUMS,
            name: file.name,
          }))
        );

        const toUpload = filesObj.map((file, index) => {
          const link = links.find((l) => l.name === file.name);
          return {
            url: link?.url,
            file,
          };
        });

        try {
          for (const { url, file } of toUpload) {
            const { data: urlRemote } = await mediasService.uploadByUrl(
              url || "",
              file
            );

            const index = (files as File[]).findIndex(
              (f) => f?.name === file.name
            );

            files[index] = urlRemote;
          }
        } catch (error) {
          console.error(error);
        } finally {
          loader.stop();
        }
      }

      setValue("photos", files, {
        shouldValidate: true,
      });
    },
    [loader, setValue]
  );

  return (
    <React.Fragment>
      <GridItem colStart={1} colSpan={isMobile ? 4 : 6} mt={2}>
        <Heading fontSize="xl" fontWeight="semibold">
          Fotos
        </Heading>
      </GridItem>

      <GridItem colStart={1} colSpan={isMobile ? 4 : 5} mt={8}>
        <Heading fontSize="sm" fontWeight="semibold">
          Galeria de fotos
        </Heading>
      </GridItem>

      {errors?.photos?.message && (
        <GridItem colStart={1} colSpan={isMobile ? 4 : 5} mt={4} mb={8}>
          <Text fontSize="xs" color="feedback.danger.medium">
            {errors?.photos?.message as string}
          </Text>
        </GridItem>
      )}

      <GridItem colStart={1} colSpan={isMobile ? 4 : 12} mt={8}>
        <Galery images={photos} onChange={handleAddImages} />
      </GridItem>

      <GridItem colStart={1} colSpan={isMobile ? 4 : 12} mt={8}>
        <CardFeedbackInfo
          title="Arraste as imagens para posicionar na ordem"
          message="Arraste para posicionar a ordem de exibição das fotos no anúncio. A primeira foto também será apresentada como a capa do nnúncio."
        />
      </GridItem>
    </React.Fragment>
  );
}
