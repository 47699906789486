import { MouseEvent } from "react";

import { Button, Flex, FlexProps, Text } from "@chakra-ui/react";

interface ShareLinkPropsI extends Omit<FlexProps, "onClick"> {
  link: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}

export function ShareLink({ link, onClick, ...props }: ShareLinkPropsI) {
  const handleCopy = (e: MouseEvent<HTMLButtonElement>) => {
    navigator.clipboard.writeText(link);

    return onClick?.(e);
  };

  return (
    <Flex
      border="hairline"
      p={2}
      pl={4}
      borderColor="neutral.medium"
      borderRadius="sm"
      justify="space-between"
      align="center"
      bg="neutral.light"
      {...props}
    >
      <Text
        color="neutral.dark"
        whiteSpace="nowrap"
        maxW="78%"
        pr={4}
        overflowX="scroll"
      >
        {link}
      </Text>
      <Button w="20%" onClick={handleCopy}>
        Copiar
      </Button>
    </Flex>
  );
}
