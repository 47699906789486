export enum GenderLabel {
  "MALE" = "Masculino",
  "FEMALE" = "Feminino",
}

export type OffsetListResponse = 10 | 25 | 50 | 100;

export interface FilterPagination {
  page?: number;
  offset?: OffsetListResponse;
}
