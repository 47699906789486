import { useFormContext } from "react-hook-form";

import { Button, Checkbox, Divider, Flex, Text } from "@chakra-ui/react";

import { SEARCH_FORM_FIELD_MOBILE } from "..";

import {
  lessonLevelOptions,
  lessonModalityOptions,
} from "../../../../../pages/school/create-class/steppers/setup";

interface MobileSearchModalityProps {
  onSubmit?: () => void;
}

export default function MobileSearchModality({
  onSubmit,
}: MobileSearchModalityProps) {
  const { setValue, watch } = useFormContext();

  const modality = watch(SEARCH_FORM_FIELD_MOBILE.MODALITY, []) ?? [];
  const level = watch(SEARCH_FORM_FIELD_MOBILE.LEVEL, []) ?? [];

  const handleOnClick = (
    field: string,
    store: string[] = [],
    key: string,
    value: string
  ) => {
    const hasValue = store?.includes(value);

    if (hasValue)
      return setValue(
        field,
        store?.filter((item: any) => item !== key)
      );

    setValue(field, [...store, key]);
  };

  return (
    <Flex direction="column" w="100%" flex={1} mb="15%">
      <Flex direction="column" w="100%" p={4} mb="10%">
        {lessonModalityOptions.map(({ label, value }, index) => (
          <Checkbox
            key={label}
            mt={index > 0 ? 8 : 0}
            value={value}
            isChecked={!!modality?.includes(value)}
            onChange={(e) => {
              e.preventDefault();
              handleOnClick(
                SEARCH_FORM_FIELD_MOBILE.MODALITY,
                modality,
                value,
                e.target.value
              );
            }}
          >
            <Text fontSize={["md", "lg"]} color="neutral.dark" ml={2}>
              {label}
            </Text>
          </Checkbox>
        ))}

        <Divider mx={-10} w="100vw" my={10} />

        {lessonLevelOptions.map(({ label, value }, index) => (
          <Checkbox
            key={label}
            mt={index > 0 ? 8 : 0}
            value={value}
            isChecked={!!level?.includes(value)}
            onChange={(e) => {
              e.preventDefault();
              handleOnClick(
                SEARCH_FORM_FIELD_MOBILE.LEVEL,
                level,
                value,
                e.target.value
              );
            }}
          >
            <Text fontSize={["md", "lg"]} color="neutral.dark" ml={2}>
              {label}
            </Text>
          </Checkbox>
        ))}
      </Flex>

      <Button size="md" w="100%" mt="auto" onClick={onSubmit}>
        Concluir
      </Button>
    </Flex>
  );
}
