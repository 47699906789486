import { useCallback, useEffect, useMemo, useState } from "react";

import { Flex, IconButton, Image } from "@chakra-ui/react";

import ChevronLeftIcon from "../../assets/icons/chevron-left";
import ChevronRightIcon from "../../assets/icons/chevron-right";

interface AlbumPhotosPropsI {
  photos: string[];
}

export default function AlbumPhotos({ photos = [] }: AlbumPhotosPropsI) {
  const [currentPhoto, setCurrentPhoto] = useState<string | null>(null);
  const indexOf = useMemo(
    () => photos.indexOf(currentPhoto || ""),
    [currentPhoto, photos]
  );

  useEffect(() => {
    if (photos.length > 0) setCurrentPhoto(photos[0]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePreviousPhoto = useCallback(() => {
    if (indexOf > 0) setCurrentPhoto(photos[indexOf - 1]);
  }, [photos, indexOf]);

  const handleNextPhoto = useCallback(() => {
    if (indexOf < photos.length - 1) setCurrentPhoto(photos[indexOf + 1]);
  }, [photos, indexOf]);

  return (
    <Flex bg="neutral.light" borderRadius="sm" position="relative">
      <Flex position="absolute" left={0} ml={8} h="100%" alignItems="center">
        <IconButton
          disabled={indexOf === 0}
          aria-label="arrow-left"
          icon={
            <ChevronLeftIcon
              color="neutral.dark"
              fontSize="2xl"
              _hover={{
                color: "neutral.lightest",
              }}
            />
          }
          borderRadius="full"
          size="lg"
          bg="neutral.lightest"
          pl={4}
          pr={4}
          pt={4}
          pb={4}
          onClick={handlePreviousPhoto}
        />
      </Flex>

      <Image
        src={currentPhoto || ""}
        w="100%"
        maxH="60vh"
        objectFit="contain"
        loading="lazy"
        borderRadius="sm"
      />

      <Flex position="absolute" right={0} mr={8} h="100%" alignItems="center">
        <IconButton
          disabled={indexOf === photos.length - 1}
          aria-label="arrow-right"
          icon={
            <ChevronRightIcon
              color="neutral.dark"
              fontSize="2xl"
              _hover={{
                color: "neutral.lightest",
              }}
            />
          }
          borderRadius="full"
          size="lg"
          bg="neutral.lightest"
          pl={4}
          pr={4}
          pt={4}
          pb={4}
          onClick={handleNextPhoto}
        />
      </Flex>
    </Flex>
  );
}
