import moment from "moment-timezone";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { ISchedule } from "@isurf-tech/types/domain";

import { OffsetListResponse } from "../@types/app";
import { SchedulesFilters } from "../@types/schedules";
import { scheduleService } from "../services/api";
import { SchedulesFactory } from "../services/api/factories/schedules";

interface SchedulesContextData {
  loading: boolean;
  schedules: { [key in string]: ISchedule[] };
  pagination: {
    page: number;
    offset: OffsetListResponse;
    count: number;
  };
  updateFilters: (filters: SchedulesFilters) => void;
  updatePage: (page: number) => void;
}

export const SchedulesContext = createContext<SchedulesContextData>(
  null as any
);

export default function SchedulesProvider({ children }: any) {
  const [loading, setLoading] = useState<boolean>(false);
  const [schedules, setSchedules] = useState<SchedulesContextData["schedules"]>(
    {}
  );
  const [startTime, setStartTime] = useState<string>();
  const [endTime, setEndTime] = useState<string>();
  const [pagination, setPagination] = useState<
    SchedulesContextData["pagination"]
  >({
    page: 0,
    count: 0,
    offset: 10,
  });

  const getSchedules = useCallback(
    async (currentPagination: any) => {
      setLoading(true);
      const { data: { results: schedules = [], page, offset, count } = {} } =
        await scheduleService.list({
          start: startTime,
          end: endTime,
          page: currentPagination.page || 0,
          offset: currentPagination?.offset || 10,
        });

      const schedulesData = SchedulesFactory.groupByDate(schedules || []);

      if (schedulesData) setSchedules(schedulesData);
      if (page > -1)
        setPagination((prev) =>
          Object.assign({}, prev, { page, offset, count })
        );

      setLoading(false);
    },
    [endTime, startTime, setPagination]
  );

  const updateFilters = useCallback((filters: SchedulesFilters) => {
    setStartTime(filters?.start || moment().startOf("day").toISOString());
    setEndTime(filters?.end || moment().endOf("day").toISOString());
    setPagination((prev) => ({ ...prev, page: filters.page || 0 }));
  }, []);

  const updatePage = useCallback(
    (page: any = 0) => {
      if (pagination.page !== page)
        setPagination((prev) => ({ ...prev, page }));
    },
    [pagination, setPagination]
  );

  const value = useMemo(
    () => ({
      loading,
      schedules,
      updateFilters,
      pagination,
      updatePage,
    }),
    [loading, schedules, updateFilters, pagination, updatePage]
  );

  useEffect(() => {
    if (!!startTime && !!endTime && pagination.page >= 0)
      getSchedules(pagination)
        .then(() => {})
        .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime, endTime, getSchedules, pagination.page]);

  return (
    <SchedulesContext.Provider value={value}>
      {children}
    </SchedulesContext.Provider>
  );
}
