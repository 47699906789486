import { Flex, Heading, IconButton } from "@chakra-ui/react";

import { SESSION_TYPE } from "../../@types/session";
import CloseIcon from "../../assets/icons/close";
import { useResposiveness } from "../../hooks/responsiveness";
import { useSession } from "../../hooks/session";

const HEADER_TITLES = {
  [SESSION_TYPE.SIGN_IN]: "acesse sua conta",
  [SESSION_TYPE.SIGN_UP]: "cadastre-se",
  [SESSION_TYPE.VERIFY_EMAIL]: "",
  [SESSION_TYPE.VERIFY_PHONE]: "",
};

export default function SessingHeader() {
  const { isMobile } = useResposiveness();
  const { type, close } = useSession();

  return (
    <Flex
      direction={isMobile ? "row-reverse" : "row"}
      justify="space-between"
      alignItems="center"
    >
      <Heading
        flex={5}
        fontSize={isMobile ? "sm" : "lg"}
        color="neutral.darkest"
        fontWeight="semibold"
        textAlign={isMobile ? "center" : "start"}
        ml={isMobile ? "-40px" : "0px"}
        sx={{
          "&::first-letter": {
            textTransform: "capitalize",
          },
        }}
      >
        {HEADER_TITLES[type]}
      </Heading>

      <IconButton
        autoFocus={false}
        variant="unstyled"
        aria-label="close icon"
        icon={<CloseIcon />}
        onClick={close}
      />
    </Flex>
  );
}
