import React from "react";

import { Box, Divider, Flex, Heading, Skeleton, Text } from "@chakra-ui/react";

import { ILesson } from "@isurf-tech/types/domain";

interface LessonNamePropsI {
  lesson: ILesson;
  loading?: boolean;
}

export default function LessonName({
  lesson,
  loading = false,
}: LessonNamePropsI) {
  return (
    <React.Fragment>
      <Skeleton isLoaded={!loading} borderRadius="sm" w="15%">
        <Flex>
          {[lesson?.modality, lesson?.variation]
            .filter((t) => !!t)
            .map((tag, index) => (
              <Box
                key={index.toString()}
                bg={
                  index % 2 === 0
                    ? "neutral.light"
                    : "feedback.success.lightest"
                }
                borderRadius={4}
                p="4px"
                ml={index > 0 ? "4px" : "0px"}
              >
                <Text
                  fontWeight={500}
                  fontSize={12}
                  color={
                    index % 2 === 0 ? "neutral.dark" : "feedback.success.dark"
                  }
                  textTransform="lowercase"
                  sx={{
                    "&::first-letter": {
                      textTransform: "uppercase",
                    },
                  }}
                >
                  {tag}
                </Text>
              </Box>
            ))}
        </Flex>
      </Skeleton>

      <Skeleton isLoaded={!loading} borderRadius="sm" w="40%">
        <Heading fontSize="xl" color="neutral.darkest" mt={1}>
          {lesson?.name}
        </Heading>
      </Skeleton>

      <Skeleton isLoaded={!loading} borderRadius="sm" w="30%">
        <Text color="neutral.darkest" fontSize="sm" mt={1}>
          {`${lesson?.location?.address} · ${lesson?.location?.city}/${lesson?.location?.state}`}
        </Text>
      </Skeleton>

      <Skeleton isLoaded={!loading} borderRadius="sm" w="25%">
        <Text color="neutral.darkest" fontSize="sm">
          {lesson?.school?.name}
        </Text>
      </Skeleton>

      <Divider mt={10} />
    </React.Fragment>
  );
}
