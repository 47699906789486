import debounce from "lodash.debounce";
import { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { Flex, Heading, Skeleton, Stack, Text } from "@chakra-ui/react";

import { SEARCH_FORM_FIELD_MOBILE } from "..";

import { ISchool } from "@isurf-tech/types/domain";

import { schoolsService } from "../../../../../services/api";
import BoxInput from "../../../../inputs/box";

interface MobileSearchSchoolProps {
  onSubmit?: () => void;
}

export default function MobileSearchSchools({
  onSubmit,
}: MobileSearchSchoolProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [schools, setSchools] = useState<ISchool[]>([]);

  const { setValue, watch } = useFormContext();

  const search = watch(SEARCH_FORM_FIELD_MOBILE.SCHOOL, "");

  const requestSchools = async () => {
    setLoading(true);

    const { data: schoolsResp = [] } = await schoolsService.list({
      q: search,
    });

    setLoading(false);
    setSchools(schoolsResp || []);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((text: string) => {
      setValue(SEARCH_FORM_FIELD_MOBILE.SCHOOL, text);
    }, 600),
    []
  );

  const handleSelect = (school: ISchool) => {
    setValue(SEARCH_FORM_FIELD_MOBILE.SCHOOL, school.name);

    if (onSubmit) onSubmit();
  };

  useEffect(() => {
    requestSchools();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <Flex direction="column">
      <BoxInput
        label="Escola"
        placeholder="Nome da escola"
        onChange={(e) => handleSearch(e.target.value)}
      />

      <Flex mt={6} direction="column">
        <Heading
          color="neutral.dark"
          fontWeight="semibold"
          fontSize={["sm", "md"]}
        >
          {!search ? "Sugestões" : "Resultados"}
        </Heading>

        <Flex direction="column" mt={8}>
          {loading ? (
            <Stack spacing={4}>
              <Skeleton height="8vh" width="100%" />
              <Skeleton height="8vh" width="100%" />
              <Skeleton height="8vh" width="100%" />
              <Skeleton height="8vh" width="100%" />
              <Skeleton height="8vh" width="100%" />
            </Stack>
          ) : (
            schools.map((school, index) => (
              <Flex
                key={school.id}
                mt={index > 0 ? 6 : 0}
                onClick={() => handleSelect(school)}
              >
                {/* <MapPinIcon height="4vh" width="4vh" color="neutral.darkest" /> */}

                <Flex direction="column" ml={6}>
                  <Heading
                    fontSize="md"
                    fontWeight="medium"
                    color="neutral.darkest"
                  >
                    {school.name}
                  </Heading>

                  <Text fontSize="sm" fontWeight="light" color="neutral.dark">
                    {school.location.city}/{school.location.stateCode}
                  </Text>
                </Flex>
              </Flex>
            ))
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}
