import React, { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import * as yup from "yup";

import { GridItem, Heading } from "@chakra-ui/react";

import { ILocation, ISchool } from "@isurf-tech/types/domain";

import CollapseSectionText from "../../../../components/collapse-section-text";
import FormLocation from "../../../../components/forms/locations";
import Select from "../../../../components/select";
import TextField from "../../../../components/text-field";
import { useAuth } from "../../../../hooks/auth";
import { useResposiveness } from "../../../../hooks/responsiveness";
import { locationsService } from "../../../../services/api";


interface ILocationForm {
  location: {
    state: string;
    city: string;
    address: string;
  };
  locationDetails: string;
}

export const formSchema = {
  location: yup
    .object()
    .shape({
      state: yup.string().required("Campo obrigatório"),
      city: yup.string().required("Campo obrigatório"),
      address: yup.string().required("Campo obrigatório"),
    })
    .required("required"),
  locationDetails: yup.string().required("Campo obrigatório"),
};

export default function StepLocalization() {
  const { isMobile } = useResposiveness();
  const { user } = useAuth<ISchool>();
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<ILocationForm>();
  const state = watch("location.state", "");
  const city = watch("location.city", "");
  const address = watch("location.address", "");

  const [locations, setLocations] = useState<ILocation[]>([]);
  const [manual, setManual] = useState(false);

  const handleSetLocation = useCallback(
    (location: ILocation) => {
      const { state, city, address } = location || {};

      if (!state || !city || !address) return;

      const config = { shouldValidate: true };

      if (state) setValue("location.state", state, config);
      if (city) setValue("location.city", city, config);
      if (address) setValue("location.address", address, config);
    },
    [setValue]
  );

  const handleSelectLocation = useCallback(
    (locationId: string) => {
      if (!locationId)
        return setValue("location", {
          state: "",
          city: "",
          address: "",
        });

      const location = locations.find((location) => location.id === locationId);

      if (location) handleSetLocation(location);
    },
    [handleSetLocation, locations, setValue]
  );

  const loadDataManualLocation = async () => {
    setManual(!manual);
  };

  const getValueOption = () => {
    const location = locations.find(
      (location: ILocation) =>
        location?.state === state &&
        location?.city === city &&
        location?.address === address
    );

    if (location) return location.id;

    return "";
  };

  const renderOptionsLocation = (
    options: any
  ): { label: string; value: any }[] => {
    return options.map((location: any) => ({
      label: `${location.address}, ${location.city} - ${location.state}`,
      value: location.id,
    }));
  };

  useEffect(() => {
    (async () => {
      const { data: locationsData } = await locationsService.list();

      setLocations(locationsData);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <GridItem colStart={1} colSpan={isMobile ? 4 : 6} mt={2}>
        <Heading fontSize="xl" fontWeight="semibold">
          Localização
        </Heading>
      </GridItem>

      <GridItem colStart={1} colSpan={4} mt={8}>
        <Select
          isDisabled={manual}
          label="Algumas opções de localização"
          placeholder="selecione a localização aqui..."
          value={getValueOption()}
          options={renderOptionsLocation(locations)}
          onChange={(e) => handleSelectLocation(e.target.value)}
          error={
            errors?.location?.state?.message ||
            errors?.location?.city?.message ||
            errors?.location?.address?.message
          }
        />
      </GridItem>

      <GridItem colStart={1} colSpan={4} mt={6}>
        <CollapseSectionText
          isOpen={!!state || !!city || !!address}
          title="Ou adicione manualmente"
          textProps={{
            fontSize: "xs",
            color: "gray.500",
          }}
          onClick={loadDataManualLocation}
        >
          <FormLocation space={4} user={user} />
        </CollapseSectionText>
      </GridItem>

      <GridItem colStart={1} colSpan={isMobile ? 4 : 5} mt={8}>
        <TextField
          type="text-area"
          label="Ponto de encontro"
          placeholder=" "
          fullWidth
          helperText="Detalhe onde os alunos deverão se encontrar com o instrutor."
          inputProps={{
            minH: "15vh",
            ...register("locationDetails"),
          }}
          error={errors?.locationDetails?.message}
        />
      </GridItem>
    </React.Fragment>
  );
}
