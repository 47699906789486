import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { Button, Flex, Heading, Link, Text } from "@chakra-ui/react";

import TextField from "../../../components/text-field";
import { useLoader } from "../../../hooks/loader";
import { useResposiveness } from "../../../hooks/responsiveness";
import { useSession } from "../../../hooks/session";
import { authService } from "../../../services/api";



const formSchema = yup
  .object()
  .shape({
    email: yup.string().email().required(),
  })
  .required();

export default function RecoveryRequest() {
  const { isMobile } = useResposiveness();
  const { openSignUp } = useSession();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(formSchema) });
  const loader = useLoader();
  const [response, setResponse] = useState<{
    success?: any;
    error?: any;
  }>({});

  const onSubmit = async ({ email }: any) => {
    loader.run();

    const { data, error } = await authService.requestRecoveyPassword(email);

    if (data)
      setResponse({
        success: data || "Email enviado com sucesso",
      });

    if (error)
      setResponse({
        error:
          typeof error === "string"
            ? error
            : error?.message || "Erro ao solicitar recuperação de senha",
      });

    loader.stop();
  };

  return (
    <Flex
      as="form"
      direction="column"
      h="100%"
      justify="center"
      mt={isMobile ? -4 : -6}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Heading fontSize="lg" color="neutral.darkest">
        Recuperação de senha
      </Heading>

      <Text mt={10} w={isMobile ? "100%" : "80%"} color="neutral.dark">
        Informe seu email cadastrado. Lhe enviaremos um email com um link de
        recuperação de senha. Ao acessar o link, você receberá um formulário
        para informar uma nova senha. Após informar uma nova senha, você será
        redirecionado para a home, onde poderá acessar sua conta com a nova
        senha.
        <br />
        <br />
        Caso não encontre o email, verifique sua caixa de spam.
      </Text>

      <TextField
        label="Email"
        type="email"
        fullWidth
        placeholder="joao.silva@surf.com"
        mt={6}
        inputProps={register("email")}
        error={errors?.email?.message}
      />

      {response?.success || response?.error ? (
        <Text
          mt={4}
          fontSize="xscd pro "
          color={
            response?.success ? "feedback.success.dark" : "feedback.danger.dark"
          }
        >
          {response?.success || response?.error}
        </Text>
      ) : (
        <Button type="submit" size="md" mt={8} w={isMobile ? "100%" : "50%"}>
          Solicitar recuperação de senha
        </Button>
      )}

      <Link mt={8} onClick={openSignUp} color="neutral.darkest">
        Ainda não tenho uma conta
      </Link>
    </Flex>
  );
}
