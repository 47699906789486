import { useNavigate } from "react-router-dom";

import { Flex } from "@chakra-ui/react";

import { EntityUserType } from "@isurf-tech/types/domain/enums";

import LogoIcon from "../../assets/icons/logo";
import routesName from "../../config/routes";
import { useAuth } from "../../hooks/auth";

export default function HeaderLogoIcon() {
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleRedirect = () => {
    if (user && user.entity === EntityUserType.SCHOOL)
      return navigate(routesName.schoolsDashboard);
    if (user && user.entity === EntityUserType.STUDENT)
      return navigate(routesName.studentsDashboard);

    return navigate(routesName.main);
  };

  return (
    <Flex alignItems="center" onClick={handleRedirect} cursor="pointer">
      <LogoIcon color="neutral.darkest" />
    </Flex>
  );
}
