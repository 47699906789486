import moment from "moment-timezone";
import { useEffect, useState } from "react";

import { Box, Flex } from "@chakra-ui/react";

import ChevronLeftIcon from "../../assets/icons/chevron-left";
import ChevronRightIcon from "../../assets/icons/chevron-right";
import dateHelper from "../../helper/date";
import { useResposiveness } from "../../hooks/responsiveness";
import DatePickerRowBoxDay from "./box-day";

const LIMIT_DAYS = 8;

interface DatePickerRowPropsI {
  current?: any;
  onClick?: (day: any) => void;
  dayLabel?: string | ((day: any) => string);
}

const format = "YYYYMMDD";

export default function DatePickerRow({
  current = moment(),
  onClick,
  dayLabel = "",
}: DatePickerRowPropsI) {
  const { isMobile } = useResposiveness();
  const [offsetDate, setOffsetDate] = useState(current);
  const [listDate, setListDate] = useState(() =>
    dateHelper.rangeBetween(current, LIMIT_DAYS)
  );

  useEffect(() => {
    const outsideRange = listDate.some(
      (day) => moment(day).format(format) === moment(current).format(format)
    );

    if (!outsideRange) {
      const newList = dateHelper.rangeBetween(current, LIMIT_DAYS);

      setListDate(newList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  const handleList = (target: "increaseDay" | "decreaseDay") => {
    const newTarget = dateHelper[target](offsetDate, LIMIT_DAYS);

    const nextList = dateHelper.rangeBetween(newTarget, LIMIT_DAYS);

    setListDate(nextList);
    setOffsetDate(newTarget);
  };

  const renderLabelDay = (day: string) => {
    if (typeof dayLabel === "string") return dayLabel;
    if (typeof dayLabel === "function") return dayLabel(day);

    return moment(day).format("DD");
  };

  return (
    <Flex
      display="flex"
      flexDirection="row"
      width="100%"
      maxW={isMobile ? "100vw" : "70vw"}
      h="min-content"
    >
      {!isMobile && (
        <Box padding={2} display="flex" alignItems="center">
          <Box onClick={() => handleList("decreaseDay")} cursor="pointer">
            <ChevronLeftIcon />
          </Box>
        </Box>
      )}

      <Box display="flex" flexDir="row" overflowX="scroll">
        {listDate.map((day, index) => (
          <DatePickerRowBoxDay
            key={index.toString()}
            index={index}
            day={day}
            selected={
              moment(current).format("YYYYMMDD") ===
              moment(day).format("YYYYMMDD")
            }
            onClick={() => onClick?.(day)}
            label={renderLabelDay(day.toISOString())}
          />
        ))}
      </Box>

      {!isMobile && (
        <Box padding={2} display="flex" alignItems="center">
          <Box onClick={() => handleList("increaseDay")} cursor="pointer">
            <ChevronRightIcon />
          </Box>
        </Box>
      )}
    </Flex>
  );
}
