import moment from "moment-timezone";

import { Flex, FlexProps, Text } from "@chakra-ui/react";

import { IClassroomStudent } from "@isurf-tech/types/domain";
import { PaymentStatus } from "@isurf-tech/types/domain/enums";

import { StudentStatusLabel } from "../../@types/student";
import ArrowRight from "../../assets/icons/arrow-right";
import { useResposiveness } from "../../hooks/responsiveness";
import MenuOptions, { MenuOptionsPropsI } from "../menu-options";
import ListTag from "../tag-list";

interface CardStudentClassroomPropsI extends FlexProps {
  data: IClassroomStudent;
  mt?: any;
  menuOptions?: MenuOptionsPropsI["options"];
}

export default function CardStudentClassroom({
  data: {
    classroom: { lesson, schedule } = {
      lesson: {},
      schedule: {},
    } as IClassroomStudent["classroom"],
    payment = {} as IClassroomStudent["payment"],
  } = {} as IClassroomStudent,
  menuOptions,
  ...props
}: CardStudentClassroomPropsI) {
  const { isMobile } = useResposiveness();

  return (
    <Flex
      p={isMobile ? "16px 24px" : "24px 32px"}
      border="hairline"
      borderColor="neutral.medium"
      borderRadius="sm"
      _hover={{
        bg: "neutral.light",
      }}
      {...props}
    >
      <Flex flex={1} direction={isMobile ? "column" : "row"}>
        <Flex flex={3} flexDir="column">
          <Flex align="center">
            <Text fontSize="sm" fontWeight="semibold">
              {schedule?.start &&
                moment(schedule?.start)
                  .tz(schedule?.timezone)
                  .format("DD MMM YYYY · HH:mm")}
            </Text>
            <Flex ml={1} justify="center" align="center">
              <ArrowRight color="neutral.darkest" />
            </Flex>
            <Text fontSize="sm" fontWeight="semibold" ml={1}>
              {schedule?.end &&
                moment(schedule?.end).tz(schedule?.timezone).format("HH:mm")}
            </Text>
          </Flex>

          <Text>{lesson?.name}</Text>

          <ListTag
            tags={[lesson?.modality, lesson?.variation]}
            size="sm"
            mt={2}
          />
        </Flex>

        <Flex
          flex={10}
          direction={isMobile ? "column" : "row"}
          align={isMobile ? "start" : "center"}
          mt={isMobile ? 2 : 0}
        >
          <Flex flex={1}>
            <Text fontSize="xs">
              {`${lesson?.location?.address} · ${lesson?.location?.city}/${lesson?.location?.state}`}
            </Text>
          </Flex>

          <Flex flex={1}>
            <Text fontSize="xs">{lesson?.school?.name}</Text>
          </Flex>

          <Flex flex={1}>
            <Text fontWeight="semibold" color="neutral.dark" fontSize="xs">
              {
                StudentStatusLabel?.[
                  payment?.status || PaymentStatus.PAYMENT_PENDING
                ]
              }
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <Flex align={isMobile ? "flex-start" : "center"}>
        <MenuOptions options={menuOptions} />
      </Flex>
    </Flex>
  );
}
