import { Box, Grid, GridItem, Heading, Text } from "@chakra-ui/react";

import { useResposiveness } from "../../hooks/responsiveness";

interface HowItWorkSectionPropsI {
  steps: {
    icon: any;
    title: string;
    description: string;
  }[];
}

export default function HowItWorkSection({
  steps = [],
}: HowItWorkSectionPropsI) {
  const { isMobile } = useResposiveness();

  return (
    <Box id="fifty" minHeight="70vh">
      <Grid
        templateColumns="repeat(12, 1fr)"
        gap={8}
        px={isMobile ? 4 : 20}
        mt={isMobile ? "70px" : "144px"}
      >
        <GridItem colStart={1} colSpan={isMobile ? 10 : 5}>
          <Heading color="neutral.darkest" fontSize={isMobile ? "lg" : "xl"}>
            Como o iSurf funciona
          </Heading>
        </GridItem>
      </Grid>

      <Grid
        templateColumns="repeat(12, 1fr)"
        gap={8}
        rowGap={isMobile ? "70px" : 0}
        px={isMobile ? 4 : 20}
        mt={35}
      >
        {steps.map((howToWork, index) => (
          <GridItem colSpan={isMobile ? 12 : 3} key={index.toString()}>
            <Box>
              <Box
                bg="neutral.light"
                borderRadius={8}
                w="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight={168}
              >
                {howToWork.icon()}
              </Box>

              <Text
                mt="25px"
                fontWeight="semibold"
                fontSize={16}
                color="neutral.darkest"
              >
                {howToWork.title}
              </Text>
              <Text mt="8px" fontSize={14} color="neutral.dark">
                {howToWork.description}
              </Text>
            </Box>
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
}
