import { ComponentStyleConfig } from "@chakra-ui/react";

const InputTheme: ComponentStyleConfig = {
  baseStyle: {
    field: {
      fontFamily: "body",
      fontWeight: "medium",
      fontSize: "xs",
      color: "neutral.darkest",

      padding: "16px !important",

      "&.chakra-input": {
        minHeight: "56px",
      },

      "::placeholder": {
        fontSize: "xs",
        justifyContent: "center",
        verticalAlign: "middle",
      },

      ":focus-visible": {
        borderColor: "brand.medium !important",
        boxShadow: "none !important",
      },

      "&[aria-invalid=true]": {
        borderColor: "feedback.danger.medium",
        color: "feedback.danger.dark",
        boxShadow: "none !important",

        "::placeholder": {
          color: "feedback.danger.dark",
          opacity: "0.74",
        },
      },
    },
  },
  sizes: {
    lg: {
      fontSize: "lg",
    },
    md: {
      fontSize: "md",
    },
    sm: {
      fontSize: "sm",
    },
    xs: {
      fontSize: "xs",
    },
  },
  variants: {
    outline: {
      field: {
        border: "hairline",
        borderColor: "neutral.medium",
      },
    },
    unstyled: {
      field: {
        "&.chakra-input": {
          minHeight: "min-content",
        },
      },
    },
  },
  defaultProps: {
    variant: "outline",
    size: "xs",
  },
};

export default InputTheme;
