import { createContext, useCallback, useMemo, useState } from "react";

import { IClassroom, IClassroomMessage } from "@isurf-tech/types/domain";

import { classroomService } from "../services/api";

interface ClassroomContextData {
  loading: boolean;
  classroom: IClassroom;
  load: (lessonId: string) => Promise<void>;
  addMessage: (message: IClassroomMessage) => Promise<void>;
}

export const ClassroomContext = createContext<ClassroomContextData>(
  null as any
);

export default function ClassroomProvider({ children }: any) {
  const [loading, setLoading] = useState(false);
  const [classroom, setClassroom] = useState<IClassroom>(null as any);

  const load = useCallback(async (lessonId: string) => {
    setLoading(true);
    const { data } = await classroomService.find(lessonId);
    setLoading(false);

    if (data && Object.keys(data).length) setClassroom(data);
  }, []);

  const addMessage = useCallback(
    async (message: IClassroomMessage) => {
      return setClassroom({
        ...classroom,
        messages: [...(classroom?.messages || []), message],
      } as IClassroom);
    },
    [classroom]
  );

  const value = useMemo(
    () => ({ loading, classroom, load, addMessage }),
    [loading, classroom, load, addMessage]
  );

  return (
    <ClassroomContext.Provider value={value}>
      {children}
    </ClassroomContext.Provider>
  );
}
