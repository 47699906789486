import { useContext } from "react";

import { MobileSearchContext } from "../contexts/mobile-search";

export function useMobileSearch() {
  const context = useContext(MobileSearchContext);

  if (!context) throw Error("hook with provider not defined");

  return context;
}
