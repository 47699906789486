import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { ILesson, ISchedule } from "@isurf-tech/types/domain";

import { QueryLessonsDto } from "../@types/lessons";
import { lessonService } from "../services/api";

interface LessonsContextI {
  loading: boolean;
  lessons: ILesson[];
  updateFilters: (filters: QueryLessonsDto) => void;
  refreshSchedulesByLesson: (
    lessonId: string,
    schedules: { [key in string]: ISchedule[] }
  ) => void;
}

export const LessonsContext = createContext<LessonsContextI>(null as any);

export function LessonsProvider({ children }: any) {
  const [loading, setLoading] = useState(false);
  const [lessons, setLessons] = useState<ILesson[]>([]);
  const [filters, setFilters] = useState<QueryLessonsDto>({});

  const updateData = useCallback(async (params: QueryLessonsDto) => {
    setLoading(true);
    const { data } = await lessonService.list(params);
    setLoading(false);

    setLessons(data);
  }, []);

  const updateFilters = useCallback(
    (params: QueryLessonsDto) => {
      setFilters({ ...filters, ...params });
    },
    [filters]
  );

  const refreshSchedulesByLesson = useCallback(
    (lessonId: string, schedules: { [key in string]: ISchedule[] }) => {
      const newLessons = lessons.map((lesson) => {
        if (lesson.id === lessonId) {
          return {
            ...lesson,
            schedules: Object.assign({}, lesson.schedules, schedules),
          };
        }

        return lesson;
      });

      setLessons(newLessons);
    },
    [lessons, setLessons]
  );

  useEffect(() => {
    (async () => {
      await updateData(filters);
    })();
  }, [filters, updateData]);

  const value = useMemo(
    () => ({ loading, lessons, updateFilters, refreshSchedulesByLesson }),
    [loading, lessons, updateFilters, refreshSchedulesByLesson]
  );

  return (
    <LessonsContext.Provider value={value}>{children}</LessonsContext.Provider>
  );
}
