/* eslint-disable import/no-anonymous-default-export */

import borders from "./borders";
import colors from "./colors";
import shadows from "./shadows";
import typography from "./typography";

export default {
  ...typography,
  ...borders,
  colors,
  shadows,
};
