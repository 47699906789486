import { ComponentStyleConfig } from "@chakra-ui/react";

import InputTheme from "./input";

const SelectTheme: ComponentStyleConfig = {
  parts: ["field"],
  baseStyle: {
    field: {
      ...(InputTheme.baseStyle as any).field,
      padding: "16px",
      minHeight: "56px",
    },
  },
  sizes: {
    field: {
      ...(InputTheme.sizes as any).field,
    },
  },
  variants: {
    outline: {
      field: {
        border: "hairline",
        borderColor: "neutral.medium",
      },
    },
  },
  defaultProps: {
    variant: "outline",
    size: "xs",
  },
};

export default SelectTheme;
