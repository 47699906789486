import moment from "moment-timezone";
import { memo } from "react";

import { Flex, Heading, useToken } from "@chakra-ui/react";

import PlusIcon from "../../../../../../assets/icons/plus";

function AppointmentSuggestion({ value, ...props }: { value: string } & any) {
  const [neutralDarkest] = useToken("colors", ["neutral.darkest"]);

  return (
    <Flex
      flex={1}
      justify="center"
      align="center"
      maxH="34px"
      borderRadius="full"
      py={2}
      as="button"
      _focus={{
        outline: "2px solid",
        outlineColor: "brand.medium",
      }}
      bg="neutral.light"
      {...props}
    >
      <PlusIcon size={16} color={neutralDarkest} />
      <Heading
        ml={2}
        fontWeight="semibold"
        fontSize="xs"
        color={neutralDarkest}
      >
        {moment()
          .set({
            hour: value,
            minute: 0,
          })
          .format("HH:mm")}
      </Heading>
    </Flex>
  );
}

export default memo(AppointmentSuggestion);
