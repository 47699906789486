export default function StarIcon({ size = 24, color = "black" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.0007 5.33301L40.2407 22.0263L58.6673 24.7197L45.334 37.7063L48.4807 56.053L32.0007 47.3863L15.5207 56.053L18.6673 37.7063L5.33398 24.7197L23.7607 22.0263L32.0007 5.33301Z"
        stroke={color}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
