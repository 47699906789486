import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Button, Flex, Heading } from "@chakra-ui/react";

import ArrowLeftIcon from "../../assets/icons/arrow-left";
import ClassroomMessage from "../../components/cards/classroom-message";
import SimpleHeader from "../../components/headers/simple";
import routesName from "../../config/routes";
import useMessages from "../../hooks/messages";
import { useQuery } from "../../hooks/query-param";
import { useResposiveness } from "../../hooks/responsiveness";

export default function MessagesPage() {
  const { isMobile } = useResposiveness();
  const navigate = useNavigate();
  const query = useQuery<{ classroom: string }>();
  const { messages, load, newMessageDialog } = useMessages();

  useEffect(() => {
    (async () => {
      if (!query.object?.classroom) return navigate(routesName.notFound);
      if (query.object?.classroom) await load(query.object?.classroom);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box w="100vw" pb={isMobile ? 4 : 0}>
      <SimpleHeader
        title="Mensagens"
        backButton
        backButtonIcon={<ArrowLeftIcon size={24} />}
        onBack={() => navigate(-1)}
      />

      <Flex
        mt={isMobile ? 8 : 10}
        px={isMobile ? "16px" : "80px"}
        align="center"
        justify="space-between"
      >
        <Heading size={isMobile ? "sm" : "md"}>Mensagens enviadas</Heading>

        {!isMobile && (
          <Button
            size="md"
            onClick={() => newMessageDialog(query.object?.classroom)}
          >
            Enviar mensagem
          </Button>
        )}
      </Flex>

      <Flex
        direction="column"
        mt={isMobile ? 4 : 10}
        px={isMobile ? "16px" : "80px"}
        align="center"
      >
        {messages.map((message, index) => (
          <ClassroomMessage
            key={message?.id ?? index.toString()}
            message={message}
            mt={index > 0 ? (isMobile ? 6 : 4) : 0}
          />
        ))}
      </Flex>

      {isMobile && (
        <Flex direction="column" mt={6} px={"16px"} align="center">
          <Button
            size="lg"
            onClick={() => newMessageDialog(query.object?.classroom)}
            w="100%"
          >
            Enviar mensagem
          </Button>
        </Flex>
      )}
    </Box>
  );
}
