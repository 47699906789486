import { useContext } from "react";

import { SchedulesContext } from "../contexts/schedules";

export function useSchedules() {
  const context = useContext(SchedulesContext);

  if (!context) throw Error("hook with provider not defined");

  return context;
}
