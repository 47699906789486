import { Icon, IconProps } from "@chakra-ui/react";

export default function LogoIcon(props: IconProps) {
  return (
    <Icon
      viewBox="0 0 70 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      w="70px"
      h="32px"
      {...props}
    >
      <path
        d="M4.19078 20.9849L8.57241 21.4673V6.36195H4.19078V20.9849Z"
        fill="currentColor"
      />
      <path
        d="M6.39188 4.3593C7.69815 4.3593 8.76784 3.3782 8.76784 2.17459C8.76784 0.981096 7.69815 0 6.39188 0C5.09591 0 4.02621 0.981096 4.02621 2.17459C4.02621 3.3782 5.09591 4.3593 6.39188 4.3593Z"
        fill="currentColor"
      />
      <path
        d="M22.9386 7.30156H27.2071C27.1454 3.60981 24.0598 1.06098 19.3696 1.06098C14.7514 1.06098 11.3674 3.56935 11.388 7.3319C11.3777 10.3864 13.5685 12.1362 17.1273 12.9757L19.421 13.5421C21.7147 14.0883 22.9901 14.7356 23.0004 16.1314C22.9901 17.6486 21.5295 18.6802 19.2667 18.6802C16.9525 18.6802 15.2862 17.6283 15.1422 15.5549L10.8326 15.3941C10.9457 19.8748 14.2062 22.3518 19.3181 22.3518C24.4609 22.3518 27.4848 19.9344 27.4951 16.1415C27.4848 12.6925 24.8415 10.8618 21.1798 10.0527L19.2873 9.60764C17.4565 9.19295 15.9239 8.5254 15.9548 7.03858C15.9548 5.70349 17.1582 4.72239 19.3387 4.72239C21.4678 4.72239 22.7741 5.67314 22.9386 7.30156Z"
        fill="currentColor"
      />
      <path
        d="M36.2197 18.6196C37.7934 18.6196 39.2436 17.5272 39.2333 15.4436V6.52275H43.615V22.0584H39.4082V19.2365H39.2436C38.5339 21.0571 36.8265 22.2607 34.4506 22.2607C31.2723 22.2607 29.1124 20.0457 29.1021 16.4146V6.52275H33.4837V15.6459C33.494 17.4766 34.5637 18.6196 36.2197 18.6196Z"
        fill="currentColor"
      />
      <path
        d="M45.9766 22.0584H50.3583V13.269C50.3583 11.3574 51.7777 10.0426 53.7114 10.0426C54.3182 10.0426 55.1513 10.1437 55.5627 10.2752V6.45195C55.1719 6.36092 54.6268 6.30023 54.1845 6.30023C52.4154 6.30023 50.9651 7.31167 50.3891 9.2334H50.2246V6.52275H45.9766V22.0584Z"
        fill="currentColor"
      />
      <path
        d="M63.9789 6.52275H67.116V9.75935H63.9789V21.4152L59.5972 22.0584V9.75935H57.3653V6.52275H59.5972V5.40005C59.5972 2.02185 61.8086 0.373206 64.7811 0.373206C66.1285 0.373206 67.3319 0.636181 67.8771 0.798011L67.1057 4.03461C66.756 3.92335 66.2211 3.8121 65.6966 3.8121C64.4109 3.8121 63.9789 4.42907 63.9789 5.48097V6.52275Z"
        fill="currentColor"
      />
      <path
        d="M69.3638 23.9004C67.1057 25.5205 53.7114 29.0488 35.4617 29.0488C29.0685 29.0488 22.2634 28.7872 16.5787 27.8624C14.0547 29.188 8.54096 31.8392 6.67714 31.8392C4.81333 31.8392 7.84202 28.3248 9.58935 26.5676C5.15063 25.6472 1.7048 24.2477 0.453636 23.6332C0.141067 23.4796 -0.0321683 23.1509 0.00496622 22.8089C0.0595726 22.306 0.5367 21.9569 1.04058 22.0551C5.06969 22.8396 21.7904 25.5205 35.4617 25.5205C53.073 25.5205 64.5106 22.9359 68.8615 22.0948C69.4545 21.9802 70 22.4287 70 23.0239C70 23.4251 69.7517 23.7776 69.3638 23.9004Z"
        fill="currentColor"
      />
    </Icon>
  );
}
