import React, { useCallback, useState } from "react";

import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  ScaleFade,
  Spinner,
  Text,
} from "@chakra-ui/react";

import CheckIcon from "../../assets/icons/check";
import CloseIcon from "../../assets/icons/close";
import { useDialog } from "../../hooks/dialog";
import { useResposiveness } from "../../hooks/responsiveness";
import { ShareLink } from "../share-link";
import TextField from "../text-field";
import BaseDialog from "./base";

const DIALOG_TYPE = "invite";

export interface InviteDialogProps {
  shareLink: string;
  email: string;
}

export default function DialogInvite() {
  const { isMobile } = useResposiveness();
  const { props: dialogProps, close } = useDialog();
  const [copied, setCopied] = useState(false);
  const props = dialogProps as unknown as InviteDialogProps;
  const [form, setForm] = useState<Record<string, string>>({});
  const [loading, setLoading] = useState(false);
  const [sended, setSended] = useState(false);
  const [error, setError] = useState<any>();

  const handleForm = useCallback(
    (field: string, value: string) => {
      setForm({
        ...form,
        [field]: value,
      });
    },
    [form]
  );

  const handleSubmit = useCallback(
    async (e: React.FormEvent<any>) => {
      e.preventDefault();

      setError("");

      setLoading(true);

      const error = await dialogProps?.action?.onClick(form);

      setLoading(false);

      if (error) return setError(error);

      setSended(true);

      return;
    },
    [dialogProps, form]
  );

  return (
    <BaseDialog type={DIALOG_TYPE}>
      <Flex
        as="form"
        direction="column"
        onSubmit={handleSubmit}
        mt={isMobile ? 6 : 0}
      >
        <Flex align="center" justify="space-between">
          <Heading color="neutral.darkest" fontSize="lg">
            {dialogProps?.title}
          </Heading>

          <IconButton
            variant="unstyled"
            aria-label="close"
            icon={<CloseIcon />}
            onClick={close}
          />
        </Flex>

        {!!props?.shareLink && (
          <React.Fragment>
            <Flex align="center" mt={6}>
              <Heading fontSize="sm" fontWeight="medium" mr={1}>
                Link para compartilhar
              </Heading>

              <ScaleFade initialScale={0.9} in={copied}>
                <Flex align="center">
                  <Text fontSize="2xs" color="neutral.dark">
                    copiado
                  </Text>
                  <CheckIcon color="green" />
                </Flex>
              </ScaleFade>
            </Flex>

            <ShareLink
              link={props.shareLink}
              mt={2}
              onClick={() => {
                setCopied(true);
              }}
            />
          </React.Fragment>
        )}

        <Heading fontSize="sm" fontWeight="medium" mt={6}>
          Envie por e-mail
        </Heading>

        <Text fontSize="xs" color="neutral.dark" mt={1}>
          {dialogProps?.description
            ? dialogProps.description
            : "Enviaremos um e-mail para o endereço informado."}
        </Text>

        <TextField
          label=""
          type="email"
          placeholder="aluno@isurf.app"
          fullWidth
          {...dialogProps?.inputProps}
          onChange={(e) => handleForm("email", e.target.value)}
          isDisabled={loading || sended}
          error={error}
        />

        {loading ? (
          <Flex mt={5} w="100%" justify="center" align="center">
            <Spinner color="brand.dark" />
          </Flex>
        ) : (
          !sended && (
            <Button type="submit" size="md" mt={4} disabled={loading}>
              {!loading &&
                (dialogProps?.action?.label
                  ? dialogProps.action.label
                  : "Enviar convite")}
            </Button>
          )
        )}

        {sended && !error && (
          <Flex mt={5} w="100%" justify="center" align="center">
            <Text fontSize="2xs" color="neutral.dark">
              Enviado com sucesso
            </Text>
            <Box ml={2}>
              <CheckIcon color="green" />
            </Box>
          </Flex>
        )}
      </Flex>
    </BaseDialog>
  );
}
