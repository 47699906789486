import { useNavigate } from "react-router-dom";

import { Box, Grid, GridItem, Heading, Text } from "@chakra-ui/react";

import { LessonModality } from "../../../../@types/lessons";
import ArrowRight from "../../../../assets/icons/arrow-right";
import BodyboardIcon from "../../../../assets/icons/sports/bodyboard";
import KiteSurfIcon from "../../../../assets/icons/sports/kitesurf";
import PaddleBoardingIcon from "../../../../assets/icons/sports/paddleboarding";
import SurferIcon from "../../../../assets/icons/sports/surfer";
import routesName from "../../../../config/routes";
import { useResposiveness } from "../../../../hooks/responsiveness";

export default function HomeMainFourtySection() {
  const { isMobile } = useResposiveness();
  const navigate = useNavigate();

  return (
    <Box
      id="fourty-section"
      position="relative"
      pt={isMobile ? "64px" : "124px"}
      pb={124}
      bg="neutral.light"
      minHeight="85vh"
    >
      <Grid
        templateColumns="repeat(12, 1fr)"
        gap={8}
        padding={isMobile ? "0px 16px" : "0px 80px"}
      >
        <GridItem colStart={1} colSpan={isMobile ? 10 : 5}>
          <Heading color="neutral.darkest" fontSize={isMobile ? "lg" : "xl"}>
            Encontre sua modalidade
          </Heading>
        </GridItem>
      </Grid>

      <Grid
        templateColumns="repeat(12, 1fr)"
        gap={8}
        mt={isMobile ? 4 : 8}
        px={isMobile ? 4 : 20}
      >
        <GridItem colStart={1} colSpan={5}>
          <Heading fontSize="sm">Modalidades</Heading>
        </GridItem>
      </Grid>

      <Grid
        templateColumns="repeat(12, 1fr)"
        gap={8}
        padding={isMobile ? "16px" : "16px 80px"}
        overflow="auto"
        gridAutoFlow="column"
      >
        {[
          { name: "surf", icon: SurferIcon, value: LessonModality.SURF },
          {
            name: "Standup Paddle",
            icon: PaddleBoardingIcon,
            value: LessonModality.SUP,
          },
          { name: "Kite Surf", icon: KiteSurfIcon, value: LessonModality.KITE },
          {
            name: "Bodyboard",
            icon: BodyboardIcon,
            value: LessonModality.BODYBOARD,
          },
        ].map((type, index) => (
          <GridItem
            key={index.toString()}
            colSpan={isMobile ? 10 : 3}
            borderRadius="8px"
            bg="white"
            p="50px 25px"
            cursor="pointer"
            onClick={() =>
              navigate(routesName.search.concat(`?modality=${type.value}`))
            }
          >
            <Box
              bg="brand.lightest"
              w="88px"
              minH={88}
              padding="24px"
              borderRadius={8}
            >
              {type.icon()}
            </Box>
            <Text
              fontWeight={600}
              fontSize={24}
              marginTop={27}
              textTransform="capitalize"
            >
              {type.name}
            </Text>
          </GridItem>
        ))}
      </Grid>

      <Grid templateColumns="repeat(12, 1fr)" gap={8} px={isMobile ? 4 : 20}>
        <GridItem
          colStart={1}
          colSpan={isMobile ? 8 : 2}
          mt={isMobile ? 10 : "90px"}
          mb={-4}
        >
          <Heading fontSize="sm" color="neutral.darkestF">
            Variações
          </Heading>
        </GridItem>
      </Grid>

      {[
        ["kids (4 a 10 anos)", "coach particular"],
        ["iniciante", "intermediario", "avançado"],
      ].map((row, index) => (
        <Grid
          key={index.toString()}
          templateColumns="repeat(12, 1fr)"
          gap={8}
          mt={8}
          px={isMobile ? 4 : 20}
        >
          {row.map((variation) => (
            <GridItem colSpan={isMobile ? 12 : 3} key={variation}>
              <Box
                bg="neutral.lightest"
                padding="24px 32px"
                borderRadius="md"
                display="flex"
                flexDir="row"
                justifyContent="space-between"
                alignItems="center"
                cursor="pointer"
              >
                <Text
                  fontWeight="semibold"
                  fontSize="sm"
                  color="neutral.darkest"
                  textTransform="capitalize"
                  maxW="max-content"
                >
                  {variation}
                </Text>
                <ArrowRight style={{ marginLeft: 4 }} />
              </Box>
            </GridItem>
          ))}
        </Grid>
      ))}
    </Box>
  );
}
