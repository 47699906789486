import { createContext, useMemo } from "react";

import { useDisclosure } from "@chakra-ui/react";

import MobileSearchDialog from "../components/dialogs/mobile/search";

export interface MobileSearchContextI {
  isOpen: boolean;
  open: () => void;
  close: () => void;
}

export const MobileSearchContext = createContext<MobileSearchContextI>(
  {} as any
);

export function MobileSearchProvider({ children }: any) {
  const {
    isOpen,
    onOpen: open,
    onClose: close,
  } = useDisclosure({ id: "mobile-search" });

  const value = useMemo(() => ({ isOpen, open, close }), [isOpen, open, close]);

  return (
    <MobileSearchContext.Provider value={value}>
      {children}
      <MobileSearchDialog />
    </MobileSearchContext.Provider>
  );
}
