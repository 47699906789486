import {
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";

import { EntityUserType } from "@isurf-tech/types/domain/enums";

import { useResposiveness } from "../../../../hooks/responsiveness";
import { useSession } from "../../../../hooks/session";

export default function HomeSchoolSecondSection() {
  const { isMobile } = useResposiveness();
  const { openSignUp } = useSession();

  return (
    <Box
      id="home-school-second"
      as="section"
      position="relative"
      minHeight={isMobile ? "110vh " : "90vh"}
      mt={isMobile ? "120px" : "80px"}
      px={isMobile ? "16px" : "80px"}
      mb={isMobile ? "64px" : "0px"}
    >
      {isMobile && (
        <Heading fontSize="xl" fontWeight="semibold" mb={12}>
          Organize suas aulas
        </Heading>
      )}

      <Grid
        templateColumns={isMobile ? "repeat(4, 1fr)" : "repeat(12, 1fr)"}
        columnGap={8}
      >
        <GridItem position="relative" colStart={1} colSpan={isMobile ? 4 : 5}>
          <Box
            bg="brand.lightest"
            ml="-80px"
            w="100%"
            height="50vh"
            borderRightRadius="lg"
            position="relative"
          >
            <Box
              position="absolute"
              left={0}
              bottom={0}
              objectPosition="center"
            >
              <Image
                ml={8}
                src={require("../../../../assets/medias/mockup-hand-class.png")}
              />
            </Box>
          </Box>
        </GridItem>

        <GridItem
          position="relative"
          colStart={isMobile ? 1 : 7}
          colSpan={isMobile ? 4 : 6}
        >
          {!isMobile && (
            <Heading fontSize="xl" fontWeight="semibold">
              Organize suas aulas
            </Heading>
          )}

          <Heading fontSize="sm" mt={10} fontWeight="semibold">
            Aumente o alcance de suas aulas
          </Heading>
          <Text fontSize="xs" mt={2} maxW={isMobile ? "100%" : "45%"}>
            Aumente o alcance de suas aulas anunciando no iSurf
          </Text>

          <Heading fontSize="sm" mt={10} fontWeight="semibold">
            Tudo que você precisa para suas aulas
          </Heading>
          <Text fontSize="xs" mt={2} maxW={isMobile ? "100%" : "45%"}>
            Receba detalhado as informações do seus alunos para preparação das
            aulas
          </Text>

          <Heading fontSize="sm" mt={10}>
            Comunicação em massa
          </Heading>
          <Text fontSize="xs" mt={2} maxW={isMobile ? "100%" : "45%"}>
            Envie mensagens para a turma relacionado para preparação aula,
            cuidados, clima ou cancelamento
          </Text>

          <Button
            size="md"
            mt={14}
            w={isMobile ? "100%" : "min-content"}
            onClick={() => openSignUp({ role: EntityUserType.SCHOOL })}
          >
            Cadastre sua escola
          </Button>
        </GridItem>
      </Grid>
    </Box>
  );
}
