import { ReactDatePickerCustomHeaderProps } from "react-datepicker";

import { Flex, IconButton, Select, Text } from "@chakra-ui/react";

import ChevronLeftIcon from "../../assets/icons/chevron-left";
import ChevronRightIcon from "../../assets/icons/chevron-right";

const YEAR_LIMIT = 60;

function range(size: number, startAt = 0) {
  // @ts-ignore
  return [...Array(size).keys()].map((i) => i + startAt);
}

function generateYears(minDate: Date = new Date(0)) {
  const minDateYear = minDate.getFullYear();

  const filterMinDate = (year: number) => year >= minDateYear;

  const currentYear = new Date().getFullYear();
  const years = [
    ...range(YEAR_LIMIT, currentYear - YEAR_LIMIT).filter(filterMinDate),
    ...range(YEAR_LIMIT, currentYear).filter(filterMinDate),
  ];

  return years;
}

export default function DatePickerHeader({
  date,
  changeYear,
  decreaseMonth,
  increaseMonth,
}: ReactDatePickerCustomHeaderProps) {
  // @ts-ignore
  const years = generateYears(this.minDate);

  const getMonth = (currentDate: Date) => {
    return currentDate.toLocaleString("default", { month: "long" });
  };

  const getYear = (currentDate: Date) => {
    return currentDate.toLocaleString("default", { year: "numeric" });
  };

  return (
    <Flex flex={1} direction="column">
      <Flex flex={1} justifyItems="center" alignItems="flex-end" mb="-8px">
        <Select
          variant="unstyled"
          fontSize="xs"
          fontWeight="semibold"
          sx={{
            "&.chakra-select": {
              w: "auto",
              minHeight: "min-content",
              padding: 0,
              marginLeft: 4,
            },
            "@media only screen and (max-width: 768px)": {
              "&.chakra-select": {
                marginLeft: 0,
              },
            },
          }}
          rootProps={{
            className: "select-date-picker",
            sx: {
              "&.select-date-picker > :last-child": {
                position: "relative",
                transform: "inherit",
              },
              "@media only screen and (max-width: 768px)": {
                "&.select-date-picker > :last-child": {
                  display: "none",
                },
              },
            },
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          value={Number(getYear(date))}
          onChange={(e) => changeYear(Number(e.target.value))}
        >
          {(years || []).map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </Select>
      </Flex>

      <Flex direction="row" align="center">
        <Flex flex={1} justifyContent="center" align="center">
          <IconButton
            variant="unstyled"
            aria-label="chevron-icon-left"
            icon={<ChevronLeftIcon />}
            onClick={decreaseMonth}
            _focus={{ outline: "0px !important", outlineColor: "none" }}
          />
        </Flex>
        <Flex flex={3} justifyContent="center" align="center">
          <Text fontSize="sm" fontWeight="semibold" textTransform="capitalize">
            {getMonth(date)}
          </Text>
        </Flex>

        <Flex flex={1} justifyContent="center" align="center">
          <IconButton
            aria-label="chevron-icon-right"
            variant="unstyled"
            icon={<ChevronRightIcon />}
            onClick={increaseMonth}
            _focus={{ outline: "0px !important", outlineColor: "none" }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
