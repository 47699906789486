import { theme as chakraTheme } from "@chakra-ui/react";

const typography = {
  //   letterSpacings: {
  //     tighter: "-0.05em",
  //     tight: "-0.025em",
  //     normal: "0",
  //     wide: "0.025em",
  //     wider: "0.05em",
  //     widest: "0.1em",
  //   },

  //   lineHeights: {
  //     normal: "normal",
  //     none: 1,
  //     shorter: 1.25,
  //     short: 1.375,
  //     base: 1.5,
  //     tall: 1.625,
  //     taller: "2",
  //     "3": ".75rem",
  //     "4": "1rem",
  //     "5": "1.25rem",
  //     "6": "1.5rem",
  //     "7": "1.75rem",
  //     "8": "2rem",
  //     "9": "2.25rem",
  //     "10": "2.5rem",
  //   },

  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },

  fonts: {
    ...chakraTheme.fonts,
    body: "Inter, sans-serif",
    heading: "Inter, sans-serif",
    mono: "DM Mono, monospace",
  },

  fontSizes: {
    "3xs": "0.625rem",
    "2xs": "0.75rem",
    xs: "0.875rem", // 14px
    sm: "1rem", // 16px
    md: "1.25rem", // 20px
    lg: "1.5rem", // 24px
    xl: "2rem", // 32px
    "2xl": "2.5rem",
    "3xl": "3rem", // 48px
  },
};

export default typography;
