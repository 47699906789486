import debounce from "lodash.debounce";
import React, { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { LayoutProps } from "@chakra-ui/react";

import { ISchool } from "@isurf-tech/types/domain";
import { EntityUserType } from "@isurf-tech/types/domain/enums";

import { States, StatesNames } from "../../@constants/locations/states";
import { sanitizeString } from "../../helper/string";
import { locationsService } from "../../services/api";
import apiLocation from "../../services/api-location";
import Select from "../select";
import TextField from "../text-field";


interface IFormLocationProps {
  space?: number;
  minWith?: string;
  w?: LayoutProps["w"];
  user?: (ISchool & { entity: EntityUserType }) | null;
}

export default function FormLocation({
  space = 0,
  w,
  user,
}: IFormLocationProps) {
  const [cities, setCities] = useState<{ label: string; value: string }[]>([]);
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const stateCode = watch("location.stateCode", "");
  const city = watch("location.city", "");
  const state = watch("location.state", "");
  const zipCode = watch("location.zip", user?.location?.zip || "");

  const getCityValue = (name: string) => {
    const city = cities.find(
      (c) => c.value.toUpperCase() === sanitizeString(name).toUpperCase()
    );

    return city?.value || "";
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getLocation = useCallback(
    debounce(async (zipCode: string) => {
      const { data } = await apiLocation.getLocationByZIP(zipCode);

      if (data?.stateCode) setValue("location.stateCode", data.stateCode);
      if (data?.state) setValue("location.state", data.state);
      if (data?.city) setValue("location.city", data.city);
      if (data?.zip) setValue("location.zip", data.zip);
      if (data?.address) setValue("location.address", data.address);
    }, 500),
    []
  );

  useEffect(() => {
    (async () => {
      if (!stateCode) return;

      const { data: cities, error } = await locationsService.getCitiesByState(
        stateCode
      );

      if (error) return;

      setCities(
        cities.map((city: string) => ({
          label: city,
          value: sanitizeString(city),
        }))
      );
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateCode]);

  useEffect(() => {
    (async () => {
      if (!zipCode || zipCode.length < 3) return;

      await getLocation(zipCode);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipCode]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "location.state") {
        const stateName = value.location.state as unknown as typeof States;

        // @ts-ignore
        if (stateName) setValue("location.stateCode", StatesNames[stateName]);
      }
    });

    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <React.Fragment>
      <TextField
        label="CEP"
        mt={space}
        placeholder="00000-00"
        fullWidth
        w={w}
        inputProps={register("location.zip")}
        error={errors?.["location.zip"]?.message}
      />
      <Select
        mt={space}
        w={w}
        label="Estado"
        placeholder=" "
        options={Object.entries(States).map(([label, value], index) => ({
          label: `${label} - ${value}`,
          value,
        }))}
        selectProps={register("location.state")}
        error={errors?.["location.state"]?.message}
      />
      <Select
        isDisabled={
          !cities.length || !(state || (user as ISchool)?.location?.state)
        }
        mt={space}
        w={w}
        label="Cidade"
        placeholder=" "
        options={cities || []}
        value={getCityValue(sanitizeString(city))}
        onChange={(e) =>
          setValue("location.city", getCityValue(e.target.value))
        }
        error={errors?.["location.city"]?.message}
      />
      <TextField
        label="Endereço"
        placeholder=" "
        mt={space}
        fullWidth
        w={w}
        inputProps={register("location.address")}
        error={errors?.["location.address"]?.message}
      />
    </React.Fragment>
  );
}
