import axios, { AxiosInstance } from "axios";

import { ServiceResponse } from "..";

interface SchoolLocation {
  state: string;
  stateCode: string;
  city: string;
  address: string;
  zip: string;
  number?: string;
  complement?: string;
}

export interface SchoolFormI {
  name: string;
  businessId: string;
  autarchismId: string;
  description: string;
  address: string;
  logo: string;
  photos: string[];
  ownerName: string;
  ownerBirthDate: string;
  ownerDocumentNumber: string;
  location: SchoolLocation;
  ownerDocumentFile?: string | null;
  bankAccount: {
    number: string;
    routingNumber: string;
  };
}

export interface SchoolFormUpdateI {
  name: string;
  description: string;
  address: string;
  logo: string;
  photos: string[];
  ownerName: string;
  ownerBirthDate: string;
  ownerDocumentNumber: string;
  location: SchoolLocation;
  ownerDocumentFile?: string | null;
  bankAccount: {
    number: string;
    routingNumber: string;
  };
}

interface ISchoolQueryParams {
  q: string;
}

export class SchoolsService {
  constructor(private api: AxiosInstance) {}

  async create(form: SchoolFormI): Promise<ServiceResponse> {
    let result = { data: null, error: null };

    try {
      const { data } = await this.api.post(`/schools`, {
        ...form,
        businessId: form.businessId.replace(/\D/g, ""),
      });

      result.data = data || {};
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result.error = error.response?.data;
      } else {
        result.error = error as any;
      }
    } finally {
      return result;
    }
  }

  async update(form: SchoolFormI & { id: string }): Promise<ServiceResponse> {
    let result = { data: null, error: null };

    try {
      const { data } = await this.api.put(`/schools/${form?.id}`, {
        name: form.name,
        description: form.description,
        logo: form.logo,
        photos: form.photos,
        ownerName: form.ownerName,
        ownerBirthDate: form.ownerBirthDate,
        ownerDocumentNumber: form.ownerDocumentNumber,
        ownerDocumentFile: form?.ownerDocumentFile ?? null,
        location: {
          state: form.location.state,
          stateCode: form.location.stateCode,
          city: form.location.city,
          address: form.location.address,
          zip: form.location.zip,
        },
        bankAccount: {
          number: form.bankAccount.number,
          routingNumber: form.bankAccount.routingNumber,
        },
      } as SchoolFormUpdateI);

      result.data = data || {};
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result.error = error.response?.data;
      } else {
        result.error = error as any;
      }
    } finally {
      return result;
    }
  }

  async list(params: ISchoolQueryParams): Promise<ServiceResponse> {
    let result = { data: null, error: null };

    try {
      const { data } = await this.api.get(`/schools`, {
        params,
      });

      result.data = data || [];
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result.error = error.response?.data;
      } else {
        result.error = error as any;
      }
    } finally {
      return result;
    }
  }
}
