import { useRef } from "react";

import { Button, Flex, Text } from "@chakra-ui/react";

import { useResposiveness } from "../hooks/responsiveness";
import { HEIGHT_IMAGE_DESKTOP, HEIGHT_IMAGE_MOBILE } from "./galery";

interface SelectImagesPropsI {
  onChange?: (files: File[]) => void;
}

export function SelectImages({ onChange }: SelectImagesPropsI) {
  const { isMobile } = useResposiveness();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleOnChange = (e: any) => {
    const files = e.target.files as FileList;

    if (onChange) onChange(Array.from(files));
  };

  return (
    <Flex
      border="1px dashed"
      borderColor="neutral.medium"
      borderRadius="base"
      h={isMobile ? HEIGHT_IMAGE_MOBILE : HEIGHT_IMAGE_DESKTOP}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <input
        type="file"
        ref={inputRef}
        hidden
        accept="image/*"
        multiple
        onChange={handleOnChange}
        maxLength={8}
      />

      <Button size="md" onClick={() => inputRef?.current?.click()}>
        Adicionar fotos
      </Button>

      {!isMobile && (
        <Text mt={6} fontSize="xs" color="neutral.darkest">
          ou arraste-as pra cá
        </Text>
      )}
    </Flex>
  );
}
