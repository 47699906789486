import { useEffect } from "react";

import { Flex, FlexProps, Text } from "@chakra-ui/react";

import CheckIcon from "../assets/icons/check";
import CloseIcon from "../assets/icons/close";

interface PasswordStepsValidateProps {
  password: string;
  passwordConfirm?: string;
  validatePasswordConfirm?: boolean;
  onValidate?: (valid: boolean) => void;
}

type RegexRule = {
  regex: RegExp;
  message: string;
};

const REGEX_RULES = {
  length: {
    regex: /^(?=.*?).{8,20}$/,
    message: "A senha deve ter entre 8 e 20 caracteres",
  },
  letterAndNumber: {
    regex: /^(?=.*[a-zA-Z])(?=.*?[0-9]).{8,20}$/,
    message: "E ao menos uma letra e um número",
  },
  upperAndLower: {
    regex: /^(?=.*?[A-Z])(?=.*?[a-z]).{8,20}$/,
    message: "E ao menos uma letra maiúscula e uma minúscula",
  },
  specialCharacter: {
    regex: /^(?=.*?[^\w\s]).{8,20}$/,
    message: "E ao menos um caractere especial",
  },
};

function Rule({
  success,
  label,
  ...props
}: { success: boolean; label: string } & FlexProps) {
  return (
    <Flex {...props}>
      {success ? (
        <CheckIcon color="feedback.success.medium" />
      ) : (
        <CloseIcon color="neutral.dark" />
      )}

      <Text
        color={success ? "feedback.success.medium" : "neutral.dark"}
        fontSize="xs"
        fontWeight="medium"
        ml={2}
      >
        {label}
      </Text>
    </Flex>
  );
}

export default function PasswordStepsValidate({
  password,
  passwordConfirm,
  onValidate,
  validatePasswordConfirm,
}: PasswordStepsValidateProps) {
  const isMatch = (regexRule: RegexRule, password: string): boolean => {
    const regex = new RegExp(regexRule.regex);

    if (regex.test(password)) return true;

    return false;
  };

  useEffect(() => {
    const isValid = Object.values(REGEX_RULES).every((rule) =>
      isMatch(rule, password)
    );

    if (validatePasswordConfirm)
      onValidate?.(isValid && password === passwordConfirm);
    else onValidate?.(isValid);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, passwordConfirm]);

  return (
    <Flex direction="column">
      {Object.entries(REGEX_RULES).map(([key, value], index) => (
        <Rule
          key={key}
          success={isMatch(value, password)}
          label={value.message}
          mt={index > 0 ? 0 : 2}
        />
      ))}

      {validatePasswordConfirm && (
        <Rule
          success={
            !!password?.length &&
            !!passwordConfirm?.length &&
            password === passwordConfirm
          }
          label="As senhas devem ser iguais"
          mt={2}
        />
      )}
    </Flex>
  );
}
