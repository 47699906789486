import { useState } from "react";

import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Spinner,
  Text,
} from "@chakra-ui/react";

import CloseIcon from "../../assets/icons/close";
import { useDialog } from "../../hooks/dialog";
import { useResposiveness } from "../../hooks/responsiveness";
import TextField from "../text-field";
import BaseDialog from "./base";
import DialogError from "./error";
import DialogSuccess from "./success";

const DIALOG_TYPE = "text-input";

export default function DialogTextInput() {
  const { isMobile } = useResposiveness();
  const { props, close } = useDialog();
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<any>(null);

  const handleAction = async () => {
    try {
      setLoading(true);

      const resp = await props?.redirect?.action?.(message);

      if (resp) setResponse(resp);
    } catch (err) {
      setResponse({
        error: {
          title: "Erro",
          description: "Ocorreu um erro ao realizar a ação",
        },
      });
    } finally {
      setLoading(false);
      if (response) close();
    }
  };

  const handleClose = async () => {
    if (props?.onClose) await props?.onClose();
    close();
  };

  if (loading)
    return (
      <BaseDialog type={DIALOG_TYPE}>
        <Flex w="100%" h="40vh" justify="center" align="center">
          <Spinner color="brand.dark" />
        </Flex>
      </BaseDialog>
    );

  if (response?.type === "success") return <DialogSuccess {...response} />;
  if (response?.type === "error") return <DialogError {...response} />;

  return (
    <BaseDialog type={DIALOG_TYPE}>
      <Flex
        flex={0.6}
        direction="column"
        justify="center"
        align="center"
        p={2}
        mt={isMobile ? 4 : 0}
      >
        <Flex justify="space-between" w="100%">
          <Heading fontSize="lg" color="neutral.darkest">
            {response?.title || props?.title || "Sucesso!"}
          </Heading>

          {!isMobile && (
            <IconButton
              variant="unstyled"
              aria-label="close"
              icon={<CloseIcon />}
              onClick={handleClose}
            />
          )}
        </Flex>

        <Box my={1}>
          <Text fontSize="sm" color="neutral.dark" mt={4} textAlign="left">
            {response?.description ||
              props?.description ||
              "Ação realizada com sucesso!"}
          </Text>
        </Box>

        <Flex flex={1} w="100%" my={6}>
          {props?.inputProps && (
            <TextField
              {...props?.inputProps}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          )}
        </Flex>

        {props?.redirect && (
          <Button variant="solid" size="md" w="100%" onClick={handleAction}>
            {props?.redirect?.label}
          </Button>
        )}

        {props?.closeLabel !== false && (
          <Button
            size="md"
            w="100%"
            mt={2}
            variant={props?.redirect ? "outline" : "solid"}
            onClick={handleClose}
          >
            {props?.closeLabel || "Fechar"}
          </Button>
        )}
      </Flex>
    </BaseDialog>
  );
}
