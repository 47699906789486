import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/react";

import CardTime from "../../../../../components/cards/time";

export const times = [
  { type: "min", value: 60 },
  { type: "min", value: 90 },
  { type: "min", value: 120 },
  { type: "min", value: 150 },
];

interface DurationOptionsPropsI {
  label: string | any;
  value?: any;
  helperText?: string;
  onChange?: (e: any) => void;
  error?: string | any;
  control?: any;
}

export default function DurationOptions({
  label,
  value,
  helperText = "",
  onChange,
  error = "",
  control,
  ...props
}: DurationOptionsPropsI) {
  return (
    <FormControl {...props} isInvalid={!!error} {...control}>
      <FormLabel>{label}</FormLabel>

      <Flex mt={4}>
        {times.map((time, index) => (
          <CardTime
            key={index.toString()}
            {...time}
            borderLeftRadius={index === 0 ? "md" : "none"}
            borderRightRadius={index === times.length - 1 ? "md" : "none"}
            onClick={() => onChange?.(time)}
            selected={value === time.value}
          />
        ))}
      </Flex>

      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
}
