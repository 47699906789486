import {
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";

import MoreVerticalIcon from "../assets/icons/more-vertical";
import { useResposiveness } from "../hooks/responsiveness";

export interface MenuOptionsPropsI {
  options?: {
    label: string;
    onClick: () => void;
  }[];
}

export default function MenuOptions({ options = [] }: MenuOptionsPropsI) {
  const { isMobile } = useResposiveness();

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        variant="unstyled"
        aria-label="more-icon"
        icon={<MoreVerticalIcon color="neutral.darkest" />}
        size={isMobile ? "2xs" : "sm"}
        onClick={(e) => e.stopPropagation()}
      />

      {options.length > 0 && (
        <MenuList>
          {options.map(({ label, onClick }) => (
            <MenuItem key={label} onClick={onClick}>
              {label}
            </MenuItem>
          ))}
        </MenuList>
      )}
    </Menu>
  );
}
