import React, { useCallback } from "react";

import { Flex, Text } from "@chakra-ui/react";

import CreditCardIcon from "../../../assets/icons/credit-card";
import RadioGroup from "../../../components/radio";
import { useResposiveness } from "../../../hooks/responsiveness";

export enum PaymentMethods {
  CREDIT_CARD = "CREDIT_CARD",
  // PAYMENT_SLIP = "PAYMENT_SLIP",
  DEBIT_CARD = "DEBIT_CARD",
}

export enum PaymentLabel {
  CREDIT_CARD = "Cartão de crédito",
  PAYMENT_SLIP = "Boleto",
  DEBIT_CARD = "Cartão de débito",
}

const paymentMethods = [
  {
    id: "1",
    label: PaymentLabel.CREDIT_CARD,
    value: PaymentMethods.CREDIT_CARD,
  },
  {
    id: "2",
    label: PaymentLabel.DEBIT_CARD,
    value: PaymentMethods.DEBIT_CARD,
  },
  // {
  //   id: "3",
  //   label: PaymentLabel.PAYMENT_SLIP,
  //   value: PaymentMethods.PAYMENT_SLIP,
  // },
];

export default function PaymentMethod({
  methods = paymentMethods,
  value,
  onChange,
  ...props
}: any) {
  const { isMobile } = useResposiveness();

  const onRenderLabel = useCallback((label: string, option: any) => {
    let icon: any;

    switch (option.value) {
      case PaymentMethods.CREDIT_CARD:
        icon = <CreditCardIcon />;
        break;

      case PaymentMethods.DEBIT_CARD:
        icon = <CreditCardIcon />;
        break;

      // case PaymentMethods.PAYMENT_SLIP:
      //   icon = <BarCodeIcon />;
      //   break;

      default:
        icon = label;
    }

    return (
      <Flex direction="row" align="center">
        {icon}
        <Text ml={2} fontSize="xs">
          {label}
        </Text>
      </Flex>
    );
  }, []);

  return (
    <Flex {...props} direction="column">
      <RadioGroup
        label="Escolha a forma de pagamento"
        options={paymentMethods}
        direction={isMobile ? "column" : "row"}
        labelProps={{ fontSize: "xl" }}
        onRenderLabel={onRenderLabel}
        variant="outline"
        value={value}
        defaultValue={value}
        onChange={(e: any) => onChange(e.target.value)}
        radioProps={{
          mt: 4,
        }}
        radioItemProps={{
          fontSize: isMobile ? "xl" : "2xs",
          p: isMobile ? 4 : 2,
          px: isMobile ? 5 : 4,
          w: isMobile ? "100%" : "none",
        }}
        spacing={isMobile ? 4 : 2}
      />
    </Flex>
  );
}
