import { SESSION_TYPE } from "../../../@types/session";
import animationData from "../../../assets/animations/email_verification.json";
import { useSession } from "../../../hooks/session";
import { authService } from "../../../services/api";
import BaseVerification from "./base";

export default function EmailVerification() {
  const { goToSessionType } = useSession();
  return (
    <BaseVerification
      title="Verificação de e-mail"
      description="Clique no botão abaixo para enviar o código de verificação para seu
  email cadastrado! Caso não tenha recebido, verifique sua caixa de spam
  ou reenvie o código."
      animationData={animationData}
      requestVerify={() => authService.requestVerify("email")}
      validate={(code) => authService.validateUserData("email", code)}
      onRedirect={() => goToSessionType(SESSION_TYPE.VERIFY_PHONE)}
    />
  );
}
