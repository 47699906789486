import { CardCvcElement } from "@stripe/react-stripe-js";
import { useState } from "react";

import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  useToken,
} from "@chakra-ui/react";

export default function CardCvcField({
  label,
  value,
  placeholder = "Type here...",
  helperText = "",
  error = "",
  formControlProps = {},
  onChange,
  mask = "",
  ...props
}: any) {
  const [neutralDark] = useToken("colors", ["neutral.dark", "neutral.darkest"]);
  const [fontWeightMedium] = useToken("fontWeights", ["medium"]);
  const [fontSizeXs] = useToken("fontSize", ["xs"]);
  const [fontsBody] = useToken("fonts", ["body"]);
  const [focus, setFocus] = useState(false);

  return (
    <FormControl isInvalid={!!error} {...props}>
      {label && <FormLabel>{label}</FormLabel>}

      <Box
        w="100%"
        border="hairline"
        borderColor={focus ? "brand.medium" : "neutral.medium"}
        borderRadius="sm"
        flexDirection="row"
        minHeight="56px"
      >
        <Box w="100%" maxHeight="56px" p="18px" pt="20px" pb="18px">
          <CardCvcElement
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            onEscape={() => setFocus(false)}
            options={{
              style: {
                base: {
                  fontFamily: fontsBody,
                  fontSize: fontSizeXs,
                  color: neutralDark,
                  fontWeight: fontWeightMedium,
                },
              },
            }}
          />
        </Box>
      </Box>

      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
}
