import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { Button, Divider, Flex, Link, Spinner, Text } from "@chakra-ui/react";

import { EntityUserType } from "@isurf-tech/types/domain/enums";

import { SESSION_TYPE } from "../../@types/session";
import { DOCS_TERMS_OF_SERVICE } from "../../config/docs";
import { useSession } from "../../hooks/session";
import PhoneInput from "../inputs/phone";
import PasswordStepsValidate from "../password-step";
import TextField from "../text-field";
import SelectRoleSignUp from "./select-role";



const formSchema = yup
  .object()
  .shape({
    name: yup.string().required(),
    cellphone: yup
      .string()
      .cellphone("Celular inválido")
      .nullable()
      .required("Campo obrigatório"),
    email: yup.string().email().required(),
    password: yup.string().required(),
  })
  .required();

export default function SessionSignUp() {
  const { openSignIn, handleSignUp, goToSessionType } = useSession();
  const {
    register,
    handleSubmit,
    setError,
    watch,
    control,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  const [role, setRole] = useState<EntityUserType | null>(null);
  const [passValid, setPassValid] = useState(false);

  const onSubmit = async (form: any) => {
    const { data, error } = await handleSignUp({
      ...form,
      entity: role,
    });

    if (error) {
      const [message] = Array.isArray(error.message)
        ? error.message
        : [error.message];

      return setError("submit", { message });
    }

    if (data) return goToSessionType(SESSION_TYPE.VERIFY_EMAIL);
  };

  return (
    <React.Fragment>
      {!role && (
        <SelectRoleSignUp onClick={(role: EntityUserType) => setRole(role)} />
      )}

      {role && Object.values(EntityUserType).includes(role) && (
        <React.Fragment>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex mt={4}>
              <Text fontSize="sm" color="neutral.dark">
                Já possui conta?
              </Text>
              <Link ml={2} onClick={openSignIn}>
                Acesse sua conta
              </Link>
            </Flex>

            {/* <AuthExternalProviders /> */}

            <Divider mt="6" />

            <Flex direction="column" mt={6}>
              <TextField
                label="Nome"
                placeholder="Escreva o nome aqui..."
                fullWidth
                inputProps={register("name")}
                error={errors?.name?.message}
              />

              <PhoneInput
                name="cellphone"
                label="Celular"
                control={control}
                placeholder="(00) 00000-0000"
                formControlProps={{
                  mt: 6,
                }}
                error={errors?.cellphone?.message}
              />

              <TextField
                label="E-mail"
                fullWidth
                mt={6}
                placeholder="medina@isurf.com"
                inputProps={register("email")}
                error={errors?.email?.message}
              />
              <TextField
                label="Senha"
                type="password"
                placeholder="******"
                fullWidth
                mt={6}
                inputProps={register("password")}
                error={errors?.password?.message}
              />
            </Flex>

            <Flex mt={1}>
              <PasswordStepsValidate
                password={watch("password") ?? ""}
                onValidate={(isValid) => setPassValid(!isValid)}
              />
            </Flex>

            {errors.submit && errors.submit.message && (
              <Flex mt={6} mb={-2} w="100%" justify="center">
                <Text fontSize="sm" color="feedback.warning.medium">
                  {errors.submit.message.toString()}
                </Text>
              </Flex>
            )}

            {isSubmitting && (
              <Flex mt={2} w="100%" justify="center">
                <Spinner color="brand.dark" />
              </Flex>
            )}

            <Flex mt={8} flexDirection="column">
              <Text fontSize="xs" color="neutral.dark">
                Ao clicar em <strong>Concordar e continuar</strong>, é declardo
                que está de acordo com os{" "}
                <Link
                  fontSize="xs"
                  color="neutral.dark"
                  href={DOCS_TERMS_OF_SERVICE}
                  target="_blank"
                  download
                >
                  Termos de Serviço e Políticas de privacidade do iSurf.
                </Link>
              </Text>
            </Flex>

            <Button
              type="submit"
              mt={8}
              size="lg"
              width="100%"
              disabled={isSubmitting || passValid}
            >
              Concordar e continuar
            </Button>
          </form>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
