import { Icon, IconProps } from "@chakra-ui/react";

export default function TrashIcon(props: IconProps) {
  return (
    <Icon
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.86523 6.56348H6.5319H19.8652"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.03125 6.5638V4.89714C9.03125 4.45511 9.20685 4.03118 9.51941 3.71862C9.83197 3.40606 10.2559 3.23047 10.6979 3.23047H14.0313C14.4733 3.23047 14.8972 3.40606 15.2098 3.71862C15.5223 4.03118 15.6979 4.45511 15.6979 4.89714V6.5638M18.1979 6.5638V18.2305C18.1979 18.6725 18.0223 19.0964 17.7098 19.409C17.3972 19.7215 16.9733 19.8971 16.5313 19.8971H8.19792C7.75589 19.8971 7.33197 19.7215 7.01941 19.409C6.70684 19.0964 6.53125 18.6725 6.53125 18.2305V6.5638H18.1979Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6992 10.7305V15.7305"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0312 10.7305V15.7305"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
