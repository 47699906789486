import { useCallback, useEffect, useState } from "react";

import {
  Box,
  Grid,
  GridItem,
  IconButton,
  Image,
} from "@chakra-ui/react";

import TrashIcon from "../../assets/icons/trash";
import { useResposiveness } from "../../hooks/responsiveness";
import { SelectImages } from "../select-images";

const SPACE = 6;
const MAX_IMAGE = 8;

export interface FileGalery extends File {
  blob: string;
}

interface GaleryPropsI {
  images?: File[] | string[];
  onChange?: (files: File[] | string[]) => void;
}

export const HEIGHT_IMAGE_MOBILE = "16vh";
export const HEIGHT_IMAGE_DESKTOP = "220px";

export default function Galery({ images = [], onChange }: GaleryPropsI) {
  const { isMobile, isTablet } = useResposiveness();
  const [stateFiles, setStateFiles] = useState<File[] | string[]>(
    (images as string[]).filter((i: any) => !!i)
  );

  useEffect(() => {
    if (images.length)
      setStateFiles((images as string[]).filter((i: any) => !!i));
  }, [images]);

  const handleAddImages = useCallback(
    async (files: File[]) => {
      if (files.length > 0) {
        const updates = [...stateFiles, ...files].slice(0, MAX_IMAGE) as any;

        setStateFiles(updates);
        if (onChange) await onChange(updates);
      }
    },
    [onChange, stateFiles]
  );

  const handleRemoveImage = useCallback(
    (file: File | string) => {
      const update = (stateFiles as any)?.filter((f: File | string) => {
        if (typeof f === "string") return f !== file;
        else return f.name !== (file as File).name;
      });

      setStateFiles(update);
      if (onChange) onChange(update);
    },
    [onChange, stateFiles]
  );

  const normalizeFile = (file: File | string): any => {
    if (typeof file === "string") return file;
    if (file instanceof File) return URL.createObjectURL(file);

    return "";
  };

  return (
    <Grid
      templateColumns={`repeat(${isMobile ? 2 : isTablet ? 3 : 4}, 1fr)`}
      gap={SPACE}
      mt={SPACE}
    >
      {stateFiles?.map((file, index) => (
        <Box
          w="100%"
          maxH={isMobile ? HEIGHT_IMAGE_MOBILE : HEIGHT_IMAGE_DESKTOP}
          borderRadius="sm"
          key={index.toString()}
          position="relative"
        >
          <IconButton
            position="absolute"
            top={2}
            right={2}
            aria-label="close"
            icon={<TrashIcon />}
            color="neutral.darkest"
            _hover={{
              color: "neutral.light",
            }}
            _active={{
              color: "neutral.light",
            }}
            borderRadius="sm"
            bg="neutral.lightest"
            onClick={() => handleRemoveImage(file)}
          />
          <Image
            src={normalizeFile(file)}
            w="100%"
            maxH={isMobile ? HEIGHT_IMAGE_MOBILE : HEIGHT_IMAGE_DESKTOP}
            borderRadius="sm"
            loading="lazy"
          />
        </Box>
      ))}

      {(stateFiles?.length || 0) < MAX_IMAGE && (
        <GridItem colSpan={isMobile ? 2 : 1}>
          <SelectImages onChange={handleAddImages} />
        </GridItem>
      )}
    </Grid>
  );
}
