import { Icon, IconProps } from "@chakra-ui/react";

export default function MessageListIcon(props: IconProps) {
  return (
    <Icon width="143" height="105" viewBox="0 0 143 105" fill="none" {...props}>
      <g clipPath="url(#clip0_4960_19327)">
        <g filter="url(#filter0_d_4960_19327)">
          <path
            d="M13.3337 13.334H66.667C70.3337 13.334 73.3337 16.334 73.3337 20.0006V60.0006C73.3337 63.6673 70.3337 66.6673 66.667 66.6673H13.3337C9.66699 66.6673 6.66699 63.6673 6.66699 60.0006V20.0006C6.66699 16.334 9.66699 13.334 13.3337 13.334Z"
            fill="white"
          />
          <path
            d="M13.3337 13.334H66.667C70.3337 13.334 73.3337 16.334 73.3337 20.0006V60.0006C73.3337 63.6673 70.3337 66.6673 66.667 66.6673H13.3337C9.66699 66.6673 6.66699 63.6673 6.66699 60.0006V20.0006C6.66699 16.334 9.66699 13.334 13.3337 13.334Z"
            stroke="#C5CED6"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <path
          d="M73.3337 20L40.0003 43.3333L6.66699 20"
          stroke="#C5CED6"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g clipPath="url(#clip1_4960_19327)">
        <g filter="url(#filter1_d_4960_19327)">
          <path
            d="M43.3337 24.334H96.667C100.334 24.334 103.334 27.334 103.334 31.0006V71.0006C103.334 74.6673 100.334 77.6673 96.667 77.6673H43.3337C39.667 77.6673 36.667 74.6673 36.667 71.0006V31.0006C36.667 27.334 39.667 24.334 43.3337 24.334Z"
            fill="white"
          />
          <path
            d="M43.3337 24.334H96.667C100.334 24.334 103.334 27.334 103.334 31.0006V71.0006C103.334 74.6673 100.334 77.6673 96.667 77.6673H43.3337C39.667 77.6673 36.667 74.6673 36.667 71.0006V31.0006C36.667 27.334 39.667 24.334 43.3337 24.334Z"
            stroke="#C5CED6"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <path
          d="M103.334 31L70.0003 54.3333L36.667 31"
          stroke="#C5CED6"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g clipPath="url(#clip2_4960_19327)">
        <g filter="url(#filter2_d_4960_19327)">
          <path
            d="M76.3337 35.334H129.667C133.334 35.334 136.334 38.334 136.334 42.0006V82.0006C136.334 85.6673 133.334 88.6673 129.667 88.6673H76.3337C72.667 88.6673 69.667 85.6673 69.667 82.0006V42.0006C69.667 38.334 72.667 35.334 76.3337 35.334Z"
            fill="white"
          />
          <path
            d="M76.3337 35.334H129.667C133.334 35.334 136.334 38.334 136.334 42.0006V82.0006C136.334 85.6673 133.334 88.6673 129.667 88.6673H76.3337C72.667 88.6673 69.667 85.6673 69.667 82.0006V42.0006C69.667 38.334 72.667 35.334 76.3337 35.334Z"
            stroke="#C5CED6"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <path
          d="M136.334 42L103 65.3333L69.667 42"
          stroke="#C5CED6"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_4960_19327"
          x="-0.0830078"
          y="11.584"
          width="78.167"
          height="64.834"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1" dy="4" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4960_19327"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4960_19327"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_4960_19327"
          x="29.917"
          y="22.584"
          width="78.167"
          height="64.834"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1" dy="4" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4960_19327"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4960_19327"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_4960_19327"
          x="62.917"
          y="33.584"
          width="78.167"
          height="64.834"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1" dy="4" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4960_19327"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4960_19327"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_4960_19327">
          <rect width="80" height="80" fill="white" />
        </clipPath>
        <clipPath id="clip1_4960_19327">
          <rect
            width="80"
            height="80"
            fill="white"
            transform="translate(30 11)"
          />
        </clipPath>
        <clipPath id="clip2_4960_19327">
          <rect
            width="80"
            height="80"
            fill="white"
            transform="translate(63 22)"
          />
        </clipPath>
      </defs>
    </Icon>
  );
}
