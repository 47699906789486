import { ComponentStyleConfig } from "@chakra-ui/react";

const TagTheme: ComponentStyleConfig = {
  baseStyle: {
    container: {
      fontFamily: "body",
      textTransform: "lowercase",
      borderRadius: "sm",
      fontWeight: "medium",
    },
  },
};

export default TagTheme;
