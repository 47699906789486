export default function UserIcon({ size = 24, color = "black" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.1109 33.0658V30.288C31.1109 28.8146 30.5256 27.4015 29.4837 26.3596C28.4418 25.3177 27.0288 24.7324 25.5553 24.7324H14.4442C12.9708 24.7324 11.5577 25.3177 10.5159 26.3596C9.47399 27.4015 8.88867 28.8146 8.88867 30.288V33.0658"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0009 19.1765C23.0691 19.1765 25.5564 16.6892 25.5564 13.621C25.5564 10.5527 23.0691 8.06543 20.0009 8.06543C16.9326 8.06543 14.4453 10.5527 14.4453 13.621C14.4453 16.6892 16.9326 19.1765 20.0009 19.1765Z"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
