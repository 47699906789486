import axios, { AxiosInstance } from "axios";

import { ServiceResponse } from "..";

import { Gender, PracticeFrequency } from "@isurf-tech/types/domain/enums";

import { QueryClassroomStudentDto } from "../../../@types/classroom-student";

interface StudentFormI {
  birthDate: string;
  gender: Gender;
  weight: number;
  height: number;
  goalDescription: string;
  frequency: PracticeFrequency;
  avatar: string;
}

interface StudentUpdateFormI {
  id: string;
  name: string;
  birthDate: string;
  gender: Gender;
  weight: number;
  height: number;
  goalDescription: string;
  frequency: PracticeFrequency;
  avatar: string;
}

export class StudentService {
  constructor(private api: AxiosInstance) {}

  async create(form: StudentFormI): Promise<ServiceResponse> {
    let result = { data: null, error: null };

    try {
      const { data } = await this.api.post(`/students`, {
        birthDate: form?.birthDate || "",
        gender: form.gender || "",
        weight: Number(form?.weight || 0),
        height: Number(form?.height || 0),
        goalDescription: form?.goalDescription?.trim() || "",
        frequency: form?.frequency || "",
        avatar: form?.avatar,
      });

      result.data = data || {};
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result.error = error.response?.data;
      } else {
        result.error = error as any;
      }
    } finally {
      return result;
    }
  }

  async update(form: StudentUpdateFormI): Promise<ServiceResponse> {
    let result = { data: null, error: null };

    try {
      const { data } = await this.api.patch(`/students/${form?.id}`, {
        logo: form?.avatar,
        birthDate: form?.birthDate || "",
        gender: form.gender || "",
        weight: Number(form?.weight || 0),
        height: Number(form?.height || 0),
        goalDescription: form?.goalDescription?.trim() || "",
        frequency: form?.frequency || "",
        name: form?.name?.trim() || "",
      });

      result.data = data || {};
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result.error = error.response?.data;
      } else {
        result.error = error as any;
      }
    } finally {
      return result;
    }
  }

  async findClassroom(
    classroomId: string,
    params: QueryClassroomStudentDto
  ): Promise<ServiceResponse> {
    let result = { data: null, error: null };

    try {
      const { data } = await this.api.get(
        `/classrooms-students/${classroomId}`,
        {
          params,
        }
      );

      result.data = data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result.error = error.response?.data;
      } else {
        result.error = error as any;
      }
    } finally {
      return result;
    }
  }

  async bookRefundLesson(
    lesson: string,
    schedules: string[]
  ): Promise<ServiceResponse> {
    let result = { data: null, error: null };

    try {
      const { data } = await this.api.post(`/lessons/booking-refund`, {
        lesson,
        schedules,
      });

      result.data = data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result.error = error.response?.data;
      } else {
        result.error = error as any;
      }
    } finally {
      return result;
    }
  }

  async cancelStudent(
    id: string,
    description?: string
  ): Promise<ServiceResponse> {
    let result = { data: null, error: null };

    try {
      const { data } = await this.api.post(`/classrooms/${id}/cancel/student`, {
        description,
      });

      result.data = data || {};
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result.error = error.response?.data;
      } else {
        result.error = error as any;
      }
    } finally {
      return result;
    }
  }
}
