import {
  Box,
  Flex,
  FlexProps,
  Heading,
  Text,
  useToken,
} from "@chakra-ui/react";

import AlertIcon from "../../assets/icons/alert";

interface CardFeedbackInfoPropsI extends FlexProps {
  title: string;
  message: string;
}
export default function CardFeedbackInfo({
  title,
  message,
  ...props
}: CardFeedbackInfoPropsI) {
  const [colorFeedbackInfoDark] = useToken("colors", ["feedback.info.dark"]);

  return (
    <Flex
      borderRadius="md"
      bg="feedback.info.lightest"
      padding="24px"
      width="100%"
      {...props}
    >
      <Box>
        <AlertIcon size={24} color={colorFeedbackInfoDark} />
      </Box>
      <Box ml={4}>
        <Heading fontSize="sm" color="feedback.info.dark">
          {title}
        </Heading>
        <Text fontSize="xs" color="neutral.dark">
          {message}
        </Text>
      </Box>
    </Flex>
  );
}
