import axios, { AxiosInstance } from "axios";

import { ServiceResponse } from "..";

import { HEADER_CHECKOUT_SESSION } from "../../../config/http";

export class PaymentsService {
  constructor(private api: AxiosInstance) {}

  async createIntent(payload: {
    lesson: string;
    schedules: string[];
  }): Promise<ServiceResponse<string | null>> {
    let result = { data: null, error: null } as ServiceResponse<string | null>;

    try {
      const { headers } = await this.api.post("/payments/intent", payload);

      const checkoutSession =
        (headers?.[HEADER_CHECKOUT_SESSION] as string) || null;

      result.data = checkoutSession;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result.error = error.response?.data;
      } else {
        result.error = error as any;
      }
    } finally {
      return result;
    }
  }

  async getCheckout(token: string): Promise<ServiceResponse> {
    let result = { data: null, error: null } as ServiceResponse;

    try {
      const response = await this.api.get(`/payments/checkout/${token}`);

      result.data = response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result.error = error.response?.data;
      } else {
        result.error = error as any;
      }
    } finally {
      return result;
    }
  }
}
