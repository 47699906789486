import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export function useQuery<T = { [key in string]: string }>() {
  const { search } = useLocation();
  const instance = useMemo(() => new URLSearchParams(search), [search]);
  const object = useMemo<T>(
    () => Object.fromEntries(instance.entries()) as any,
    [instance]
  );

  return useMemo(
    () => ({
      text: search,
      instance,
      object,
    }),
    [search, instance, object]
  );
}
