import { useContext } from "react";

import { AuthContext, AuthContextI } from "../contexts/auth";

export function useAuth<T>() {
  const context = useContext<AuthContextI<T>>(AuthContext as any);

  if (!context) throw Error("hook with provider not defined");

  return context;
}
