const KEY = "@isurf-app";

export function save(key: string, data: any) {
  localStorage.setItem(`${KEY}:${key}`, JSON.stringify(data));
}

export function load(key: string, defaultValue: any = null) {
  const data = localStorage.getItem(`${KEY}:${key}`);

  if (
    data === null ||
    data === "null" ||
    data === undefined ||
    data === "undefined"
  )
    return defaultValue;

  try {
    return JSON.parse(data as any);
  } catch (err) {
    return {};
  }
}

export function remove(key: string) {
  localStorage.removeItem(`${KEY}:${key}`);
}

export function clear() {
  localStorage.clear();
}
