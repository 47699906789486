import DatePickerComponent, { ReactDatePickerProps } from "react-datepicker";

import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";

import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from "../../../assets/icons/calendar";
import DatePickerDay from "../../date-picker/day";
import DatePickerHeader from "../../date-picker/header";
import "./style.css";

interface DatePickerPropsI {
  label?: string;
  value?: any;
  placeholder?: string;
  helperText?: string;
  error?: string | any;
  datePickerProps?: Partial<ReactDatePickerProps>;
  inputProps?: any;
  onChange?: (value: Date) => void;
  calendarHelperText?: string;
  endAdormentIcon?: any;
  w?: string;
  mt?: any;
}

export default function DatePicker({
  label,
  value,
  placeholder = "Type here...",
  helperText = "",
  inputProps = {},
  datePickerProps = {},
  calendarHelperText = "",
  error = "",
  onChange,
  endAdormentIcon = CalendarIcon,
  ...props
}: DatePickerPropsI) {
  const handleChange = (date: any, event: any) => {
    if (inputProps.onChange) inputProps.onChange(event);
    if (onChange) onChange(date);
  };

  return (
    <FormControl isInvalid={!!error} {...props}>
      {label && <FormLabel>{label}</FormLabel>}

      <InputGroup isolation="auto">
        <DatePickerComponent
          selected={value}
          customInput={<Input {...inputProps} inputMode="none" />}
          {...datePickerProps}
          {...inputProps}
          onChange={handleChange}
          renderDayContents={DatePickerDay}
          renderCustomHeader={DatePickerHeader}
          showYearDropdown
        >
          {calendarHelperText && (
            <Text
              wordBreak="break-word"
              height="20px"
              w="100%"
              px={2}
              color="neutral.dark"
              fontSize="2xs"
            >
              {calendarHelperText}
            </Text>
          )}
        </DatePickerComponent>

        {endAdormentIcon && (
          <InputRightElement
            pointerEvents="none"
            children={
              <Box>
                <CalendarIcon />
              </Box>
            }
            minHeight="56px"
            p={6}
          />
        )}
      </InputGroup>

      {error && <FormErrorMessage>{error}</FormErrorMessage>}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
