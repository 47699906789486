import moment from "moment-timezone";
import { useCallback, useMemo, useState } from "react";

import { Flex, IconButton } from "@chakra-ui/react";

import { ISchedule } from "@isurf-tech/types/domain";

import { Schedules } from "../../@types/lessons";
import ChevronLeftIcon from "../../assets/icons/chevron-left";
import ChevronRightIcon from "../../assets/icons/chevron-right";
import { useResposiveness } from "../../hooks/responsiveness";
import ScheduleCardTime from "./card-time";
import ScheduleDayHeader from "./day-header";
import { fillColumnsBySize, getRowsLength, sortTimes } from "./utils";



export const ROWS_MIN = 3;
export const COLUMNS_MAX = 5;

interface SchedulesCalendarPropsI {
  schedules?: Schedules;
  selecteds?: Schedules;
  onClick: (date: string, time: ISchedule) => void;
  limitColumns?: number;
  disabled?: boolean;
  startDate?: string;
  onClickNext?: (offset: number, daysBySection: number) => void;
  onClickPrev?: (offset: number, daysBySection: number) => void;
}

export default function ScheduleCalendar({
  schedules = {},
  selecteds = {},
  onClick,
  limitColumns = COLUMNS_MAX,
  disabled,
  startDate = moment().toISOString(),
  onClickNext,
  onClickPrev,
}: SchedulesCalendarPropsI) {
  const { isMobile } = useResposiveness();
  const [offsetDay, setOffSetDay] = useState(0);
  const rowsLength = useMemo(() => getRowsLength(schedules), [schedules]);
  const columns = useMemo(
    () => fillColumnsBySize(schedules, offsetDay, startDate),
    [schedules, offsetDay, startDate]
  );
  const includes = useCallback(
    (date: any, time: any) => {
      if (!selecteds[date]) return false;

      return selecteds[date].find(
        (t) => t.start === time.start && t.end === time.end
      );
    },
    [selecteds]
  );

  const fillRowsBySize = (data: any[] = [], size: number) => {
    const TARGET = size <= ROWS_MIN ? ROWS_MIN : size;

    const rest = new Array(TARGET).fill({} as ISchedule);

    return [...data, ...rest].slice(0, TARGET);
  };

  const handleCurrentDay = async (target: "add" | "sub") => {
    const newOffset = +offsetDay + (target === "add" ? 1 : -1);

    setOffSetDay(newOffset);

    if (onClickNext && target === "add")
      return await onClickNext(offsetDay, COLUMNS_MAX);

    if (onClickPrev && target === "sub")
      return await onClickPrev(newOffset, COLUMNS_MAX);
  };

  return (
    <Flex mt={2}>
      <Flex
        ml={isMobile ? "-8px" : "-48px"}
        mr={isMobile ? "-16px" : "0px"}
        pt="14px"
      >
        <IconButton
          disabled={offsetDay === 0}
          variant="unstyled"
          aria-label="arrow-left"
          icon={<ChevronLeftIcon />}
          onClick={() => handleCurrentDay("sub")}
        />
      </Flex>

      <Flex overflow="hidden" overflowY="scroll" maxH="40vh">
        {columns.slice(0, limitColumns).map(([date, times], index) => (
          <Flex
            direction="column"
            ml={index > 0 ? 1 : 0}
            key={index.toString()}
          >
            <ScheduleDayHeader date={date} />

            <Flex direction="column">
              {fillRowsBySize(times, rowsLength)
                .sort(sortTimes)
                .map((time, tIndex) => (
                  <ScheduleCardTime
                    key={tIndex.toString()}
                    index={tIndex}
                    selected={includes(date, time)}
                    time={time}
                    date={date}
                    onClick={() => onClick(date, time)}
                    disabled={disabled}
                  />
                ))}
            </Flex>
          </Flex>
        ))}
      </Flex>

      <Flex
        mr={isMobile ? "-8px" : "-48px"}
        ml={isMobile ? "-16px" : "0px"}
        pt="14px"
      >
        <IconButton
          variant="unstyled"
          aria-label="arrow-left"
          icon={<ChevronRightIcon />}
          onClick={() => handleCurrentDay("add")}
        />
      </Flex>
    </Flex>
  );
}
