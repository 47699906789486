import moment, { Moment } from "moment-timezone";

import { DateBase, DateFormats, TIME } from "./date.abstract";

export class DateMoment implements DateBase<Moment> {
  readonly locale: string;
  readonly timezone: string;

  constructor() {
    this.locale = window.navigator.language;
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  private getInstance(date?: Moment): Moment {
    return moment(date).tz(this.timezone).locale(this.locale);
  }

  getDate(date: any): Moment {
    return this.getInstance(date);
  }

  today(): Moment {
    return this.getInstance();
  }

  increaseHour(date: moment.Moment, target: number): moment.Moment {
    return this.getInstance(date).add(target, "hour");
  }

  increaseDay(date: Moment, target: number): Moment {
    return this.getInstance(date).add(target, "day");
  }

  decreaseDay(date: Moment, target: number): Moment {
    return this.getInstance(date).subtract(target, "day");
  }

  isSameDay(compare: Moment, target: Moment): boolean {
    const format = "YYYYMMDD";
    return target.format(format) === compare.format(format);
  }

  isSame(compare: Moment, target: Moment, format = "YYYYMMDDHHmmss"): boolean {
    return (
      this.getInstance(target).format(format) ===
      this.getInstance(compare).format(format)
    );
  }

  format(date: Moment, type: DateFormats): string {
    return date.format(type);
  }

  rangeBetween(date: Moment, target: number): Moment[] {
    const before = new Array(target - 1)
      .fill(null)
      .map((_, index) => {
        return this.getInstance(date).subtract(index + 1, "day");
      }, {})
      .reverse();

    const after = new Array(target - 1).fill(null).map((_, index) => {
      return this.getInstance(date).add(index + 1, "day");
    }, {});

    return [...before, moment(date), ...after];
  }

  toDate(date: any): Date {
    return this.getInstance(date).toDate();
  }

  weekDays(): string[] {
    return moment.weekdays(true);
  }

  setTime(date: Moment, time: Partial<TIME>): moment.Moment {
    return this.getInstance(date).set(time);
  }
}
