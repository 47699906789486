import { Icon, IconProps } from "@chakra-ui/react";

interface ChevronRightIconPropsI extends IconProps {}

export default function ChevronRightIcon(props: ChevronRightIconPropsI) {
  return (
    <Icon
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 18L15 12L9 6"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
