export enum MediaContext {
  "MEDIAS" = "MEDIAS",
  "FORM-MEDIAS" = "FORM-MEDIAS",
  'DOCUMENTS' = 'DOCUMENTS',
}

export enum MediaObject {
  "PERFIL" = "PERFIL",
  "ALBUMS" = "ALBUMS",
  'PERSON' = 'PERSON',
}

export interface MediaPayload {
  context: MediaContext;
  object: MediaObject;

  name: string;

  id?: string;
}
