import {
  Box,
  Button,
  Flex,
  Heading,
  Img,
  Text,
  useToken,
} from "@chakra-ui/react";

import { ISchool } from "@isurf-tech/types/domain";

import WaveIcon from "../assets/icons/wave";
import BoardsSurf from "../assets/medias/boards.png";
import PeopleLessonSurf from "../assets/medias/people-lesson.png";
import Header from "../components/headers/main";
import { DialogActionResponse } from "../contexts/dialog";
import { useAuth } from "../hooks/auth";
import { useDialog } from "../hooks/dialog";
import { useResposiveness } from "../hooks/responsiveness";
import { supportService } from "../services/api";

export default function SchoolAnalyse() {
  const { isDesktop } = useResposiveness();
  const { user: school } = useAuth<ISchool>();
  const [brandLightColor] = useToken("colors", ["brand.light"]);
  const dialog = useDialog();

  const handleSendHelpMessage = async (
    message: string
  ): Promise<DialogActionResponse> => {
    if (!school)
      return {
        type: "error",
        title: "Erro ao enviar mensagem.",
        description:
          "Você não está logado, por favor faça login e tente novamente.",
      };

    const { data, error } = await supportService.create({
      name: school.name,
      email: school.owner.email,
      description: message,
    });

    if (error)
      return {
        type: "error",
        title: "Erro ao enviar mensagem.",
        description:
          error?.message ||
          "Houve um erro ao enviar mensagem, por favor tente novamente ou entre em contato com o suporte.",
      };

    return {
      type: "success",
      title: "Mensagem enviada com sucesso!",
      description:
        data ||
        "A mensagem foi enviada com sucesso. Logo seus alunos serão notificados.",
    };
  };

  const requestSendMessage = () => {
    dialog.open({
      type: "text-input",
      title: "Diga o que você precisa",
      description:
        "Envie uma mensagem para o suporte e logo entraremos em contato com você.",
      inputProps: {
        label: "Mensagem",
        type: "text-area",
        placeholder: "Digite aqui sua mensagem aqui...",
        fullWidth: true,
        inputProps: {
          minH: "20vh",
        },
      },
      redirect: {
        label: "Enviar mensagem",
        action: (content) => handleSendHelpMessage(content),
      },
      closeLabel: "Cancelar mensagem",
    });
  };

  return (
    <Box>
      <Header />
      <Flex w="100%" h="calc(100vh - 64px)">
        <Flex
          w={isDesktop ? "40%" : "100%"}
          direction="column"
          alignItems="flex-start"
          padding={isDesktop ? "120px 80px 80px 80px" : "40px 16px"}
        >
          <Heading fontSize="lg" maxW={isDesktop ? "100%" : "80%"}>
            Sua conta está em análise
          </Heading>

          <Text
            mt={isDesktop ? 14 : 8}
            fontSize="sm"
            color="neutral.darkest"
            maxW={isDesktop ? "22vw" : "100%"}
          >
            Para garantir qualidade aos alunos sempre analisamos a criação da
            conta das escolas, mas fique tranquilo logo você reberá um contato.
          </Text>

          <Text
            mt={6}
            fontSize="sm"
            fontWeight="semibold"
            color="neutral.darkest"
            maxW={isDesktop ? "22vw" : "100%"}
          >
            Você deverá receber algumas instruções por email e sua conta deve
            ser ativada em 2 dias úteis.
          </Text>

          <Heading fontSize="sm" mt={8} maxW={isDesktop ? "22vw" : "60%"}>
            Tem dúvidas? Manda uma mensagem pra gente.
          </Heading>

          <Button
            size={isDesktop ? "md" : "lg"}
            mt={6}
            onClick={requestSendMessage}
          >
            Mandar mensagem
          </Button>
        </Flex>

        {isDesktop && (
          <Flex
            w="60%"
            h="100%"
            bg="brand.lightest"
            justifyContent="center"
            pt="120px"
            position="relative"
          >
            <Flex position="absolute" left="10%">
              <WaveIcon color={brandLightColor} />
            </Flex>

            <Flex position="absolute" left="22%" top="30%">
              <Img src={PeopleLessonSurf} height="55vh" />
            </Flex>

            <Flex position="absolute" left="50%" top="10%">
              <Img src={BoardsSurf} height="55vh" />
            </Flex>
          </Flex>
        )}
      </Flex>
    </Box>
  );
}
