import { ComponentStyleConfig } from "@chakra-ui/react";

const LinkTheme: ComponentStyleConfig = {
  baseStyle: {
    textDecoration: "underline",
    textUnderlineOffset: "3px",
    color: "neutral.darkest",
    fontFamily: "body",
    fontWeight: "semibold",
    cursor: "pointer",
    ":hover": {
      color: "brand.dark",
    },
    ":focus": {
      color: "brand.dark",
      outline: "2px solid",
      outlineColor: "brand.medium",
      outlineOffset: "1px",
      borderRadius: "sm",
    },
  },
  sizes: {
    small: {
      fontSize: "2xs",
    },
    medium: {
      fontSize: "xs",
    },
    large: {},
  },
  variants: {},
  defaultProps: {
    size: "medium",
  },
};

export default LinkTheme;
