import { useState } from "react";

import {
  Collapse,
  Divider,
  Flex,
  IconButton,
  Text,
  TextProps,
} from "@chakra-ui/react";

import ChevronDownIcon from "../assets/icons/chevron-down";

interface CollapseSectionTextProps {
  isOpen?: boolean;
  title: string;
  textProps?: TextProps;
  onClick?: () => void;
  children: any;
}

export default function CollapseSectionText({
  isOpen = false,
  title,
  textProps,
  onClick,
  children,
}: CollapseSectionTextProps) {
  const [isOpenState, setIsOpen] = useState(isOpen);

  const handleToggle = async () => {
    setIsOpen(!isOpenState);

    if (onClick) return await onClick?.();
  };

  return (
    <>
      <Flex align="center" justify="space-between" onClick={handleToggle}>
        <Text {...textProps}>{title}</Text>

        <IconButton
          variant="unstyled"
          aria-label="arrow"
          icon={<ChevronDownIcon fontSize="md" color="neutral.dark" />}
          transition="all 0.3s ease-out"
          transform={isOpenState ? "rotate(-180deg)" : "rotate(0deg)"}
          _focus={{ outline: "0px !important", outlineColor: "none" }}
        />
      </Flex>

      <Divider my={2} mb={4} />

      <Collapse in={isOpenState} animateOpacity>
        {children}
      </Collapse>
    </>
  );
}
