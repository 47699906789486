import axios, { AxiosInstance } from "axios";

import { ServiceResponse } from "..";

import { EntityUserType } from "@isurf-tech/types/domain/enums";

import { STORAGE_KEY_ACCESS_TOKEN } from "../../../config/http";

export class AuthService {
  constructor(private api: AxiosInstance) {}

  public async signin<T = any>(
    email: string,
    password: string
  ): Promise<ServiceResponse<T>> {
    let result = { data: null, error: null };

    try {
      const { data } = await this.api.post("/auth/signin", {
        email,
        password,
      });

      if (data[STORAGE_KEY_ACCESS_TOKEN]) {
        this.api.setDefaultToken(data[STORAGE_KEY_ACCESS_TOKEN]);
      }

      result.data = data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result.error = error.response?.data;
      } else {
        result.error = error as any;
      }
    } finally {
      return result as any;
    }
  }

  public async signup(form: {
    email: string;
    cellphone: string;
    password: string;
    name: string;
    entity: EntityUserType;
  }): Promise<ServiceResponse> {
    let result = { data: null, error: null };

    try {
      const { data } = await this.api.post("/auth/signup", form);

      if (data[STORAGE_KEY_ACCESS_TOKEN]) {
        this.api.setDefaultToken(data[STORAGE_KEY_ACCESS_TOKEN]);

        delete data[STORAGE_KEY_ACCESS_TOKEN];
      }

      result.data = data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result.error = error.response?.data;
      } else {
        result.error = error as any;
      }
    } finally {
      return result;
    }
  }

  public async profile(): Promise<ServiceResponse> {
    let result = { data: null, error: null };

    try {
      const { data } = await this.api.get("/auth/profile");

      result.data = data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result.error = error.response?.data;
      } else {
        result.error = error as any;
      }
    } finally {
      return result;
    }
  }

  public async requestVerify(
    type: "email" | "cellphone"
  ): Promise<ServiceResponse> {
    let result = { data: null, error: null };

    try {
      const { data } = await this.api.post(`/auth/verify/${type}`);

      result.data = data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result.error = error.response?.data;
      } else {
        result.error = error as any;
      }
    } finally {
      return result;
    }
  }

  public async validateUserData(
    type: "email" | "cellphone",
    code: string
  ): Promise<ServiceResponse> {
    let result = { data: null, error: null };

    try {
      const { data } = await this.api.get(`/auth/confirm/${type}?code=${code}`);

      result.data = data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result.error = error.response?.data;
      } else {
        result.error = error as any;
      }
    } finally {
      return result;
    }
  }

  public async requestRecoveyPassword(email: string): Promise<ServiceResponse> {
    let result = { data: null, error: null };

    try {
      const { data } = await this.api.post(
        `/auth/recovery/password/${email}/request`
      );

      result.data = data?.message || "Email enviado com sucesso";
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result.error = error.response?.data?.message || "Erro ao enviar email";
      } else {
        result.error = error as any;
      }
    } finally {
      return result;
    }
  }

  public async confirmRecoveyPassword(
    token: string,
    password: string
  ): Promise<ServiceResponse> {
    let result = { data: null, error: null };

    try {
      const { data } = await this.api.post(
        `/auth/recovery/password/confirm`,
        {
          hash: btoa(password),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      result.data = data?.message || "Senha alterada com sucesso";
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result.error = error.response?.data;
      } else {
        result.error = error as any;
      }
    } finally {
      return result;
    }
  }
}
