
import React from "react";

import { Flex } from "@chakra-ui/react";

// import SecondSection from "./components/second-section";

import HowToWorkFirstIcon from "../../../assets/icons/how-to-work/home/first";
import HowToWorkFourtyIcon from "../../../assets/icons/how-to-work/home/fourty";
import HowToWorkSecondIcon from "../../../assets/icons/how-to-work/home/second";
import HowToWorkThirdIcon from "../../../assets/icons/how-to-work/home/third";
import Footer from "../../../components/footer";
import Header from "../../../components/headers/main";
import HowItWorkSection from "../../../components/sections/how-it-work";
import SectionQuestion from "../../../components/sections/question";
import { useMobileSearch } from "../../../hooks/mobile-search";
import { useResposiveness } from "../../../hooks/responsiveness";
import FiftySection from "./components/fifty-section";
import FirstSection from "./components/first-section";
import FourtySection from "./components/fourty-section";
import SixthSection from "./components/sixth-section";
import ThirdSection from "./components/third-section";

const howItWorkSteps = [
  {
    icon: HowToWorkFirstIcon,
    title: "Busque uma aula nas praias que você mais gosta ",
    description:
      "Utilize a busca para encontrar uma aula nas praias que você mais gosta, na modalidade que queira aprender.",
  },
  {
    icon: HowToWorkSecondIcon,
    title: "Confira os detalhes das aulas",
    description:
      "Veja todos os detalhes de funcionamento das aulas, local, horários disponíveis, modalidade, escola.",
  },
  {
    icon: HowToWorkThirdIcon,
    title: "Agende uma ou mais aulas",
    description:
      "Selecione uma ou mais aulas nos horários disponíveis. Realize o cadastro e pagamento na plataforma, assim você tem tudo pronto para curtir.",
  },
  {
    icon: HowToWorkFourtyIcon,
    title: "Agora é só curtir",
    description:
      "A escola preparará as suas aulas e você só precisará seguir as instruções para chegar no local e curtir. ",
  },
];

function Home() {
  const { isMobile } = useResposiveness();
  const { open: openMobileSearch } = useMobileSearch();

  return (
    <Flex direction="column">
      <Header onSearch={isMobile ? openMobileSearch : null} />

      <FirstSection />
      {/* <SecondSection /> */}

      <ThirdSection />
      <HowItWorkSection steps={howItWorkSteps} />
      <FourtySection />

      <FiftySection />
      <SixthSection />

      <SectionQuestion />

      <Footer />
    </Flex>
  );
}

export default Home;
