import React, { useCallback, useEffect, useState } from "react";

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Flex,
  Heading,
  Link,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import { getRegionsEnabled, regionRestrictionEnabled } from "../config/region";
import useLocalStorage from "../hooks/storage";
import apiGeolocation from "../services/api-geolocation";

const geolocationAPI = navigator.geolocation;

export function RegionRestriction({ children }: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<any>();
  const [coords, setCoords] = useLocalStorage<{
    lat: number;
    lng: number;
  } | null>('coords', null);
  const [isRegionEnabled, setIsRegionEnabled] = useState<boolean>(true);

  const getUserCoordinates = (): Promise<{ data: any; error: any }> => {
    return new Promise((resolve) => {
      if (!geolocationAPI)
        return resolve({
          data: null,
          error: "Geolocation is not supported by this browser.",
        });

      geolocationAPI.getCurrentPosition(
        (position) => {
          const { coords } = position;

          return resolve({
            data: {
              lat: coords.latitude,
              lng: coords.longitude,
            },
            error: null,
          });
        },
        (error) => {
          return resolve({
            data: null,
            error: error.message,
          });
        }
      );
    });
  };

  const checkRegionRestriction = useCallback(async () => {
    if (!coords) return;

    const { data } = await apiGeolocation.getGeolocationData(
      coords?.lat,
      coords?.lng
    );

    if (!data) return;

    const regionsEnabled = getRegionsEnabled();

    if (!regionsEnabled?.includes(data?.countryCode?.toUpperCase()))
      setIsRegionEnabled(false);
  }, [coords]);

  useEffect(() => {
    (async () => {
      await checkRegionRestriction();

      if (!coords) return onOpen();
      if (isOpen && coords) return onClose();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coords]);

  useEffect(() => {
    (async () => {
      const { data } = await getUserCoordinates();

      setCoords(data);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!regionRestrictionEnabled) return children;

  if (!coords)
    return (
      <React.Fragment>
        {children}

        <AlertDialog
          leastDestructiveRef={cancelRef}
          onClose={() => {}}
          isOpen={isOpen}
          isCentered
          size="lg"
        >
          <AlertDialogOverlay />

          <AlertDialogContent>
            <AlertDialogHeader>
              <Heading fontSize="md" color="neutral.darkest">
                Permitir localização?
              </Heading>
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text fontSize="sm" color="neutral.dark">
                Precisamos da sua localização para mostrar os conteúdos mais
                próximos de você. Caso não permita, você poderá alterar isso nas
                configurações do seu navegador:
                <Link
                  href="https://support.google.com/chrome/answer/142065?hl=pt-BR"
                  target="_blank"
                  color="brand.dark"
                  ml={2}
                  fontSize="sm"
                >
                  Como liberar sua localização
                </Link>
              </Text>
            </AlertDialogBody>
          </AlertDialogContent>
        </AlertDialog>
      </React.Fragment>
    );

  if (!isRegionEnabled)
    return (
      <Flex>
        <Text>Região não permitida</Text>
      </Flex>
    );

  return children;
}
