import { ComponentStyleConfig } from "@chakra-ui/react";

const RadioTheme: ComponentStyleConfig = {
  parts: ["container", "control", "label"],
  baseStyle: {
    container: {
      p: "6px",
      px: "8px",
      w: "max-content",
      _hover: {
        bg: "neutral.light",
        borderRadius: "full",
      },
    },
    control: {
      border: "hairline",
      borderColor: "neutral.dark",
      borderRadius: "full",
      minHeight: "24px",
      minWidth: "24px",
      color: "neutral.lightest",
      bg: "white",
      "&[data-checked]": {
        bg: "brand.dark",
        borderColor: "brand.dark",
      },
      _hover: {
        "&[data-checked]": {
          bg: "brand.dark",
          borderColor: "brand.dark",
        },
      },
    },
    label: {
      ml: 2,
      fontFamily: "body",
      fontSize: "sm",
      fontWeight: "medium",
      color: "neutral.darkest",
    },
  },
  sizes: {},
  variants: {
    outline: {
      container: {
        border: "hairline",
        borderRadius: "full",
        borderColor: "neutral.medium",

        "&[data-checked]": {
          bg: "brand.lightest",
          borderColor: "brand.dark",
        },
      },
    },
  },
  defaultProps: {
    size: "sm",
  },
};

export default RadioTheme;
