import moment from "moment-timezone";
import { useCallback, useState } from "react";

import { Button, Divider, Flex, Heading, Text } from "@chakra-ui/react";

import { Schedules } from "../../@types/lessons";
import { useResposiveness } from "../../hooks/responsiveness";
import SchedulesCalendar from "./calendar";

const formatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

interface SchedulesComponentPropsI {
  simple?: boolean;
  data?: Schedules;
  price?: number;
  disabled?: boolean;
  startDate?: string;
  onChange?: (data: Schedules) => void;
  onClick?: (data: Schedules) => void;
  onRefresh?: (start: string, end: string) => void;
  selecteds?: Schedules;
}

export default function SchedulesComponent({
  simple,
  data = {},
  price = 0,
  disabled,
  onChange,
  onClick,
  startDate = moment().toISOString(),
  onRefresh,
  selecteds = {},
}: SchedulesComponentPropsI) {
  const { isMobile } = useResposiveness();
  const [total, setTotal] = useState(0);

  const addOrRemove = useCallback(
    (date: any, time: any) => {
      let targetTotal = total;
      const data = Object.assign({}, selecteds);

      if (!data[date]) data[date] = [];

      const includes = selecteds[date]?.find(
        (t) => t.start === time.start && t.end === time.end
      );

      if (includes) targetTotal -= 1;
      else targetTotal += 1;

      if (data[date] && includes)
        data[date] = data[date].filter(
          (t) => !(t.start === time.start && t.end === time.end)
        );
      else if (data[date] && !includes) data[date].push(time);

      setTotal(targetTotal);
      if (onChange) onChange(data);
    },
    [onChange, selecteds, total]
  );

  const getNextDates = async (offset: number, columns: number) => {
    if (offset % 2 !== 0) {
      const target = columns * (offset + 1);

      const start = moment()
        .add(target + columns, "days")
        .startOf("day")
        .toISOString();
      const end = moment(start)
        .add(columns * 2, "days")
        .endOf("day")
        .toISOString();

      await onRefresh?.(start, end);
    }
  };

  const isSimple = simple ? "none" : "flex";

  return (
    <Flex
      direction="column"
      shadow={simple ? "" : "sm"}
      p={isMobile ? "32px 16px" : simple ? "0px" : "48px 56px 32px 56px"}
      borderRadius="md"
      maxW="min-content"
      height="min-content"
    >
      <Heading fontWeight="semibold" fontSize="lg" display={isSimple}>
        Horários
      </Heading>

      <Flex direction="row" mt={2} display={isSimple}>
        <Text color="neutral.darkest">{formatter.format(price * 0.01)}</Text>
        <Text color="neutral.dark" ml={1}>
          /aula
        </Text>
      </Flex>

      <Text
        color="neutral.dark"
        mt={1}
        fontSize="2xs"
        fontWeight="medium"
        display={isSimple}
      >
        Agende 5 aulas para receber um desconto!
      </Text>

      <SchedulesCalendar
        schedules={data}
        selecteds={selecteds}
        onClick={addOrRemove}
        limitColumns={isMobile ? 4 : 5}
        disabled={disabled}
        startDate={startDate}
        onClickNext={getNextDates}
      />

      <Text mt={2} fontWeight="medium" fontSize="2xs" color="neutral.dark">
        Selecione mais de de um horário para agendar mais aulas
      </Text>

      <Divider my={4} display={isSimple} />

      <Flex justify="space-between" display={isSimple}>
        <Text fontSize="xs" fontWeight="medium">
          {total} aulas selecionadas
        </Text>
        <Text fontSize="xs" fontWeight="medium">
          {total}x {formatter.format(price * 0.01)}
        </Text>
      </Flex>

      <Flex justify="space-between" mt={2} display={isSimple}>
        <Text fontSize="sm" fontWeight="semibold">
          Total
        </Text>
        <Text fontSize="sm" fontWeight="semibold">
          {formatter.format(total * (price * 0.01))}
        </Text>
      </Flex>

      <Button
        mt={simple ? 2 : 8}
        size="lg"
        onClick={() => onClick?.(selecteds)}
        disabled={disabled}
      >
        Agendar horários
      </Button>
    </Flex>
  );
}
