import { Button, Flex, FlexProps, Heading, Text } from "@chakra-ui/react";

import CardsListIcon from "../assets/icons/cards-list";
import { useResposiveness } from "../hooks/responsiveness";

interface IEmptyListProps extends FlexProps {
  title?: string;
  description?: string;
  buttonLabel?: string;
  onClick?: () => void;
  noIcon?: boolean;
  icon?: JSX.Element;
}

export default function EmptyList({ buttonLabel, onClick, ...props }: IEmptyListProps) {
  const { isMobile } = useResposiveness();

  return (
    <Flex
      flexDirection="column"
      align="center"
      justify="center"
      backdropFilter="blur(10px)"
      {...props}
    >
      {!!!props.noIcon &&
        (props.icon ? (
          props.icon
        ) : (
          <CardsListIcon height={isMobile ? "8vh" : "10vh"} />
        ))}

      <Heading mt={6} color="neutral.darkest" fontSize={isMobile ? "sm" : "md"}>
        {props.title || "Não há items"}
      </Heading>

      <Text
        color="neutral.dark"
        fontSize={isMobile ? "2xs" : "xs"}
        maxW={isMobile ? "100vw" : "25vw"}
        textAlign="center"
        mt={2}
      >
        {props.description || "Adicione items para começar a usar o sistema"}
      </Text>

      {onClick && (
        <Button my={4} onClick={onClick}>
          {buttonLabel || "Adicionar item"}
        </Button>
      )}
    </Flex>
  );
}
