import { ComponentStyleConfig } from "@chakra-ui/react";

const TabsTheme: ComponentStyleConfig = {
  parts: ["tab"],
  baseStyle: {
    tablist: {
      //   borderColor: "red !important",
      borderBottomColor: "neutral.medium !important",
    },
    tab: {
      p: 2,
      color: "neutral.dark",
      fontWeight: "semibold",
      "&[aria-selected=true]": {
        color: "brand.dark !important",
        borderColor: "brand.dark !important",
      },
    },
    tabpanel: {},
  },
};

export default TabsTheme;
