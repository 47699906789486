import { useContext } from "react";

import { SessionContext } from "../contexts/session";

export const useSession = () => {
  const context = useContext(SessionContext);

  if (!context) throw Error("hook with provider not defined");

  return context;
};
