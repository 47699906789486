import React, { useCallback, useMemo } from "react";

import { Button, Flex, IconButton } from "@chakra-ui/react";

import ChevronLeftIcon from "../../assets/icons/chevron-left";
import ChevronRightIcon from "../../assets/icons/chevron-right";

interface PaginatorPropsI {
  disabled?: boolean;
  page: number;
  count?: number;
  offset?: number;
  onChange: (target: number) => void;
}

const MAX_PAGES = 4;

export default function Paginator({
  disabled = false,
  page = 0,
  count = 0,
  offset = 10,
  onChange,
}: PaginatorPropsI) {
  const total = useMemo(
    () => Math.abs(Math.ceil(count / offset)),
    [count, offset]
  );
  const chunk = useMemo(() => Math.floor(page / MAX_PAGES), [page]);
  const chunks = useMemo(() => Math.floor(total / MAX_PAGES), [total]);

  const next = useCallback(() => {
    const next = page + 1;

    if (next >= total) return;
    if (next !== page && onChange) onChange(next);
  }, [onChange, page, total]);

  const previous = useCallback(() => {
    const previous = page - 1;

    if (previous < 0) return;
    if (page !== previous && onChange) onChange(page - 1);
  }, [onChange, page]);

  const handleSetPage = (target: number) => {
    if (target > total) return;

    if (target > page || target < page) onChange(target);
  };

  return (
    <Flex>
      <IconButton
        variant="unstyled"
        aria-label="left"
        icon={<ChevronLeftIcon fontSize="lg" color="neutral.dark" />}
        outlineOffset={-2}
        px={0}
        mr={2}
        onClick={previous}
        disabled={disabled}
      />

      {new Array(MAX_PAGES)
        .fill(null)
        .map((_, index) => index + MAX_PAGES * chunk)
        .filter((id) => id >= 0 && id < total)
        .map((id) => (
          <React.Fragment key={id.toString()}>
            <Button
              variant={page === id ? "solid" : "unstyled"}
              mx={1}
              onClick={() => handleSetPage(id)}
              disabled={disabled}
            >
              {id + 1}
            </Button>
          </React.Fragment>
        ))}

      {chunk < chunks && (
        <Button
          variant="unstyled"
          mx={1}
          cursor="auto"
          as="div"
          display="flex"
          alignItems="end"
          pb={1}
          disabled={disabled}
        >
          ...
        </Button>
      )}

      <IconButton
        variant="unstyled"
        aria-label="left"
        icon={<ChevronRightIcon fontSize="lg" color="neutral.dark" />}
        outlineOffset={-2}
        px={0}
        ml={2}
        onClick={next}
        disabled={disabled}
      />
    </Flex>
  );
}
