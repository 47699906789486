export default function KiteSurfIcon() {
  return (
    <svg
      width="40"
      height="38"
      viewBox="0 0 40 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.8544 31.397C38.2598 31.0527 37.4984 31.2561 37.1542 31.8509C36.1242 33.6313 34.2082 34.7375 32.1543 34.7375C30.1003 34.7375 28.1843 33.6315 27.1545 31.8511C26.932 31.4665 26.5216 31.2298 26.0774 31.2298C25.6333 31.2298 25.2227 31.4665 25.0003 31.8511C23.9705 33.6315 22.0548 34.7375 20.0006 34.7375C17.9466 34.7375 16.031 33.6315 15.001 31.8511C14.7786 31.4667 14.3681 31.2298 13.9239 31.2298C13.4798 31.2298 13.0693 31.4665 12.8469 31.8509C11.8169 33.6313 9.90093 34.7375 7.84695 34.7375C5.79314 34.7375 3.8772 33.6313 2.84706 31.8509C2.50279 31.256 1.74159 31.0531 1.1468 31.397C0.551844 31.7411 0.348768 32.5025 0.692868 33.0973C2.16632 35.644 4.90751 37.2261 7.84679 37.2261C10.1732 37.2261 12.3758 36.2348 13.9236 34.5588C15.4714 36.235 17.6739 37.2261 20.0003 37.2261C22.3268 37.2261 24.5293 36.235 26.0773 34.5588C27.6251 36.235 29.8275 37.2261 32.1541 37.2261C35.0934 37.2261 37.8347 35.644 39.308 33.0973C39.6524 32.5023 39.4492 31.7411 38.8544 31.397Z"
        fill="#0F7E99"
      />
      <path
        d="M39.9058 22.5881C34.5854 9.74344 25.5706 0.772461 17.9836 0.772461C16.8106 0.772461 15.6892 0.990634 14.6503 1.42101C14.0154 1.68398 13.7139 2.41199 13.9769 3.04677L24.683 28.8936C24.8814 29.3727 25.3448 29.6621 25.833 29.6621C25.9917 29.6621 26.1531 29.6315 26.3087 29.567L39.2324 24.2139C39.5373 24.0876 39.7795 23.8454 39.9058 23.5404C40.032 23.2357 40.032 22.8931 39.9058 22.5881ZM26.506 26.7916L16.8032 3.36681C20.0423 2.77053 24.1419 4.73541 28.1321 8.74266C31.5825 12.208 34.7461 17.0226 37.1173 22.3963L26.506 26.7916Z"
        fill="#0F7E99"
      />
      <path
        d="M4.0114 13.3693C6.22316 13.3693 8.02263 11.5698 8.02263 9.35808C8.02263 7.14615 6.22316 5.34668 4.0114 5.34668C1.79947 5.34685 0 7.14632 0 9.35808C0 11.5698 1.79947 13.3693 4.0114 13.3693ZM4.0114 7.83551C4.85091 7.83551 5.53396 8.51857 5.53396 9.35824C5.53396 10.1978 4.85091 10.8808 4.0114 10.8808C3.17172 10.8806 2.48867 10.1976 2.48867 9.35808C2.48867 8.51857 3.17172 7.83551 4.0114 7.83551Z"
        fill="#0F7E99"
      />
      <path
        d="M11.7543 16.5694L16.3701 14.1873C16.9808 13.8722 17.2204 13.1216 16.9052 12.5109C16.5901 11.9002 15.8395 11.6605 15.2288 11.9759L10.8814 14.2193H4.01188C3.57785 14.2193 3.17519 14.4454 2.94938 14.8161C2.72358 15.1867 2.70699 15.6481 2.90591 16.0339L7.00043 23.975C7.21396 24.389 7.64068 24.6491 8.10639 24.6491H15.2577L19.5539 29.2653C19.7991 29.5288 20.1316 29.662 20.4651 29.662C20.7687 29.662 21.073 29.5516 21.3126 29.3285C21.8156 28.8605 21.844 28.0729 21.3758 27.5698L16.7105 22.557C16.4751 22.304 16.1451 22.1603 15.7997 22.1603H8.86477L6.05341 16.7078H11.1835C11.3821 16.708 11.5778 16.6605 11.7543 16.5694Z"
        fill="#0F7E99"
      />
    </svg>
  );
}
