import { Box, Button, Flex, Heading, Text, useToken } from "@chakra-ui/react";

import CloseIcon from "../../assets/icons/close";
import { useDialog } from "../../hooks/dialog";
import BaseDialog from "./base";

const DIALOG_TYPE = "error";

interface DialogErrorProps {
  title?: string;
  description?: string;
}

export default function DialogError(params: DialogErrorProps) {
  const { props, close } = useDialog();
  const [feedbackDangerMediumColor] = useToken("colors", [
    "feedback.danger.medium",
  ]);

  const handleAction = () => {
    if (props?.redirect?.action) props?.redirect?.action();
    close();
  };

  const handleClose = () => {
    if (props?.onClose) props?.onClose();
    close();
  };

  return (
    <BaseDialog type={DIALOG_TYPE}>
      <Flex flex={0.6} direction="column" justify="center" align="center" p={4}>
        <Box bg="feedback.danger.lightest" borderRadius="full" p={4}>
          <CloseIcon color={feedbackDangerMediumColor} />
        </Box>

        <Box my={6}>
          <Heading fontSize="lg" color="neutral.darkest" textAlign="center">
            {params?.title || props?.title || "Erro!"}
          </Heading>
          <Text
            fontSize="sm"
            color="neutral.dark"
            mt={4}
            textAlign="center"
            dangerouslySetInnerHTML={{
              __html:
                params?.description ||
                props?.description ||
                "Algo errado aconteceu!",
            }}
          />
        </Box>

        {props?.redirect && (
          <Button variant="outline" size="md" w="100%" onClick={handleAction}>
            {props?.redirect?.label}
          </Button>
        )}

        <Button size="md" w="100%" mt={2} onClick={handleClose}>
          {props?.closeLabel || "Fechar"}
        </Button>
      </Flex>
    </BaseDialog>
  );
}
