import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useMemo } from "react";

import paymentGatewayConfig from "../config/payment-gateway";

export const PaymentGatewayProvider = ({ children }: any) => {
  const stripePromise = useMemo(
    () => loadStripe(paymentGatewayConfig.publishableKey),
    []
  );

  return <Elements stripe={stripePromise}>{children}</Elements>;
};
