import React from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { Button, GridItem, Heading } from "@chakra-ui/react";

import Select from "../../../../components/select";
import routesName from "../../../../config/routes";
import { useResposiveness } from "../../../../hooks/responsiveness";

const subscriptionLimitOptions = [
  {
    label: "1 hora antes",
    value: 1,
  },
  {
    label: "3 horas antes",
    value: 3,
  },
  {
    label: "6 horas antes",
    value: 6,
  },
  {
    label: "12 horas antes",
    value: 12,
  },
  {
    label: "1 dia antes",
    value: 24,
  },
  {
    label: "2 dia antes",
    value: 24 * 2,
  },
  {
    label: "3 dia antes",
    value: 24 * 3,
  },
  {
    label: "sem data limite",
    value: 0,
  },
];

const cancelationLimitOptions = [
  {
    label: "1 hora antes",
    value: 1,
  },
  {
    label: "3 horas antes",
    value: 3,
  },
  {
    label: "6 horas antes",
    value: 6,
  },
  {
    label: "12 horas antes",
    value: 12,
  },
  {
    label: "1 dia antes",
    value: 24,
  },
  {
    label: "2 dia antes",
    value: 24 * 2,
  },
  {
    label: "3 dia antes",
    value: 24 * 3,
  },
  {
    label: "sem data limite",
    value: 0,
  },
];

export const formSchema = {
  limitDateSubscription: yup
    .number()
    .typeError("Selecione uma opção")
    .nullable()
    .oneOf(subscriptionLimitOptions.map((s) => s.value))
    .optional(),
  limitDateCancelation: yup
    .number()
    .typeError("Selecione uma opção")
    .oneOf(cancelationLimitOptions.map((s) => s.value))
    .nullable()
    .optional(),
};

export default function StepConclusion() {
  const { isMobile } = useResposiveness();
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    getValues,
  } = useFormContext();

  return (
    <React.Fragment>
      <GridItem colStart={1} colSpan={isMobile ? 4 : 6} mt={2}>
        <Heading fontSize="xl" fontWeight="semibold">
          Conclusão
        </Heading>
      </GridItem>

      <GridItem colStart={1} colSpan={isMobile ? 4 : 3} mt={8}>
        <Select
          label="Data limite para insrição"
          placeholder="selecione uma data"
          options={subscriptionLimitOptions}
          selectProps={register("limitDateSubscription", {
            valueAsNumber: true,
          })}
          error={errors?.limitDateSubscription?.message}
        />
      </GridItem>

      <GridItem colStart={1} colSpan={isMobile ? 4 : 3} mt={8}>
        <Select
          label="Data limite para cancelamento gratuito"
          placeholder="selecione uma data"
          options={cancelationLimitOptions}
          selectProps={register("limitDateCancelation", {
            valueAsNumber: true,
          })}
          error={errors?.limitDateCancelation?.message}
        />
      </GridItem>

      <GridItem
        colStart={1}
        colSpan={isMobile ? 4 : 5}
        mt={8}
        mb={isMobile ? "5vh" : "20vh"}
      >
        <Button
          size="lg"
          onClick={() =>
            navigate(
              routesName.lessonById.replace(":id", routesName.newLessonPreview),
              {
                state: {
                  preview: true,
                  lesson: getValues(),
                },
              }
            )
          }
        >
          Ver anúncio
        </Button>
      </GridItem>
    </React.Fragment>
  );
}
