import { ComponentStyleConfig } from "@chakra-ui/react";

const HeadingTheme: ComponentStyleConfig = {
  baseStyle: {
    fontFamily: "body",
  },
  sizes: {},
  variants: {},
  defaultProps: {},
};

export default HeadingTheme;
