import { useContext } from "react";

import { ResponsivenessContext } from "../contexts/responsiveness";

export const useResposiveness = () => {
  const context = useContext(ResponsivenessContext);

  if (!context) throw Error("hook with provider not defined");

  return context;
};
