import ptBR from "date-fns/locale/pt-BR";
import moment from "moment-timezone";
import React from "react";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ReactDOM from "react-dom/client";

import { ChakraProvider, ColorModeProvider } from "@chakra-ui/react";

import "./helper/yup";
import { themeConfig } from "./config/theme";
import reportWebVitals from "./reportWebVitals";
import Routes from "./routes";
import customTheme from "./theme";

import "moment/locale/pt-br";

moment.locale("pt-br");

registerLocale("pt-BR", ptBR);
setDefaultLocale("pt-BR");

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ChakraProvider resetCSS theme={customTheme}>
    <ColorModeProvider options={themeConfig}>
      <Routes />
    </ColorModeProvider>
  </ChakraProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
