import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ILesson, IStudent } from "@isurf-tech/types/domain";
import { EntityUserType } from "@isurf-tech/types/domain/enums";

import { Schedules as ISchedules } from "../@types/lessons";
import routesName from "../config/routes";
import { paymentsService, studentService } from "../services/api";
import { useAuth } from "./auth";
import { useDialog } from "./dialog";
import { useLoader } from "./loader";
import { useSession } from "./session";

export default function useCheckout() {
  const navigate = useNavigate();
  const dialog = useDialog();
  const loader = useLoader();
  const { user, refreshUser } = useAuth<IStudent>();
  const { openSignIn } = useSession();

  const [schedulesSelect, setSchedulesSelect] = useState<any>({});
  const [lessonSelect, setLessonSelect] = useState<any>(null);

  const handleSelect = useCallback(
    (lesson: ILesson, schedulesAdd: ISchedules) => {
      const hasTimes = !!Object.values(schedulesAdd).flat().length;

      setLessonSelect(hasTimes ? lesson.id : null);
      setSchedulesSelect(schedulesAdd);
    },
    []
  );

  const handleCheckout = useCallback(
    async (lesson: ILesson) => {
      loader.run();

      const { data, error } = await paymentsService.createIntent({
        lesson: lesson.id,
        schedules: Object.values(schedulesSelect)
          .flat()
          .map((s: any) => s.id),
      });

      loader.stop();

      if (error?.statusCode === 400)
        return dialog.open({
          type: "error",
          title: "Ops, ocorreu um erro!",
          description: error?.message,
        });
      else if (error?.statusCode === 401) return openSignIn(false);

      const param = data ? `?checkout_session=${data}` : "";

      return (
        lessonSelect &&
        navigate(routesName.paymentCheckout.concat(param), {
          state: { lesson, schedules: schedulesSelect },
        })
      );
    },
    [dialog, lessonSelect, loader, navigate, openSignIn, schedulesSelect]
  );

  const goToCheckout = useCallback(
    async (currentLesson: ILesson) => {
      const schedulesLength = (
        Object.values(schedulesSelect || {}) || []
      ).flat().length;

      if (!schedulesLength) return;
      if (!user?.id) return openSignIn(false);
      if (user?.entity === EntityUserType.SCHOOL)
        return dialog.open({
          type: "error",
          title: "Você não pode reservar aulas",
          description:
            "Você não pode reservar aulas, pois é uma escola. Entre em contato com o administrador do sistema para mais informações.",
        });

      if (user?.refundsCredits?.length) {
        const total = user?.refundsCredits?.reduce(
          (acc, curr) => acc + curr.amount,
          0
        );

        if (total && schedulesLength && schedulesLength > total)
          return dialog.open({
            type: "error",
            title: "Creditos insuficientes",
            description:
              "Você não tem creditos suficientes para reservar essa aula. Por favor, selecione o numero de agendamentos de acordo com seu saldo.",
          });

        return dialog.open({
          type: "success",
          title: "Creditos com a escola",
          description: `Vimos que voce tem ${total} creditos com a escola, por alguma remarcação ou cancelamento. Gostaria de utilizar?`,
          redirect: {
            label: "Comprar aula",
            action: () => handleCheckout(currentLesson),
          },
          closeLabel: "Usar creditos",
          onClose: async () => {
            loader.run();

            const { error } = await studentService.bookRefundLesson(
              currentLesson.id,
              Object.values(schedulesSelect)
                .flat()
                .filter((s: any) => s.id)
                .map((s: any) => s.id)
            );

            loader.stop();

            await refreshUser();

            if (error) {
              dialog.open({
                type: "error",
                title: "Ops, ocorreu um erro!",
                description: error.message,
              });

              return;
            } else {
              dialog.open({
                type: "success",
                title: "Aula reservada com sucesso!",
                description:
                  "Sua aula já está reservada e deverá ser confirmada em breve. Confira mais em minhas aulas",
                redirect: {
                  label: "Minhas aulas",
                  action: () => navigate(routesName.studentsDashboard),
                },
                closeLabel: "Continuar comprando",
                onClose: () => {
                  setSchedulesSelect({});
                  return navigate(routesName.search);
                },
              });

              return;
            }
          },
        });
      }

      return handleCheckout(currentLesson);
    },
    [
      schedulesSelect,
      user,
      dialog,
      handleCheckout,
      loader,
      navigate,
      openSignIn,
      refreshUser,
    ]
  );

  return { schedulesSelect, lessonSelect, handleSelect, goToCheckout };
}
