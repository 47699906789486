export default function AlertIcon({ size = 24, color = "black" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3674_10907)">
        <path
          d="M11.9993 20.3337C16.6017 20.3337 20.3327 16.6027 20.3327 12.0003C20.3327 7.39795 16.6017 3.66699 11.9993 3.66699C7.39698 3.66699 3.66602 7.39795 3.66602 12.0003C3.66602 16.6027 7.39698 20.3337 11.9993 20.3337Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 8.66699V12.0003"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 15.333H12.0083"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3674_10907">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
