import { Icon, IconProps } from "@chakra-ui/react";

export default function CheckIcon(props: IconProps) {
  return (
    <Icon
      width="24px"
      height="24px"
      viewBox={`0 0 40 40`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M33.3346 10L15.0013 28.3333L6.66797 20"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
