import { ComponentStyleConfig } from "@chakra-ui/react";

const ModalTheme: ComponentStyleConfig = {
  baseStyle: {
    overlay: {
      bg: "neutral.dark",
      opacity: "0.85 !important",
    },
    dialogContainer: {
      opacity: 1,
      '@supports(height: -webkit-fill-available)': {},
    },
    dialog: {
      borderRadius: "md",
      bg: "white",
      color: "inherit",
      my: "16",
      maxH: "calc(100% - 7.5rem)",
      boxShadow: "sm",
      padding: "32px",
    },
  },
  sizes: {},
  variants: {},
  defaultProps: {},
};

export default ModalTheme;
