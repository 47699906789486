import { ComponentStyleConfig } from "@chakra-ui/react";

const CheckboxTheme: ComponentStyleConfig = {
  parts: ["checkbox", "input", "control", "label"],
  baseStyle: {
    checkbox: {
      bg: "red",
    },
    control: {
      border: "hairline",
      borderColor: "neutral.dark",
      borderRadius: "sm",
      minHeight: "24px",
      minWidth: "24px",
      color: "neutral.lightest",
      "&[data-checked]": {
        bg: "brand.dark",
        borderColor: "brand.dark",
      },
    },
    label: {
      ml: 2,
      fontFamily: "body",
      fontSize: "sm",
      fontWeight: "medium",
      color: "neutral.darkest",
    },
  },
  sizes: {},
  variants: {},
  defaultProps: {
    size: "sm",
  },
};

export default CheckboxTheme;
