import { ComponentStyleConfig } from "@chakra-ui/react";

const FormErrorMessageTheme: ComponentStyleConfig = {
  baseStyle: {
    text: {
      color: "feedback.danger.dark",
      fontSize: "2xs",
      fontWeight: "regular",
      mt: 2,
    },
  },
  sizes: {},
  variants: {},
  defaultProps: {},
};

export default FormErrorMessageTheme;
