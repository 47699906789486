import { isValidPhoneNumber } from "react-phone-number-input";
import * as yup from "yup";

yup.addMethod(
  yup.string,
  "cellphone",
  function (message = "invalid cellphone", { country = "BR" } = {}) {
    return this.test("cellphone", message, (phone = "") =>
      isValidPhoneNumber(phone ?? "", country)
    );
  }
);
