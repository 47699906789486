export default function BodyboardIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.0252 9.0251C26.6861 8.3641 27.565 8 28.5 8C29.4349 8 30.3138 8.3641 30.9748 9.0252C31.6358 9.6861 32 10.565 32 11.5C32 12.4349 31.6358 13.3138 30.9747 13.9748C30.3138 14.6358 29.4349 15 28.5 15C27.565 15 26.6861 14.6358 26.0251 13.9747C25.3641 13.3138 25 12.4349 25 11.5C25 10.565 25.3641 9.6861 26.0252 9.0251ZM28.5001 13.0001C28.9006 13.0001 29.2773 12.844 29.5606 12.5607C29.844 12.2773 30 11.9006 30.0001 11.5001C30.0001 11.0995 29.8441 10.7228 29.5608 10.4395C29.2774 10.1561 28.9007 10.0001 28.5001 10.0001C28.0995 10.0001 27.7228 10.1561 27.4395 10.4394C27.1561 10.7228 27 11.0994 27.0001 11.5001C27.0001 11.9006 27.1561 12.2773 27.4394 12.5606C27.7228 12.844 28.0994 13.0001 28.5001 13.0001Z"
        fill="#0F7E99"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.6622 9.36589C37.7872 9.49095 37.8705 9.65158 37.9007 9.82575C37.9388 10.0462 38.7894 15.2841 34.7895 20.5397C30.9486 25.5865 16.146 37.3187 15.5178 37.8157C15.3612 37.9395 15.1734 38.0006 14.9863 38.0006C14.7658 38.0006 14.5463 37.9158 14.3799 37.7495L12.9631 36.3327C12.8248 36.1945 12.738 36.0131 12.717 35.8186C12.7141 35.7945 12.3606 33.0049 9.67714 30.3214C6.99364 27.6379 4.20403 27.2844 4.17617 27.2811C3.9846 27.2587 3.80228 27.1719 3.66582 27.0355L2.24903 25.6186C1.94156 25.311 1.91311 24.8218 2.18294 24.4807C2.67992 23.8525 14.4122 9.04994 19.4589 5.20902C23.1276 2.41692 26.8242 2 28.7011 2C29.5812 2 30.1148 2.08803 30.1728 2.09806C30.347 2.12814 30.5077 2.21146 30.6327 2.33652L37.6622 9.36589ZM15.0507 35.9959C17.9036 33.7127 30.1379 23.8211 33.4254 19.5015C36.5058 15.454 36.3448 11.4051 36.2425 10.3706L29.6284 3.75657C29.4259 3.73668 29.1084 3.71431 28.7012 3.71431C27.0402 3.71431 23.7657 4.08572 20.4971 6.57327C16.1775 9.86072 6.28598 22.0951 4.00285 24.948L4.68215 25.6273C5.59194 25.7987 8.28264 26.5024 10.8895 29.1092C13.4963 31.7161 14.2 34.4068 14.3714 35.3166L15.0507 35.9959Z"
        fill="#0F7E99"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.0252 9.0251C26.6861 8.3641 27.565 8 28.5 8C29.4349 8 30.3138 8.3641 30.9748 9.0252C31.6358 9.6861 32 10.565 32 11.5C32 12.4349 31.6358 13.3138 30.9747 13.9748C30.3138 14.6358 29.4349 15 28.5 15C27.565 15 26.6861 14.6358 26.0251 13.9747C25.3641 13.3138 25 12.4349 25 11.5C25 10.565 25.3641 9.6861 26.0252 9.0251ZM28.5001 13.0001C28.9006 13.0001 29.2773 12.844 29.5606 12.5607C29.844 12.2773 30 11.9006 30.0001 11.5001C30.0001 11.0995 29.8441 10.7228 29.5608 10.4395C29.2774 10.1561 28.9007 10.0001 28.5001 10.0001C28.0995 10.0001 27.7228 10.1561 27.4395 10.4394C27.1561 10.7228 27 11.0994 27.0001 11.5001C27.0001 11.9006 27.1561 12.2773 27.4394 12.5606C27.7228 12.844 28.0994 13.0001 28.5001 13.0001Z"
        stroke="#0F7E99"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.6622 9.36589C37.7872 9.49095 37.8705 9.65158 37.9007 9.82575C37.9388 10.0462 38.7894 15.2841 34.7895 20.5397C30.9486 25.5865 16.146 37.3187 15.5178 37.8157C15.3612 37.9395 15.1734 38.0006 14.9863 38.0006C14.7658 38.0006 14.5463 37.9158 14.3799 37.7495L12.9631 36.3327C12.8248 36.1945 12.738 36.0131 12.717 35.8186C12.7141 35.7945 12.3606 33.0049 9.67714 30.3214C6.99364 27.6379 4.20403 27.2844 4.17617 27.2811C3.9846 27.2587 3.80228 27.1719 3.66582 27.0355L2.24903 25.6186C1.94156 25.311 1.91311 24.8218 2.18294 24.4807C2.67992 23.8525 14.4122 9.04994 19.4589 5.20902C23.1276 2.41692 26.8242 2 28.7011 2C29.5812 2 30.1148 2.08803 30.1728 2.09806C30.347 2.12814 30.5077 2.21146 30.6327 2.33652L37.6622 9.36589ZM15.0507 35.9959C17.9036 33.7127 30.1379 23.8211 33.4254 19.5015C36.5058 15.454 36.3448 11.4051 36.2425 10.3706L29.6284 3.75657C29.4259 3.73668 29.1084 3.71431 28.7012 3.71431C27.0402 3.71431 23.7657 4.08572 20.4971 6.57327C16.1775 9.86072 6.28598 22.0951 4.00285 24.948L4.68215 25.6273C5.59194 25.7987 8.28264 26.5024 10.8895 29.1092C13.4963 31.7161 14.2 34.4068 14.3714 35.3166L15.0507 35.9959Z"
        stroke="#0F7E99"
      />
    </svg>
  );
}
