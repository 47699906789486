import { ComponentStyleConfig } from "@chakra-ui/react";

const FormControlTheme: ComponentStyleConfig = {
  parts: ["container", "helperText"],
  baseStyle: {
    container: {
      "&>label": {
        mb: 2,
      },
    },
    helperText: {
      color: "neutral.dark",
      fontSize: "xs",
      fontWeight: "regular",
      mt: 2,
      "&[data-invalid]": {
        color: "feedback.danger.dark",
      },
    },
  },
  sizes: {},
  variants: {},
  defaultProps: {},
};

export default FormControlTheme;
