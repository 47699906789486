import moment from "moment-timezone";
import { memo } from "react";

import { Flex, Text } from "@chakra-ui/react";

function ScheduleDayHeader({ date }: any) {
  return (
    <Flex direction="column" key={date} align="center" p="14px">
      <Text color="neutral.darkest" fontSize="2xs" fontWeight="semibold">
        {moment(date).format("ddd")}
      </Text>
      <Text
        color="neutral.dark"
        fontSize="2xs"
        fontWeight="semibold"
        whiteSpace="nowrap"
      >
        {moment(date).format("DD MMM")}
      </Text>
    </Flex>
  );
}

export default memo(ScheduleDayHeader);
