import { Flex, Heading, Skeleton, Stack, Text } from "@chakra-ui/react";

import debounce from "lodash.debounce";
import { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { ILocation } from "@isurf-tech/types/domain";

import MapPinIcon from "../../../../../assets/icons/map-pin";
import BoxInput from "../../../../inputs/box";
import { locationsService } from "../../../../../services/api";


import { SEARCH_FORM_FIELD_MOBILE } from "..";


interface MobileSearchLocalizationsProps {
  onSubmit?: () => void;
}

export default function MobileSearchLocalizations({
  onSubmit,
}: MobileSearchLocalizationsProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [localizations, setLocalizations] = useState<ILocation[]>([]);

  const { setValue, watch } = useFormContext();

  const search = watch(SEARCH_FORM_FIELD_MOBILE.LOCALIZATION, "");

  const requestLocations = async () => {
    setLoading(true);

    const { data: localizations = [] } = await locationsService.list({
      q: search,
    });

    setLoading(false);
    setLocalizations(localizations || []);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((text: string) => {
      setValue(SEARCH_FORM_FIELD_MOBILE.LOCALIZATION, text);
    }, 600),
    []
  );

  const handleSelect = (localization: ILocation) => {
    setValue(
      SEARCH_FORM_FIELD_MOBILE.LOCALIZATION,
      `${localization.address},${localization.city}/${localization.stateCode}`
    );

    if (onSubmit) onSubmit();
  };

  useEffect(() => {
    requestLocations();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <Flex direction="column">
      <BoxInput
        label="localizacão"
        placeholder="Qual praia você quer surfar?"
        onChange={(e) => handleSearch(e.target.value)}
      />

      <Flex mt={6} direction="column">
        <Heading
          color="neutral.dark"
          fontWeight="semibold"
          fontSize={["sm", "md"]}
        >
          {!search ? "Sugestões" : "Resultados"}
        </Heading>

        <Flex direction="column" mt={8}>
          {loading ? (
            <Stack spacing={4}>
              <Skeleton height="8vh" width="100%" />
              <Skeleton height="8vh" width="100%" />
              <Skeleton height="8vh" width="100%" />
              <Skeleton height="8vh" width="100%" />
              <Skeleton height="8vh" width="100%" />
            </Stack>
          ) : (
            localizations.map((location, index) => (
              <Flex
                key={location.id}
                mt={index > 0 ? 6 : 0}
                onClick={() => handleSelect(location)}
              >
                <MapPinIcon height="4vh" width="4vh" color="neutral.darkest" />

                <Flex direction="column" ml={6}>
                  <Heading
                    fontSize="md"
                    fontWeight="medium"
                    color="neutral.darkest"
                  >
                    {location.address}
                  </Heading>

                  <Text fontSize="sm" fontWeight="light" color="neutral.dark">
                    {location.city}/{location.stateCode}
                  </Text>
                </Flex>
              </Flex>
            ))
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}
