import axios, { AxiosInstance } from "axios";

import { ServiceResponse } from "..";

import { ILesson } from "@isurf-tech/types/domain";

import { LessonCreateParams } from "../../../@types/lessons";
import { SchedulesFactory } from "../factories/schedules";

export enum DaysWeek {
  SU,
  MO,
  TU,
  WE,
  TH,
  FR,
  SA,
}

export class LessonService {
  constructor(private api: AxiosInstance) {}

  async create<T = any>(
    params: LessonCreateParams
  ): Promise<ServiceResponse<T>> {
    let result = { data: null, error: null };

    params.recurrence = Object.keys(params.schedules).every(
      (day) => day in DaysWeek
    );

    try {
      const payload = {
        name: params.name,
        modality: params.modality,
        location: params.location,
        locationDetails: params.locationDetails,
        variation: params.level,
        seats: params.seats,
        description: params.description,
        price: Number(params.price.toFixed(2).replace(/\D/g, "") || 0),
        adStartDate: params.adStartDate,
        adEndDate: params.adEndDate,
        duration: params.duration,
        schedules: Object.entries(params.schedules).map(
          ([targetDay, times]: any) => {
            const day =
              !!params.recurrence === true ? DaysWeek[targetDay] : targetDay;

            return {
              day,
              times,
            };
          }
        ),
        photos: params.photos,
        limitDateSubscription: Number(params.limitDateSubscription || 0),
        limitDateCancelation: Number(params.limitDateCancelation || 0),
      };

      const { data } = await this.api.post("/lessons", payload);

      result.data = data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result.error = error.response?.data;
      } else {
        result.error = error as any;
      }
    } finally {
      return result as any;
    }
  }

  async list(params: any): Promise<ServiceResponse<ILesson[]>> {
    let result = { data: [], error: null };

    try {
      let {
        data: { results: lessons = [] },
      } = await this.api.get("/lessons", { params });

      if (lessons && lessons.length > 0) {
        lessons = lessons.map((lesson: any) => ({
          ...lesson,
          schedules: SchedulesFactory.groupByDate(lesson?.schedules),
        }));
      }

      result.data = lessons || [];
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result.error = error.response?.data;
      } else {
        result.error = error as any;
      }
    } finally {
      return result;
    }
  }

  async findOne(id: string): Promise<ServiceResponse<ILesson | null>> {
    let result = { data: null, error: null };

    try {
      const { data } = await this.api.get(`/lessons/${id}`);

      result.data = Object.assign({}, data, {
        schedules: SchedulesFactory.groupByDate(data?.schedules),
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result.error = error.response?.data;
      } else {
        result.error = error as any;
      }
    } finally {
      return result;
    }
  }
}
