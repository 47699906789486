import moment from "moment-timezone";

import { Flex, FlexProps, Heading, Text } from "@chakra-ui/react";

import { IClassroomMessage } from "@isurf-tech/types/domain";

interface IClassroomMessageProps extends FlexProps {
  message: IClassroomMessage;
}

export default function ClassroomMessage({
  message,
  ...props
}: IClassroomMessageProps) {
  return (
    <Flex
      key={message.id}
      border="hairline"
      borderColor="neutral.medium"
      borderRadius="sm"
      direction="column"
      p="24px"
      w="100%"
      {...props}
    >
      <Heading fontSize="sm">{message.title}</Heading>
      <Text fontSize="sm" color="neutral.dark" mt={2}>
        {message?.content ?? ""}
      </Text>

      <Text color="neutral.dark" mt={8} fontWeight="semibold">
        Enviado em {moment(message.createdAt).format("DD/MM - HH:mm")}
      </Text>
    </Flex>
  );
}
