import moment from "moment-timezone";
import React, { useCallback } from "react";

import {
  Box,
  BoxProps,
  FormControl,
  IconButton,
  Input,
  InputProps,
  Text,
} from "@chakra-ui/react";

import SearchIcon from "../../assets/icons/search";
import { lessonModalityOptions } from "../../pages/school/create-class/steppers/setup";
import DatePicker from "../inputs/date-picker";
import Select from "../select";

const inputProps = {
  variant: "unstyled",
  padding: "0px",
  minHeight: "min-content !important",
  fontSize: "sm",
  textAlign: "start",
  color: "neutral.dark",
  mt: 1,
} as InputProps;

type state = {
  modality: string;
  date: string;
  school: string;
  localization: string;
};

interface SearchBoxPropsI extends Omit<BoxProps, "onChange" | "border"> {
  value?: state;
  border?: boolean;
  onChange?: (data: state) => void;
  onClick?: () => void;
}

export default function SearchBox({
  value = {} as state,
  border,
  onChange,
  onClick,
  ...props
}: SearchBoxPropsI) {
  const handleUpdate = useCallback(
    (field: string, current: string) => {
      if (onChange) onChange({ ...value, [field]: current });
    },
    [onChange, value]
  );

  return (
    <Box {...props}>
      <Box
        bg="neutral.lightest"
        width="100%"
        height="80px"
        borderRadius="sm"
        border={border ? "hairline" : ""}
        borderColor="neutral.medium"
        boxShadow={border ? "" : "0px 8px 24px rgba(0, 0, 0, 0.08)"}
        padding="8px"
        display="flex"
        flexDirection="row"
      >
        {[
          {
            label: "Localização",
            content: "Qual praia você quer surfar?",
            field: "localization",
            flex: 3,
            input: true,
            value: value?.localization,
          },
          {
            label: "Escola",
            content: "Nome da escola",
            field: "school",
            flex: 2,
            input: true,
            value: value?.school,
          },
          {
            label: "Data e hora",
            content: "Insira a data",
            field: "date",
            flex: 2,
            input: DatePicker,
            variant: "unstyled",
            border: "none",
            endAdormentIcon: null,
            value: value?.date ? moment(value.date).toDate() : null,
            format: "DD/MM/YYYY",
            datePickerProps: {
              dateFormat: "dd/MM/yyyy",
              placeholderText: "Insira a data",
            },
            inputProps,
            onChange: (date: Date) => handleUpdate("date", date?.toISOString()),
          },
          {
            label: "Modalidade",
            placeholder: "Escolha a modalidade da aula",
            flex: 3,
            field: "modality",
            input: Select,
            mt: 0,
            selectProps: {
              sx: inputProps,
              ...inputProps,
              mt: 0,
              value: value?.modality,
            },
            ...inputProps,
            options: lessonModalityOptions,
            onChange: (e: any) => handleUpdate("modality", e.target.value),
          },
        ].map(
          (
            {
              label,
              content,
              flex,
              input,
              field,
              selectOptions,
              value,
              ...props
            }: any,
            index: number
          ) => (
            <Box
              key={label}
              display="flex"
              flex={flex}
              flexDirection="row"
              alignItems="center"
              w="100%"
              pt={4}
              pb={4}
              pr={0}
            >
              {index > 0 && <Box bg="neutral.medium" w="1px" h="160%" mx={2} />}

              <FormControl
                display="flex"
                flexDirection="column"
                justifyContent="center"
                p={2}
                pr={0}
                width="100%"
                _hover={{
                  bg: "neutral.light",
                  borderRadius: "sm",
                }}
                sx={{
                  "&.chakra-input, &:focus-within": {
                    border: "hairline",
                    borderColor: "neutral.light",
                    bg: "transparent",
                  },
                }}
              >
                <Text textAlign="start" fontWeight="semibold" fontSize="2xs">
                  {label}
                </Text>

                {input !== undefined &&
                  typeof input !== "boolean" &&
                  React.createElement(input, { value, ...props })}

                {input !== undefined && typeof input === "boolean" && (
                  <Input
                    placeholder={content}
                    value={value || ""}
                    {...inputProps}
                    onChange={(e) => handleUpdate(field, e.target.value)}
                  />
                )}
              </FormControl>
            </Box>
          )
        )}

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          p={1}
          ml={2}
        >
          <IconButton
            aria-label="search-icon"
            variant="unstyled"
            icon={<SearchIcon w="20px" h="20px" />}
            borderRadius="full"
            display="flex"
            justifyContent="center"
            alignItems="center"
            bg="neutral.light"
            onClick={onClick}
            w="min-content"
            h="min-content"
            p="12px"
          />
        </Box>
      </Box>
    </Box>
  );
}
