import { createContext, useMemo } from "react";

import { useMediaQuery } from "@chakra-ui/react";

export interface ResponsivenessContextI {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

export const ResponsivenessContext = createContext<ResponsivenessContextI>({
  isMobile: false,
  isTablet: false,
  isDesktop: false,
});

const SIZE = {
  small: "414px",
  medium: "768px",
};

export const ResponsivenessProvider = ({ children }: any) => {
  const [isMobile] = useMediaQuery(`(max-width: ${SIZE.small})`);
  const [isTablet] = useMediaQuery(
    `(min-width: ${SIZE.small}) and (max-width: ${SIZE.medium})`
  );
  const [isDesktop] = useMediaQuery(`(min-width: ${SIZE.medium})`);

  const value = useMemo(
    () => ({ isMobile, isTablet, isDesktop }),
    [isDesktop, isMobile, isTablet]
  );

  return (
    <ResponsivenessContext.Provider value={value}>
      {children}
    </ResponsivenessContext.Provider>
  );
};
