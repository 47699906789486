/* eslint-disable import/no-anonymous-default-export */
export default {
  brand: {
    darkest: "#015266",
    dark: "#0F7E99",
    medium: "#29ACCC",
    light: "#D0E1E5",
    lightest: "#EDF5F7",
  },
  neutral: {
    darkest: "#363A3C",
    dark: "#72818F",
    medium: "#C5CED6",
    light: "#F0F4F7",
    lightest: "#FFFFFF",
  },
  feedback: {
    danger: {
      darkest: "#9C0013",
      dark: "#C23127",
      medium: "#E55C53",
      light: "#FFCFCC",
      lightest: "#FFE7E7",
    },
    warning: {
      darkest: "#5C3D02",
      dark: "#8A5C03",
      medium: "#CC9952",
      light: "#F5DCC4",
      lightest: "#FFF2E7",
    },
    info: {
      darkest: "#00407A",
      dark: "#2556B8",
      medium: "#6699FF",
      light: "#D0DFFC",
      lightest: "#F0F5FF",
    },
    success: {
      darkest: "#004738",
      dark: "#028468",
      medium: "#14A384",
      light: "#74C2B1", 
      lightest: "#E9F7F4",
    },
  },
};
