import { SESSION_TYPE } from "../../../@types/session";
import animationData from "../../../assets/animations/email_verification.json";
import { useSession } from "../../../hooks/session";
import { authService } from "../../../services/api";
import BaseVerification from "./base";

export default function CellphoneVerification() {
  const { goToSessionType } = useSession();

  return (
    <BaseVerification
      title="Verificação de celular"
      description="Clique no botão abaixo para enviar o código de verificação para seu
celular cadastrado! Caso não tenha recebido, envie novamente o código."
      animationData={animationData}
      requestVerify={() => authService.requestVerify("cellphone")}
      validate={(code) => authService.validateUserData("cellphone", code)}
      onRedirect={() => goToSessionType(SESSION_TYPE.SIGN_IN)}
    />
  );
}
