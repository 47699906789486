export default function HowToWorkSecondIcon() {
  return (
    <svg
      width="208"
      height="112"
      viewBox="0 0 208 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 8C0.5 3.85787 3.85787 0.5 8 0.5H200C204.142 0.5 207.5 3.85786 207.5 8V104C207.5 108.142 204.142 111.5 200 111.5H8.00001C3.85787 111.5 0.5 108.142 0.5 104V8Z"
        fill="white"
        stroke="#C5CED6"
      />
      <rect x="16" y="16" width="80" height="80" rx="8" fill="#F0F4F7" />
      <mask
        id="mask0_511_732"
        maskUnits="userSpaceOnUse"
        x="16"
        y="16"
        width="80"
        height="80"
      >
        <rect x="16" y="16" width="80" height="80" rx="8" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_511_732)">
        <path
          d="M38.0479 83.0996L42.1486 85.8333H96.8246V83.0996H38.0479Z"
          fill="#0F7E99"
        />
        <path
          d="M66.7539 94.0352H83.1565V96.7691H66.7539V94.0352Z"
          fill="#0F7E99"
        />
        <path
          d="M61.2861 88.5664H88.6239V91.3007H61.2861V88.5664Z"
          fill="#0F7E99"
        />
        <path
          d="M83.6046 58.0815C88.4088 62.8859 88.4088 70.6754 83.6046 75.4798C78.8001 80.2832 71.011 80.2832 66.2065 75.4798C61.4025 70.6754 61.4025 62.8859 66.2065 58.0815C71.011 53.2775 78.8001 53.2775 83.6046 58.0815Z"
          fill="#0F7E99"
        />
        <path
          d="M29.3193 81.3433C37.3816 67.9547 42.2173 52.8725 43.4435 37.2912L58.1963 52.092C60.5249 47.484 59.6444 41.9023 56.0094 38.2352L54.7095 36.9247C53.8439 36.0544 52.8461 35.3251 51.7547 34.7654L65.4778 34.8268C63.8668 29.9207 59.2962 26.5961 54.1323 26.5747L52.2854 26.5665C50.9666 26.5629 49.6566 26.778 48.4074 27.2039L58.2498 17.3961C53.6417 15.0678 48.0614 15.9483 44.3943 19.5822L43.0838 20.8829C42.2132 21.7475 41.4842 22.7457 40.9248 23.8377C40.3731 22.7413 39.6531 21.7368 38.7907 20.8634L37.4903 19.5526C33.8578 15.8856 28.2857 14.9547 23.6574 17.2413L33.4077 27.1355C32.1615 26.6993 30.8529 26.4725 29.5341 26.4637L27.6888 26.4561C22.5262 26.4325 17.9275 29.7147 16.272 34.6049L29.9962 34.6665C28.8985 35.2172 27.8951 35.9374 27.0217 36.8L25.7103 38.1007C22.0435 41.7338 21.1129 47.3062 23.4004 51.9348L37.95 37.5997C36.9474 52.5772 31.5175 66.9189 22.3493 78.8038C20.2908 78.2184 18.1973 77.7666 16.0808 77.4513L16 95.2206L47.4384 95.3565L47.3784 95.2817C42.5238 89.296 36.3397 84.5238 29.3193 81.3433ZM25.5758 93.8988L22.8418 93.8865L22.8539 91.1525L25.5873 91.1648L25.5758 93.8988ZM33.7874 91.2015L31.0537 91.1892L31.0671 88.4555L33.8011 88.4675L33.7874 91.2015Z"
          fill="#0F7E99"
        />
      </g>
      <rect x="110" y="44" width="86" height="16" rx="8" fill="#F0F4F7" />
      <rect x="110" y="20" width="86" height="16" rx="8" fill="#F0F4F7" />
    </svg>
  );
}
