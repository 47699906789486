import axios from "axios";

import {
  HEADER_FORCE_REFRESH_TOKEN,
  STORAGE_KEY_ACCESS_TOKEN,
} from "../../config/http";
import { load, save } from "../storage";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "http://localhost:6824",
});

instance.setDefaultToken = (token: string) => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

instance.hasDefaultToken = () => {
  return !!instance.defaults.headers.common["Authorization"];
};

instance.interceptors.request.use((request) => {
  const token = load(STORAGE_KEY_ACCESS_TOKEN);

  if (token && request.headers && !request?.headers?.["Authorization"]) {
    request.headers["Authorization"] = `Bearer ${token}`;
  }

  return request;
});

instance.interceptors.response.use((response) => {
  const forceRefreshToken =
    response?.headers?.[HEADER_FORCE_REFRESH_TOKEN] || false;

  if (forceRefreshToken) {
    instance.setDefaultToken(forceRefreshToken);
    save(STORAGE_KEY_ACCESS_TOKEN, forceRefreshToken);
  }

  return response;
});

export default instance;
