import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";

import WaveIcon from "../../../../assets/icons/wave";
import photo from "../../../../assets/medias/people-lesson.png";
import SearchBox from "../../../../components/searchables/search-box";
import routesName from "../../../../config/routes";
import { SEARCH_LESSON_FILTERS } from "../../../../config/storage-keys";
import { useResposiveness } from "../../../../hooks/responsiveness";
import useLocalStorage from "../../../../hooks/storage";

export default function HomeMainFirstSection() {
  const { isMobile, isDesktop } = useResposiveness();
  const navigate = useNavigate();
  const [state, setState] = useLocalStorage(SEARCH_LESSON_FILTERS, {});

  return (
    <Box
      id="home-main-first"
      as="section"
      position="relative"
      minHeight={isMobile ? "110vh " : "100vh"}
      mt={isMobile ? "0px" : "-80px"}
    >
      <Box
        position="absolute"
        zIndex={-100}
        w="100vw"
        h="100vh"
        top={0}
        left={0}
      >
        <Grid
          templateColumns={isMobile ? "repeat(4, 1fr)" : "repeat(12, 1fr)"}
          templateRows={isMobile ? "auto auto" : "auto"}
          columnGap={8}
        >
          <GridItem
            position="relative"
            colStart={1}
            colSpan={isMobile ? 4 : 11}
            rowStart={1}
          >
            <Box
              height={isMobile ? "90vh" : "80vh"}
              width={isMobile ? "100vw" : "75vw"}
              bg="neutral.light"
              ml={isMobile ? "0px" : "-80px"}
              borderBottomEndRadius="lg"
              position="absolute"
              top={0}
              left={0}
              zIndex={-3}
            />
          </GridItem>

          <GridItem
            position="relative"
            colStart={isMobile ? 1 : 7}
            colSpan={isMobile ? 4 : 5}
            rowStart={2}
            mt={isMobile ? "60vh" : "10vh"}
            ml={isMobile ? "5vw" : "0vh"}
          >
            <Box
              position="absolute"
              top={0}
              left={0}
              zIndex={-2}
              w="200px"
              overflow="hidden"
            >
              <WaveIcon width={isMobile ? "100vw" : "40vw"} height="100%" />
            </Box>
          </GridItem>

          <GridItem
            position="relative"
            colStart={isMobile ? 1 : 8}
            colSpan={isMobile ? 4 : 5}
            rowStart={2}
            mt={isMobile ? "55vh" : "12vh"}
            ml={isMobile ? "15vw" : ""}
          >
            <Image
              src={photo}
              position="absolute"
              top={0}
              left={0}
              zIndex={-1}
              w={isMobile ? "80vw" : "30vw"}
            />
          </GridItem>
        </Grid>
      </Box>

      <Grid
        templateColumns={isMobile ? "repeat(4, 1fr)" : "repeat(12, 1fr)"}
        templateRows={isMobile ? "auto min-content" : "repeat(1, 1fr)"}
        columnGap={8}
        rowGap={6}
        padding={isMobile ? "0px 24px" : "0px 80px"}
        mt={isMobile ? "40px" : "30vh"}
      >
        <GridItem colSpan={4} rowStart={1}>
          <Heading fontSize={isMobile ? "2xl" : "3xl"} color="neutral.darkest">
            Agende online suas aulas de surfe
          </Heading>
        </GridItem>

        <GridItem colSpan={isMobile ? 4 : 3} rowStart={2}>
          <Text fontSize="sm" color="neutral.darkest">
            Encontre a aula perfeita de surf e outras modalidades nas melhore
            praias!
          </Text>
        </GridItem>

        <GridItem
          colSpan={isMobile ? 4 : 10}
          rowStart={3}
          mt={isMobile ? 2 : 4}
        >
          {isMobile && (
            <Button
              size="md"
              width="100%"
              onClick={() =>
                navigate(routesName.search, {
                  state,
                })
              }
            >
              Encontre sua aula
            </Button>
          )}

          {isDesktop && (
            <SearchBox
              value={state}
              onChange={(values) => setState(values)}
              onClick={() =>
                navigate(routesName.search, {
                  state,
                })
              }
            />
          )}
        </GridItem>
      </Grid>
    </Box>
  );
}
