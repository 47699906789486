import moment from "moment-timezone";
import { useMemo } from "react";

import { Avatar, Flex, Heading, Text } from "@chakra-ui/react";

import { IClassroomStudent } from "@isurf-tech/types/domain";
import { PaymentStatus } from "@isurf-tech/types/domain/enums";

import { StudentStatusLabel } from "../../@types/student";
import { useResposiveness } from "../../hooks/responsiveness";
import MenuOptions, { MenuOptionsPropsI } from "../menu-options";

interface CardClassroomStudentPropsI {
  data: IClassroomStudent;
  mt?: any;
  menuOptions?: MenuOptionsPropsI["options"];
}

enum GenderLabel {
  "MALE" = "Masculino",
  "FEMALE" = "Feminino",
}

export default function CardClassroomStudent({
  data: { student, payment },
  mt,
  menuOptions,
}: CardClassroomStudentPropsI) {
  const { isMobile } = useResposiveness();
  const yearsOlder = useMemo(
    () => (student.birthDate ? moment().diff(student.birthDate, "year") : null),
    [student.birthDate]
  );

  return (
    <Flex
      p={isMobile ? "24px 20px" : "14px 24px"}
      border="hairline"
      borderColor="neutral.medium"
      borderRadius="sm"
      mt={mt}
      _hover={{
        bg: "neutral.light",
      }}
      w="100%"
      flex={3}
    >
      <Flex>
        <Avatar name={student?.user?.name} src={student?.user?.avatar} />
      </Flex>

      <Flex
        flex={isMobile ? 1 : 6}
        ml={4}
        direction={isMobile ? "column" : "row"}
      >
        <Flex
          direction="column"
          ml={isMobile ? 0 : 3}
          flex={1}
          justify={isMobile ? "flex-start" : "center"}
        >
          <Heading fontWeight="semibold" fontSize="sm" color="neutral.darkest">
            {student?.user?.name}
          </Heading>

          {!!yearsOlder && (
            <Text fontSize="xs" color="neutral.dark">
              {yearsOlder} anos · {GenderLabel?.[student?.gender]}
            </Text>
          )}
        </Flex>

        <Flex
          flex={isMobile ? 1 : 4}
          flexWrap={isMobile ? "wrap" : "nowrap"}
          mt={isMobile ? 2 : 0}
          columnGap={6}
        >
          <Flex flex={1}>
            <Heading fontSize="xs" my="auto">
              Altura
            </Heading>
            <Text my="auto" ml={2} w="max-content">
              {student.height || 0}cm
            </Text>
          </Flex>

          <Flex flex={1}>
            <Heading fontSize="xs" my="auto">
              Peso
            </Heading>
            <Text my="auto" color="neutral.darkest" ml={1} w="max-content">
              {student.weight || 0}Kg
            </Text>
          </Flex>

          <Flex flex={1}>
            <Heading fontSize="xs" my="auto">
              Aula
            </Heading>
            <Text my="auto" color="neutral.darkest" ml={1} w="max-content">
              1/1
            </Text>
          </Flex>
        </Flex>

        <Flex flex={1} mt={isMobile ? 2 : 0}>
          <Text
            my="auto"
            color="neutral.dark"
            textTransform="uppercase"
            w="max-content"
          >
            {
              StudentStatusLabel?.[
                payment?.status || PaymentStatus.PAYMENT_PENDING
              ]
            }
          </Text>
        </Flex>
      </Flex>

      <Flex align={isMobile ? "flex-start" : "center"} justify="center">
        <MenuOptions options={menuOptions} />
      </Flex>
    </Flex>
  );
}
