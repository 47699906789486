import { useNavigate } from "react-router-dom";

import { Box, Button, Grid, GridItem, Heading, Text } from "@chakra-ui/react";

import BellIcon from "../../../../assets/icons/bell";
import CalendarIcon from "../../../../assets/icons/calendar";
import CreditCardIcon from "../../../../assets/icons/credit-card";
import SearchIcon from "../../../../assets/icons/search";
import SurfBoardIcon from "../../../../assets/icons/sports/surfboard";
import routesName from "../../../../config/routes";
import { useResposiveness } from "../../../../hooks/responsiveness";

export default function HomeMainFiftySection() {
  const { isMobile } = useResposiveness();
  const navigate = useNavigate();

  return (
    <Box
      as="section"
      id="sixth"
      minHeight="85vh"
      pt={isMobile ? "64px" : "124px"}
    >
      <Grid
        templateColumns="repeat(12, 1fr)"
        gap={8}
        pt={isMobile ? "64px" : "124px"}
        padding={isMobile ? "0px 16px" : "0px 80px"}
      >
        <GridItem colStart={1} colSpan={isMobile ? 10 : 6}>
          <Heading color="neutral.darkest" fontSize={isMobile ? "lg" : "xl"}>
            Encontre a aula perfeita para você
          </Heading>
        </GridItem>
      </Grid>

      <Grid
        templateColumns="repeat(12, 1fr)"
        gap={isMobile ? 4 : 8}
        padding={isMobile ? "16px" : "16px 80px"}
        mt={isMobile ? "24px" : "32px"}
      >
        {[
          {
            title: "Conheça as escolas e as praias",
            description:
              "Você encontra mais possibilidades de escolas e aulas que melhor se adequem ao que você procura",
            icon: SearchIcon,
          },
          {
            title: "Agendamento prático",
            description:
              "Veja todos os horários disponíveis das aulas que você quer fazer e agende na hora uma ou mais.",
            icon: CalendarIcon,
          },
          {
            title: "Pagamento online e seguro",
            description:
              "Realize o pagamento online com toda segurança sem custo nenhum.",
            icon: CreditCardIcon,
          },
          {
            title: "Conheça as escolas e as praias",
            description:
              "Você encontra mais possibilidades de escolas e aulas que melhor se adequem ao que você procura",
            icon: SurfBoardIcon,
          },
          {
            title: "Nunca mais esqueça suas aulas",
            description:
              "Você terá organizado toda sua agenda e lembraremos você sempre 1 dia antes.",
            icon: BellIcon,
          },
        ].map((classInfos, index) => (
          <GridItem
            key={index.toString()}
            colSpan={isMobile ? 12 : 4}
            padding="32px 24px 42px 24px"
            border="1px solid #C5CED6"
            borderRadius={8}
            cursor="pointer"
          >
            <Box>{classInfos.icon({})}</Box>
            <Heading mt={4} fontSize={16} color="#242424">
              {classInfos.title}
            </Heading>
            <Text mt={4} fontWeight={400} fontSize={14} color="neutral.darkest">
              {classInfos.description}
            </Text>
          </GridItem>
        ))}
      </Grid>

      {!isMobile && (
        <Grid
          templateColumns="repeat(12, 1fr)"
          gap={8}
          padding="0px 80px"
          mt="48px"
        >
          <GridItem
            colStart={1}
            colSpan={6}
            onClick={() => navigate(routesName.search)}
          >
            <Button size="lg">Encontrar aulas</Button>
          </GridItem>
        </Grid>
      )}
    </Box>
  );
}
