import { useEffect } from "react";

import { Box, Button, Flex, Heading, Text, useToken } from "@chakra-ui/react";

import CheckIcon from "../../assets/icons/check";
import { useDialog } from "../../hooks/dialog";
import BaseDialog from "./base";

const DIALOG_TYPE = "success";

interface DialogSuccessProps {
  title?: string;
  description?: string;
}

export default function DialogSuccess(params: DialogSuccessProps) {
  const { props, resetProps, close } = useDialog();

  const [feedbackSuccessMediumColor] = useToken("colors", [
    "feedback.success.medium",
  ]);

  const handleAction = () => {
    if (!Object.keys(params).length && props?.redirect?.action)
      props?.redirect?.action();
    close();
  };

  const handleClose = () => {
    if (!Object.keys(params).length && props?.onClose) props?.onClose();
    close();
  };

  useEffect(() => {
    if (Object.values(params).length) resetProps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return (
    <BaseDialog type={DIALOG_TYPE}>
      <Flex flex={0.6} direction="column" justify="center" align="center" p={4}>
        <Box bg="feedback.success.lightest" borderRadius="full" p={4}>
          <CheckIcon w="40px" h="40px" color={feedbackSuccessMediumColor} />
        </Box>

        <Box my={6}>
          <Heading fontSize="lg" color="neutral.darkest" textAlign="center">
            {params?.title || props?.title || "Sucesso!"}
          </Heading>
          <Text fontSize="sm" color="neutral.dark" mt={4} textAlign="center">
            {params?.description ||
              props?.description ||
              "Ação realizada com sucesso!"}
          </Text>
        </Box>

        {props?.redirect && (
          <Button variant="outline" size="md" w="100%" onClick={handleAction}>
            {props?.redirect?.label}
          </Button>
        )}

        <Button size="md" w="100%" mt={2} onClick={handleClose}>
          {props?.closeLabel || "Fechar"}
        </Button>
      </Flex>
    </BaseDialog>
  );
}
