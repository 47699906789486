export enum StatesNames {
  "Acre" = "AC",
  "Alagoas" = "AL",
  "Amazonas" = "AM",
  "Amapá" = "AP",
  "Bahia" = "BA",
  "Ceará" = "CE",
  "Distrito Federal" = "DF",
  "Espírito Santo" = "ES",
  "Goiás" = "GO",
  "Maranhão" = "MA",
  "Minas Gerais" = "MG",
  "Mato Grosso do Sul" = "MS",
  "Mato Grosso" = "MT",
  "Pará" = "PA",
  "Paraíba" = "PB",
  "Pernambuco" = "PE",
  "Piauí" = "PI",
  "Paraná" = "PR",
  "Rio de Janeiro" = "RJ",
  "Rio Grande do Norte" = "RN",
  "Rondônia" = "RO",
  "Roraima" = "RR",
  "Rio Grande do Sul" = "RS",
  "Santa Catarina" = "SC",
  "Sergipe" = "SE",
  "São Paulo" = "SP",
  "Tocantins" = "TO",
}

export enum States {
  "AC" = "Acre",
  "AL" = "Alagoas",
  "AM" = "Amazonas",
  "AP" = "Amapá",
  "BA" = "Bahia",
  "CE" = "Ceará",
  "DF" = "Distrito Federal",
  "ES" = "Espírito Santo",
  "GO" = "Goiás",
  "MA" = "Maranhão",
  "MG" = "Minas Gerais",
  "MS" = "Mato Grosso do Sul",
  "MT" = "Mato Grosso",
  "PA" = "Pará",
  "PB" = "Paraíba",
  "PE" = "Pernambuco",
  "PI" = "Piauí",
  "PR" = "Paraná",
  "RJ" = "Rio de Janeiro",
  "RN" = "Rio Grande do Norte",
  "RO" = "Rondônia",
  "RR" = "Roraima",
  "RS" = "Rio Grande do Sul",
  "SC" = "Santa Catarina",
  "SE" = "Sergipe",
  "SP" = "São Paulo",
  "TO" = "Tocantins",
}

export enum StatesInitials {
  "AC" = "AC",
  "AL" = "AL",
  "AM" = "AM",
  "AP" = "AP",
  "BA" = "BA",
  "CE" = "CE",
  "DF" = "DF",
  "ES" = "ES",
  "GO" = "GO",
  "MA" = "MA",
  "MG" = "MG",
  "MS" = "MS",
  "MT" = "MT",
  "PA" = "PA",
  "PB" = "PB",
  "PE" = "PE",
  "PI" = "PI",
  "PR" = "PR",
  "RJ" = "RJ",
  "RN" = "RN",
  "RO" = "RO",
  "RR" = "RR",
  "RS" = "RS",
  "SC" = "SC",
  "SE" = "SE",
  "SP" = "SP",
  "TO" = "TO",
}
