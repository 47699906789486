import moment from "moment-timezone";
import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Divider,
  Flex,
  Heading,
  Image,
  Link,
  Skeleton,
  Slide,
  Stack,
  Text,
} from "@chakra-ui/react";

import { ILesson } from "@isurf-tech/types/domain";

import { Schedules } from "../../@types/lessons";
import ArrowRight from "../../assets/icons/arrow-right";
import Footer from "../../components/footer";
import Header from "../../components/headers/main";
import SchedulesComponent from "../../components/schedules";
import SearchBox from "../../components/searchables/search-box";
import routesName from "../../config/routes";
import { SEARCH_LESSON_FILTERS } from "../../config/storage-keys";
import useCheckout from "../../hooks/checkout";
import { useLessons } from "../../hooks/lessons";
import { useMobileSearch } from "../../hooks/mobile-search";
import { useQuery } from "../../hooks/query-param";
import { useResposiveness } from "../../hooks/responsiveness";
import useLocalStorage from "../../hooks/storage";
import { scheduleService } from "../../services/api";

const formatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
  minimumFractionDigits: 2,
});

interface ILessonFilter {
  modality: string;
  date: string;
  school: string;
  localization: string;
}

export default function SearchPage() {
  const navigate = useNavigate();
  const query = useQuery();

  const { isMobile } = useResposiveness();
  const { open: openMobileSearch } = useMobileSearch();
  const { loading, lessons, updateFilters, refreshSchedulesByLesson } =
    useLessons();
  const { lessonSelect, schedulesSelect, handleSelect, goToCheckout } =
    useCheckout();

  const [filters, setFilters] = useLocalStorage<ILessonFilter>(
    SEARCH_LESSON_FILTERS,
    {}
  );

  const refreshSchedules = async (
    lesson: ILesson,
    start: string,
    end: string
  ) => {
    const { data } = await scheduleService.getByLessonId(lesson.id, {
      start,
      end,
    });

    refreshSchedulesByLesson(lesson.id, data as any);
  };

  const handleUpdateFilters = useCallback(
    (params?: any) => {
      updateFilters({
        start: moment(params?.date || filters?.date)
          .startOf("day")
          .toISOString(),
        end: moment(params?.date || filters?.date)
          .endOf("day")
          .toISOString(),
        modality: params?.modality || filters?.modality,
        school: params?.school || filters?.school,
        localization: params?.localization || filters?.localization,
      });
    },
    [filters, updateFilters]
  );

  const handleOnChangeFilter = (values: any) => {
    setFilters(values);
    const entries = Object.entries(JSON.parse(JSON.stringify(values))).filter(
      ([, value]) => !!value
    ) as [string, string][];

    navigate(
      routesName.search + `?${new URLSearchParams(entries).toString()}`,
      {
        replace: true,
      }
    );
  };

  const goToLesson = (lesson: ILesson) => {
    navigate(routesName.lessonById.replace(":id", lesson.id), {
      state: {
        lesson,
      },
    });
  };

  useEffect(() => {
    const {
      modality = "",
      date = "",
      school = "",
      localization = "",
    } = query.object;

    if (modality || date || school || localization) {
      setFilters({ modality, date, school, localization });
    }

    handleUpdateFilters(query.object);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFilters]);

  return (
    <Slide
      direction="right"
      in={true}
      style={{ overflow: "hidden", overflowY: "scroll" }}
    >
      <Flex direction="column" h="100vh" flex={1}>
        <Header onSearch={openMobileSearch}>
          {!isMobile && (
            <SearchBox
              value={filters}
              mt={4}
              border
              onClick={() => handleUpdateFilters()}
              onChange={handleOnChangeFilter}
            />
          )}
        </Header>

        <Flex
          direction="column"
          px={isMobile ? "20px" : "80px"}
          py={6}
          pb={20}
          flex={3}
        >
          {!isMobile && (
            <Box pb={14}>
              {filters?.localization && (
                <Heading>Aulas em {filters?.localization}</Heading>
              )}
              <Text mt={2}>
                {[
                  filters?.school,
                  filters?.date
                    ? moment(filters?.date).format("DD/MM/YYYY")
                    : null,
                  filters?.modality,
                ]
                  .filter((f) => !!f)
                  .join(" · ")}
              </Text>
            </Box>
          )}

          {loading ? (
            <Stack spacing={isMobile ? 5 : 10}>
              <Skeleton height="30vh" borderRadius="sm" />
              <Skeleton height="30vh" borderRadius="sm" />
              <Skeleton height="30vh" borderRadius="sm" />
            </Stack>
          ) : (
            lessons.map((lesson, index) => (
              <React.Fragment key={lesson.id}>
                {index > 0 && (
                  <Divider color="neutral.dark" my={isMobile ? 5 : 10} />
                )}

                <Flex
                  key={index}
                  justifyContent="space-between"
                  onClick={isMobile ? () => goToLesson(lesson) : () => {}}
                >
                  <Flex direction={isMobile ? "column" : "row"}>
                    <Box>
                      <Image
                        src={lesson.photos[0]}
                        maxH={isMobile ? "30vh" : "40vh"}
                        boxSize={isMobile ? "calc(100vw - 40px)" : "28vw"}
                        borderRadius="md"
                        objectFit="cover"
                        objectPosition={isMobile ? "center" : "left"}
                        fallbackSrc="https://via.placeholder.com/150"
                      />
                    </Box>

                    <Box
                      ml={isMobile ? 0 : 8}
                      mt={isMobile ? 2 : 0}
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-between"
                    >
                      <Box>
                        <Heading size={isMobile ? "sm" : "lg"}>
                          {lesson.name}
                        </Heading>
                        <Text
                          color="neutral.dark"
                          size={isMobile ? "xs" : "sm"}
                        >
                          {`${lesson?.location?.address} · ${lesson?.location?.city}/${lesson?.location?.state}`}
                        </Text>

                        <Text color="neutral.dark">{lesson?.school?.name}</Text>

                        <Flex mt={isMobile ? 1 : 0}>
                          <Text color="neutral.darkest">
                            {formatter.format(lesson.price * 0.01)}
                          </Text>
                          <Text ml={1} color="neutral.dark">
                            /aula
                          </Text>
                        </Flex>
                      </Box>

                      {!isMobile && (
                        <Link
                          mt={8}
                          mb={-2}
                          display="flex"
                          flexDir="row"
                          alignItems="center"
                          padding="8px 0px"
                          fontSize="sm"
                          color="neutral.darkest"
                          cursor="pointer"
                          textDecoration="unset"
                          onClick={() => goToLesson(lesson)}
                        >
                          ver detalhes
                          <ArrowRight style={{ marginLeft: 4 }} />
                        </Link>
                      )}
                    </Box>
                  </Flex>

                  {!isMobile && (
                    <SchedulesComponent
                      simple
                      data={lesson.schedules! as unknown as Schedules}
                      selecteds={schedulesSelect}
                      onChange={(schedules) => handleSelect(lesson, schedules)}
                      disabled={
                        lessonSelect !== lesson.id && lessonSelect !== null
                      }
                      onClick={() => goToCheckout(lesson)}
                      startDate={filters.date}
                      onRefresh={(start, end) =>
                        refreshSchedules(lesson, start, end)
                      }
                    />
                  )}
                </Flex>
              </React.Fragment>
            ))
          )}
        </Flex>

        <Footer mt={isMobile ? "70px" : "0px"} />
      </Flex>
    </Slide>
  );
}
