export default function PaddleBoardingIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_521_3893)">
        <path
          d="M33.3478 18.4969C45.1118 3.87654 39.2198 -3.4337 29.1874 1.56943C16.7233 7.45362 -3.2612 30.4503 0.478217 37.8244C3.83965 44.2373 16.7257 34.3314 20.0821 31.6866C24.4408 28.2583 29.9597 22.8563 33.3478 18.4969ZM27.8459 4.96177C42.0864 -3.17576 38.027 9.21278 31.8806 16.5797H23.4796V7.98113C24.9891 6.81809 26.4599 5.79702 27.8459 4.96177ZM19.2955 29.3156C14.6061 33.0599 10.3065 36.0668 5.81141 37.3692C-2.99264 39.6767 7.00668 24.1685 12.1373 18.7537V26.7452C12.1373 27.3908 12.6611 27.9144 13.3072 27.9144H20.9654C20.4143 28.3884 19.8575 28.8563 19.2955 29.3156ZM23.5753 25.576H14.4772V16.1844C16.6378 13.9058 18.905 11.7601 21.1397 9.87211V17.7488C21.1397 18.3945 21.6635 18.918 22.3097 18.918H29.9753C28.0742 21.1456 25.9045 23.4043 23.5753 25.576Z"
          fill="#0F7E99"
        />
        <path
          d="M39.6259 21.4099C39.1691 20.9532 38.4282 20.9532 37.9713 21.4099L30.4118 28.9641C27.4278 27.8573 26.6432 29.4218 24.0757 31.9063C21.6005 34.2214 19.9248 35.9348 21.9764 38.7302C25.3287 41.8233 27.5241 38.5008 29.2979 36.8118C31.6852 34.3896 33.1904 33.5 32.0728 30.6111C39.3918 23.1172 40.7654 22.7252 39.6259 21.4099ZM29.7369 33.0663C29.5106 33.227 25.329 37.6342 25.1159 37.5316C23.9589 37.9924 22.9789 36.6216 23.6534 35.662C23.702 35.5349 26.3678 32.9456 27.5451 31.7461C29.1011 29.7997 30.8649 31.8805 29.7369 33.0663Z"
          fill="#0F7E99"
        />
      </g>
      <defs>
        <clipPath id="clip0_521_3893">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
