import { useEffect, useRef } from "react";

import { Box, Text } from "@chakra-ui/react";

import dateHelper from "../../helper/date";
import { useResposiveness } from "../../hooks/responsiveness";

interface DatePickerRowBoxDayPropsI {
  index: number;
  day: any;
  label: string;
  selected: boolean;
  onClick: (...props: any) => void;
}

export default function DatePickerRowBoxDay({
  index,
  day,
  selected,
  onClick,
  label = "",
}: DatePickerRowBoxDayPropsI) {
  const { isMobile } = useResposiveness();
  const ref = useRef<any>(null);
  const id = `date-picker-row-day-${index}`;

  const centralize = () =>
    ref?.current?.scrollIntoView({ behavior: "smooth", inline: "center" });

  const handleOnClick = (...props: any) => {
    centralize();
    if (onClick) onClick(...props);
  };

  useEffect(() => {
    if (selected) centralize();
  }, [selected]);

  return (
    <Box
      ref={ref}
      id={id}
      key={id}
      ml={index > 0 ? "4px" : "0px"}
      p="16px 24px"
      bg={selected ? "brand.dark" : "neutral.light"}
      borderRadius="4px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      onClick={handleOnClick}
      minW={isMobile ? "110px" : "98px"}
      cursor="pointer"
    >
      <Text
        fontWeight="semibold"
        fontSize="lg"
        color={selected ? "neutral.lightest" : "neutral.darkest"}
      >
        {dateHelper.format(day, "DD")}
      </Text>
      <Text
        fontWeight="semibold"
        fontSize="2xs"
        color={selected ? "neutral.lightest" : "neutral.dark"}
      >
        {dateHelper.format(day, "ddd")}
      </Text>

      {label && (
        <Text
          fontWeight="medium"
          fontSize="2xs"
          color={selected ? "neutral.lightest" : "neutral.dark"}
          whiteSpace="nowrap"
        >
          {label}
        </Text>
      )}
    </Box>
  );
}
