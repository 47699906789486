import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useDisclosure } from "@chakra-ui/react";

import { EntityStatus } from "@isurf-tech/types/core/enums";
import { EntityUserType } from "@isurf-tech/types/domain/enums";

import { SESSION_TYPE } from "../@types/session";
import Session from "../components/session";
import { STORAGE_KEY_ACCESS_TOKEN } from "../config/http";
import routesName from "../config/routes";
import { useAuth } from "../hooks/auth";
import api, { authService } from "../services/api";
import * as localstorage from "../services/storage";

export interface SessionContextI {
  type: SESSION_TYPE;
  isOpen: boolean;
  openSignIn: (redirectAfterSign?: any, closeAfterSignIn?: any) => any;
  handleSignIn: (data: any) => Promise<any>;
  handleSignUp: (data: any) => any;
  openSignUp: (...metata: any) => any;
  close: () => void;
  metadata: { [key in string]: any };
  logout: () => any;
  goToSessionType: (type: SESSION_TYPE) => void;
}

export const SessionContext = createContext<SessionContextI>({} as any);

export const SessionProvider = ({ children }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [type, setType] = useState<SESSION_TYPE>(SESSION_TYPE.VERIFY_EMAIL);
  const [metadata, setMetadata] = useState({});
  const {
    isOpen,
    onOpen: open,
    onClose: close,
  } = useDisclosure({ id: "session" });
  const { logout: authLogout, authUser } = useAuth();

  const openSignIn = useCallback(
    (redirectAfterSignIn: boolean = true, closeAfterSignIn: boolean = true) => {
      setType(SESSION_TYPE.SIGN_IN);

      if (!isOpen) open();
    },
    [isOpen, open]
  );

  const handleNavigate = useCallback(
    (path: string | null, closeModal = true) => {
      if (closeModal) close();

      if (path) return navigate(path);

      return;
    },
    [close, navigate]
  );

  const handleSignIn = useCallback(
    async (form: any) => {
      const { data, error } = await authService.signin<{
        access_token: string;
        metadata: {
          entity: string;
          status: string;
          properties: string[];
        };
      }>(form.email, form.password);

      const token = data?.access_token;

      api.setDefaultToken(token);
      localstorage.save(STORAGE_KEY_ACCESS_TOKEN, token);
      const user: any = await authUser(token);

      if (data?.metadata) {
        const { entity, properties, status } = data.metadata;

        if (
          entity.toUpperCase() === "USER" &&
          status.toUpperCase() === EntityStatus.PENDING
        ) {
          if (properties.includes("email"))
            return setType(SESSION_TYPE.VERIFY_EMAIL);
          if (properties.includes("cellphone"))
            return setType(SESSION_TYPE.VERIFY_PHONE);
        }

        if (entity.toUpperCase() === EntityUserType.STUDENT) {
          if (status.toUpperCase() === EntityStatus.PENDING) {
            return handleNavigate(routesName.registerStudents);
          }
        }

        if (entity.toUpperCase() === EntityUserType.SCHOOL) {
          if (status.toUpperCase() === EntityStatus.PENDING) {
            return handleNavigate(routesName.registerSchools);
          }

          if (status.toUpperCase() === EntityStatus.AWAITING_APPROVAL) {
            return handleNavigate(routesName.schoolsAnalyse);
          }
        }
      }

      if (user?.entity === EntityUserType.STUDENT) {
        if (location.pathname === routesName.search)
          return handleNavigate(null);

        return handleNavigate(routesName.studentsDashboard);
      }

      if (user?.entity === EntityUserType.SCHOOL)
        return handleNavigate(routesName.schoolsDashboard);

      return { error };
    },
    [authUser, handleNavigate, location.pathname]
  );

  const handleSignUp = useCallback((form: any) => {
    return authService.signup(form);
  }, []);

  const openSignUp = useCallback(
    (metaParams: any) => {
      setType(SESSION_TYPE.SIGN_UP);
      setMetadata({ ...metadata, ...metaParams });
      if (!isOpen) open();
    },
    [isOpen, metadata, open]
  );

  const goToSessionType = useCallback(
    (type: SESSION_TYPE) => {
      setType(type);
    },
    [setType]
  );

  const logout = useCallback(() => {
    navigate(routesName.main);
    localstorage.clear();
    authLogout();
  }, [authLogout, navigate]);

  const value = useMemo(
    () => ({
      type,
      isOpen,
      openSignIn,
      openSignUp,
      close,
      metadata,
      handleSignIn,
      handleSignUp,
      logout,
      goToSessionType,
    }),
    [
      type,
      isOpen,
      openSignIn,
      openSignUp,
      close,
      metadata,
      handleSignIn,
      handleSignUp,
      logout,
      goToSessionType,
    ]
  );

  useEffect(() => {
    if (isOpen === false) setMetadata({});
  }, [isOpen]);

  return (
    <SessionContext.Provider value={value}>
      {children}
      <Session />
    </SessionContext.Provider>
  );
};
