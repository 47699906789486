import axios, { AxiosInstance } from "axios";

import { ServiceResponse } from "..";

interface ISupportTicket {
  name: string;
  email: string;
  description: string;
}

export class SupportService {
  constructor(private api: AxiosInstance) {}

  async create(form: ISupportTicket): Promise<ServiceResponse<string>> {
    const result = { data: null, error: null } as ServiceResponse;

    try {
      const { data } = await this.api.post(`/support/help`, {
        ...form,
      });

      if (data.error) result.error = data.error ?? "";
      if (data.success) result.data = data.success ?? "";
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result.error = error.response?.data;
      } else {
        result.error = error as any;
      }
    } finally {
      return result;
    }
  }
}
