import merge from "lodash.merge";
import moment from "moment-timezone";

import { ISchedule } from "@isurf-tech/types/domain";

import { Schedules } from "../../@types/lessons";
import { COLUMNS_MAX } from "./calendar";

export function transformDayToDayIso(schedules: Schedules) {
  return Object.fromEntries(
    Object.entries(schedules)
      .filter(([key]) => {
        if (Number(key) >= 0 && Number(key) < moment().day()) return false;

        return true;
      })
      .map(([key, value]) => {
        const keyWeek =
          Number(key) > 0
            ? moment()
                .startOf("week")
                .add(key, "days")
                .startOf("day")
                .toISOString()
            : null;

        return [keyWeek || key, value];
      })
  );
}

export function getRowsLength(schedules: Schedules) {
  return Object.values(schedules).reduce(
    (s, n) => (n.length >= s ? n.length : s),
    0
  );
}

export function fillColumnsBySize(
  data: Schedules,
  offset = 0,
  startDate = moment().toISOString()
): [keyof Schedules, Schedules[keyof Schedules]][] {
  const schedulesSamplesDays = new Array(COLUMNS_MAX + 1)
    .fill({})
    .reduce((store, _, i) => {
      const date = moment(startDate)
        .startOf("day")
        .add(i - 1 + COLUMNS_MAX * offset, "day")
        .toISOString() as string;

      if (!moment(date).isValid()) return store;

      return {
        ...store,
        [date]: [],
      };
    });

  const schedulesFormated = transformDayToDayIso(data);

  return Object.entries(merge({}, schedulesSamplesDays, schedulesFormated));
}

export function sortTimes(a: ISchedule, b: ISchedule) {
  if (!a?.start?.length) return 1;

  const hourA = moment(a.start).format("HH");
  const minuteA = moment(a.start).format("mm");

  const hourB = moment(b.start).format("HH");
  const minuteB = moment(b.start).format("mm");

  return `${hourA}${minuteA}` > `${hourB}${minuteB}` ? 1 : -1;
}

export function formatSchedulesByStartKey(
  schedules: ISchedule[],
  field: keyof ISchedule = "start"
): Schedules {
  return schedules.reduce<Schedules>((store, schedule) => {
    const value = schedule[field] as string;
    const key = moment(value).startOf("day").toISOString();

    return {
      ...store,
      [key]: [...(store[key] || []), schedule],
    };
  }, {});
}
