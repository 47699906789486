import {
  FormControl,
  FormControlProps,
  Heading,
  Input,
  InputProps,
  Text,
} from "@chakra-ui/react";

interface IBoxInputProps extends InputProps {
  label: string;
  noInput?: boolean;
  boxProps?: FormControlProps;
}

export default function BoxInput({
  label,
  noInput,
  boxProps,
  onClick,
  ...props
}: IBoxInputProps) {
  return (
    <FormControl
      border="hairline"
      borderColor="neutral.medium"
      borderRadius="md"
      p={4}
      _focus={{
        borderColor: "brand.dark",
      }}
      onClick={onClick}
      {...boxProps}
    >
      <Heading fontSize="xs" color="neutral.darkest">
        {label}
      </Heading>

      {noInput ? (
        <Text fontSize="sm" color="neutral.dark" fontWeight="medium">
          {props.value ?? props.defaultValue ?? props.placeholder ?? ""}
        </Text>
      ) : (
        <Input
          placeholder="Type here..."
          variant="unstyled"
          p={0}
          fontSize="sm"
          color="neutral.dark"
          _placeholder={{
            color: "neutral.dark",
          }}
          {...props}
        />
      )}
    </FormControl>
  );
}
