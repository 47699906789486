import React, { createContext, useCallback, useMemo, useState } from "react";

import { useDisclosure } from "@chakra-ui/react";

import DialogError from "../components/dialogs/error";
import DialogInvite from "../components/dialogs/invite";
import DialogSuccess from "../components/dialogs/success";
import DialogTextInput from "../components/dialogs/text-input";
import { TextFieldPropsI } from "../components/text-field";

export type DialogType = "success" | "error" | "text-input" | "invite";

export type DialogActionResponse = {
  type: DialogType;
  title: string;
  description: string;
};

type Props = {
  type: DialogType;
  title: string;
  description: string;
  redirect?: {
    label: string;
    action: (
      params?: any
    ) =>
      | void
      | Promise<void>
      | Promise<DialogActionResponse>
      | DialogActionResponse;
  };
  action?: {
    label: string;
    onClick: (params?: any) => void;
  };
  onClose?: () => void;
  closeLabel?: string | boolean;
  inputProps?: TextFieldPropsI;
};

interface DialogContextI {
  isOpen: boolean;
  register: (id: DialogType) => void;
  open: <T = any>(params?: Partial<Props & T>) => any;
  close: () => any;
  props?: Props;
  resetProps: () => void;
}

export const DialogContext = createContext<DialogContextI>({} as any);

export function DialogProvider({ children }: any) {
  const [type, setType] = useState<DialogType | null>(null);
  const [props, setProps] = useState<DialogContextI["props"]>({} as any);
  const {
    isOpen,
    onOpen: open,
    onClose: close,
  } = useDisclosure({ id: type || new Date().toISOString() });

  const register = useCallback((type: DialogType) => {
    setType(type);
  }, []);

  const handleOpen = useCallback(
    (params: any) => {
      setType(params.type);
      setProps(params);
      open();
    },
    [open]
  );

  const handleClose = useCallback(() => {
    setType(null);
    setProps({} as any);
    close();
  }, [close]);

  const resetProps = useCallback(() => {
    setProps({} as any);
  }, []);

  const value = useMemo(
    () => ({
      props,
      isOpen,
      open: handleOpen,
      close: handleClose,
      register,
      resetProps,
    }),
    [props, isOpen, handleOpen, handleClose, register, resetProps]
  );

  return (
    <DialogContext.Provider value={value}>
      {children}
      {type === "success" && <DialogSuccess />}
      {type === "error" && <DialogError />}
      {type === "text-input" && <DialogTextInput />}
      {type === "invite" && <DialogInvite />}
    </DialogContext.Provider>
  );
}
