import React from "react";

import {
  Button,
  Flex,
  GridItem,
  Heading,
  Text,
  useToken,
} from "@chakra-ui/react";

import { IClassroom } from "@isurf-tech/types/domain";

import AlertIcon from "../../../../assets/icons/alert";
import { DialogActionResponse } from "../../../../contexts/dialog";
import { useDialog } from "../../../../hooks/dialog";
import { useResposiveness } from "../../../../hooks/responsiveness";
import { classroomService } from "../../../../services/api";

interface ClassroomCancelationProps {
  classroom: IClassroom;
}

export default function ClassroomCancelation({
  classroom = {} as IClassroom,
}: ClassroomCancelationProps) {
  const { isMobile } = useResposiveness();
  const [feedbackColor] = useToken("colors", ["feedback.warning.dark"]);
  const dialog = useDialog();

  const handleRequestCancelClassroom = async (
    description: string
  ): Promise<DialogActionResponse> => {
    const { data, error } = await classroomService.cancel(
      classroom.id!,
      description
    );

    if (error?.statusCode && error?.message)
      return {
        type: "error",
        title: "Erro ao cancelar aula.",
        description:
          error?.message ||
          "Houve um erro ao cancelar aula, por favor tente novamente ou entre em contato com o suporte.",
      };

    return {
      type: "success",
      title: "Aula cancelada com sucesso!",
      description:
        data?.message ||
        "A aula foi cancelada com sucesso. Logo seus alunos serão notificados.",
    };
  };

  const handleCancelClassroom = () => {
    return dialog.open({
      type: "text-input",
      title: "Cancelamento de turmas do dia",
      description:
        "Explique o motivo de cancelamento das turmas desse dia. A mensagem será disparada por email para todos os alunos das turmas desse dia.",

      inputProps: {
        label: "Motivo do cancelamento",
        type: "text-area",
        fullWidth: true,
      },
      closeLabel: false,
      redirect: {
        label: "Cancelar turmas",
        action: handleRequestCancelClassroom,
      },
    });
  };

  return (
    <React.Fragment>
      <GridItem colSpan={isMobile ? 4 : 12} mt={10}>
        <Heading fontSize="lg">Cancelamento de aula</Heading>
      </GridItem>

      <GridItem colSpan={isMobile ? 4 : 12} mt={4}>
        <Flex p="24px" borderRadius="sm" bg="feedback.warning.lightest">
          <Flex minW="30px">
            <AlertIcon color={feedbackColor} />
          </Flex>

          <Flex direction="column" ml={4}>
            <Heading fontSize="sm" color="feedback.warning.dark">
              Atenção
            </Heading>
            <Text
              fontSize="xs"
              maxW={isMobile ? "100%" : "80%"}
              mt={2}
              color="neutral.dark"
            >
              Ao cancelar essa aula os alunos receberão de volta os valores
              pagos na inscrição, ficando ao critério deles a remarcação da
              aula. Somente esse horário será cancelado.
            </Text>
            <Button
              variant="danger"
              size="md"
              mt={4}
              w={isMobile ? "80%" : "15%"}
              onClick={handleCancelClassroom}
            >
              Cancelar aula
            </Button>
          </Flex>
        </Flex>
      </GridItem>
    </React.Fragment>
  );
}
