import { useNavigate } from "react-router";

import { Box, Divider, Flex, Heading, Link, Text } from "@chakra-ui/react";

import { ILesson } from "@isurf-tech/types/domain";

import ArrowRight from "../../assets/icons/arrow-right";
import CheckIcon from "../../assets/icons/check";
import MapPinIcon from "../../assets/icons/map-pin";
import SurferIcon from "../../assets/icons/sports/surfer";
import routesName from "../../config/routes";

export default function InformationsLesson({ lesson }: { lesson: ILesson }) {
  const navigate = useNavigate();

  return (
    <Flex direction="column">
      <Heading fontSize="xl" color="neutral.darkest">
        Modalidade
      </Heading>

      <Flex direction="row" mt={8}>
        {[{ name: "surf", icon: SurferIcon }].map((type, index) => (
          <Flex
            key={index.toString()}
            bg="white"
            cursor="pointer"
            direction="column"
            align="center"
            justify="center"
          >
            <Box
              bg="brand.lightest"
              w="88px"
              minH={88}
              padding="24px"
              borderRadius="full"
            >
              {type.icon()}
            </Box>
            <Text
              fontWeight={600}
              fontSize="sm"
              marginTop={4}
              textTransform="capitalize"
            >
              {type.name}
            </Text>
          </Flex>
        ))}
      </Flex>

      <Divider my={10} />

      <Heading fontSize="xl" color="neutral.darkest">
        Localização
      </Heading>

      <Flex mt={4}>
        <Flex>
          <MapPinIcon />
        </Flex>
        <Flex direction="column" ml={3}>
          <Heading fontWeight="semibold" fontSize="sm" color="neutral.darkest">
            Ponto de encontro
          </Heading>
          <Text fontSize="sm" color="neutral.darkest">
            {`${lesson?.location?.address} · ${lesson?.location?.city}/${lesson?.location?.state}`}
          </Text>
          <Text fontSize="xs" color="neutral.dark">
            {lesson?.locationDetails}
          </Text>
        </Flex>
      </Flex>

      <Divider my={10} />

      <Heading fontSize="xl" color="neutral.darkest">
        Avisos prévios
      </Heading>

      <Text mt={4} color="neutral.darkest" fontSize="sm">
        {lesson?.description}
      </Text>

      <Divider my={10} />

      <Heading fontSize="xl" color="neutral.darkest">
        Sobre a praia
      </Heading>

      {[
        "Boas ondas",
        "Estacionamento próximo",
        "Badalado",
        "Restaurantes em volta",
      ].map((x) => (
        <Flex direction="row" mt={4} key={x}>
          <CheckIcon />
          <Text ml={6}>{x}</Text>
        </Flex>
      ))}

      <Divider my={10} />

      <Heading fontSize="xl" color="neutral.darkest">
        Sobre a escola
      </Heading>

      <Text mt={4} color="neutral.darkest" fontSize="sm">
        {lesson?.school?.description}
      </Text>

      <Link
        textDecoration="none"
        display="flex"
        flexDirection="row"
        alignItems="center"
        w="max-content"
        mt={10}
        onClick={() =>
          navigate(routesName.search.concat(`?school=${lesson.school.name}`))
        }
      >
        <Heading fontSize="sm" color="neutral.darkest">
          Ver outras aulas dessa escola
        </Heading>
        <Flex m={2} justify="center" alignItems="center">
          <ArrowRight />
        </Flex>
      </Link>
    </Flex>
  );
}
