import { Icon, IconProps } from "@chakra-ui/react";

interface ChevronLeftIconPropsI extends IconProps {}

export default function ChevronLeftIcon(props: ChevronLeftIconPropsI) {
  return (
    <Icon
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15 18L9 12L15 6"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
