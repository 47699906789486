import {
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";

import { EntityUserType } from "@isurf-tech/types/domain/enums";

import { useResposiveness } from "../../../../hooks/responsiveness";
import { useSession } from "../../../../hooks/session";

export default function HomeSchoolFourtySection() {
  const { isMobile } = useResposiveness();
  const { openSignUp } = useSession();

  return (
    <Box
      id="home-school-second"
      as="section"
      position="relative"
      minHeight={isMobile ? "80vh " : "60vh"}
      mt={isMobile ? "80px" : "0px"}
      px={isMobile ? "16px" : "80px"}
    >
      {isMobile && (
        <Heading fontSize="xl" fontWeight="semibold" mb={12}>
          Organize seu financeiro
        </Heading>
      )}

      <Grid
        templateColumns={isMobile ? "repeat(4, 1fr)" : "repeat(12, 1fr)"}
        columnGap={8}
      >
        <GridItem position="relative" colStart={1} colSpan={isMobile ? 4 : 5}>
          <Box
            bg="brand.lightest"
            ml="-80px"
            w="100%"
            height="50vh"
            borderRightRadius="lg"
            position="relative"
          >
            <Box
              position="absolute"
              left={0}
              bottom={0}
              objectPosition="center"
            >
              <Image
                ml={8}
                src={require("../../../../assets/medias/mockup-hand-finances.png")}
              />
            </Box>
          </Box>
        </GridItem>

        <GridItem
          position="relative"
          colStart={isMobile ? 1 : 7}
          colSpan={isMobile ? 4 : 6}
          pt={8}
        >
          {!isMobile && (
            <Heading fontSize="xl" fontWeight="semibold">
              Organize seu financeiro
            </Heading>
          )}

          <Text fontSize="xs" mt={6} maxW={isMobile ? "100%" : "45%"}>
            Tenha sua agenda de aulas organizadas, receba relatórios financeiros
            mensais e aumente sua receita com mais público.
          </Text>

          <Button
            size="md"
            mt={10}
            w={isMobile ? "100%" : "min-content"}
            onClick={() => openSignUp({ role: EntityUserType.SCHOOL })}
          >
            Cadastre sua escola
          </Button>
        </GridItem>
      </Grid>
    </Box>
  );
}
