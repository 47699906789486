import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment-timezone";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Heading,
  Image,
  Link,
  SimpleGrid,
  Text,
  useToken,
} from "@chakra-ui/react";

import { ISchool } from "@isurf-tech/types/domain";

import { MediaContext, MediaObject } from "../../@types/medias";
import StarIcon from "../../assets/icons/start";
import CardFeedbackInfo from "../../components/cards/feedback-info";
import FormLocation from "../../components/forms/locations";
import Galery from "../../components/galery";
import UploadButton from "../../components/galery/upload-button";
import Header from "../../components/headers/main";
import SimpleHeader from "../../components/headers/simple";
import DatePicker from "../../components/inputs/date-picker";
import { SelectDocument } from "../../components/select-document";
import TextField from "../../components/text-field";
import routesName from "../../config/routes";
import { useAuth } from "../../hooks/auth";
import { useDialog } from "../../hooks/dialog";
import { useLoader } from "../../hooks/loader";
import { useResposiveness } from "../../hooks/responsiveness";
import useLocalStorage from "../../hooks/storage";
import { mediasService, schoolsService } from "../../services/api";
import { SchoolFormI } from "../../services/api/services/schools";



const SPACE = 6;
// eslint-disable-next-line no-useless-escape
const CNPJ_REGEX = /[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2}/;

const formSchema = yup.object().shape({
  name: yup.string().required("Campo obrigatório"),
  description: yup.string().required("Campo obrigatório"),
  businessId: yup
    .string()
    .matches(CNPJ_REGEX, "CNPJ invalido!")
    .required("Campo obrigatório"),
  autarchismId: yup.string().required("Campo obrigatório"),
  logo: yup.string().required("Campo obrigatório"),
  photos: yup.array().of(yup.string()).required("Campo obrigatório"),
  location: yup.object().shape({
    zip: yup.string().required("Campo obrigatório"),
    state: yup.string().required("Campo obrigatório"),
    stateCode: yup.string().required("Campo obrigatório"),
    city: yup.string().required("Campo obrigatório"),
    address: yup.string().required("Campo obrigatório"),
  }),
  ownerBirthDate: yup
    .string()
    .matches(
      /(^0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-(\d{4}$)/,
      "Data de nascimento inválida"
    )
    .required("Campo obrigatório"),
  ownerDocumentNumber: yup
    .string()
    .matches(/^\d{3}\d{3}\d{3}\d{2}$/g, "CPF inválido")
    .required("Campo obrigatório"),
  ownerDocumentFile: yup.string().required("Campo obrigatório"),
  bankAccount: yup.object().shape({
    number: yup
      .string()
      .nullable(true)
      .matches(/^\d{7}$/, "Número da conta inválido. Ex: 0000000")
      .required("Campo obrigatório")
      .default(""),
    routingNumber: yup
      .string()
      .nullable(true)
      .matches(
        /^\d{3}-\d{4}$/,
        "banco ou agência inválido. Ex: 000-0000, {Banco}-{Agência}"
      )
      .required("Campo obrigatório"),
  }),
});

enum PerfilAction {
  CREATE = "create",
  UPDATE = "update",
}

export default function PerfilSchoolPage() {
  const navigate = useNavigate();
  const loader = useLoader();
  const { isMobile } = useResposiveness();
  const MIN_WIDTH = useMemo(() => (isMobile ? "100%" : "40%"), [isMobile]);
  const [colorBrandDark] = useToken("colors", ["brand.dark"]);
  const { user, refreshUser } = useAuth<ISchool>();
  const isUpdate = useMemo(() => !!user?.id, [user]);
  const [form, setForm] = useLocalStorage("register-form-school", user);
  const dialog = useDialog();

  const formMethods = useForm<SchoolFormI>({
    resolver: yupResolver(
      formSchema.shape(
        user
          ? {
              ownerName: yup.string().required("Campo obrigatório"),
            }
          : {}
      )
    ),
    defaultValues:
      (user ? { ...user, logo: user?.owner?.avatar } : null) || form,
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = formMethods;

  const logo = watch("logo", user?.owner?.avatar || form?.logo || "");
  const photos = watch("photos", form?.photos || "");
  const ownerDocumentFile = watch(
    "ownerDocumentFile",
    user?.ownerDocumentFile || form?.ownerDocumentFile
  );
  const ownerBirthDate = watch(
    "ownerBirthDate",
    user?.ownerBirthDate ||
      form?.ownerBirthDate ||
      moment().format("YYYY-MM-DD")
  );

  const onSubmit = async (form: any) => {
    const action = isUpdate ? PerfilAction.UPDATE : PerfilAction.CREATE;

    if (!action) throw new Error("Ação não definida");

    await loader.run();

    const { data, error } = await schoolsService?.[action](form);

    await loader.stop();

    if (error) {
      return dialog.open({
        title: "Erro",
        type: "error",
        description:
          error.statusCode === 400
            ? error?.message
            : `Ocorreu um erro ao ${
                action === PerfilAction.CREATE ? "Criar" : "Alterar"
              } o perfil!`,
      });
    }

    await refreshUser();

    if (
      !error &&
      ((data?.record && data?.record?.id) || action === PerfilAction.UPDATE)
    ) {
      return dialog.open({
        title: "Sucesso",
        type: "success",
        description: "Perfil atualizado com sucesso!",
        onClose: () => navigate(routesName.schoolsDashboard),
      });
    }
  };

  const uploadLogoImage = async (files: File[] = []): Promise<void> => {
    const [file] = files;

    await loader.run();

    try {
      const { data: uploadUrl } = await mediasService.getUploadUrl({
        context: MediaContext["FORM-MEDIAS"],
        object: MediaObject.PERFIL,
        name: file.name,
      });

      const { data: url } = await mediasService.uploadByUrl(uploadUrl, file);

      setValue("logo", url, {
        shouldValidate: true,
      });
    } catch (err) {
      console.log(err);
    } finally {
      await loader.stop();
    }
  };

  const uploadManyImages = async (
    files: File[] | string[] = []
  ): Promise<void> => {
    try {
      const urls = await Promise.all(
        files.map(async (file) => {
          if (typeof file === "string") return file;

          try {
            await loader.run();

            const { data: uploadUrl } = await mediasService.getUploadUrl({
              context: MediaContext["FORM-MEDIAS"],
              object: MediaObject.ALBUMS,
              name: file.name,
              id: "school",
            });

            const { data: url } = await mediasService.uploadByUrl(
              uploadUrl,
              file
            );

            return url;
          } catch (err) {
            return "";
          } finally {
            await loader.stop();
          }
        })
      );

      setValue("photos", urls, {
        shouldValidate: true,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const uploadDocument = async (file: File | null) => {
    try {
      if (!file) return;

      const { data: uploadUrl } = await mediasService.getUploadUrl({
        context: MediaContext["DOCUMENTS"],
        object: MediaObject.PERSON,
        name: file.name,
      });

      const { data: url } = await mediasService.uploadByUrl(uploadUrl, file);

      setValue("ownerDocumentFile", url);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      setForm(value);
    });

    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch, setForm]);

  return (
    <FormProvider {...formMethods}>
      <Box w="100vw">
        {user && user?.id ? (
          <Header />
        ) : (
          <SimpleHeader title="Conclusão da conta" />
        )}

        <Box
          paddingX={isMobile ? "16px" : "80px"}
          paddingY={isMobile ? 6 : 8}
          w="100%"
          as="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Heading fontSize="xl" color="neutral.darkest">
            {user && user?.id ? "Minha conta" : "Perfil da escola"}
          </Heading>

          <Heading fontSize="lg" color="neutral.darkest" mt={8}>
            Dados da escola
          </Heading>

          <TextField
            label="Nome da escola"
            placeholder=" "
            mt={SPACE}
            fullWidth
            w={MIN_WIDTH}
            inputProps={register("name")}
            error={errors.name?.message}
          />
          <TextField
            label="CNPJ"
            mt={SPACE}
            placeholder=" "
            fullWidth
            w={MIN_WIDTH}
            inputProps={register("businessId")}
            error={errors.businessId?.message}
            isDisabled={!!user?.businessId}
          />
          <TextField
            label="CREF"
            mt={SPACE}
            placeholder=" "
            fullWidth
            w={MIN_WIDTH}
            inputProps={register("autarchismId")}
            error={errors.autarchismId?.message}
            isDisabled={!!user?.autarchismId}
          />
          <TextField
            type="text-area"
            label="Sobre escola"
            helperText="Descreva sua escola de surfe, como ela funciona em quais praias opera..."
            placeholder=" "
            mt={SPACE}
            fullWidth
            w={MIN_WIDTH}
            inputProps={register("description")}
            error={errors.description?.message}
          />

          <Divider my={SPACE} w={MIN_WIDTH} />

          <Heading fontSize="lg" color="neutral.darkest">
            Dados do responsável
          </Heading>

          {user?.owner?.name && (
            <TextField
              label="Nome do responsável"
              mt={SPACE}
              placeholder=" "
              fullWidth
              w={MIN_WIDTH}
              inputProps={register("ownerName", {
                value: user?.owner?.name,
              })}
              error={errors.ownerName?.message}
            />
          )}

          <DatePicker
            label="Data de nascimento"
            value={moment(ownerBirthDate).startOf("day").toDate() as Date}
            inputProps={register("ownerBirthDate")}
            error={errors?.ownerBirthDate?.message}
            onChange={(date: Date) =>
              setValue("ownerBirthDate", moment(date).format("DD-MM-YYYY"))
            }
            datePickerProps={{
              dateFormat: "dd/MM/yyyy",
            }}
            w={MIN_WIDTH}
            mt={SPACE}
          />

          <TextField
            label="Numero de documento"
            mt={SPACE}
            placeholder="000.000.000-00"
            fullWidth
            w={MIN_WIDTH}
            inputProps={register("ownerDocumentNumber")}
            error={errors.ownerDocumentNumber?.message}
          />

          <SelectDocument
            w={MIN_WIDTH}
            mt={SPACE}
            file={ownerDocumentFile as unknown as File}
            onChange={uploadDocument}
          />

          {errors.ownerDocumentFile?.message && (
            <Text color="feedback.danger.medium" fontSize="xs" mt={2}>
              {errors.ownerDocumentFile?.message as string}
            </Text>
          )}

          <Divider my={SPACE} mt={SPACE + 4} w={MIN_WIDTH} />

          <Heading fontSize="lg" color="neutral.darkest">
            Dados bancários
          </Heading>

          <TextField
            label="Numero do banco e agência"
            placeholder="000-0000"
            mt={SPACE}
            fullWidth
            w={MIN_WIDTH}
            inputProps={register("bankAccount.routingNumber")}
            error={errors?.bankAccount?.routingNumber?.message}
          />

          <TextField
            label="Numero da conta"
            placeholder="0000000"
            mt={SPACE}
            fullWidth
            w={MIN_WIDTH}
            inputProps={register("bankAccount.number")}
            error={errors?.bankAccount?.number?.message}
          />

          <Divider my={SPACE} w={MIN_WIDTH} mt={SPACE + 4} />

          <Heading fontSize="lg" color="neutral.darkest">
            Localização
          </Heading>

          <FormLocation space={SPACE} w={MIN_WIDTH} user={user} />

          <Text
            fontSize="sm"
            color="neutral.darkest"
            fontWeight="bold"
            mt={SPACE}
          >
            Como é a praia?
          </Text>

          <SimpleGrid
            columns={isMobile ? 1 : 2}
            spacing={4}
            width={MIN_WIDTH}
            mt={SPACE}
          >
            {[
              "Tem restaurante por perto",
              "Bom para iniciante",
              "Tem estacionamento",
              "Surf avançado",
              "Mar calmo",
              "Praia badalada",
              "Boas ondas",
              "Paradisíaca",
            ].map((value) => (
              <Checkbox key={value}>{value}</Checkbox>
            ))}
          </SimpleGrid>

          <TextField
            label="Outro"
            placeholder=" "
            mt={SPACE}
            fullWidth
            w={MIN_WIDTH}
          />

          <Divider my={SPACE} w={MIN_WIDTH} />

          <Heading fontSize="lg" color="neutral.darkest">
            Logo da escola
          </Heading>

          <Flex mt={SPACE}>
            <Flex
              w="140px"
              h="140px"
              bg="brand.lightest"
              borderRadius="full"
              justify="center"
              align="center"
            >
              {logo ? (
                <Image src={logo} w="140px" h="140px" borderRadius="full" />
              ) : (
                <StarIcon size={64} color={colorBrandDark} />
              )}
            </Flex>

            <Flex
              ml={4}
              alignItems="flex-start"
              direction="column"
              justifyContent="center"
            >
              <Text>Logo da escola</Text>
              <UploadButton onUpload={uploadLogoImage}>
                <Link>Alterar image</Link>
              </UploadButton>
            </Flex>
          </Flex>

          {errors.logo?.message && (
            <Text color="feedback.danger.medium" fontSize="xs" mt={2}>
              {errors.logo?.message as string}
            </Text>
          )}

          <Divider my={SPACE} w={MIN_WIDTH} />

          <Heading fontSize="lg" color="neutral.darkest">
            Galeria de imagens
          </Heading>

          <Galery
            images={(photos || [])?.filter((x: any) => !!x)}
            onChange={(files) => uploadManyImages(files)}
          />

          <Divider my={SPACE * 2} />

          <CardFeedbackInfo
            title="Important"
            message="Após confirmação o iSurf irá analisar sua inscrição e retornará
              com a conclusão da conta em até 2 dias úteis."
            mt={10}
          />

          <Button
            variant="solid"
            size="lg"
            mt={8}
            type="submit"
            w={isMobile ? "100%" : "min-content"}
          >
            Salvar
          </Button>
        </Box>
      </Box>
    </FormProvider>
  );
}
