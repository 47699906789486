import DatePickerComponent, { ReactDatePickerProps } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./custom.css";
import DatePickerDay from "./day";
import DatePickerHeader from "./header";

interface DatePickerPropsI extends ReactDatePickerProps {
  current?: Date;
}

export default function DatePicker({
  current,
  onChange,
  ...props
}: DatePickerPropsI) {
  return (
    <DatePickerComponent
      selected={current}
      inline
      onChange={onChange}
      renderDayContents={DatePickerDay}
      renderCustomHeader={DatePickerHeader}
      {...props}
    />
  );
}
