export default function WaveIcon({
  width = 460,
  height = 312,
  color = "#D0E1E6",
  ...props
}: {
  height?: number | string;
  width?: number | string;
  color?: string;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 459 312"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M453.139 52.0775H459V34.6248H445.679C429.595 33.6815 421.011 44.5143 412.065 55.8032C407.15 62.0058 402.126 68.3461 395.688 72.9464C392.231 75.541 387.909 73.8113 384.451 72.0816C379.506 68.4663 374.728 64.558 369.983 60.676L369.983 60.6757C353.16 46.9131 336.74 33.481 314.699 34.6075H310.455C288.846 34.1148 272.487 22.7872 255.32 10.9001C252.296 8.80639 249.247 6.69534 246.14 4.62309C229.097 -7.1817 219.51 5.87915 210.217 18.5389L210.217 18.5391C207.255 22.5741 204.324 26.5684 201.19 29.7038C195.139 34.0281 188.223 34.8929 181.498 34.6075H154.795C135.548 33.4724 122.885 47.1187 110.791 60.1507C107.497 63.7004 104.246 67.2045 100.915 70.3518C95.728 74.6761 88.8125 77.2707 82.7614 72.0816C78.6881 68.2327 74.9109 63.7911 71.1268 59.3413C60.4558 46.7932 49.7293 34.1798 32.1485 34.6075H0V52.0775H32.1485C50.9002 50.8041 61.8542 65.0103 72.4162 78.7081L72.4163 78.7082C75.2684 82.407 78.0918 86.0687 81.0325 89.3786C85.3547 93.7029 90.5414 93.7029 95.728 91.1083C102.142 86.6441 107.837 80.9197 113.489 75.2382L113.489 75.2381C125.356 63.3086 137.035 51.5678 154.795 52.0775H190.375C205.874 53.425 213.273 41.7551 220.528 30.3134C223.191 26.1134 225.834 21.9441 228.852 18.4607C234.903 12.4068 243.547 16.731 250.463 20.1904C255.557 23.376 260.495 26.9266 265.422 30.4692C280.56 41.3534 295.592 52.1623 314.734 52.0775H325.971C343.666 50.961 355.737 62.5695 368.058 74.4182C373.584 79.7321 379.159 85.0942 385.315 89.3786C389.637 91.9732 393.96 93.7029 398.282 91.1083C404.095 86.6768 409.022 80.8262 413.928 75.0013C424.339 62.6377 434.653 50.3903 453.139 52.0775Z"
        fill={color}
      />
      <path
        d="M457.496 83.5237H459L458.965 66.071H453.139C435.418 66.0375 424.162 79.008 413.157 91.69C409.957 95.3771 406.779 99.0399 403.468 102.352C400.011 105.811 393.095 107.541 388.773 104.946C382.598 100.453 377.153 94.8664 371.767 89.3414L371.767 89.3412C360.566 77.8496 349.626 66.6259 332.93 66.071C331.789 66.0278 330.631 66.0278 329.472 66.0278H314.699C297.139 66.0278 284.422 56.1981 271.517 46.2232C264.532 40.8239 257.491 35.3822 249.598 31.4337C246.14 29.704 242.683 27.9743 239.225 29.704C232.195 33.2208 227.452 39.8836 222.708 46.5465C215.787 56.2694 208.865 65.9926 194.836 65.9413C193.349 66.0278 191.862 66.0278 190.375 66.0278H154.795C136.698 65.4499 125.29 76.843 113.522 88.5954L113.522 88.5955C107.679 94.4309 101.747 100.355 94.8636 104.946C89.6769 107.541 84.4903 107.541 80.1681 103.216C76.3434 99.3899 72.8115 94.9776 69.2745 90.5588C59.2211 77.9995 49.1261 65.388 32.1485 66.0278H0V83.5237H26.8581C28.5524 83.5237 30.3159 83.5237 32.1399 83.567C46.9631 83.4354 55.0083 93.5881 63.1473 103.859C69.988 112.492 76.8949 121.208 87.9481 123.973C93.1347 124.838 97.4569 120.513 100.915 117.054C103.532 114.581 106.125 111.961 108.74 109.319C121.671 96.2524 135.144 82.6389 154.795 83.5237H190.375C191.862 83.5237 193.349 83.5151 194.836 83.4632C204.647 83.3248 212.427 79.0006 219.343 72.9466C222.679 70.1227 225.633 65.9272 228.615 61.6945C235.676 51.6679 242.885 41.4331 255.649 48.7307C262.137 52.6819 267.981 57.7381 273.79 62.7647C285.783 73.1418 297.63 83.3926 314.699 83.5324H332.947C349.907 82.3288 360.668 93.5563 371.568 104.929C378.213 111.863 384.91 118.85 393.095 123.108C395.688 123.973 399.146 123.108 401.74 121.378C408.01 116.897 413.146 111.074 418.199 105.346C427.773 94.4918 437.047 83.9776 453.174 83.5756C455.049 83.5237 456.51 83.5237 457.496 83.5237Z"
        fill={color}
      />
      <path
        d="M459 114.979H457.461C439.606 113.974 428.79 126.455 417.921 138.996C413.815 143.735 409.701 148.481 405.197 152.513C402.604 155.108 398.282 156.837 394.824 155.108C385.802 151.289 378.73 143.707 371.676 136.145C361.157 124.867 350.677 113.633 333.829 114.979H311.708C310.723 114.857 309.731 114.763 308.734 114.67C305.485 114.364 302.178 114.053 298.871 112.73C290.296 109.373 283.972 101.994 277.888 94.8947C269.866 85.5341 262.261 76.6603 250.463 78.1358C239.777 79.3237 231.99 87.5816 224.064 95.9876C214.678 105.942 205.096 116.104 190.272 114.979H149.989C132.085 113.513 120.709 125.5 109.211 137.617C103.072 144.086 96.898 150.592 89.6769 155.108C88.2609 155.607 86.7524 155.789 85.2584 155.639C83.7644 155.49 82.3216 155.013 81.0325 154.243C74.3763 149.702 68.8848 143.361 63.4586 137.094C53.3815 125.457 43.5291 114.079 26.8581 114.979H0V97.4999H26.8581C44.6549 96.6366 55.021 108.88 65.533 121.296C69.6598 126.17 73.8091 131.071 78.4392 135.216C81.0325 136.946 85.3547 139.54 87.9481 137.811C97.6723 133.847 105.296 126.28 112.867 118.765C123.469 108.243 133.968 97.822 149.989 97.5344H190.237C191.741 97.5344 193.245 97.5344 194.75 97.4826C211.153 96.5991 221.573 84.8349 231.905 73.1708L231.905 73.1706L231.906 73.1704L231.906 73.17C235.43 69.1909 238.945 65.2234 242.683 61.7036C246.14 58.2442 250.463 59.1091 253.92 60.8388C263.051 64.1272 270.175 71.1206 277.308 78.1222C287.058 87.6931 296.824 97.2795 311.751 97.4393C313.186 97.4999 314.613 97.4999 316.074 97.4999H333.872C351.904 97.2531 363.683 109.686 375.478 122.135C379.805 126.702 384.133 131.27 388.773 135.216C394.824 140.405 402.604 136.946 407.791 131.757C409.312 130.044 410.823 128.301 412.337 126.553C424.594 112.407 437.104 97.9694 457.461 97.4999H459V114.979Z"
        fill={color}
      />
      <path
        d="M457.677 146.459H459V128.954H457.677C440.745 127.525 431.162 138.737 421.372 150.191C414.814 157.865 408.163 165.647 399.146 169.81C396.553 170.675 393.96 169.81 391.366 168.945C384.789 164.159 379.349 158.029 374.013 152.015C363.92 140.641 354.199 129.686 337.857 129.041C336.25 128.954 334.633 128.954 333.008 128.954H313.126C311.7 128.954 310.256 128.937 308.804 128.885C293.664 127.46 284.891 117.76 275.961 107.886C270.364 101.697 264.705 95.44 257.378 91.1085C253.92 88.514 249.598 87.6491 246.14 90.2437C239.983 94.7241 234.55 100.293 229.179 105.798C217.961 117.297 207.01 128.521 190.28 128.989H145.226C129.352 129.098 119.695 139.698 109.79 150.571C103.912 157.023 97.9458 163.571 90.5414 168.08C87.9481 168.945 85.3547 169.81 82.7614 168.945C74.7192 164.587 68.4956 157.5 62.3447 150.495C52.6282 139.43 43.0933 128.572 26.8581 129.006C25.1638 128.954 23.5387 128.954 21.9913 128.954H0V146.424H21.9913C23.5387 146.424 25.1293 146.433 26.8581 146.52C45.2648 147.214 56.8001 160.839 67.9507 174.01L67.9512 174.01C69.7194 176.099 71.4779 178.176 73.2526 180.188C78.4392 185.377 87.0836 188.837 93.9991 183.648C98.2039 179.908 102.156 175.474 106.15 170.993C116.937 158.892 128.029 146.447 145.226 146.416H185.716C203.664 146.449 216.514 134.091 228.995 122.088L228.995 122.088C232.685 118.539 236.342 115.022 240.089 111.865C254.401 99.8375 263.405 111.705 272.625 123.857C277.327 130.055 282.086 136.327 287.634 139.54C288.821 140.134 289.982 140.753 291.134 141.367C296.69 144.33 302.011 147.167 308.821 146.407H333.025C333.839 146.407 334.651 146.42 335.461 146.433L335.463 146.433C336.269 146.446 337.073 146.459 337.875 146.459C354.042 147.619 364.421 158.899 374.982 170.376C380.164 176.008 385.391 181.687 391.366 186.242C393.96 187.107 396.553 187.972 399.146 187.107C408.188 182.932 414.991 175.118 421.678 167.438C431.614 156.026 441.293 144.908 457.677 146.459Z"
        fill={color}
      />
      <path
        d="M459 177.87H457.626C440.762 178.285 430.202 190.031 419.374 202.076C414.854 207.103 410.288 212.183 405.197 216.512C401.74 219.107 397.417 220.836 393.095 218.242C385.516 213.626 379.569 206.873 373.7 200.208C364.173 189.39 354.85 178.802 339.076 177.957C337.443 177.879 335.809 177.87 334.158 177.87H304.412C287.12 179.964 277.836 168.331 268.34 156.431C263.401 150.241 258.403 143.979 252.192 139.54C248.196 137.142 244.201 139.177 240.888 140.865C240.617 141.003 240.351 141.139 240.089 141.27C233.673 145.456 227.977 150.724 222.329 155.947C210.474 166.909 198.83 177.677 181.178 177.853H145.226C125.443 179.134 113.71 193.749 102.438 207.788L102.438 207.788L102.437 207.789C100.775 209.859 99.1232 211.917 97.4569 213.917C93.1347 218.242 85.3547 219.971 79.3037 215.647C74.4582 211.526 70.0201 206.862 65.6341 202.253C54.3708 190.416 43.4512 178.94 26.8581 177.957C25.1293 177.879 23.5387 177.87 21.9913 177.87H0V160.4H21.9913C23.5387 160.4 25.1638 160.4 26.8581 160.487C32.7677 161.179 38.5171 162.869 43.8616 165.486C50.4952 168.646 56.0899 175.386 61.5737 181.993C71.0967 193.466 80.2852 204.537 93.9991 195.755C100.565 191.689 106 186.039 111.378 180.447C120.87 170.579 130.184 160.896 145.226 160.426H181.178C182.691 160.426 184.203 160.426 185.725 160.366C203.698 159.071 215.9 147.51 228.417 135.649C233.293 131.03 238.216 126.364 243.547 122.243C246.14 120.513 248.734 119.649 251.327 120.513C259.997 124.128 266.101 131.821 272.189 139.493C280.667 150.179 289.114 160.825 304.421 160.357C305.881 160.4 307.342 160.4 308.794 160.4H334.158C343.822 159.432 352.466 163.756 361.111 168.945C366.124 172.19 370.533 178.052 374.923 183.887C383.4 195.158 391.803 206.328 404.333 198.35C411.216 193.968 416.854 187.773 422.42 181.656C432.232 170.873 441.823 160.334 457.626 160.4H459V177.87Z"
        fill={color}
      />
      <path
        d="M457.747 209.325H459V191.864H457.582C440.205 192.19 429.048 204.619 417.919 217.017C414.322 221.024 410.727 225.028 406.926 228.62C404.566 231.026 401.385 232.451 398.019 232.612C394.653 232.772 391.351 231.655 388.773 229.485C384.473 225.635 380.409 221.134 376.324 216.608C364.807 203.848 353.117 190.897 335.454 191.829H300.004C284.076 193.164 275.594 182.502 266.926 171.604C261.334 164.574 255.665 157.447 247.869 153.378C246.14 152.513 243.547 153.378 240.954 154.243C233.858 158.502 227.415 164.16 221 169.793C207.878 181.315 194.877 192.732 176.657 191.855H145.226C137.221 192.296 131.17 194.026 124.255 198.35C119.554 201.746 115.169 207.51 110.743 213.329C100.515 226.773 90.0641 240.512 74.9815 226.025C72.269 223.312 69.5899 220.365 66.8744 217.379C55.7431 205.136 44.0016 192.223 26.8581 191.924C25.1638 191.846 23.5387 191.846 21.9913 191.846H0V209.36H26.8581C44.1064 209.522 55.2124 221.72 66.1992 233.786C70.999 239.058 75.776 244.304 81.0325 248.511C87.9481 253.701 94.8636 250.241 100.05 245.052C101.827 242.893 103.605 240.622 105.41 238.315C115.898 224.913 127.335 210.298 145.234 209.368C146.808 209.316 148.398 209.316 149.989 209.316H172.162C189.954 210.038 202.716 199.258 215.587 188.386C223.09 182.049 230.63 175.68 239.225 171.54C240.843 171.153 242.524 171.107 244.161 171.405C245.798 171.703 247.355 172.338 248.734 173.269C252.787 177.086 256.446 181.495 260.094 185.891C269.673 197.434 279.182 208.893 295.578 209.325H334.175C335.748 209.325 337.322 209.325 338.878 209.377C354.711 209.51 364.398 220.402 374.155 231.373C380.23 238.202 386.331 245.062 393.96 249.376C396.553 250.241 399.146 249.376 401.74 248.511C409.697 244.199 415.873 237.216 421.966 230.327C431.76 219.253 441.339 208.424 457.747 209.325Z"
        fill={color}
      />
      <path
        d="M459 240.771H457.695C449.345 240.697 441.21 243.422 434.588 248.512C429.422 252.16 425.217 257.306 421.11 262.332C413.534 271.601 406.291 280.464 393.96 278.782C386.057 278.217 381.103 271.382 375.969 264.299L375.968 264.299C373.24 260.536 370.461 256.702 367.162 253.701C359.382 246.782 350.738 241.593 339.215 240.832C337.624 240.78 336.025 240.771 334.417 240.771H289.933C272.958 241.969 263.241 230.11 253.465 218.179C249.471 213.305 245.467 208.418 240.954 204.404C238.36 202.675 234.038 202.675 231.445 203.539C223.3 207.765 215.997 213.676 208.744 219.546C195.214 230.497 181.858 241.306 163.535 240.771H149.989C132.123 240.07 120.321 253.829 109.106 266.905C105.795 270.765 102.535 274.565 99.1858 277.917C93.9991 282.241 86.2192 284.835 80.1681 278.782C76.723 275.335 73.405 271.506 70.0577 267.644C58.287 254.063 46.1543 240.064 26.8581 240.771H0V223.301H26.8581C45.3351 223.409 58.0164 237.818 69.7279 251.126L69.7284 251.126C72.0818 253.8 74.3961 256.43 76.7104 258.89C81.897 264.079 91.4058 267.538 97.4569 261.484C101.149 257.79 104.647 253.609 108.167 249.401C119.48 235.877 131.021 222.081 149.989 223.301H167.943C187.047 223.392 200.409 212.323 214.161 200.932C219.985 196.107 225.88 191.224 232.309 187.107C234.408 185.763 236.879 185.122 239.366 185.278C241.853 185.433 244.226 186.377 246.14 187.972C250.613 191.95 254.586 196.714 258.527 201.439L258.527 201.439C268.294 213.151 277.861 224.624 294.35 223.293H337.883C354.445 223.391 365.35 235.291 376.199 247.129C380.566 251.894 384.924 256.648 389.637 260.62C393.095 263.214 397.417 264.079 400.875 262.349C408.822 258.043 415.247 251.198 421.596 244.434C431.823 233.54 441.853 222.854 457.729 223.319H459V240.771Z"
        fill={color}
      />
      <path
        d="M457.755 272.217H459V254.756H457.651C441.378 255.232 430.561 265.707 419.529 276.39C414.927 280.847 410.287 285.341 405.197 289.16C391.213 299.793 383.02 288.698 374.529 277.199C369.898 270.928 365.178 264.537 359.382 261.484C358.072 260.902 356.786 260.294 355.511 259.693C349.217 256.721 343.196 253.877 335.835 254.747H285.507C269.074 257.01 259.866 245.349 250.621 233.642C246.472 228.388 242.316 223.125 237.496 219.107C234.903 216.512 229.716 215.647 226.258 218.242C219.125 222.359 212.513 227.522 205.936 232.659C191.792 243.705 177.805 254.629 159.117 254.764H149.989C133.994 253.605 124.18 264.713 114.197 276.011C107.578 283.502 100.886 291.076 92.2703 295.214C90.8543 295.713 89.3457 295.895 87.8517 295.745C86.3577 295.596 84.9149 295.119 83.6259 294.349C77.7281 289.789 72.4954 284.148 67.3175 278.566C55.7991 266.15 44.5521 254.025 26.8581 254.747H0V272.217H26.8581C47.0446 271.188 60.0459 285.811 72.4456 299.758C74.7383 302.337 77.0105 304.892 79.3037 307.322C84.4903 312.511 92.2703 313.375 98.3213 308.186C102.421 304.516 106.252 300.469 110.052 296.454C121.475 284.384 132.624 272.604 149.989 272.234C150.258 272.232 150.527 272.229 150.796 272.226C152.129 272.213 153.468 272.2 154.812 272.157C171.441 271.474 183.974 261.587 196.592 251.633C205.39 244.691 214.23 237.718 224.529 233.809C230.581 232.079 234.903 235.539 237.496 238.998C238.506 240.56 239.516 242.171 240.539 243.802C249.144 257.523 258.646 272.673 276.586 272.217H335.178C336.742 272.217 338.298 272.243 339.846 272.304C355.948 272.625 365.309 284.603 374.535 296.409C377.494 300.194 380.438 303.962 383.586 307.322C387.909 311.646 393.095 313.375 398.282 310.781C405.477 306.282 411.737 300.326 417.919 294.444C429.562 283.366 440.929 272.55 457.755 272.217Z"
        fill={color}
      />
    </svg>
  );
}
