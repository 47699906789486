import { ComponentStyleConfig } from "@chakra-ui/react";

const FormLabelTheme: ComponentStyleConfig = {
  baseStyle: {
    color: "neutral.darkest",
    fontSize: "xs",
    fontWeight: "semibold",
    "&[data-invalid]": {
      color: "feedback.danger.dark",
    },
  },
  sizes: {},
  variants: {},
  defaultProps: {},
};

export default FormLabelTheme;
