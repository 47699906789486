import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Button, Flex, IconButton } from "@chakra-ui/react";

import SearchIcon from "../../assets/icons/search";
import UserIcon from "../../assets/icons/user";
import routesName from "../../config/routes";
import { useResposiveness } from "../../hooks/responsiveness";
import { useSession } from "../../hooks/session";


export default function HeaderHomeMenu({
  onSearch,
  logged = false,
}: {
  onSearch?: any;
  logged?: boolean;
}) {
  const { isMobile } = useResposiveness();
  const { openSignIn, openSignUp } = useSession();
  const navigate = useNavigate();
  const location = useLocation();

  const isHomes =
    location.pathname === routesName.main ||
    location.pathname === routesName.schools;

  return (
    <React.Fragment>
      {!isMobile && isHomes && (
        <Flex direction="row">
          <Button
            variant="unstyled"
            size="md"
            onClick={() => {
              if (location.pathname === routesName.main)
                return navigate(routesName.schools);
              if (location.pathname === routesName.schools)
                return navigate(routesName.main);
            }}
          >
            {location.pathname === routesName.schools
              ? "Procurar aulas"
              : "Oferecer aulas"}
          </Button>
          <Button variant="unstyled" size="md" mr={4} onClick={openSignIn}>
            Login
          </Button>
          <Button size="md" onClick={openSignUp}>
            Cadastre-se
          </Button>
        </Flex>
      )}

      {isMobile && (
        <Flex>
          {onSearch && (
            <IconButton
              variant="unstyled"
              aria-label="search-icon"
              icon={<SearchIcon />}
              mr={4}
              onClick={onSearch}
            />
          )}

          {isHomes && (
            <IconButton
              variant="unstyled"
              aria-label="user-icon"
              icon={<UserIcon />}
              onClick={openSignIn}
            />
          )}
        </Flex>
      )}
    </React.Fragment>
  );
}
