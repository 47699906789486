import moment from "moment-timezone";
import { useFormContext } from "react-hook-form";

import { Button, Flex, Heading, Text } from "@chakra-ui/react";

import { SEARCH_FORM_FIELD_MOBILE } from "..";

import DatePicker from "../../../../date-picker";

interface MobileSearchModalityProps {
  onSubmit?: () => void;
}

export default function MobileSearchDate({
  onSubmit,
}: MobileSearchModalityProps) {
  const { setValue, watch } = useFormContext();

  const date = watch(SEARCH_FORM_FIELD_MOBILE.DATE, moment().toISOString());

  return (
    <Flex direction="column" w="100%" flex={1} mb="15%" p={4}>
      <Heading fontSize={["sm", "md"]} color="neutral.dark">
        Data
      </Heading>

      <Flex
        direction="column"
        border="hairline"
        justify="center"
        align="center"
        py={4}
        borderRadius={4}
        borderColor="neutral.medium"
        mt={3}
      >
        <DatePicker
          current={moment(date).toDate()}
          onChange={(date: Date) =>
            setValue(
              SEARCH_FORM_FIELD_MOBILE.DATE,
              moment(date).startOf("day").toISOString()
            )
          }
        />

        <Text fontSize="xs" color="neutral.dark" mt={3} px={4}>
          Selecione uma data para que possamos te ajudar a encontrar a melhor
          aula para você.
        </Text>
      </Flex>

      <Button size="md" w="100%" mt="auto" onClick={onSubmit}>
        Concluir
      </Button>
    </Flex>
  );
}
