import React from "react";

import {
  Divider,
  Modal,
  ModalContent,
  ModalOverlay,
  useMediaQuery,
} from "@chakra-ui/react";

import { SESSION_TYPE } from "../../@types/session";
import { useSession } from "../../hooks/session";
import SessingHeader from "./header";
import SessionSignIn from "./signin";
import SessionSignUp from "./signup";
import CellphoneVerification from "./verifications/cellphone";
import EmailVerification from "./verifications/email";

export default function Session() {
  const { type, isOpen, close } = useSession();
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  return (
    <Modal isOpen={isOpen} onClose={close} size={isMobile ? "full" : "2xl"}>
      <ModalOverlay />
      <ModalContent
        padding={isMobile ? "16px" : "32px"}
        max-width="550px"
        overflowY={isMobile ? "scroll" : "auto"}
      >
        <SessingHeader />

        {isMobile && <Divider mx="-16px" width="100vw" mt={5} />}

        {type === SESSION_TYPE.SIGN_IN && <SessionSignIn />}
        {type === SESSION_TYPE.SIGN_UP && <SessionSignUp />}
        {type === SESSION_TYPE.VERIFY_EMAIL && <EmailVerification />}
        {type === SESSION_TYPE.VERIFY_PHONE && <CellphoneVerification />}
      </ModalContent>
    </Modal>
  );
}
