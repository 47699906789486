import moment from "moment-timezone";
import { useEffect, useMemo, useState } from "react";

import {
  Box,
  Flex,
  IconButton,
  Input,
  ScaleFade,
  Text,
} from "@chakra-ui/react";

import { Schedule } from ".";
import CheckIcon from "../../../../../../assets/icons/check";
import TrashIcon from "../../../../../../assets/icons/trash";

const TIMEZONE = "America/Sao_Paulo";

interface AppointmentPropsI {
  _id?: any;
  start: any;
  end: any;
  onUpdate?: (schedule: Schedule) => void;
  onRemove?: () => void;
  duration: number;
}

export default function Appointment({
  _id,
  start,
  end,
  onUpdate,
  onRemove,
  duration,
}: AppointmentPropsI) {
  const [state, setState] = useState<Schedule>({} as Schedule);
  const endValue = useMemo(() => {
    if (start && end) return moment(end).tz(TIMEZONE).format("HH:mm");
    if (state.start && state.end)
      return moment(state.end).tz(TIMEZONE).format("HH:mm");
    return "--:--";
  }, [start, end, state.start, state.end]);

  const formatStartValue = (value: string) => {
    if (value) {
      const hour = Number(value.split(":")[0]);
      const minutes = Number(value.split(":")[1]);

      return moment()
        .tz(TIMEZONE)
        .set("hour", hour)
        .set("minute", minutes)
        .set("second", 0)
        .set("millisecond", 0)
        .toISOString();
    }

    return start;
  };

  const handleUpdate = (field: string, value: any) => {
    setState({ ...state, [field]: value });
  };

  const handleUpdated = () => {
    if (onUpdate)
      onUpdate({
        start: state.start,
        end: state.end,
        _id,
      });

    setState({} as any);
  };

  useEffect(() => {
    const _end = moment(state.start).add(duration, "minutes").toISOString();

    setState({ ...state, end: _end });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.start, start, end, duration]);

  return (
    <ScaleFade initialScale={-0.34} in={true}>
      <Flex
        p={4}
        border="hairline"
        borderColor="neutral.medium"
        borderRadius="sm"
      >
        <Box flex={1}>
          {start ? (
            <Text fontSize="sm" fontWeight="semibold" color="neutral.darkest">
              {moment(start).tz(TIMEZONE).format("HH:mm")}
            </Text>
          ) : (
            <Input
              type="time"
              variant="unstyled"
              onChange={(e) =>
                handleUpdate("start", formatStartValue(e.target.value))
              }
              minH="min-content !important"
              py={1}
              autoFocus
              sx={{
                "&::-webkit-calendar-picker-indicator": {
                  display: "none",
                },
              }}
            />
          )}

          <Text fontSize="xs" fontWeight="medium" color="neutral.dark" whiteSpace="nowrap" >
            até {endValue}
          </Text>
        </Box>

        <Flex flex={1} justifyContent="flex-end" align="start">
          {!!_id ? (
            <IconButton
              variant="unstyled"
              aria-label="trash-icon"
              icon={<CheckIcon w={22} h={22} />}
              onClick={handleUpdated}
              mr="-2px"
            />
          ) : (
            <IconButton
              variant="unstyled"
              aria-label="trash-icon"
              icon={<TrashIcon h={7} w={7} />}
              onClick={onRemove}
            />
          )}
        </Flex>
      </Flex>
    </ScaleFade>
  );
}
