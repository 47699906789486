import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { Button, Flex, Heading, Text } from "@chakra-ui/react";

import PasswordStepsValidate from "../../../components/password-step";
import TextField from "../../../components/text-field";
import routesName from "../../../config/routes";
import { useDialog } from "../../../hooks/dialog";
import { useLoader } from "../../../hooks/loader";
import { useQuery } from "../../../hooks/query-param";
import { useResposiveness } from "../../../hooks/responsiveness";
import { authService } from "../../../services/api";

const formSchema = yup
  .object()
  .shape({
    password: yup.string().required(),
    passwordConfirmation: yup.string().required(),
  })
  .required();

export default function RecoveryConfirm() {
  const { isMobile } = useResposiveness();
  const { token } = useQuery<{ token: string }>().object;
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(formSchema) });
  const loader = useLoader();
  const dialog = useDialog();
  const navigate = useNavigate();

  const [error, setError] = useState<string | null>(null);
  const [isPassValid, setIsPassValid] = useState(false);

  const onSubmit = async ({ password, passwordConfirmation }: any) => {
    if (password !== passwordConfirmation) {
      return setError("As senhas não conferem!");
    }

    loader.run();

    const { data, error } = await authService.confirmRecoveyPassword(
      token,
      password
    );

    loader.stop();

    if (error) {
      setError(
        error.statusCode === 401
          ? "Token invalido! Vamos redirecionar você para a página de recuperação de senha. aguarde um momento..."
          : error.message
      );

      if (error.statusCode === 401) {
        setTimeout(() => {
          setError(null);
          navigate("/recovery-password");
        }, 3800);
      }
    }

    if (data) {
      return dialog.open({
        type: "success",
        title: "Senha alterada com sucesso!",
        description: "Você será redirecionado para a home.",
        closeLabel: "Ok",
        onClose: () => navigate(routesName.main),
      });
    }
  };

  if (!token) return null;

  const password = watch("password");
  const passwordConfirmation = watch("passwordConfirmation");

  return (
    <Flex
      as="form"
      direction="column"
      h="100%"
      justify="center"
      mt={isMobile ? -10 : -6}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Heading fontSize="lg" color="neutral.darkest">
        Alteração de senha
      </Heading>

      <Text
        mt={isMobile ? 6 : 10}
        w={isMobile ? "100%" : "80%"}
        color="neutral.dark"
      >
        Informe sua nova senha. Após informar uma nova senha, você será
        redirecionado para a home, onde poderá acessar sua conta com a nova
        senha.
      </Text>

      <TextField
        label="Nova Senha"
        type="password"
        fullWidth
        mt={6}
        inputProps={register("password")}
        error={errors?.password?.message}
      />

      <TextField
        label="Confirmação de senha"
        type="password"
        fullWidth
        mt={6}
        inputProps={register("passwordConfirmation")}
        error={errors?.passwordConfirmation?.message}
      />

      <Flex mt={2}>
        <PasswordStepsValidate
          password={password}
          passwordConfirm={passwordConfirmation}
          onValidate={setIsPassValid}
          validatePasswordConfirm
        />
      </Flex>

      {error && (
        <Text fontSize="xs" color="feedback.danger.dark" mt={4}>
          {error}
        </Text>
      )}

      <Button
        type="submit"
        size="md"
        mt={8}
        w={isMobile ? "100%" : "50%"}
        disabled={!isPassValid}
      >
        Alterar senha
      </Button>
    </Flex>
  );
}
