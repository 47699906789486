import Button from "./button";
import Checkbox from "./checkbox";
import Divider from "./divider";
import Form from "./form/form-control";
import FormError from "./form/form-error-message";
import FormLabel from "./form/form-label";
import Heading from "./heading";
import Input from "./input";
import Link from "./link";
import Modal from "./modal";
import PinInput from "./pin-input";
import Radio from "./radio";
import Select from "./select";
import Tabs from "./tabs";
import Tag from "./tag";
import Text from "./text";
import Textarea from "./text-area";

const components = {
  Button,
  Checkbox,
  Divider,
  Link,
  Input,
  FormLabel,
  FormError,
  Form,
  Modal,
  Select,
  Textarea,
  Radio,
  Text,
  Heading,
  PinInput,
  Tabs,
  Tag
};

export default components;
