import { Stack, StackProps, Tag, TagProps } from "@chakra-ui/react";

import { LessonModality } from "../pages/school/create-class/steppers/setup";

interface ListTagPropsI extends StackProps {
  tags: string[];
  direction?: "row" | "column";
  hanldeOnProps?: (tag: string, index: number) => TagProps;
  size?: TagProps["size"];
}

export default function ListTag({
  tags = [],
  direction = "row",
  hanldeOnProps = () => ({}),
  size,
  ...props
}: ListTagPropsI) {
  return (
    <Stack direction={direction} {...props}>
      {tags.map((tag, index) => (
        <Tag
          key={tag}
          size={size}
          fontSize="2xs"
          bg={
            Object.values(LessonModality).includes((tag as any)?.toUpperCase())
              ? "neutral.light"
              : "feedback.success.lightest"
          }
          color={
            Object.values(LessonModality).includes((tag as any)?.toUpperCase())
              ? "neutral.dark"
              : "feedback.success.dark"
          }
          {...hanldeOnProps(tag, index)}
        >
          {tag}
        </Tag>
      ))}
    </Stack>
  );
}
