import axios, { Axios } from "axios";

import { ILocation } from "@isurf-tech/types/domain";

import { States } from "../../@constants/locations/states";

interface IAPILocationResponse {
  cep: string;
  logradouro: string; // "Rua André Nichele",
  complemento: string; //"",
  bairro: string; //"Mato Grande",
  localidade: string; //"Canoas",
  uf: string; //"RS",
  ibge: string; //"4304606",
  gia: string; //"",
  ddd: string; //"51",
  siafi: string; //"8589"
}

class ApiLocation {
  api: Axios;

  constructor() {
    this.api = axios.create({
      baseURL: "https://viacep.com.br/ws",
      // "https://h-apigateway.conectagov.estaleiro.serpro.gov.br/api-cep/v1/consulta/cep",
    });
  }

  async getLocationByZIP(zipCode: string = ""): Promise<{
    data: ILocation | null;
    error: any;
  }> {
    let result = { data: null, error: null } as {
      data: ILocation | null;
      error: any;
    };

    try {
      const { data } = await this.api.get<IAPILocationResponse>(
        `/${zipCode}/json`
      );

      if (!data) result.data = null;
      else
        result.data = {
          city: data.localidade,
          address: data.logradouro,
          stateCode: data.uf,
          state: States[data.uf as keyof typeof States],
        } as ILocation;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result.error = error.response?.data;
      } else {
        result.error = error as any;
      }
    } finally {
      return result;
    }
  }
}

const apiLocation = new ApiLocation();
export default apiLocation;
