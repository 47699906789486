import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import {
  Divider,
  Flex,
  Heading,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Text,
  useBoolean,
} from "@chakra-ui/react";

import { IStudentRefundsCredits } from "@isurf-tech/types/domain";

import ArrowRight from "../assets/icons/arrow-right";
import SurfBoardIcon from "../assets/icons/sports/surfboard";
import { useResposiveness } from "../hooks/responsiveness";

interface RefoundsCreditsProps {
  refunds?: IStudentRefundsCredits[];
}

export default function RefoundsCredits({
  refunds = [],
}: RefoundsCreditsProps) {
  const navigate = useNavigate();
  const { isMobile } = useResposiveness();
  const [isEditing, setIsEditing] = useBoolean();

  const total = useMemo(() => {
    if (refunds.length === 0) {
      return 0;
    }

    return refunds.reduce((acc, refund) => {
      return acc + (refund?.amount || 0);
    }, 0);
  }, [refunds]);

  return (
    <Popover
      placement="bottom-end"
      isOpen={isEditing}
      onOpen={total > 0 ? setIsEditing.on : () => {}}
      onClose={setIsEditing.off}
      closeOnBlur={false}
      isLazy
      lazyBehavior="keepMounted"
    >
      <PopoverTrigger>
        <Flex
          border="hairline"
          borderColor="neutral.medium"
          borderRadius="sm"
          p={1}
          px={3}
          direction="row"
          align="center"
          justify="center"
          cursor={total > 0 ? "pointer" : "default"}
        >
          <SurfBoardIcon fontSize="24px" color="neutral.dark" />
          <Heading size="4xs" px={2.5} color="neutral.dark">
            x
          </Heading>
          <Text color="neutral.dark" fontSize="md">
            {`${total}`.padStart(2, "0") ?? "00"}
          </Text>
        </Flex>
      </PopoverTrigger>

      <PopoverContent w={isMobile ? "100vw" : "100%"}>
        <PopoverBody p={6}>
          <Heading fontSize="md">Creditos de aula</Heading>

          <Flex direction="column" mt={6}>
            {refunds.map((refund, index) => (
              <React.Fragment key={refund.id}>
                <Flex
                  my={2}
                  direction="row"
                  justify="space-between"
                  align="center"
                >
                  <Text color="neutral.dark" fontSize="sm" fontWeight="medium">
                    {` ${refund.amount.toString().padStart(2, "0") ?? "00"} x ${
                      refund.school.name
                    }`}
                  </Text>

                  <IconButton
                    variant="unstyled"
                    aria-label="close"
                    icon={<ArrowRight color="neutral.dark" mt={-1} />}
                    borderRadius="sm"
                    justifyContent="center"
                    onClick={() =>
                      refund?.school?.name &&
                      navigate(`/search?school=${refund.school.name}`)
                    }
                  />
                </Flex>

                {index !== refunds.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </Flex>
        </PopoverBody>

        <PopoverFooter p={6}>
          <Text fontSize="xs" color="neutral.dark">
            Voce tem um prazo de 90 dias para usar seus creditos a partir da
            data que foi creditado.
          </Text>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}
