import { Flex, FlexProps, Heading, Input, Text } from "@chakra-ui/react";

interface CardTimePropsI extends FlexProps {
  type: string;
  value: number;
  selected?: boolean;
  onChange?: (e: any) => any;
  inputProps?: any;
}

export default function CardTime({
  type,
  value,
  onChange,
  selected = false,
  inputProps,
  ...props
}: CardTimePropsI) {
  return (
    <Flex
      flex={1}
      as="button"
      justify="center"
      align="center"
      direction="column"
      border="hairline"
      borderColor="neutral.medium"
      p="16px"
      {...props}
      _hover={
        onChange
          ? {}
          : {
              bg: selected ? "" : "neutral.light",
            }
      }
      _focus={{
        outline: "2px solid",
        outlineColor: "brand.medium",
      }}
      {...(selected && {
        bg: "brand.dark",
        outline: "none",
        border: "none",
      })}
      w="100%"
    >
      {onChange ? (
        <Input
          type="number"
          variant="unstyled"
          minH="min-content !important"
          textAlign="center"
          fontSize="md"
          fontWeight="semibold"
          p="0px"
          mt="-5px"
          onChange={(e) => onChange?.(Number(e?.target?.value || 0) || 0)}
          value={value}
        />
      ) : (
        <Heading
          fontSize="md"
          {...(selected && {
            color: "neutral.light",
          })}
        >
          {value}
        </Heading>
      )}

      <Text
        fontSize="xs"
        color="neutral.dark"
        {...(selected && {
          color: "neutral.light",
        })}
      >
        {type}
      </Text>
    </Flex>
  );
}
