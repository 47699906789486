import moment from "moment-timezone";
import React, { useEffect, useMemo, useCallback, useState } from "react";

import { Box, Divider, Flex, IconButton, Text } from "@chakra-ui/react";

import { Schedules } from "../../../../../../@types/lessons";
import ChevronLeftIcon from "../../../../../../assets/icons/chevron-left";
import ChevronRightIcon from "../../../../../../assets/icons/chevron-right";
import { useResposiveness } from "../../../../../../hooks/responsiveness";
import AddAppointment from "./add-appointment";
import Appointment from "./appointment";
import AppointmentSuggestion from "./appointment-suggestion";
import { generateColumns } from "./utils";

export type Schedule = {
  start: string;
  end: string;
  _id?: any;
};

interface CreateClassCalendarPropsI {
  w: any;
  recurrence: boolean;
  schedules: Schedules;
  onChange: (schedules: Schedules) => void;
  duration: number;
}

const TIMEZONE = "America/Sao_Paulo";

export default function CreateClassCalendar({
  w,
  recurrence = true,
  schedules = {},
  onChange,
  duration = 60,
}: CreateClassCalendarPropsI) {
  const { isMobile } = useResposiveness();
  const [offsetDay, setOffSetDay] = useState(0);
  const columns = useMemo(
    () => generateColumns(recurrence, TIMEZONE, offsetDay),
    [recurrence, offsetDay]
  );

  useEffect(() => {
    const isRecurrence = Object.keys(schedules).some(
      (date) => Number(date) >= 0
    )
      ? true
      : false;

    if (recurrence !== isRecurrence) {
      onChange({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recurrence]);

  const push = useCallback(
    (day: any, startTime?: any, empty = false) => {
      const current =
        Number(day) >= 0
          ? moment().tz(TIMEZONE).startOf("day")
          : moment(day).tz(TIMEZONE).startOf("day");

      const id = recurrence ? day : current.toISOString();

      const start = moment(current)
        .tz(TIMEZONE)
        .startOf("day")
        .add(startTime, "hour");
      const end = moment(start).tz(TIMEZONE).add(duration, "minutes");

      const schedule = empty
        ? ({ _id: new Date().getTime().toString() } as Schedule)
        : { start: start.toISOString(), end: end.toISOString() };

      const schedulesUpdate = Object.assign({}, schedules);

      if (!schedulesUpdate[id]?.length) schedulesUpdate[id] = [];

      const update = {
        ...schedulesUpdate,
        [id]: [...schedulesUpdate[id], schedule],
      };

      if (onChange) onChange(update);
    },
    [recurrence, schedules, onChange, duration]
  );

  const pop = useCallback(
    (day: any, scheduleCancel: any) => {
      const update = {
        ...schedules,
        [day]: schedules[day].filter(
          (s: any) =>
            !moment(s.start).isSame(scheduleCancel.start) &&
            !moment(s.end).isSame(scheduleCancel.end)
        ),
      };

      if (onChange) onChange(update);
    },
    [schedules, onChange]
  );

  const update = useCallback(
    (day: any, schedule: Schedule) => {
      const start = moment(schedule.start).tz(TIMEZONE).toISOString();

      const end = moment(schedule.start)
        .tz(TIMEZONE)
        .add(duration, "minutes")
        .toISOString();

      const update = {
        ...schedules,
        [day]: [
          ...schedules[day].filter((s: any) => s._id !== schedule._id),
          {
            start,
            end,
          },
        ],
      };

      if (onChange) onChange(update);
    },
    [schedules, onChange, duration]
  );

  const sort = (prev: any, next: any) =>
    moment(prev.start).valueOf() - moment(next.start).valueOf();

  const handleCreateAppoitament = (day: any) => {
    const hasSample = schedules[day]?.some((s: any) => !!s._id);

    if (hasSample) return;

    push(day, null, true);
  };

  const handleCurrentDay = (target: "add" | "sub") => {
    if (recurrence) return;
    setOffSetDay(+offsetDay + (target === "add" ? 1 : -1));
  };

  return (
    <Flex flex={7}>
      <Flex pt="14px" maxW="60px" justify="center" w="100%">
        {!recurrence && !isMobile && (
          <IconButton
            disabled={offsetDay === 0}
            variant="unstyled"
            aria-label="arrow-left"
            icon={<ChevronLeftIcon />}
            alignItems="center"
            onClick={() => handleCurrentDay("sub")}
          />
        )}
      </Flex>

      <Flex p={4} direction="column" px={isMobile ? "16px" : 0} w={w}>
        <Flex>
          {columns.map((day, index) => (
            <Text
              key={index.toString()}
              flex={1}
              fontSize="sm"
              fontWeight="semibold"
              color="neutral.darkest"
              ml={index > 0 ? 4 : 0}
            >
              {day.label}
            </Text>
          ))}
        </Flex>
        <Divider my={4} />
        <Flex>
          {columns.map((day, colIndex) => (
            <Flex
              key={colIndex.toString()}
              flex={1}
              ml={colIndex > 0 ? 4 : 0}
              direction="column"
              minW={isMobile ? "40vw" : "min-content"}
            >
              {schedules[day.value]
                ?.sort(sort)
                .map((schedule: any, index: number) => (
                  <Box key={index.toString()} mt={index > 0 ? 2 : 0}>
                    <Appointment
                      {...schedule}
                      duration={duration}
                      onUpdate={(s: Schedule) => update(day.value, s)}
                      onRemove={() => pop(day.value, schedule)}
                    />
                  </Box>
                ))}

              <AddAppointment
                onClick={() => handleCreateAppoitament(day.value)}
              />

              {[8, 9, 10, 17]
                .map((hour) => moment().tz(TIMEZONE).set({ hour }).hour())
                .filter(
                  (s) =>
                    !schedules[day.value]?.find(
                      (schedule) =>
                        moment(schedule.start).tz(TIMEZONE).hour() === s
                    )
                )
                .map((suggestion, sugIndex) => (
                  <AppointmentSuggestion
                    key={(colIndex + sugIndex).toString()}
                    value={suggestion}
                    mt={isMobile ? 4 : 2}
                    onClick={() => push(day.value, suggestion)}
                  />
                ))}
            </Flex>
          ))}
        </Flex>
      </Flex>

      <Flex pt="14px" maxW="60px" w="100%">
        {!recurrence && !isMobile && (
          <IconButton
            disabled={recurrence}
            variant="unstyled"
            aria-label="arrow-left"
            icon={<ChevronRightIcon />}
            onClick={() => handleCurrentDay("add")}
          />
        )}
      </Flex>
    </Flex>
  );
}
