import { Divider, Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";

import { useDialog } from "../../hooks/dialog";
import { useResposiveness } from "../../hooks/responsiveness";
import DialogHeader from "./header";

export default function BaseDialog({ type, children }: any) {
  const { isMobile } = useResposiveness();
  const { props, isOpen, close } = useDialog();

  return (
    <Modal
      id={type}
      isOpen={isOpen}
      onClose={close}
      size={isMobile ? "full" : "2xl"}
    >
      <ModalOverlay />
      <ModalContent
        padding={isMobile ? "16px" : "32px"}
        max-width="550px"
        overflowY={isMobile ? "scroll" : "auto"}
      >
        {isMobile && <DialogHeader title={props?.title} close={close} />}
        {isMobile && <Divider mx="-16px" width="100vw" mt={5} />}

        {children}
      </ModalContent>
    </Modal>
  );
}
