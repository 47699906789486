import { useContext } from "react";

import { LessonsContext } from "../contexts/lessons";

export function useLessons() {
  const context = useContext(LessonsContext);

  if (!context) throw Error("hook with provider not defined");

  return context;
}
