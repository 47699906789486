import React from "react";
import { useParams } from "react-router-dom";

import { Button, GridItem, Skeleton, Stack } from "@chakra-ui/react";

import { IClassroom, IClassroomStudent, IStudent } from "@isurf-tech/types/domain";

import CardClassroomStudent from "../../../../components/cards/classroom-students";
import { InviteDialogProps } from "../../../../components/dialogs/invite";
import EmptyList from "../../../../components/empty-list";
import { DialogActionResponse } from "../../../../contexts/dialog";
import { useDialog } from "../../../../hooks/dialog";
import { useResposiveness } from "../../../../hooks/responsiveness";
import { classroomService, invitesService } from "../../../../services/api";

export default function ClassroomStudents({
  loading = false,
  classroom,
  classroomStudents = [],
}: {
  loading?: boolean;
  classroom: IClassroom;
  classroomStudents: IClassroomStudent[];
}) {
  const { isMobile } = useResposiveness();
  const params = useParams<{ id: string }>();
  const dialog = useDialog();

  const handleRequestInvite = async (form: { email: string }) => {
    if (!form.email) return "Preencha o campo de e-mail.";

    const { error } = await invitesService.student(form.email, params.id!);

    if (error?.statusCode && error.message) return error?.message;
    if (error?.statusCode && !error.message) return "Erro ao enviar convite.";

    return;
  };

  const handleInvite = () => {
    dialog.open<InviteDialogProps>({
      type: "invite",
      title: "Convide para aula",
      shareLink: `${window.location.origin}/lessons/${params.id}`,
      description:
        "Enviaremos um email convidando aluno para que ele realize o cadastro e a compra da aula em especifico.",
      inputProps: {
        label: "",
        inputProps: {
          name: "email",
        },
      },
      action: {
        label: "Enviar",
        onClick: handleRequestInvite,
      },
    });
  };

  const handleRequestCancelClassroom = async (
    classroom: IClassroom,
    student: IStudent,
    description: string
  ): Promise<DialogActionResponse> => {
    const { data, error } = await classroomService.cancelStudent(
      classroom.id!,
      student?.user?.email,
      description ?? ""
    );

    if (error?.statusCode && error.message)
      return {
        type: "error",
        title: "Erro ao cancelar aula.",
        description:
          error?.message ||
          "Houve um erro ao cancelar aula, por favor tente novamente ou entre em contato com o suporte.",
      };

    return {
      type: "success",
      title: "Aula cancelada com sucesso!",
      description:
        data?.message ||
        `A aula para o aluno ${student.user.name} foi cancelada com sucesso.`,
    };
  };

  const handleCancel = (classroomStudent: IClassroom, student: IStudent) => {
    if (!classroomStudent) return;

    return dialog.open({
      type: "text-input",
      title: "Cancelamento de aula",
      description:
        "Você tem certeza que deseja cancelar a aula para o aluno? Após o cancelamento, o aluno não poderá mais acessar a aula e receberá um email com o motivo do cancelamento e um credito para realizar uma nova aula.",

      inputProps: {
        name: "description",
        label: "Motivo do cancelamento",
        type: "text-area",
        fullWidth: true,
      },
      closeLabel: false,
      redirect: {
        label: "Cancelar aula",
        action: (description) =>
          handleRequestCancelClassroom(classroomStudent, student, description),
      },
    });
  };

  return (
    <React.Fragment>
      {!isMobile && (
        <GridItem
          colSpan={isMobile ? 4 : 6}
          mt={12}
          display="flex"
          justifyContent={isMobile ? "flex-start" : "flex-end"}
        >
          <Button
            size="md"
            onClick={handleInvite}
            w={isMobile ? "100%" : "min-content"}
          >
            Adicionar alunos
          </Button>
        </GridItem>
      )}

      <GridItem mt={4} colSpan={isMobile ? 4 : 12}>
        {loading ? (
          <Stack spacing={4}>
            <Skeleton height="10vh" borderRadius="sm" />
            <Skeleton height="10vh" borderRadius="sm" />
            <Skeleton height="10vh" borderRadius="sm" />
          </Stack>
        ) : !classroomStudents.length ? (
          <EmptyList
            title="Ainda não há alunos ainda"
            description="Fique tranquilo, você pode adicionar alunos a qualquer momento. enviando um convite para o email deles ou pelo link de compartilhamento."
            buttonLabel="Adicione alunos"
            mt={4}
          />
        ) : (
          classroomStudents.map((classrooomStudent, index) => (
            <CardClassroomStudent
              key={classrooomStudent.id}
              data={classrooomStudent}
              mt={index > 0 ? 4 : 0}
              menuOptions={[
                {
                  label: "Cancelar Aula",
                  onClick: () =>
                    handleCancel(classroom, classrooomStudent.student),
                },
              ]}
            />
          ))
        )}
      </GridItem>

      {isMobile && (
        <GridItem
          colSpan={isMobile ? 4 : 6}
          mt={10}
          display="flex"
          justifyContent={isMobile ? "flex-start" : "flex-end"}
        >
          <Button
            size="md"
            onClick={handleInvite}
            w={isMobile ? "100%" : "min-content"}
          >
            Adicionar alunos
          </Button>
        </GridItem>
      )}
    </React.Fragment>
  );
}
