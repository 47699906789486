import { useNavigate } from "react-router-dom";

import { Box, Heading, Image, Link, Text } from "@chakra-ui/react";

import ArrowRight from "../../../../assets/icons/arrow-right";
import routesName from "../../../../config/routes";
import { useResposiveness } from "../../../../hooks/responsiveness";

export default function HomeMainSixthSection() {
  const { isMobile } = useResposiveness();
  const navigate = useNavigate();

  return (
    <Box
      as="section"
      id="seventh"
      minHeight={isMobile ? "60vh" : "80vh"}
      pt={isMobile ? "64px" : "120px"}
    >
      <Box padding={isMobile ? "0px" : "0px 80px"} position="relative">
        <Image
          src={require("../../../../assets/medias/beach.png")}
          width={isMobile ? "100vw" : "100%"}
          height={isMobile ? "340px" : "100%"}
          borderRadius="md"
        />

        <Box
          position="absolute"
          bg="neutral.lightest"
          top={isMobile ? "40px" : ""}
          bottom={isMobile ? "" : "0"}
          maxW={isMobile ? "" : "40%"}
          height="80%"
          mx={isMobile ? 4 : 0}
          padding={isMobile ? "40px 16px" : "72px 10% 24px 0px"}
          borderRadius={isMobile ? "md" : 0}
          borderTopRightRadius="md"
        >
          <Heading fontSize={isMobile ? "lg" : "xl"} color="neutral.darkest">
            Cadastre sua escola
          </Heading>

          <Text
            mt={4}
            fontSize={isMobile ? "xs" : "sm"}
            color="neutral.dark"
            maxW={isMobile ? "70%" : "100%"}
          >
            Tenha sua agenda de aulas organizadas, receba relatórios financeiros
            mensais e aumente sua receita com mais público.
          </Text>

          <Link
            mt={8}
            display="flex"
            flexDir="row"
            alignItems="center"
            padding="8px 0px"
            fontSize="sm"
            color="neutral.darkest"
            cursor="pointer"
            textDecoration="unset"
            onClick={() => {
              navigate(routesName.schools);
              return window.scrollTo(0, 0);
            }}
          >
            Saiba mais
            <ArrowRight style={{ marginLeft: 4 }} />
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
