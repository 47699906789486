import { Outlet, useLocation } from "react-router-dom";

import { ISchool, IStudent } from "@isurf-tech/types/domain";
import { EntityUserType } from "@isurf-tech/types/domain/enums";

import FullLoader from "../components/loaders/full";
import routesName from "../config/routes";
import { useAuth } from "../hooks/auth";
import NotFoundPage from "../pages/not-found";

const entityRoutes = {
  [EntityUserType.SCHOOL]: [
    routesName.schoolsDashboard,
    routesName.schoolsPerfil,
    routesName.schoolsCreateClass,
    routesName.schoolsClassroomById.replace(":id", ""),
  ],
  [EntityUserType.STUDENT]: [
    routesName.studentsDashboard,
    routesName.studentsPerfil,
  ],
};

export function EntityProtected({ children }: any) {
  const { hasToken, user } = useAuth<IStudent | ISchool>();
  const location = useLocation();

  if (
    user &&
    user.entity in EntityUserType &&
    entityRoutes[user.entity]?.some((route) =>
      location.pathname.includes(route)
    )
  )
    return <Outlet />;

  if (!user && hasToken) return <FullLoader asComponent />;

  return <NotFoundPage />;
}
