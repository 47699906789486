import { useContext } from "react";

import { ClassroomContext } from "../contexts/classroom";

export function useClassroom() {
  const context = useContext(ClassroomContext);

  if (!context) throw Error("hook with provider not defined");

  return context;
}
