import { useContext } from "react";

import { LoaderContext } from "../contexts/loader";

export function useLoader() {
  const context = useContext(LoaderContext);

  if (!context) throw Error("hook with provider not defined");

  return context;
}
