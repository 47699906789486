import axios, { AxiosInstance } from "axios";

import { ServiceResponse } from "..";

import { SchedulesFilters } from "../../../@types/schedules";
import { SchedulesFactory } from "../factories/schedules";

export class ScheduleService {
  constructor(private api: AxiosInstance) {}

  async list(params: SchedulesFilters): Promise<ServiceResponse<any>> {
    let result = { data: {}, error: null };

    try {
      const { data } = await this.api.get("/schedules", { params });

      result.data = data || {};
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result.error = error.response?.data;
      } else {
        result.error = error as any;
      }
    } finally {
      return result;
    }
  }

  async getByLessonId(
    lessonId: string,
    { start, end }: { start: string; end: string }
  ): Promise<ServiceResponse<any>> {
    let result = { data: {}, error: null };

    try {
      const { data = [] } = await this.api.get(
        `/schedules/lessons/${lessonId}`,
        {
          params: {
            start,
            end,
          },
        }
      );

      result.data = SchedulesFactory.groupByDate(data);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result.error = error.response?.data;
      } else {
        result.error = error as any;
      }
    } finally {
      return result;
    }
  }

  async countByDay(start: string, end: string): Promise<ServiceResponse<any>> {
    let result = { data: {}, error: null };

    try {
      const { data = {} } = await this.api.get(`/schedules/count`, {
        params: {
          // start,
          // end,
        },
      });

      result.data = data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result.error = error.response?.data;
      } else {
        result.error = error as any;
      }
    } finally {
      return result;
    }
  }
}
