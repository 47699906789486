import React, { useMemo, useRef, useState } from "react";

import {
  Button,
  Flex,
  FlexProps,
  Img,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
} from "@chakra-ui/react";

import { useResposiveness } from "../hooks/responsiveness";
import { HEIGHT_IMAGE_DESKTOP, HEIGHT_IMAGE_MOBILE } from "./galery";

interface SelectDocumentPropsI extends Omit<FlexProps, "onChange"> {
  file?: File | null;
  onChange?: (file: File | null) => void;
}

const extractNameFromUrl = (url: string) => {
  const hasExtension = [".jpeg", ".png", ".pdf"].includes(url);

  if (hasExtension) return url;

  const urlSplited = decodeURI(url).split("/").slice(-1)?.[0];

  return urlSplited;
};

export function SelectDocument({
  file: fileProps,
  onChange,
  ...props
}: SelectDocumentPropsI) {
  const { isMobile } = useResposiveness();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File | null>(fileProps ?? null);
  const typeofFile = useMemo(() => typeof file, [file]);
  const fileName = useMemo(
    () => file?.name ?? extractNameFromUrl(file as unknown as string),
    [file]
  );
  const fileExtension = useMemo(
    () => fileName?.split(".")?.slice(-1)?.[0],
    [fileName]
  );

  const handleOnChange = async (e: any) => {
    const [file] = e.target.files as File[];

    if (onChange) await onChange(file);
    if (file) setFile(file);
  };

  const onExclude = async () => {
    setFile(null);
    if (onChange) await onChange(null);
  };

  return (
    <Flex
      border={!file ? "1px dashed" : "none"}
      borderColor="neutral.medium"
      borderRadius="base"
      h={
        isMobile
          ? !file
            ? HEIGHT_IMAGE_MOBILE
            : `calc(${HEIGHT_IMAGE_MOBILE} + 40vh)`
          : !file
          ? HEIGHT_IMAGE_DESKTOP
          : `calc(${HEIGHT_IMAGE_DESKTOP} + 30px)`
      }
      direction="column"
      justifyContent="center"
      alignItems="center"
      {...props}
    >
      {!!file && (
        <Tag
          borderRadius="sm"
          alignSelf="flex-start"
          mb={2}
          border="hairline"
          borderColor="neutral.lightest"
          bg="brand.dark"
          mt={["image/jpeg", "image/png"].includes(file.type) ? 4 : 0}
        >
          <TagLabel color="neutral.lightest" fontSize="xs" fontWeight="normal">
            {fileName}
          </TagLabel>
          <TagCloseButton
            color="neutral.lightest"
            opacity={1}
            onClick={onExclude}
          />
        </Tag>
      )}

      {file && ["pdf"].includes(fileExtension) && (
        <iframe
          title={fileName}
          src={
            typeofFile === "string"
              ? (file as unknown as string)
              : URL.createObjectURL(file)
          }
          width="100%"
          height="100%"
        />
      )}

      {file && ["jpeg", "png"].includes(fileExtension) && (
        <Img
          title={fileName ?? "Document"}
          src={
            typeofFile === "string"
              ? (file as unknown as string)
              : URL.createObjectURL(file)
          }
          width="100%"
          height="100%"
        />
      )}

      {!file && (
        <React.Fragment>
          <input
            type="file"
            ref={inputRef}
            hidden
            accept="application/pdf, image/jpeg, image/png"
            onChange={handleOnChange}
          />

          <Button size="md" onClick={() => inputRef?.current?.click()}>
            Adicionar documento
          </Button>

          {!isMobile && (
            <Text mt={6} fontSize="xs" color="neutral.darkest">
              ou arraste-as pra cá
            </Text>
          )}
        </React.Fragment>
      )}
    </Flex>
  );
}
