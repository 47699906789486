import { ComponentStyleConfig } from "@chakra-ui/react";

import InputTheme from "./input";

const TextareaTheme: ComponentStyleConfig = {
  baseStyle: {
    ...(InputTheme.baseStyle as any).field,
    minHeight: "76px",
  },
  sizes: {
    ...(InputTheme.sizes as any).field,
  },
  variants: {},
  defaultProps: {
    variant: "outline",
    size: "xs",
  },
};

export default TextareaTheme;
