import React from "react";

import { Flex } from "@chakra-ui/react";


import HowToWorkFirstIcon from "../../../assets/icons/how-to-work/school/first";
import HowToWorkFourtyIcon from "../../../assets/icons/how-to-work/school/fourty";
import HowToWorkSecondIcon from "../../../assets/icons/how-to-work/school/second";
import HowToWorkThirdIcon from "../../../assets/icons/how-to-work/school/third";
import Footer from "../../../components/footer";
import Header from "../../../components/headers/main";
import HowItWorkSection from "../../../components/sections/how-it-work";
import SectionQuestion from "../../../components/sections/question";
import HomeSchoolFirstSection from "./components/first-section";
import FourtySection from "./components/fourty-section";
import SecondSection from "./components/second-section";
import ThirdSection from "./components/third-section";

const howItWorkSteps = [
  {
    icon: HowToWorkFirstIcon,
    title: "Anuncie suas aulas",
    description:
      "Cadastre-se na plataforma e inscreva suas aulas indicando a modalidade, praia, instrutor, avisos prévios...",
  },
  {
    icon: HowToWorkSecondIcon,
    title: "Agendamento de aulas",
    description:
      "Os alunos irão encontrar seus anúncios e se inscreverão nas aulas realizando o pagamento das aulas pela plataforma.",
  },
  {
    icon: HowToWorkThirdIcon,
    title: "Consulte suas aulas",
    description:
      "Você receberá uma lista completa dos seus alunos do dia com todas as informações necessárias para o preparamento da aula e escolha dos equipamentos.",
  },
  {
    icon: HowToWorkFourtyIcon,
    title: "Relatório financeiro",
    description:
      "Você também poderá acompanhar seu extrato mensal e o histórico por aulas.",
  },
];

function HomeSchool() {
  return (
    <Flex direction="column">
      <Header />

      <HomeSchoolFirstSection />

      <HowItWorkSection steps={howItWorkSteps} />
      <SecondSection />
      <ThirdSection />
      <FourtySection />

      <SectionQuestion />

      <Footer />
    </Flex>
  );
}

export default HomeSchool;
