/* eslint-disable import/no-anonymous-default-export */
// BORDER RADIUS
export const radii = {
  none: "0",
  sm: "0.25rem",
  base: "0.25rem",
  md: "0.5rem",
  lg: "0.75rem",
  xl: "1rem",
  "2xl": "1.2rem",
  "3xl": "1.5rem",
  full: "9999px",
};

export const borders = {
  none: 0,
  hairline: "1px solid",
  thin: "2px solid",
  thick: "4px solid",
  heavy: "8px solid",
};

export default { radii, borders };
