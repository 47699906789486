import {
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";

import { useResposiveness } from "../../../../hooks/responsiveness";

export default function HomeSchoolThirdSection() {
  const { isMobile } = useResposiveness();

  return (
    <Box
      id="home-school-payment"
      as="section"
      position="relative"
      minHeight={isMobile ? "110vh " : "90vh"}
      mt={isMobile ? "60px" : "80px"}
      px={isMobile ? "16px" : "80px"}
      mb={isMobile ? "64px" : "0px"}
    >
      {isMobile && (
        <Heading fontSize="xl" fontWeight="semibold">
          Pagamento facilitado
        </Heading>
      )}

      <Grid
        templateColumns={isMobile ? "repeat(4, 1fr)" : "repeat(12, 1fr)"}
        columnGap={8}
        pt={isMobile ? "10vh" : 0}
      >
        <GridItem
          position="relative"
          colStart={isMobile ? 1 : 2}
          colSpan={isMobile ? 4 : 4}
          rowStart={isMobile ? 2 : 1}
        >
          {!isMobile && (
            <Heading fontSize="xl" fontWeight="semibold">
              Pagamento facilitado
            </Heading>
          )}

          <Heading fontSize="sm" mt={10} fontWeight="semibold">
            Cobre o quanto quiser por aula
          </Heading>
          <Text fontSize="xs" mt={2} maxW={isMobile ? "100%" : "85%"}>
            Defina o preço ideal de suas aulas na configuração das aulas. Você
            pode configurar pacotes para vender mais aulas.{" "}
          </Text>

          <Heading fontSize="sm" mt={10} fontWeight="semibold">
            Pagamentos online
          </Heading>
          <Text fontSize="xs" mt={2} maxW={isMobile ? "100%" : "85%"}>
            Seus alunos farão o pagamento online quando se inscreverem na aula.
            Assim você tem total segurança e garantia do recebimento.
          </Text>

          <Heading fontSize="sm" mt={10}>
            Receba por suas aulas
          </Heading>
          <Text fontSize="xs" mt={2} maxW={isMobile ? "100%" : "85%"}>
            Após as aulas você recebe o pagamento, e será cobrado apenas R$4,00
            por inscrição.
          </Text>

          <Button
            size="md"
            mt={14}
            w={isMobile ? "100%" : "min-content"}
            onClick={() => {
              document
                ?.getElementById("contact")
                ?.scrollIntoView({ behavior: "smooth", block: "center" });
            }}
          >
            Fale com a gente
          </Button>
        </GridItem>

        <GridItem
          position="relative"
          colStart={isMobile ? 1 : 8}
          colSpan={isMobile ? 4 : 12}
          mr="-80px"
        >
          <Box
            bg="#FFECFD"
            w="100%"
            pr="80px"
            height="50vh"
            borderLeftRadius="lg"
            position="relative"
          >
            <Box
              position="absolute"
              right={0}
              bottom={0}
              objectPosition="center"
            >
              <Image
                mr={isMobile ? "20vw" : "10vw"}
                w={isMobile ? "90vw" : "100%"}
                src={require("../../../../assets/medias/mockup-hand-checkout.png")}
              />
            </Box>
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
}
