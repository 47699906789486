import { Icon, IconProps } from "@chakra-ui/react";

export default function SurfBoardIcon(props: IconProps) {
  return (
    <Icon
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.25706 23.0099C12.8377 21.3894 21.3362 10.6923 18.0354 2.32986C9.14304 3.65254 4.12831 16.361 5.51519 22.0042L7.78404 20.0858L7.25706 23.0099Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M7.55085 20.3398L11 15"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
