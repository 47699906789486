import moment from "moment-timezone";
import { useMemo } from "react";

import { Divider, Flex, Heading, Text } from "@chakra-ui/react";

import { Schedules } from "../../../@types/lessons";
import { useResposiveness } from "../../../hooks/responsiveness";

interface PaymentReceiptProps {
  data?: {
    quantity: number;
    amount: number;
    discount: number;
    schedules: Schedules;
  };
}

const formatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

export default function PaymentReceipt({
  data = { quantity: 0, amount: 0, discount: 0, schedules: {} },
}: PaymentReceiptProps) {
  const { isMobile } = useResposiveness();
  const tz = useMemo(
    () => Intl.DateTimeFormat().resolvedOptions().timeZone,
    []
  );

  return (
    <Flex
      shadow="sm"
      p={isMobile ? "16px" : "48px 56px"}
      direction="column"
      borderRadius="md"
      mx={isMobile ? -2 : 0}
    >
      <Heading fontSize="lg">{data?.quantity} aulas selecionadas</Heading>
      <Heading fontSize="sm" mt={2} mb={1}>
        {data.quantity}x {formatter.format(data.amount * 0.01)}
      </Heading>

      {Object.entries(data?.schedules || {})
        .map(([date, schedules = []]) =>
          schedules.map((schedule, index) => (
            <Text mt={2} key={index.toString() + schedule.start}>
              {moment(schedule.start)
                .tz(schedule?.timezone || tz)
                .format("DD MMM")}{" "}
              ·{" "}
              {moment(schedule.start)
                .tz(schedule?.timezone || tz)
                .format("HH:mm")}
              {" -> "}
              {moment(schedule.end)
                .tz(schedule?.timezone || tz)
                .format("HH:mm")}
            </Text>
          ))
        )
        .flat()}

      <Divider my={4} />

      <Flex justify="space-between" mt={2}>
        <Text fontSize="xs">Desc. pacote</Text>
        <Text fontSize="xs">- {formatter.format(data.discount)}</Text>
      </Flex>

      <Flex justify="space-between" mt={2}>
        <Heading fontSize="xs">Total</Heading>
        <Heading fontSize="xs">
          {formatter.format(data.amount * 0.01 * data.quantity)}
        </Heading>
      </Flex>
    </Flex>
  );
}
