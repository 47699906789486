import moment from "moment-timezone";

import { Flex, Text, useToken } from "@chakra-ui/react";

import { ISchedule } from "@isurf-tech/types/domain";

import ArrowRight from "../../assets/icons/arrow-right";
import { useResposiveness } from "../../hooks/responsiveness";
import ListTag from "../tag-list";

interface CardClassPropsI {
  mt?: number;
  h?: any;
  schedule: ISchedule;
  onClick?: (schedule: ISchedule) => void;
  small?: boolean;
}

export default function CardSchedule({
  schedule,
  onClick,
  small = false,
  ...props
}: CardClassPropsI) {
  const { isMobile } = useResposiveness();
  const [neutralDarkestColor] = useToken("colors", ["neutral.darkest"]);

  if (!schedule?.start) return null;

  return (
    <Flex
      border="hairline"
      borderColor="neutral.medium"
      p={isMobile ? "24px 16px" : "32px"}
      borderRadius="sm"
      alignItems={isMobile ? "flex-start" : "center"}
      justify={isMobile ? "flex-start" : "flex-start"}
      w="100%"
      as={onClick ? "button" : "div"}
      onClick={(e) => onClick?.(schedule)}
      {...props}
      _hover={
        onClick
          ? {
              bg: "neutral.light",
            }
          : {}
      }
    >
      <Flex
        flex={3}
        direction={isMobile ? "column" : "row"}
        alignItems={isMobile ? "flex-start" : "center"}
        justify={isMobile ? "flex-start" : "flex-start"}
      >
        <Flex flex={1} justify="center" align="center">
          <Text fontSize="sm" fontWeight="semibold">
            {moment(schedule.start).tz(schedule.timezone).format("HH:mm")}
          </Text>
          <Flex ml={1} justify="center" align="center">
            <ArrowRight color={neutralDarkestColor} />
          </Flex>
          <Text fontSize="sm" fontWeight="semibold" ml={1}>
            {moment(schedule.end).tz(schedule.timezone).format("HH:mm")}
          </Text>
        </Flex>

        <Flex
          flex={10}
          ml={isMobile ? 0 : 8}
          direction={isMobile ? "column" : "row"}
          justify="flex-start"
          alignItems={isMobile ? "flex-start" : "center"}
        >
          <Text
            fontSize={isMobile ? "md" : "sm"}
            fontWeight={isMobile ? "normal" : "semibold"}
          >
            {schedule?.lesson?.name}
          </Text>

          <Flex mt={isMobile ? 2 : 0} ml={2} alignItems="center">
            <ListTag
              tags={[schedule?.lesson?.modality, schedule?.lesson?.variation]}
              size="sm"
            />
          </Flex>
        </Flex>

        {!small && (
          <Flex mt={isMobile ? 2 : 0} justify="center" align="center">
            <Text
              mr={2}
              fontSize="xs"
              fontWeight="semibold"
              color="neutral.dark"
              letterSpacing="0.5px"
            >
              {schedule?.classroom?.students?.length || 0}/
              {schedule?.lesson?.seats} inscritos
            </Text>
          </Flex>
        )}
      </Flex>

      {/* {!small && (
        <Flex justify="center" align="center">
          <IconButton
            variant="unstyled"
            aria-label="more-icon"
            icon={<MoreVerticalIcon color={neutralDarkestColor} />}
          />
        </Flex>
      )} */}
    </Flex>
  );
}
