import moment from "moment-timezone";

import { ISchedule } from "@isurf-tech/types/domain";

export class SchedulesFactory {
  static groupByDate(data: ISchedule[] = []): { [key: string]: ISchedule[] } {
    return data.reduce<{ [key in string]: ISchedule[] }>((store, schedule) => {
      const keyDay = moment(schedule.start).startOf("day").toISOString();

      if (!store[keyDay]) store[keyDay] = [];

      store[keyDay].push(schedule);

      return store;
    }, {});
  }
}
