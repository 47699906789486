import {
  NumberFormatValues,
  NumericFormat,
  NumericFormatProps,
  SourceInfo,
} from "react-number-format";

import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from "@chakra-ui/react";

interface CurrencyFieldPropsI extends NumericFormatProps {
  label: string;
  value?: string | any;
  placeholder?: string;
  helperText?: string;
  inputProps?: any;
  formControlProps?: FormControlProps;
  error?: string | any;
  onChange?: (e: any) => void;
  currency?: any;
}

export default function CurrencyField({
  label,
  value,
  placeholder = "Type here...",
  helperText = "",
  inputProps: { ref, ...inputProps } = {},
  error = "",
  formControlProps = {},
  currency = "R$",
  onChange,
  ...props
}: CurrencyFieldPropsI) {
  const handleChangeValue = (
    values: NumberFormatValues,
    sourceInfo: SourceInfo
  ) => {
    if (onChange) onChange(values.floatValue);
    if (inputProps.onChange) inputProps.onChange(sourceInfo.event);
  };

  return (
    <FormControl isInvalid={!!error}>
      <FormLabel>{label}</FormLabel>

      <InputGroup>
        {currency && (
          <InputLeftElement
            pointerEvents="none"
            children={
              <Text fontSize="xs" color="neutral.dark">
                {currency}
              </Text>
            }
            minHeight="56px"
            p={6}
          />
        )}

        <NumericFormat
          customInput={Input}
          placeholder={placeholder}
          pl={currency ? "40px !important" : 0}
          onValueChange={handleChangeValue}
          value={value}
          inputMode="decimal"
          {...inputProps}
          {...props}
        />
      </InputGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
}
