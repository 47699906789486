export default function HowToWorkFirstIcon() {
  return (
    <svg
      width="108"
      height="109"
      viewBox="0 0 108 109"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1160_16375)">
        <path
          d="M37.4926 77.4982L37.3394 77.5295C36.1368 77.7748 34.9373 77.8633 33.7621 77.8125L29.7019 92.957C29.3298 94.3445 30.1638 95.768 31.5559 96.1226L40.1323 98.3063C41.5004 98.6547 42.8946 97.8383 43.2601 96.4749L49.1881 74.3644L37.4926 77.4982Z"
          fill="#0F7E99"
        />
        <path
          d="M64.693 10.7655L45.884 30.5692L56.5131 70.2377L82.6276 77.5848C84.5525 78.1264 86.3352 76.3665 85.8178 74.4355L69.0543 11.8731C68.5329 9.92719 66.0806 9.30454 64.693 10.7655Z"
          fill="#0F7E99"
        />
        <path
          d="M20.5142 67.3335L16.0319 50.6053C15.7751 49.6467 15.6286 48.685 15.5793 47.7326L11.9574 48.7031C9.34463 49.4032 7.79392 52.0881 8.49379 54.7001L11.9386 67.5564C12.6385 70.1683 15.3239 71.7182 17.9367 71.0181L21.5573 70.048C21.1238 69.1958 20.7704 68.2896 20.5142 67.3335Z"
          fill="#0F7E99"
        />
        <path
          d="M50.7373 68.6124L36.309 72.4785C31.5563 73.448 26.7496 70.6738 25.4968 65.9986L21.0146 49.2706C19.7618 44.5953 22.5375 39.7896 27.2903 38.8202L41.7186 34.9541L50.7373 68.6124Z"
          fill="#0F7E99"
        />
        <path
          d="M93.8199 60.5487C93.1824 60.7195 92.4797 60.6471 91.863 60.2912L85.4918 56.6141C84.2583 55.9024 83.8358 54.3256 84.5481 53.0924C85.2603 51.8594 86.8378 51.4367 88.071 52.1484L94.4423 55.8255C95.6757 56.5371 96.0982 58.114 95.3859 59.3472C95.0298 59.9638 94.4575 60.3778 93.8199 60.5487Z"
          fill="#0F7E99"
        />
        <path
          d="M80.787 32.0105C80.1493 32.1814 79.4468 32.1091 78.83 31.753C77.5966 31.0413 77.1741 29.4645 77.8864 28.2313L81.5655 21.8612C82.2778 20.628 83.855 20.2054 85.0885 20.9173C86.3217 21.629 86.7442 23.2058 86.0321 24.439L82.353 30.8091C81.997 31.4256 81.4245 31.8397 80.787 32.0105Z"
          fill="#0F7E99"
        />
        <path
          d="M93.5046 41.9244L86.3979 43.8287C85.0221 44.1973 83.608 43.3812 83.2395 42.0059C82.871 40.6306 83.6876 39.2168 85.0634 38.8481L92.1701 36.9439C93.5459 36.5752 94.96 37.3914 95.3285 38.7667C95.697 40.142 94.8804 41.5558 93.5046 41.9244Z"
          fill="#0F7E99"
        />
      </g>
      <defs>
        <clipPath id="clip0_1160_16375">
          <rect
            width="88"
            height="88"
            fill="white"
            transform="translate(0 23) rotate(-15)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
