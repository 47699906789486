import { Icon, IconProps } from "@chakra-ui/react";

interface ChevronDownIconPropsI extends IconProps {}

export default function ChevronDownIcon(props: ChevronDownIconPropsI) {
  return (
    <Icon
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 6L8 10L12 6"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
