export default function InstagramIcon({ size = 24, color = "black" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.167 3.66699H7.83366C5.53247 3.66699 3.66699 5.53247 3.66699 7.83366V16.167C3.66699 18.4682 5.53247 20.3337 7.83366 20.3337H16.167C18.4682 20.3337 20.3337 18.4682 20.3337 16.167V7.83366C20.3337 5.53247 18.4682 3.66699 16.167 3.66699Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3337 11.4753C15.4366 12.1688 15.3181 12.8771 14.9952 13.4994C14.6723 14.1218 14.1614 14.6264 13.5351 14.9416C12.9088 15.2569 12.1991 15.3666 11.5069 15.2552C10.8147 15.1438 10.1752 14.817 9.67944 14.3212C9.18367 13.8254 8.85685 13.186 8.74546 12.4938C8.63408 11.8015 8.74379 11.0918 9.05901 10.4656C9.37423 9.8393 9.8789 9.32837 10.5012 9.00545C11.1236 8.68254 11.8319 8.56407 12.5254 8.66692C13.2328 8.77182 13.8878 9.10147 14.3935 9.60717C14.8992 10.1129 15.2288 10.7678 15.3337 11.4753Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.583 7.41699H16.5913"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
