import axios, { AxiosInstance } from "axios";

import { ServiceResponse } from "..";

import {
  MediaContext,
  MediaObject,
  MediaPayload,
} from "../../../@types/medias";

interface UploadUrlResponse {
  links: {
    context: MediaContext;
    object: MediaObject;
    name: string;
    url: string;
  }[];
}

const mockUploadUrl = "https://storage.googleapis.com/isurf-app-development/mocks/surf.jpeg";

export class MediasService {
  constructor(private api: AxiosInstance) {}

  private returnMockUploadUrl<T = any>(): ServiceResponse<T> {
    return {
      data: mockUploadUrl as any,
      error: null,
    };
  }

  public async getUploadUrl<T = any>(
    payload: MediaPayload
  ): Promise<ServiceResponse<T>> {
    let result = { data: null, error: null };

    if (process.env.NODE_ENV === "development")
      return this.returnMockUploadUrl();

    try {
      const { data } = await this.api.post("/medias", {
        medias: [
          {
            context: payload?.context,
            object: payload?.object,
            name: payload?.name,
            id: payload?.id,
          },
        ],
      });

      if (!data.links) data.links = [];

      const [link] = data.links;
      const url = link?.url;

      result.data = url;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result.error = error.response?.data;
      } else {
        result.error = error as any;
      }
    } finally {
      return result as any;
    }
  }

  private returnMockUploadUrls<T = any>(
    payload: MediaPayload[]
  ): ServiceResponse<T> {
    return {
      data: {
        links: payload.map((p) => mockUploadUrl),
      } as any,
      error: null,
    };
  }

  public async getUploadUrls<T = UploadUrlResponse>(
    payload: MediaPayload[]
  ): Promise<ServiceResponse<T>> {
    let result = { data: { links: [] }, error: null };

    if (process.env.NODE_ENV === "development")
      return this.returnMockUploadUrls(payload);

    try {
      const { data } = await this.api.post("/medias", {
        medias: payload.map((p) => ({
          context: p?.context,
          object: p?.object,
          name: p?.name,
          id: p?.id,
        })),
      });

      result.data = { links: data?.links || [] };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result.error = error.response?.data;
      } else {
        result.error = error as any;
      }
    } finally {
      return result as any;
    }
  }

  private sanitizeUrl(url: string) {
    return url.replace(/\/+$/, "").split("?").slice(0, 1).join("");
  }

  public async uploadByUrl<T = any>(
    url: string,
    file: File
  ): Promise<ServiceResponse<T>> {
    let result = { data: null as any, error: null };

    if (process.env.NODE_ENV === "development")
      return {
        data: mockUploadUrl as any,
        error: null,
      };

    try {
      await this.api.put(url, file, {
        headers: {
          "Content-Type": file.type,
          "Access-Control-Allow-Origin": "*",
        },
      });

      result.data = this.sanitizeUrl(url).replace("googleapis", "cloud.google");
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result.error = error.response?.data;
      } else {
        result.error = error as any;
      }
    } finally {
      return result;
    }
  }
}
