export const regionRestrictionEnabled =
  process.env.REACT_APP_REGION_RESTRICTION_ENABLED === "true" || true;
export const regions = process.env.REACT_APP_REGIONS_ENABLED || "BR";

export function getRegionsEnabled() {
  if (!regions) return [];

  return regions
    .trim()
    .split(",")
    .map((region) => region.trim().toUpperCase());
}
