import React from "react";

import {
  Box,
  Button,
  Flex,
  Heading,
  SimpleGrid,
  Text,
  useToken,
} from "@chakra-ui/react";

import BookOpenIcon from "../../assets/icons/book-open";
import UserIcon from "../../assets/icons/user";
import { useResposiveness } from "../../hooks/responsiveness";

export default function SelectRoleSignUp({ onClick }: any) {
  const { isMobile } = useResposiveness();
  const [colorInfoMedium] = useToken("colors", ["feedback.info.medium"]);

  return (
    <React.Fragment>
      <Text
        fontSize="sm"
        color="neutral.dark"
        fontWeight="normal"
        mt={isMobile ? 6 : 4}
      >
        Clique em uma das opções para se cadastrar.
      </Text>

      <SimpleGrid columns={isMobile ? 1 : 2} spacing={7} mt={6}>
        {[
          {
            icon: <UserIcon size={40} color={colorInfoMedium} />,
            label: "Aluno",
            description: "Agende e acompanhe suas aulas",
            value: "STUDENT",
          },
          {
            icon: <BookOpenIcon size={40} color={colorInfoMedium} />,
            label: "Escola",
            description:
              "Acompanhe os agendamentos e relatórios financeiros de suas aulas",
            value: "SCHOOL",
          },
        ].map((role) => (
          <Flex
            key={role.label}
            border="hairline"
            borderColor="neutral.medium"
            borderRadius="base"
            padding="24px"
            display="flex"
            flexDirection="column"
          >
            <Box flex={1}>
              <Flex
                borderRadius="full"
                w="72px"
                h="72px"
                bg="feedback.info.lightest"
                justify="center"
                align="center"
              >
                {role.icon}
              </Flex>
            </Box>

            <Box flex={2}>
              <Heading size="sm" mt={4}>
                {role.label}
              </Heading>
              <Text
                size="xs"
                color="neutral.dark"
                textAlign="start"
                // maxW="90%"
                mt={1}
              >
                {role.description}
              </Text>
            </Box>

            <Box flex={1}>
              <Button
                variant="outline"
                width="100%"
                size="lg"
                mt={6}
                onClick={() => onClick(role.value)}
              >
                Cadastrar
              </Button>
            </Box>
          </Flex>
        ))}
      </SimpleGrid>
    </React.Fragment>
  );
}
