import { DateMoment } from "./moment";

const DATE_HELPER = process.env.REACT_APP_DATE_LIB ?? "moment";

function getInstance() {
  switch (DATE_HELPER) {
    case "moment":
      return new DateMoment();
  }

  throw new Error("Does not have any lib to date!");
}

const dateHelper = getInstance();

export default dateHelper;
