import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  GridItem,
  Heading,
  SimpleGrid,
} from "@chakra-ui/react";

import { ILesson, IStudent } from "@isurf-tech/types/domain";

import { Schedules } from "../../../@types/lessons";
import SimpleHeader from "../../../components/headers/simple";
import { formatSchedulesByStartKey } from "../../../components/schedules/utils";
import TextField from "../../../components/text-field";
import routesName from "../../../config/routes";
import { useAuth } from "../../../hooks/auth";
import { useDialog } from "../../../hooks/dialog";
import { useLoader } from "../../../hooks/loader";
import { useQuery } from "../../../hooks/query-param";
import { useResposiveness } from "../../../hooks/responsiveness";
import { useSession } from "../../../hooks/session";
import { paymentsService } from "../../../services/api";
import PaymentCardForm from "./form-methods/card";
import PaymentMethod, { PaymentLabel, PaymentMethods } from "./payment-method";
import PaymentReceipt from "./receipt";

export default function PaymentCheckoutPage() {
  const navigate = useNavigate();
  const { isMobile } = useResposiveness();
  const query = useQuery<{ checkout_session: string }>();
  const [lesson, setLesson] = useState<ILesson>({} as ILesson);
  const [schedules, setSchedules] = useState<Schedules>({});
  const quantity = useMemo(
    () => Object.values(schedules || {}).flat().length,
    [schedules]
  );
  const { user } = useAuth<IStudent>();
  const { openSignIn } = useSession();
  const stripe = useStripe();
  const dialog = useDialog();
  const loader = useLoader();
  const elements = useElements();
  const [name, setName] = useState<any>(false);
  const [saveCard, setSaveCard] = useState<any>(false); // NOT WORKING IN STRIPE INTEGRATION
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethods>(
    PaymentMethods.CREDIT_CARD
  );

  const card = elements?.getElement(CardNumberElement);

  const handleSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();

      const { checkout_session } = query.object || {};

      if (!user?.id) return openSignIn(false);

      if (!stripe || !elements || !card || !checkout_session) return;

      if (!name)
        return dialog.open({
          type: "error",
          title: "Erro para realizar o pagamento!",
          description: "Preencha o nome do titular do cartão.",
        });

      loader.run();

      try {
        const { error: stripeError } = await stripe.confirmCardPayment(
          checkout_session,
          {
            payment_method: {
              card,
              billing_details: {
                name,
                email: user?.user?.email,
              },
            },
            receipt_email: user?.user?.email,
          }
        );

        if (stripeError) {
          let description =
            "Ocorreu um erro ao tentar realizar o pagamento. Tente novamente mais tarde.";

          if (stripeError.code === "card_declined")
            description =
              "Infelizmente seu cartão foi recusado. Tente outro cartão ou outro método de pagamento.";

          if (stripeError.decline_code === "insufficient_funds")
            description =
              "Infelizmente seu cartão não possui saldo suficiente para realizar o pagamento.";

          if (stripeError.decline_code === "invalid_expiry_month")
            description =
              "Infelizmente o mês de expiração do seu cartão está inválido.";

          if (stripeError.decline_code === "invalid_expiry_year")
            description =
              "Infelizmente o ano de expiração do seu cartão está inválido.";

          if (stripeError.decline_code === "invalid_cvc")
            description =
              "Infelizmente o código de segurança do seu cartão está inválido.";

          if (stripeError.decline_code === "invalid_number")
            description = "Infelizmente o número do seu cartão está inválido.";

          return dialog.open({
            type: "error",
            title: "Pagamento nao realizado!",
            description,
          });
        }

        return dialog.open({
          type: "success",
          title: "Pagamento realizado!",
          description:
            "Sua aula já está reservada e deverá ser confirmada em breve. Confira mais em minhas aulas",
          closeLabel: "Continuar comprando",
          onClose: () => navigate(routesName.search),
          redirect: {
            label: "Minhas aulas",
            action: () => navigate(routesName.studentsDashboard),
          },
        });
      } catch (err) {
        console.log(err);
      } finally {
        loader.stop();
      }
    },
    [
      card,
      dialog,
      elements,
      loader,
      name,
      navigate,
      openSignIn,
      query.object,
      stripe,
      user?.id,
      user?.user?.email,
    ]
  );

  useEffect(() => {
    (async () => {
      const { checkout_session } = query.object || {};

      if (!checkout_session) return navigate(routesName.notFound);

      if (checkout_session) {
        const { data = {} } = await paymentsService.getCheckout(
          checkout_session
        );

        if (data?.lesson && data?.schedules) {
          const { lesson, schedules } = data;

          const schedulesFormated = formatSchedulesByStartKey(schedules);

          setLesson(lesson);
          setSchedules(schedulesFormated);
        } else {
          return navigate(routesName.notFound);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex direction="column" as="form" onSubmit={handleSubmit}>
      <SimpleHeader title="Checkout" backButton onBack={() => navigate(-1)} />

      <Box px={isMobile ? "18px" : "80px"} mt={10}>
        <PaymentMethod
          value={paymentMethod}
          onChange={(method: any) => setPaymentMethod(method)}
        />

        <Divider my={8} />

        <SimpleGrid columns={12} spacing={8} mt={6}>
          <GridItem colSpan={12}>
            <Heading fontSize="lg">
              Pagamento com {PaymentLabel?.[paymentMethod]}
            </Heading>
          </GridItem>

          <GridItem colSpan={isMobile ? 12 : 4} mt={isMobile ? -2 : 0}>
            <PaymentReceipt
              data={{
                quantity,
                amount: lesson?.price || 0,
                discount: 0,
                schedules,
              }}
            />
          </GridItem>

          <GridItem
            colSpan={isMobile ? 12 : 6}
            colStart={isMobile ? undefined : 7}
            mt={isMobile ? 4 : 0}
            mb={isMobile ? 6 : 0}
          >
            {paymentMethod === PaymentMethods.CREDIT_CARD && (
              <PaymentCardForm
                nameField={
                  <TextField
                    label="Nome do titular"
                    mb={6}
                    fullWidth
                    onChange={(e) => setName(e.target.value)}
                  />
                }
              />
            )}

            {paymentMethod === PaymentMethods.DEBIT_CARD && (
              <PaymentCardForm
                label="Cartão de débito"
                nameField={
                  <TextField
                    label="Nome do titular"
                    mb={6}
                    fullWidth
                    onChange={(e) => setName(e.target.value)}
                  />
                }
              />
            )}

            {[PaymentMethods.CREDIT_CARD, PaymentMethods.DEBIT_CARD].includes(
              paymentMethod
            ) && (
              <Checkbox
                mt={6}
                color="neutral.medium"
                value={saveCard}
                onChange={() => setSaveCard(!saveCard)}
              >
                Salvar cartão para compras futuras
              </Checkbox>
            )}

            <Divider my={6} />

            <Button w={isMobile ? "100%" : "30%"} size="lg" type="submit">
              Concluir pagamento
            </Button>
          </GridItem>
        </SimpleGrid>
      </Box>
    </Flex>
  );
}
