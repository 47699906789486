import {
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";

import { EntityUserType } from "@isurf-tech/types/domain/enums";

import { useResposiveness } from "../../../../hooks/responsiveness";
import { useSession } from "../../../../hooks/session";

export default function HomeSchoolFirstSection() {
  const { isMobile } = useResposiveness();
  const { openSignUp } = useSession();

  return (
    <Box
      id="home-main-first"
      as="section"
      position="relative"
      minHeight={isMobile ? "110vh " : "100vh"}
      mt={isMobile ? "0px" : "-80px"}
      px={isMobile ? "16px" : "80px"}
      mb={isMobile ? "64px" : "0px"}
    >
      <Box
        position="absolute"
        zIndex={-100}
        w="100vw"
        h="100vh"
        top={0}
        left={0}
      >
        <Grid
          templateColumns={isMobile ? "repeat(4, 1fr)" : "repeat(12, 1fr)"}
          templateRows={isMobile ? "auto auto" : "auto"}
          columnGap={8}
        >
          <GridItem
            position="relative"
            colStart={1}
            colSpan={isMobile ? 4 : 11}
            rowStart={1}
          >
            <Box
              height={isMobile ? "90vh" : "80vh"}
              width={isMobile ? "100vw" : "75vw"}
              bg="feedback.warning.lightest"
              ml={isMobile ? "0px" : "-80px"}
              borderBottomEndRadius="lg"
              position="absolute"
              top={0}
              left={0}
              zIndex={-3}
            />
          </GridItem>

          <GridItem
            position="relative"
            colStart={isMobile ? 1 : 6}
            colSpan={isMobile ? 4 : 6}
            rowStart={2}
            mt={isMobile ? "calc(100vh - 80%)" : "15vh"}
            ml={isMobile ? "10vw" : "15vh"}
          >
            <Box
              position="absolute"
              top={0}
              left={0}
              zIndex={-2}
              overflow="hidden"
            >
              <Image
                w={isMobile ? "100vw" : "35vw"}
                src={require(`../../../../assets/medias/mockup-details.${
                  isMobile ? "small" : "large"
                }.png`)}
              />
              {/* <WaveIcon width={isMobile ? "100vw" : "40vw"} height="100%" /> */}
            </Box>
          </GridItem>
        </Grid>
      </Box>

      <Grid
        templateColumns={isMobile ? "repeat(4, 1fr)" : "repeat(12, 1fr)"}
        templateRows={isMobile ? "auto min-content auto" : "repeat(1, 1fr)"}
        columnGap={8}
        rowGap={6}
        mt={isMobile ? "40px" : "30vh"}
      >
        <GridItem colSpan={4} rowStart={1}>
          <Heading fontSize={isMobile ? "2xl" : "3xl"} color="neutral.darkest">
            Suas aulas de surf mais organizadas
          </Heading>
        </GridItem>

        <GridItem colSpan={isMobile ? 4 : 3} rowStart={2}>
          <Text fontSize="sm" color="neutral.darkest">
            Anuncie suas aulas de surf, receba o agendamento automático dos
            alunos e tenha suas aulas organizadas na palma de sua mão.
          </Text>
        </GridItem>

        <GridItem colSpan={isMobile ? 4 : 3} rowStart={3} mt={isMobile ? 2 : 4}>
          <Button
            size={isMobile ? "md" : "lg"}
            w="100%"
            onClick={() => openSignUp({ role: EntityUserType.SCHOOL })}
          >
            Cadastre sua escola
          </Button>
        </GridItem>
      </Grid>
    </Box>
  );
}
