import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { Box, Button, Grid, GridItem, Heading, Text } from "@chakra-ui/react";

import { useDialog } from "../../hooks/dialog";
import { useLoader } from "../../hooks/loader";
import { useResposiveness } from "../../hooks/responsiveness";
import { supportService } from "../../services/api";
import TextField from "../text-field";

const formSchema = yup
  .object()
  .shape({
    name: yup.string().required(),
    email: yup.string().email().required(),
    description: yup.string().required(),
  })
  .required();

export default function SectionQuestion() {
  const { isMobile } = useResposiveness();
  const loader = useLoader();
  const dialog = useDialog();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const onSubmit = async (formData: any) => {
    loader.run();

    const { data, error } = await supportService.create({
      name: formData.name,
      email: formData.email,
      description: formData.description,
    });

    loader.stop();

    dialog.open({
      type: error ? "error" : "success",
      title: error ? "Erro" : "Obrigado por entrar em contato!",
      description: error ? error?.message : data,
      onClose: () => {
        if (!error) reset();
      },
    });
  };

  return (
    <Box id="contact" as="section">
      <Grid
        templateColumns={isMobile ? "repeat(4, 1fr)" : "repeat(12, 1fr)"}
        templateRows={isMobile ? "auto auto" : "auto"}
        gap={8}
        padding={isMobile ? "16px 16px" : "32px 80px"}
        pt={isMobile ? "64px" : "120px"}
      >
        <GridItem colSpan={isMobile ? 4 : 3} rowStart={isMobile ? 1 : 1}>
          <Box>
            <Heading fontSize="3xl" color="neutral.darkset">
              Tem alguma dúvida?
            </Heading>
            <Text fontSize="sm" color="neutral.dark">
              Manda pra gente que a gente responde!
            </Text>
          </Box>
        </GridItem>

        <GridItem
          colStart={isMobile ? 1 : 7}
          colSpan={isMobile ? 4 : 7}
          rowStart={isMobile ? 2 : 1}
          as="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextField
            label="Nome (opcional)"
            placeholder="Gabriel Medina"
            fullWidth
            inputProps={register("name")}
            error={errors?.name?.message}
          />
          <TextField
            label="Email"
            placeholder="nome@email.com"
            fullWidth
            mt={4}
            inputProps={register("email")}
            error={errors?.email?.message}
          />
          <TextField
            label="Mensagem"
            type="text-area"
            placeholder=" "
            fullWidth
            mt={4}
            inputProps={register("description")}
            error={errors?.description?.message}
          />

          <Button type="submit" size="lg" mt="24px">
            Enviar
          </Button>
        </GridItem>
      </Grid>
    </Box>
  );
}
