import React from "react";
import { useFormContext } from "react-hook-form";
import * as yup from "yup";

import { GridItem, Heading } from "@chakra-ui/react";

import CurrencyField from "../../../../components/inputs/currency";
import { useResposiveness } from "../../../../hooks/responsiveness";

export const formSchema = {
  price: yup
    .number()
    .min(15, "O valor deve ser no minimo R$ 15,00")
    .required("Preço é obrigatório"),
};

export default function StepPrices() {
  const { isMobile } = useResposiveness();
  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const price = watch("price");

  return (
    <React.Fragment>
      <GridItem colStart={1} colSpan={isMobile ? 4 : 6} mt={2}>
        <Heading fontSize="xl" fontWeight="semibold">
          Preços
        </Heading>
      </GridItem>

      <GridItem colStart={1} colSpan={isMobile ? 4 : 5} mt={8}>
        <CurrencyField
          label="Preço unitário"
          placeholder="00,00"
          value={Number(Number(price * 0.01).toFixed(2))}
          defaultValue={0}
          inputProps={register("price", {
            required: "Campo obrigatório",
          })}
          error={errors?.price?.message}
          valueIsNumericString
          decimalSeparator=","
          thousandSeparator="."
          onChange={(value) => setValue("price", parseInt(String(value * 100)) || 0)}
        />
      </GridItem>
    </React.Fragment>
  );
}
