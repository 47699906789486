import axios, { Axios } from "axios";

interface IGeolocationData {
  country: string;
  countryCode: string;
  state: string;
  city: string;
  postcode: string;
}

interface IResponse {
  place_id: number;
  licence: string;
  osm_type: string;
  osm_id: number;
  lat: string;
  lon: string;
  place_rank: number;
  category: string;
  type: string;
  importance: number;
  addresstype: string;
  name: string;
  display_name: string;
  address: {
    tourism: string;
    road: string;
    town: string;
    county: string;
    state: string;
    postcode: string;
    country: string;
    country_code: string;
  };
}

class ApiGeolocation {
  api: Axios;

  constructor() {
    this.api = axios.create({
      baseURL: "https://nominatim.openstreetmap.org/reverse",
      params: {
        format: "jsonv2",
      },
    });
  }

  async getGeolocationData(
    lat: number,
    lon: number
  ): Promise<{
    data: IGeolocationData | null;
    error: any;
  }> {
    let result = { data: null, error: null } as {
      data: IGeolocationData | null;
      error: any;
    };

    try {
      const { data } = await this.api.get<IResponse>("", {
        params: {
          lat,
          lon,
        },
      });

      if (!data) result.data = null;
      else
        result.data = {
          country: data.address.country,
          countryCode: data.address.country_code,
          state: data.address.state,
          city: data.address.town,
          postcode: data.address.postcode,
        } as IGeolocationData;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result.error = error.response?.data;
      } else {
        result.error = error as any;
      }
    } finally {
      return result;
    }
  }
}

const apiGeolocation = new ApiGeolocation();
export default apiGeolocation;
