import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import {
  Avatar,
  Box,
  Divider,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useToken,
} from "@chakra-ui/react";

import { ISchool, IStudent } from "@isurf-tech/types/domain";
import { EntityUserType } from "@isurf-tech/types/domain/enums";

import ChevronDownIcon from "../assets/icons/chevron-down";
import routesName from "../config/routes";
import { useAuth } from "../hooks/auth";
import { useResposiveness } from "../hooks/responsiveness";
import { useSession } from "../hooks/session";

export default function MenuAvatar() {
  const { isMobile } = useResposiveness();
  const { logout } = useSession();
  const { user } = useAuth<IStudent | ISchool>();
  const navigate = useNavigate();

  const [neutralDarkestColor] = useToken("colors", ["neutral.darkest"]);

  const goToLessons = useCallback(() => {
    if (user?.entity === EntityUserType.SCHOOL)
      return navigate(routesName.schoolsDashboard);

    if (user?.entity === EntityUserType.STUDENT)
      return navigate(routesName.studentsDashboard);
  }, [navigate, user]);

  const goToPerfil = useCallback(() => {
    if (user?.entity === EntityUserType.SCHOOL)
      return navigate(routesName.schoolsPerfil);

    if (user?.entity === EntityUserType.STUDENT)
      return navigate(routesName.studentsPerfil);
  }, [navigate, user]);

  const goToHelp = () => {
    window.open(routesName.external.help, "_blank");
  };

  return (
    <Menu>
      <MenuButton
        sx={{
          "> span": {
            display: "flex",
            alignItems: "center",
          },
        }}
        _focus={{
          outline: "2px solid",
          outlineColor: "brand.medium",
          outlineOffset: 6,
          borderRadius: "sm",
        }}
      >
        <Avatar
          size="sm"
          src={
            (user as ISchool)?.owner?.avatar || (user as IStudent)?.user?.avatar
          }
        />

        {!isMobile && (
          <Text
            ml={2}
            maxW="5vw"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {(user as ISchool)?.name || (user as IStudent)?.user?.name}
          </Text>
        )}

        {isMobile && !user && (
          <Box
            border="none"
            _focus={{
              outline: "none",
            }}
            ml={isMobile ? -2 : 2}
            display="flex"
            alignItems="center"
            justifyContent="center"
            py="auto"
          >
            <ChevronDownIcon fontSize="md" color={neutralDarkestColor} />
          </Box>
        )}
      </MenuButton>
      <MenuList shadow="none" borderRadius="md">
        <MenuItem p="16px" onClick={goToLessons}>
          <Heading fontSize="sm">Minhas aulas</Heading>
        </MenuItem>
        <MenuItem p="16px" onClick={goToPerfil}>
          <Heading fontSize="sm">Conta</Heading>
        </MenuItem>
        <Divider />
        <MenuItem p="16px" color="neutral.dark" onClick={goToHelp}>
          Ajuda
        </MenuItem>
        <MenuItem p="16px" color="neutral.dark" onClick={logout}>
          Sair da conta
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
