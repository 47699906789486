import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputProps,
  Textarea,
  TextareaProps,
} from "@chakra-ui/react";

export interface TextFieldPropsI extends FormControlProps {
  label: string;
  value?: string;
  placeholder?: string;
  helperText?: string;
  fullWidth?: boolean;
  type?: InputProps["type"] | "text-area";
  startAdormentIcon?: any;
  inputProps?: InputProps | TextareaProps;
  onChange?: (e: any) => void;
  error?: string | any;
  rightIcon?: any;
}

export default function TextField({
  label,
  value,
  placeholder = "Type here...",
  helperText = "",
  fullWidth = false,
  type = "text",
  inputProps = {},
  onChange,
  error = "",
  rightIcon,
  ...props
}: TextFieldPropsI) {
  return (
    <FormControl {...props} isInvalid={!!error}>
      <FormLabel>{label}</FormLabel>

      <InputGroup>
        {type === "text-area" ? (
          <Textarea
            value={value}
            placeholder={placeholder}
            w={fullWidth ? "100%" : ""}
            onChange={onChange}
            {...(inputProps as TextareaProps)}
          />
        ) : (
          <Input
            type={type}
            value={value}
            placeholder={placeholder}
            w={fullWidth ? "100%" : ""}
            onChange={onChange}
            {...(inputProps as InputProps)}
          />
        )}
      </InputGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}

      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
}
