import { Flex } from "@chakra-ui/react";

import CardCvcField from "../../../../components/stripe/card-cvc-element";
import CardExpiryField from "../../../../components/stripe/card-expiry-element";
import CreditCardField from "../../../../components/stripe/card-number-element";
import TextField from "../../../../components/text-field";
import { useResposiveness } from "../../../../hooks/responsiveness";

export default function PaymentCardForm({
  label = "Cartão de crédito",
  nameField = <TextField label="Nome do titular" mb={6} fullWidth />,
}) {
  const { isMobile } = useResposiveness();

  return (
    <Flex mt={-2} direction="column">
      {nameField}

      <CreditCardField label={label} />

      <Flex
        alignItems={isMobile ? "left" : "center"}
        mt={6}
        direction={isMobile ? "column" : "row"}
      >
        <CardExpiryField
          label="Validade"
          formControlProps={{ w: isMobile ? "45%" : "20%" }}
        />

        <CardCvcField
          label="Código de segurança"
          w={isMobile ? "45%" : "22%"}
          ml={isMobile ? 0 : 6}
          mt={isMobile ? 6 : 0}
        />
      </Flex>
    </Flex>
  );
}
