import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Box, Button, Flex } from "@chakra-ui/react";

import { ISchool, IStudent } from "@isurf-tech/types/domain";
import { EntityUserType } from "@isurf-tech/types/domain/enums";

import routesName from "../../config/routes";
import { useAuth } from "../../hooks/auth";
import { useResposiveness } from "../../hooks/responsiveness";
import MenuAvatar from "../menu-avatar";
import RefoundsCredits from "../refounds-credits";
import HeaderHomeMenu from "./home-menu";
import HeaderLogoIcon from "./logo";


interface HeaderPropsI {
  buttonRedirect?: {
    label: string;
    redirect: string;
  } | null;
  onSearch?: any;
  children?: any;
}

export default function Header({
  buttonRedirect,
  onSearch,
  children,
}: HeaderPropsI) {
  const navigate = useNavigate();
  const { isMobile } = useResposiveness();
  const { user } = useAuth<IStudent | ISchool>();
  const location = useLocation();

  const isHome = useMemo(
    () => location.pathname === routesName.main,
    [location.pathname]
  );

  const isHomeSchool = useMemo(
    () => location.pathname === routesName.schools,
    [location.pathname]
  );

  const isSearchPage = useMemo(
    () => location.pathname === routesName.search,
    [location.pathname]
  );

  return (
    <Box
      maxHeight={children ? "" : "80px"}
      borderBottom={
        (!isHome && !isHomeSchool) || isMobile ? "1px solid #C5CED6" : "none"
      }
      padding={isMobile ? "16px" : "16px 40px"}
      zIndex={1000}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <HeaderLogoIcon />

        <Flex>
          {((!isHomeSchool && !isSearchPage) || (isSearchPage && isMobile)) && (
            <HeaderHomeMenu onSearch={onSearch} logged={!!user} />
          )}

          {((!isHome && isHomeSchool) || (isMobile && isHomeSchool)) && (
            <HeaderHomeMenu logged={!!user} />
          )}

          {user && !(isHome || isHomeSchool) && (
            <Flex direction="row" alignItems="center">
              {user &&
                user.entity === EntityUserType.STUDENT &&
                (user as IStudent).refundsCredits &&
                !!(user as IStudent).refundsCredits.length && (
                  <Flex
                    direction="row"
                    alignItems="center"
                    mr={6}
                    maxHeight={isMobile ? "16px" : "40px"}
                  >
                    <RefoundsCredits
                      refunds={(user as IStudent)?.refundsCredits || []}
                    />
                  </Flex>
                )}

              {buttonRedirect && buttonRedirect?.label && (
                <Button
                  size="md"
                  mr={8}
                  onClick={() => navigate(buttonRedirect.redirect)}
                >
                  {buttonRedirect.label}
                </Button>
              )}

              <MenuAvatar />
            </Flex>
          )}
        </Flex>
      </Flex>
      {children}
    </Box>
  );
}
