import { useCallback, useState } from "react";

import { IClassroomMessage } from "@isurf-tech/types/domain";

import { DialogActionResponse } from "../contexts/dialog";
import { classroomService } from "../services/api";
import { useClassroom } from "./classroom";
import { useDialog } from "./dialog";
import { useLoader } from "./loader";

export default function useMessages() {
  const dialog = useDialog();
  const loader = useLoader();
  const { addMessage } = useClassroom();
  const [messages, setMessages] = useState<IClassroomMessage[]>([]);

  const load = useCallback(async (classroomId: string) => {
    if (!classroomId) return;

    const { data = [] } = await classroomService.findMessages(classroomId);

    setMessages(data.filter((message: IClassroomMessage) => message.id));
  }, []);

  const requestNewMessage = useCallback(
    async (
      classroomId: string,
      content: string
    ): Promise<DialogActionResponse> => {
      if (!classroomId)
        return {
          type: "error",
          title: "Erro ao enviar mensagem.",
          description:
            "Houve um erro ao enviar mensagem, por favor tente novamente ou entre em contato com o suporte.",
        };

      const { data, error } = await classroomService.sendMessage(
        classroomId,
        "TEST",
        content
      );

      if (error)
        return {
          type: "error",
          title: "Erro ao enviar mensagem.",
          description:
            error?.message ||
            "Houve um erro ao enviar mensagem, por favor tente novamente ou entre em contato com o suporte.",
        };

      if (data && !error) await addMessage(data);
      if (data) setMessages([data, ...messages]);

      return {
        type: "success",
        title: "Mensagem enviada com sucesso!",
        description:
          data?.message ||
          "A mensagem foi enviada com sucesso. Logo seus alunos serão notificados.",
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addMessage, loader, messages]
  );

  const newMessageDialog = useCallback(
    (classroomId: string) => {
      dialog.open({
        type: "text-input",
        title: "Mensagem para turma",
        description:
          "Sua mensagem será enviada por email para todos os participantes da turma.",
        inputProps: {
          label: "Mensagem",
          type: "text-area",
          placeholder: "Digite aqui sua mensagem aqui...",
          fullWidth: true,
          inputProps: {
            minH: "20vh",
          },
        },
        redirect: {
          label: "Enviar mensagem",
          action: (content) => requestNewMessage(classroomId, content),
        },
        closeLabel: "Cancelar mensagem",
      });
    },
    [dialog, requestNewMessage]
  );

  return { messages, load, newMessageDialog, requestNewMessage };
}
