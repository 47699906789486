import axios, { AxiosInstance } from "axios";

import { ServiceResponse } from "..";

export class ClassroomService {
  constructor(private api: AxiosInstance) {}

  async find(lessonId: string): Promise<ServiceResponse> {
    let result = { data: null, error: null };

    try {
      const { data } = await this.api.get(`/classrooms/${lessonId}`);

      result.data = data || {};
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result.error = error.response?.data;
      } else {
        result.error = error as any;
      }
    } finally {
      return result;
    }
  }

  async sendMessage(
    lessonId: string,
    title: string,
    content: string
  ): Promise<ServiceResponse> {
    let result = { data: null, error: null };

    try {
      const { data } = await this.api.post(`/classrooms/${lessonId}/messages`, {
        title,
        content,
      });

      result.data = data || {};
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result.error = error.response?.data;
      } else {
        result.error = error as any;
      }
    } finally {
      return result;
    }
  }

  async cancel(id: string, description?: string): Promise<ServiceResponse> {
    let result = { data: null, error: null };

    try {
      const { data } = await this.api.post(`/classrooms/${id}/cancel`, {
        description,
      });

      result.data = data || {};
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result.error = error.response?.data;
      } else {
        result.error = error as any;
      }
    } finally {
      return result;
    }
  }

  async findMessages(classroomId: string): Promise<ServiceResponse> {
    let result = { data: null, error: null };

    try {
      const { data } = await this.api.get(
        `/classrooms/${classroomId}/messages`
      );

      result.data = data || {};
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result.error = error.response?.data;
      } else {
        result.error = error as any;
      }
    } finally {
      return result;
    }
  }

  async cancelStudent(
    id: string,
    student: string,
    description?: string
  ): Promise<ServiceResponse> {
    let result = { data: null, error: null };

    try {
      const { data } = await this.api.post(`/classrooms/${id}/cancel/student`, {
        student,
        description,
      });

      result.data = data || {};
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result.error = error.response?.data;
      } else {
        result.error = error as any;
      }
    } finally {
      return result;
    }
  }
}
