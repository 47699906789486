import React from "react";
import {
  NumberFormatValues,
  NumericFormatProps,
  SourceInfo,
  PatternFormat,
} from "react-number-format";

import {
  Box,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  useToken,
} from "@chakra-ui/react";

import CreditCardIcon from "../../assets/icons/credit-card";

interface CreditCardFieldPropsI extends Omit<NumericFormatProps, "onChange"> {
  label: string;
  value?: string | any;
  placeholder?: string;
  helperText?: string;
  inputProps?: any;
  formControlProps?: FormControlProps;
  error?: string | any;
  onChange?: (e: any, values: any, info: any) => void;
  mask?: string;
  input?: any;
}

export default function CreditCardField({
  label,
  value,
  placeholder = "0000 0000 0000 0000",
  helperText = "",
  inputProps: { ref, ...inputProps } = {},
  error = "",
  formControlProps = {},
  onChange,
  mask = "#### #### #### ####",
  input,
  ...props
}: CreditCardFieldPropsI) {
  const [neutralDark] = useToken("colors", ["neutral.dark"]);

  const handleChangeValue = (
    values: NumberFormatValues,
    sourceInfo: SourceInfo
  ) => {
    if (onChange) onChange(values.value, values, sourceInfo);
    if (inputProps.onChange) inputProps.onChange(sourceInfo.event);
  };

  return (
    <FormControl isInvalid={!!error}>
      <FormLabel>{label}</FormLabel>

      <InputGroup>
        <InputLeftElement
          zIndex={-1}
          bg="neutral.light"
          borderLeftRadius="sm"
          minHeight="56px"
          p={6}
          children={
            <Box fontSize="xs">
              <CreditCardIcon color={neutralDark} />
            </Box>
          }
        />
        {input ? (
          input
        ) : (
          <PatternFormat
            customInput={Input}
            valueIsNumericString
            placeholder={placeholder}
            pl="60px !important"
            onValueChange={handleChangeValue}
            value={value}
            format={mask}
            {...inputProps}
            {...props}
          />
        )}
      </InputGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
}
