import { ComponentStyleConfig } from "@chakra-ui/react";

const ButtonTheme: ComponentStyleConfig = {
  baseStyle: {
    padding: "16px 32px",
    borderRadius: "base",
    fontSize: "xs",
    fontWeight: "semibold",
    fontFamily: "body",

    ":disabled": {
      opacity: "0.32",
    },
    ":focus": {
      borderRadius: "base",
      border: "thin",
      borderColor: "brand.medium",
    },
  },
  sizes: {
    sm: {
      fontSize: "xs",
      padding: "8px 16px",
      minH: "1.875rem",
    },
    md: {
      fontSize: "xs",
      padding: "16px 32px",
      minH: "2.875rem",
    },
    lg: {
      fontSize: "sm",
      padding: "24px 48px",
      minH: "4rem",
    },
  },
  variants: {
    outline: {
      bg: "neutral.lightest",
      color: "neutral.dark",
      border: "hairline",
      borderColor: "neutral.dark",

      ":hover": {
        bg: "neutral.darkest",
        color: "neutral.lightest",
      },
      ":disabled": {
        bg: "neutral.lightest",
      },
    },
    "outline-boxed": {
      width: "100%",
      bg: "neutral.lightest",
      color: "neutral.dark",
      border: "hairline",
      borderColor: "neutral.dark",

      ":hover": {
        bg: "neutral.darkest",
        color: "neutral.lightest",
      },
      ":disabled": {
        bg: "neutral.lightest",
      },
    },
    solid: {
      bg: "brand.dark",
      color: "neutral.lightest",
      ":hover": {
        bg: "brand.darkest",
      },
      ":disabled": {
        bg: "brand.darkest",
      },
    },
    "solid-boxed": {
      width: "100%",
      bg: "brand.dark",
      color: "neutral.lightest",
      ":hover": {
        bg: "brand.darkest",
      },
      ":disabled": {
        bg: "brand.darkest",
      },
    },
    danger: {
      width: "100%",
      bg: "feedback.danger.dark",
      color: "neutral.lightest",
      ":hover": {
        bg: "feedback.danger.darkest",
      },
      ":disabled": {
        bg: "feedback.danger.dark",
      },
      ":focus": {
        borderColor: "feedback.danger.dark",
      },
    },
  },
  defaultProps: {
    variant: "solid",
    size: "sm",
  },
};

export default ButtonTheme;
