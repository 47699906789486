import { CardNumberElement } from "@stripe/react-stripe-js";
import { useState } from "react";

import { Box, useToken } from "@chakra-ui/react";

import CreditCardFieldComponent from "../inputs/credit-card";

export default function CreditCardField({ label }: any) {
  const [neutralDark] = useToken("colors", ["neutral.dark", "neutral.darkest"]);
  const [fontWeightMedium] = useToken("fontWeights", ["medium"]);
  const [fontSizeXs] = useToken("fontSize", ["xs"]);
  const [fontsBody] = useToken("fonts", ["body"]);
  const [focus, setFocus] = useState(false);

  return (
    <CreditCardFieldComponent
      label={label}
      input={
        <Box
          w="100%"
          border="hairline"
          borderColor={focus ? "brand.medium" : "neutral.medium"}
          borderRadius="sm"
          flexDirection="row"
          minHeight="56px"
        >
          <Box
            w="100%"
            maxHeight="56px"
            p="18px"
            pl="60px"
            pt="20px"
            pb="18px"
            m={0}
          >
            <CardNumberElement
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
              onEscape={() => setFocus(false)}
              options={{
                style: {
                  base: {
                    fontFamily: fontsBody,
                    fontSize: fontSizeXs,
                    color: neutralDark,
                    fontWeight: fontWeightMedium,
                  },
                },
              }}
            />
          </Box>
        </Box>
      }
    />
  );
}
