export default function BookOpenIcon({ size = 24, color = "black" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.11133 7.5H14.4447C15.9181 7.5 17.3312 8.08532 18.373 9.12718C19.4149 10.1691 20.0002 11.5821 20.0002 13.0556V32.5C20.0002 31.3949 19.5612 30.3351 18.7798 29.5537C17.9984 28.7723 16.9386 28.3333 15.8335 28.3333H6.11133V7.5Z"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.8889 7.5H25.5556C24.0821 7.5 22.6691 8.08532 21.6272 9.12718C20.5853 10.1691 20 11.5821 20 13.0556V32.5C20 31.3949 20.439 30.3351 21.2204 29.5537C22.0018 28.7723 23.0616 28.3333 24.1667 28.3333H33.8889V7.5Z"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
