import { Flex, Heading, useToken } from "@chakra-ui/react";

import PlusIcon from "../../../../../../assets/icons/plus";

export default function AddAppointment({ onClick }: any) {
  const [neutralDark] = useToken("colors", ["neutral.dark"]);

  return (
    <Flex
      flex={1}
      justify="center"
      align="center"
      maxH="48px"
      border="hairline"
      borderColor="neutral.dark"
      borderRadius="sm"
      py={4}
      as="button"
      _focus={{
        outline: "2px solid",
        outlineColor: "brand.medium",
      }}
      mt={2}
      onClick={onClick}
    >
      <PlusIcon size={16} color={neutralDark} />
      <Heading ml={2} fontSize="sm" color={neutralDark}>
        Adicionar
      </Heading>
    </Flex>
  );
}
