export default function CreditCardIcon({ size = 20, color = "#363A3C" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3265_1969)">
        <path
          d="M17.4997 3.33301H2.49967C1.5792 3.33301 0.833008 4.0792 0.833008 4.99967V14.9997C0.833008 15.9201 1.5792 16.6663 2.49967 16.6663H17.4997C18.4201 16.6663 19.1663 15.9201 19.1663 14.9997V4.99967C19.1663 4.0792 18.4201 3.33301 17.4997 3.33301Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.833008 8.33301H19.1663"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3265_1969">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
