import axios, { AxiosInstance } from "axios";

import { ServiceResponse } from "..";

export class InvitesService {
  constructor(private api: AxiosInstance) {}

  async student(email: string, lesson: string): Promise<ServiceResponse> {
    let result = { data: null, error: null };

    try {
      const { data } = await this.api.post(`/invites/students`, {
        email,
        lesson,
      });

      result.data = data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result.error = error.response?.data;
      } else {
        result.error = error as any;
      }
    } finally {
      return result;
    }
  }
}
