import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import {
  Box,
  Button,
  Divider,
  Flex,
  Link,
  Spinner,
  Text,
} from "@chakra-ui/react";


import routesName from "../../config/routes";
import { useResposiveness } from "../../hooks/responsiveness";
import { useSession } from "../../hooks/session";
import TextField from "../text-field";
// import AuthExternalProviders from "./auth-external-providers";


const formSchema = yup
  .object()
  .shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
  })
  .required();

export default function SessionSignIn() {
  const navigate = useNavigate();
  const { isMobile } = useResposiveness();
  const { openSignUp, handleSignIn, close } = useSession();
  const {
    register,
    handleSubmit,
    setError,
    formState: { isSubmitting, errors },
  } = useForm({ resolver: yupResolver(formSchema) });

  const onSubmit = async (form: any) => {
    const result = await handleSignIn(form);

    if (result && result?.error)
      return setError("submit", {
        message: "Dados incorretos! verifique seu email e senha.",
      });
  };

  const forgotPassword = () => {
    navigate(routesName.recoveryPassword);
    close();
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Text
          fontSize="sm"
          color="neutral.dark"
          fontWeight="normal"
          mt={isMobile ? 6 : 4}
        >
          Para continuar, inicie sua sessão com sua conta no iSurf.
        </Text>

        {/* <AuthExternalProviders /> */}

        <Divider mt="6" />

        <Flex direction="column" mt={6}>
          <TextField
            label="Email"
            placeholder="medina@isurf.com"
            fullWidth
            inputProps={register("email")}
            error={errors?.email?.message}
          />
          <TextField
            type="password"
            label="Senha"
            placeholder="Digite sua senha aqui..."
            fullWidth
            mt={6}
            inputProps={register("password")}
            error={errors?.password?.message}
          />
        </Flex>

        <Box mt={6} w="100%">
          <Link fontSize="xs" onClick={forgotPassword}>
            Esqueceu sua senha?
          </Link>
        </Box>

        {isSubmitting && (
          <Flex mt={2} w="100%" justify="center">
            <Spinner color="brand.dark" />
          </Flex>
        )}

        {errors.submit && errors.submit.message && (
          <Flex mt={6} mb={-2} w="100%" justify="center">
            <Text fontSize="sm" color="feedback.warning.medium">
              {errors.submit.message.toString()}
            </Text>
          </Flex>
        )}

        <Button
          type="submit"
          mt={9}
          size="lg"
          width="100%"
          disabled={isSubmitting}
        >
          Acessar conta
        </Button>

        <Flex mt={4} justify="center" align="center">
          <Text color="neutral.dark" fontSize="xs">
            Não tem uma conta?
          </Text>
          <Link ml={1} fontSize="xs" onClick={openSignUp}>
            Cadastre-se agora
          </Link>
        </Flex>
      </form>
    </React.Fragment>
  );
}
