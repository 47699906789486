import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup as RadioGroupComponent,
  Stack,
} from "@chakra-ui/react";

type Option = {
  id?: string;
  label: string;
  value: any;
};

interface RadioGroupPropsI extends FormControlProps {
  label: string;
  value?: string;
  placeholder?: string;
  helperText?: string;
  error?: string | any;
  options: Option[];
  radioProps?: any;
  radioItemProps?: any;
  direction?: "row" | "column";
  labelProps?: any;
  variant?: "outline" | any;
  spacing?: number;
  onRenderLabel?: (label: string, option: Option) => any;
}

export default function RadioGroup({
  label,
  helperText,
  options,
  placeholder,
  error,
  radioProps,
  radioItemProps,
  direction = "column",
  labelProps,
  variant,
  onRenderLabel,
  value,
  defaultValue,
  spacing,
  ...props
}: RadioGroupPropsI) {
  return (
    <FormControl {...props} isInvalid={!!error}>
      <FormLabel {...labelProps}>{label}</FormLabel>
      <RadioGroupComponent value={value} defaultValue={defaultValue} {...radioProps}>
        <Stack direction={direction} spacing={spacing}>
          {options.map(({ label, value, ...props }) => (
            <Radio
              key={value}
              value={value}
              variant={variant}
              {...radioItemProps}
            >
              {onRenderLabel
                ? onRenderLabel(label, { label, value, ...props })
                : label}
            </Radio>
          ))}
        </Stack>
      </RadioGroupComponent>

      {error && <FormErrorMessage>{error}</FormErrorMessage>}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
