export default function HowToWorkThirdIcon() {
  return (
    <svg
      width="199"
      height="108"
      viewBox="0 0 199 108"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 8C0.5 3.85786 3.85786 0.5 8 0.5H175C179.142 0.5 182.5 3.85786 182.5 8V36C182.5 40.1421 179.142 43.5 175 43.5H8C3.85786 43.5 0.5 40.1421 0.5 36V8Z"
        fill="white"
        stroke="#C5CED6"
      />
      <rect x="11" y="11" width="22" height="22" rx="11" fill="#F0F4F7" />
      <g clipPath="url(#clip0_1160_13263)">
        <path
          d="M22.0013 13.4453C19.5568 13.4453 17.5586 15.4435 17.5586 17.888C17.5586 20.3325 19.5568 22.3307 22.0013 22.3307C24.4457 22.3307 26.444 20.3325 26.444 17.888C26.444 15.4435 24.4457 13.4453 22.0013 13.4453Z"
          fill="#0F7E99"
        />
        <path
          d="M29.6256 25.8809C29.5092 25.5899 29.354 25.3183 29.1794 25.0661C28.287 23.7468 26.9096 22.8738 25.3575 22.6604C25.1635 22.641 24.9501 22.6798 24.7949 22.7962C23.9801 23.3976 23.0101 23.708 22.0013 23.708C20.9924 23.708 20.0224 23.3976 19.2076 22.7962C19.0524 22.6798 18.839 22.6216 18.645 22.6604C17.093 22.8738 15.6961 23.7468 14.8231 25.0661C14.6485 25.3183 14.4933 25.6093 14.3769 25.8809C14.3187 25.9973 14.3381 26.1331 14.3963 26.2495C14.5515 26.5211 14.7455 26.7927 14.9201 27.0255C15.1917 27.3942 15.4827 27.7239 15.8126 28.0343C16.0841 28.3059 16.3945 28.5581 16.705 28.8103C18.2376 29.955 20.0806 30.5564 21.9819 30.5564C23.8831 30.5564 25.7262 29.955 27.2588 28.8103C27.5692 28.5776 27.8796 28.3059 28.1512 28.0343C28.4616 27.7239 28.772 27.3941 29.0436 27.0255C29.2376 26.7733 29.4122 26.5211 29.5674 26.2495C29.6644 26.1331 29.6838 25.9973 29.6256 25.8809Z"
          fill="#0F7E99"
        />
      </g>
      <rect x="41" y="14" width="126" height="16" rx="8" fill="#F0F4F7" />
      <path
        d="M8.5 40C8.5 35.8579 11.8579 32.5 16 32.5H183C187.142 32.5 190.5 35.8579 190.5 40V68C190.5 72.1421 187.142 75.5 183 75.5H16C11.8579 75.5 8.5 72.1421 8.5 68V40Z"
        fill="white"
        stroke="#C5CED6"
      />
      <rect x="19" y="43" width="22" height="22" rx="11" fill="#F0F4F7" />
      <g clipPath="url(#clip1_1160_13263)">
        <path
          d="M30.0013 45.4453C27.5568 45.4453 25.5586 47.4435 25.5586 49.888C25.5586 52.3325 27.5568 54.3307 30.0013 54.3307C32.4457 54.3307 34.444 52.3325 34.444 49.888C34.444 47.4435 32.4457 45.4453 30.0013 45.4453Z"
          fill="#0F7E99"
        />
        <path
          d="M37.6256 57.8809C37.5092 57.5899 37.354 57.3183 37.1794 57.0661C36.287 55.7468 34.9096 54.8738 33.3575 54.6604C33.1635 54.641 32.9501 54.6798 32.7949 54.7962C31.9801 55.3976 31.0101 55.708 30.0013 55.708C28.9924 55.708 28.0224 55.3976 27.2076 54.7962C27.0524 54.6798 26.839 54.6216 26.645 54.6604C25.093 54.8738 23.6961 55.7468 22.8231 57.0661C22.6485 57.3183 22.4933 57.6093 22.3769 57.8809C22.3187 57.9973 22.3381 58.1331 22.3963 58.2495C22.5515 58.5211 22.7455 58.7927 22.9201 59.0255C23.1917 59.3942 23.4827 59.7239 23.8126 60.0343C24.0841 60.3059 24.3945 60.5581 24.705 60.8103C26.2376 61.955 28.0806 62.5564 29.9819 62.5564C31.8831 62.5564 33.7262 61.955 35.2588 60.8103C35.5692 60.5776 35.8796 60.3059 36.1512 60.0343C36.4616 59.7239 36.772 59.3941 37.0436 59.0255C37.2376 58.7733 37.4122 58.5211 37.5674 58.2495C37.6644 58.1331 37.6838 57.9973 37.6256 57.8809Z"
          fill="#0F7E99"
        />
      </g>
      <rect x="49" y="46" width="126" height="16" rx="8" fill="#F0F4F7" />
      <path
        d="M16.5 72C16.5 67.8579 19.8579 64.5 24 64.5H191C195.142 64.5 198.5 67.8579 198.5 72V100C198.5 104.142 195.142 107.5 191 107.5H24C19.8579 107.5 16.5 104.142 16.5 100V72Z"
        fill="white"
        stroke="#C5CED6"
      />
      <rect x="27" y="75" width="22" height="22" rx="11" fill="#F0F4F7" />
      <g clipPath="url(#clip2_1160_13263)">
        <path
          d="M38.0013 77.4453C35.5568 77.4453 33.5586 79.4435 33.5586 81.888C33.5586 84.3325 35.5568 86.3307 38.0013 86.3307C40.4457 86.3307 42.444 84.3325 42.444 81.888C42.444 79.4435 40.4457 77.4453 38.0013 77.4453Z"
          fill="#0F7E99"
        />
        <path
          d="M45.6256 89.8809C45.5092 89.5899 45.354 89.3183 45.1794 89.0661C44.287 87.7468 42.9096 86.8738 41.3575 86.6604C41.1635 86.641 40.9501 86.6798 40.7949 86.7962C39.9801 87.3976 39.0101 87.708 38.0013 87.708C36.9924 87.708 36.0224 87.3976 35.2076 86.7962C35.0524 86.6798 34.839 86.6216 34.645 86.6604C33.093 86.8738 31.6961 87.7468 30.8231 89.0661C30.6485 89.3183 30.4933 89.6093 30.3769 89.8809C30.3187 89.9973 30.3381 90.1331 30.3963 90.2495C30.5515 90.5211 30.7455 90.7927 30.9201 91.0255C31.1917 91.3942 31.4827 91.7239 31.8126 92.0343C32.0841 92.3059 32.3945 92.5581 32.705 92.8103C34.2376 93.955 36.0806 94.5564 37.9819 94.5564C39.8831 94.5564 41.7262 93.955 43.2588 92.8103C43.5692 92.5776 43.8796 92.3059 44.1512 92.0343C44.4616 91.7239 44.772 91.3941 45.0436 91.0255C45.2376 90.7733 45.4122 90.5211 45.5674 90.2495C45.6644 90.1331 45.6838 89.9973 45.6256 89.8809Z"
          fill="#0F7E99"
        />
      </g>
      <rect x="57" y="78" width="126" height="16" rx="8" fill="#F0F4F7" />
      <defs>
        <clipPath id="clip0_1160_13263">
          <rect
            width="17.1111"
            height="17.1111"
            fill="white"
            transform="translate(13.4443 13.4453)"
          />
        </clipPath>
        <clipPath id="clip1_1160_13263">
          <rect
            width="17.1111"
            height="17.1111"
            fill="white"
            transform="translate(21.4443 45.4453)"
          />
        </clipPath>
        <clipPath id="clip2_1160_13263">
          <rect
            width="17.1111"
            height="17.1111"
            fill="white"
            transform="translate(29.4443 77.4453)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
