import { Box, Divider, Flex, IconButton, Link, Text } from "@chakra-ui/react";

import FacebookIcon from "../assets/icons/facebook";
import InstagramIcon from "../assets/icons/instagram";
import LogoIcon from "../assets/icons/logo";
import TwitterIcon from "../assets/icons/twitter";
import { DOCS_TERMS_OF_SERVICE } from "../config/docs";
import { useResposiveness } from "../hooks/responsiveness";

const path = {
  instagram: "https://www.instagram.com/appisurf/",
};

function SocialButtons() {
  const redirect = (option: keyof typeof path) => {
    if (path[option]) window.open(path[option], "_blank");
  };

  return (
    <Flex direction="row" alignItems="flex-start">
      <IconButton
        aria-label="insta-icon"
        variant="unstyled"
        icon={<InstagramIcon />}
        onClick={() => redirect("instagram")}
      />

      <IconButton
        aria-label="insta-icon"
        variant="unstyled"
        icon={<FacebookIcon />}
      />

      <IconButton
        aria-label="insta-icon"
        variant="unstyled"
        icon={<TwitterIcon />}
      />
    </Flex>
  );
}

export default function Footer({ ...props }: any) {
  const { isMobile, isDesktop } = useResposiveness();

  return (
    <Box
      as="footer"
      padding={isMobile ? "32px 16px" : "56px 80px 37px 80px"}
      bg="neutral.light"
      mt={isMobile ? "70px" : "200px"}
      zIndex={10000}
      {...props}
    >
      <Flex direction="row" justify="space-between" alignItems="center">
        <Box>
          <LogoIcon />
        </Box>
        {isMobile && <SocialButtons />}
      </Flex>
      <Divider my={6} />

      <Flex direction="row" justifyContent="space-between">
        <Flex direction="row" alignItems="center">
          <Text fontSize="xs" color="neutral.darkset">
            © 2020 iSurf ·
          </Text>
          <Link
            fontWeight="semibold"
            fontSize="xs"
            color="neutral.darkset"
            textDecorationLine="underline"
            ml={2}
            cursor="pointer"
            href={DOCS_TERMS_OF_SERVICE}
            target="_blank"
            download
          >
            Termos de serviços
          </Link>
        </Flex>

        {isDesktop && <SocialButtons />}
      </Flex>
    </Box>
  );
}
