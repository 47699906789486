import { ComponentStyleConfig } from "@chakra-ui/react";

const PinInputTheme: ComponentStyleConfig = {
  baseStyle: {
    fontFamily: "body",
    fontWeight: "medium",
    fontSize: "xs",
    color: "neutral.darkest",
    
    "&.chakra-input": {
      minHeight: "56px",
    },

    "::placeholder": {
      fontSize: "xs",
      justifyContent: "center",
      verticalAlign: "middle",
    },

    ":focus-visible": {
      borderColor: "brand.medium !important",
      boxShadow: "none !important",
    },

    "&[aria-invalid=true]": {
      borderColor: "feedback.danger.medium",
      color: "feedback.danger.dark",
      boxShadow: "none !important",

      "::placeholder": {
        color: "feedback.danger.dark",
        opacity: "0.74",
      },
    },
  },
  sizes: {
    lg: {
      fontSize: "lg",
      w: 12,
      h: 12,
      borderRadius: "md",
    },
    md: {
      fontSize: "md",
      w: 10,
      h: 10,
      borderRadius: "md",
    },
    sm: {
      fontSize: "sm",
      w: 8,
      h: 8,
      borderRadius: "sm",
    },
    xs: {
      fontSize: "xs",
      w: 6,
      h: 6,
      borderRadius: "sm",
    },
  },
  variants: {
    outline: {
      field: {
        border: "hairline",
        borderColor: "neutral.medium",
      },
    },
  },
  defaultProps: {
    variant: "outline",
    size: "xs",
  },
};

export default PinInputTheme;
