import { useCallback, useEffect, useState } from "react";

import * as storage from "../services/storage";

export default function useLocalStorage<T = any>(
  key: string,
  defaultValue: any
): [T, (value: T) => void] {
  const [value, setValue] = useState<T>(() => storage.load(key, defaultValue));

  const updateValue = useCallback(
    (newValue: T) => {
      setValue(newValue);
      storage.save(key, newValue);
    },
    [key]
  );

  useEffect(() => {
    storage.save(key, value);
  }, [key, value, defaultValue]);

  return [value, updateValue];
}
