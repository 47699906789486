import Lottie from "react-lottie";

import { Heading, Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";

import animationData from "../../assets/animations/isurf_loader.json";
import { useLoader } from "../../hooks/loader";
import { useResposiveness } from "../../hooks/responsiveness";



export default function FullLoader({ asComponent }: any) {
  const { isMobile } = useResposiveness();
  const { isOpen, stop } = useLoader();

  return (
    <Modal
      isOpen={asComponent ? true : isOpen}
      closeOnOverlayClick={false}
      onClose={stop}
      size={isMobile ? "full" : "2xl"}
      isCentered
    >
      <ModalOverlay
        opacity={isMobile ? "0.98 !important" : "0.80 !important"}
      />
      <ModalContent
        shadow="none"
        bg="transparent"
        pt={-8}
        maxW="100vw"
        w="100%"
        cursor="wait"
      >
        <Heading
          fontSize="3xl"
          color="neutral.lightest"
          textAlign="center"
          mt={8}
          mb={-16}
        >
          Serie vindo...
        </Heading>

        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData,
          }}
          isClickToPauseDisabled
          height={isMobile ? "80vh" : "100vh"}
          width={isMobile ? "80vw" : "100vw"}
          style={{
            cursor: "wait",
          }}
        />
      </ModalContent>
    </Modal>
  );
}
